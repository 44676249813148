import { Input } from 'components';
import React from 'react';
import { useTicketPurchaseForm } from '../hooks';
import { TempTicket } from '../models';

interface Props {
  event: EventInfo;
  tempTickets: TempTicket[];
  addTicket: (ticket: TempTicket) => void;
}

export const TicketPurchaseForm: ReactFC<Props> = ({
  event,
  tempTickets,
  addTicket,
}) => {
  const {
    loading,
    form,
    maxTicketsBought,
    ticketLimitReached,
    getTicketPrice,
    handleAddTicket,
  } = useTicketPurchaseForm(tempTickets, event, addTicket);
  const { state, validation, update } = form;

  return (
    <>
      <div className="purchase-tickets__form">
        <div>
          <label
            htmlFor="ticket-type-select"
            className="t-small field__lbl t-faded s-bottom--tny"
          >
            Ticket type
          </label>
          <div className="f f--column g-16">
            {event.ticketTypes.map((type, i) => (
              <React.Fragment key={i}>
                <div
                  className="f f--align-center pointer u-width--content"
                  onClick={() => {
                    update('type', type.name);
                    update('price', getTicketPrice(type.name));
                  }}
                >
                  <input
                    name="ticketType"
                    type="radio"
                    className="purchase-tickets__input--radio"
                    id={`filter-${type.name}-${i}`}
                    disabled={maxTicketsBought || ticketLimitReached}
                    checked={state.type === type.name}
                    onChange={e => {
                      update('type', e.target.value);
                      update('price', getTicketPrice(e.target.value));
                    }}
                    value={type.name}
                  />
                  <div className="f f--align-center g-8 t-base pointer s-left--med">
                    <span className="t-bold t-text-1"> {type.name}</span>{' '}
                    <span className="t-text-3">
                      (
                      {new Intl.NumberFormat('en-AU', {
                        style: 'currency',
                        currency: 'AUD',
                      }).format(type.price)}
                      )
                    </span>
                  </div>
                  {(maxTicketsBought || ticketLimitReached) &&
                    type.name === state.type && (
                      <p className="t-tiny u--desktop--only s-left--lrg purchase-tickets__limit__container">
                        Ticket limit reached{' '}
                      </p>
                    )}
                </div>
                {(maxTicketsBought || ticketLimitReached) &&
                  type.name === state.type && (
                    <p className="t-tiny u--mobile--only purchase-tickets__limit__container">
                      Ticket limit reached{' '}
                    </p>
                  )}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div>
          <div className="u-maxwidth--464__responsive">
            <span className="t-small field__lbl t-faded s-bottom--tny">
              Ticket holder info
            </span>
            <Input
              disabled={maxTicketsBought || ticketLimitReached}
              label="Name"
              onChange={value => update('name', value)}
              value={state.name}
              placeholder="Enter holder name"
            />
            {validation.name === false && (
              <p className="t-warning t-small">
                We need a name to assign the ticket to.
              </p>
            )}
          </div>

          <div className="u-maxwidth--464__responsive s-top--med">
            <Input
              disabled={maxTicketsBought || ticketLimitReached}
              label="Email"
              onChange={value => update('email', value)}
              value={state.email}
              placeholder="Enter holder email"
            />
            {validation.email === false && (
              <p className="t-warning t-small">
                The email you entered is not valid.
              </p>
            )}
          </div>
        </div>

        <div className="purchase-tickets__form-footer">
          <span className="f f--align-center s-right--lrg">
            <p className="t-base t-text-3 s-right--med">Subtotal</p>
            <p className="t-medium t-bold t-text-2">
              {' '}
              {new Intl.NumberFormat('en-AU', {
                style: 'currency',
                currency: 'AUD',
              }).format(state.price)}
            </p>
          </span>
          <button
            type="button"
            className="button button--outline-primary button--medium"
            onClick={handleAddTicket}
            disabled={loading || maxTicketsBought || ticketLimitReached}
          >
            Add ticket
          </button>
        </div>
      </div>
    </>
  );
};
