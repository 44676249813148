import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RoleCheck, Role } from 'modules/authorization';
import { useAuthentication } from 'modules/authentication';

interface Props {
  desktopOnly?: boolean;
}

export const DashboardHeader: ReactFC<Props> = ({ desktopOnly }) => {
  const { user } = useAuthentication();
  const location = useLocation();

  return (
    <header className={`dashboard-header ${desktopOnly ? 'desktop-only' : ''}`}>
      <div className="wrapper">
        <div className="dashboard-header__person">
          {user && (
            <img
              alt="profile"
              src={
                user.photoURL || require('assets/images/placeholder-square.png')
              }
              className="avatar avatar--profile"
            />
          )}
          <div>
            <p className="t-gamma t-negative t-extrabold">
              Hi, {user && user.displayName}
            </p>
            <p className="dashboard-header__pick-up t-negative t-zeta s-top--sml desktop-only">
              Pick up where you left off
            </p>
          </div>
        </div>
        <p className="dashboard-header__pick-up t-negative t-small s-top--sml mobile-only">
          Pick up where you left off
        </p>
      </div>
      <div className="dashboard-header__nav__container wrapper">
        <ul className="dashboard-header__nav t-bold">
          <li>
            <NavLink
              className={({ isActive }) =>
                `dashboard-header__nav__item ${isActive ? 'active' : ''}`
              }
              to="/dashboard"
            >
              Overview
            </NavLink>
          </li>
          <RoleCheck role={Role.TeamLeader}>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `dashboard-header__nav__item ${isActive ? 'active' : ''}`
                }
                to={`/team`}
              >
                Team
              </NavLink>
            </li>
          </RoleCheck>
          <RoleCheck role={Role.Administrator}>
            <li>
              <NavLink
                to={'/users'}
                className={({ isActive }) =>
                  `dashboard-header__nav__item ${isActive ? 'active' : ''}`
                }
              >
                Users
              </NavLink>
            </li>
          </RoleCheck>

          <li>
            <NavLink
              className={({ isActive }) =>
                `dashboard-header__nav__item ${isActive ? 'active' : ''}`
              }
              to="/tickets"
            >
              Tickets
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                `dashboard-header__nav__item ${
                  isActive || location.pathname.includes('playlists')
                    ? 'active'
                    : ''
                }`
              }
              to="/audio-playlists"
            >
              Playlists
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                `dashboard-header__nav__item ${isActive ? 'active' : ''}`
              }
              to="/shared-items"
            >
              Shared&nbsp;items
            </NavLink>
          </li>
          <RoleCheck role={Role.Administrator}>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `dashboard-header__nav__item ${isActive ? 'active' : ''}`
                }
                to="/reports"
              >
                Reports
              </NavLink>
            </li>
          </RoleCheck>
          <RoleCheck role={Role.TeamLeader}>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `dashboard-header__nav__item ${isActive ? 'active' : ''}`
                }
                to="/event-stats"
              >
                Event stats
              </NavLink>
            </li>
          </RoleCheck>
        </ul>
      </div>
    </header>
  );
};
