import { useAuthentication } from 'modules/authentication';
import { callable } from 'modules/firebase';
import { useSubscription } from 'modules/subscription';
import { useTeams } from 'modules/teams';
import { toast } from 'react-toastify';
import { isLocalHost } from 'utils';

interface StripeCheckoutData {
  tickets: TempTicket[];
  email?: string;
  event: EventInfo;
}

export function useStripeCheckout() {
  const callableFn = callable<CreateCheckoutData, StripeCheckoutSession>(
    'createCheckoutSession',
  );
  const { user } = useAuthentication();
  const { teams } = useTeams();
  const { subscription } = useSubscription();

  async function createCheckout({ tickets, email, event }: StripeCheckoutData) {
    if (!callableFn || !tickets?.length || !user) return;
    try {
      const team = teams.find(team => team.id === user.teamId);
      const { data } = await callableFn({
        tickets,
        email,
        purchasedByTeamName: team?.name || null,
        purchasedByName: user?.displayName,
        eventId: event.id,
        userId: user.id,
        isLocalHost: isLocalHost(),
        customerId: subscription?.stripe?.customerId,
        eventName: event?.title || '',
      });

      if (!data.success) throw new Error(data.message);
      return data.payload;
    } catch (error) {
      console.error(error);
      toast.error(
        'Something went wrong. Please try again later or contact support.',
      );
      return;
    }
  }

  return {
    createCheckout,
  };
}
