import { ACLType } from 'modules/authorization';
import * as views from 'views';

import { AppRoute } from '../models';

const authenticatedRoutes: AppRoute[] = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    path: '/dashboard',
    access: ACLType.Authenticated,
    Element: views.Dashboard,
  },
  {
    key: 'events',
    title: 'Events',
    path: '/events',
    access: ACLType.Authenticated,
    Element: views.Events,
  },
  {
    key: 'event-details',
    access: ACLType.Authenticated,
    Element: views.EventDetails,
    title: 'Event details',
    path: '/events/:id',
  },
  {
    key: 'events-history',
    title: 'Events history',
    path: '/events-history',
    access: ACLType.Authenticated,
    Element: views.Events,
  },
  {
    title: 'Event history details',
    path: '/events-history/:id',
    key: 'event-history-details',
    access: ACLType.Authenticated,
    Element: views.EventDetails,
  },
  {
    key: 'buy-tickets',
    title: 'Buy tickets',
    path: '/buy-tickets/:id',
    access: ACLType.Authenticated,
    Element: views.BuyTickets,
  },
  {
    key: 'payment-success',
    title: 'Payment success',
    path: '/payment-success',
    access: ACLType.Authenticated,
    Element: views.PaymentSuccess,
  },
  {
    key: 'payment-failure',
    title: 'Payment failure',
    path: '/payment-failure',
    access: ACLType.Authenticated,
    Element: views.PaymentFailure,
  },
  {
    key: 'videos',
    access: ACLType.Authenticated,
    Element: views.VideoCategories,
    title: 'Videos',
    path: '/videos/*',
  },
  {
    key: 'video-category-details',
    access: ACLType.Authenticated,
    Element: views.Videos,
    title: 'Video category details',
    path: '/videos/:categoryName/*',
  },
  {
    key: 'video-details',
    access: ACLType.Authenticated,
    Element: views.VideoDetails,
    title: 'Video details',
    path: '/videos/:categoryName/:id',
  },
  {
    key: 'audios',
    access: ACLType.Authenticated,
    Element: views.AudioCategories,
    title: 'Audios',
    path: '/audios',
  },
  {
    key: 'audio-category-details',
    access: ACLType.Authenticated,
    Element: views.Audios,
    title: 'Audio category details',
    path: '/audios/:categoryName',
  },
  {
    key: 'audio-details',
    access: ACLType.Authenticated,
    Element: views.AudioDetails,
    title: 'Audio details',
    path: '/audios/:categoryName/:id',
  },
  {
    key: 'team',
    access: ACLType.TeamLeadersAndAdmin,
    Element: views.Team,
    title: 'Team details',
    path: '/team/:id?/*',
  },
  {
    key: 'team-members',
    access: ACLType.TeamLeadersAndAdmin,
    Element: views.TeamMembers,
    title: 'Team details',
    path: '/team/:id/members',
  },
  {
    key: 'audio-playlists',
    access: ACLType.Authenticated,
    Element: views.AudioPlaylists,
    title: 'Playlists',
    path: '/audio-playlists',
  },
  {
    key: 'audio-playlists-details',
    access: ACLType.Authenticated,
    Element: views.AudioPlaylists,
    title: 'Audio Playlist',
    path: '/audio-playlists/:playlist',
  },
  {
    key: 'audio-playlists-item',
    access: ACLType.Authenticated,
    Element: views.AudioDetails,
    title: 'Audio Playlist Details',
    path: '/audio-playlists/:playlist/:id',
  },
  {
    key: 'video-playlists',
    access: ACLType.Authenticated,
    Element: views.VideoPlaylists,
    title: 'Playlists',
    path: '/video-playlists',
  },
  {
    key: 'video-playlists-details',
    access: ACLType.Authenticated,
    Element: views.VideoPlaylists,
    title: 'Video Playlists',
    path: '/video-playlists/:playlist',
  },
  {
    key: 'video-playlists-item',
    access: ACLType.Authenticated,
    Element: views.VideoDetails,
    title: 'Video Playlist Details',
    path: '/video-playlists/:playlist/:id',
  },
  {
    key: 'tickets-overview',
    access: ACLType.Authenticated,
    Element: views.DashboardTicketOverview,
    title: 'Your tickets',
    path: '/tickets',
  },
  {
    key: 'tickets',
    access: ACLType.Authenticated,
    Element: views.DashboardTicketDetails,
    title: 'Event tickets',
    path: '/tickets/:id',
  },
  {
    key: 'shared-items',
    access: ACLType.Authenticated,
    Element: views.SharedItems,
    title: 'Shared items',
    path: '/shared-items',
  },
  {
    key: 'profile',
    access: ACLType.Authenticated,
    Element: views.Profile,
    title: 'User Profile',
    path: '/profile',
  },
  {
    key: 'subscription',
    access: ACLType.Authenticated,
    Element: views.Subscription,

    title: 'Subscription',
    path: '/subscription',
  },
  {
    key: 'subscription-success',
    access: ACLType.Authenticated,
    Element: views.SubscriptionSuccess,

    title: 'Subscription',
    path: '/subscription-success',
  },
  {
    key: 'event-stats',
    access: ACLType.TeamLeadersAndAdmin,
    Element: views.EventStatsList,
    title: 'Event stats',
    path: '/event-stats',
  },
  {
    key: 'event-stats-details',
    access: ACLType.TeamLeadersAndAdmin,
    Element: views.EventStatsOverview,
    title: 'Event stats',
    path: '/event-stats/:id',
  },
  {
    key: 'member-profile',
    access: ACLType.TeamLeadersAndAdmin,
    Element: views.DashboardMemberDetails,
    title: 'Member profile',
    path: '/member-profile/:memberId',
  },
  {
    key: 'users-list',
    access: ACLType.AdministratorOnly,
    Element: views.DashboardUsers,
    title: 'Users list',
    path: '/users',
  },
  {
    key: 'subscription-management',
    access: ACLType.Authenticated,
    Element: views.SubscriptionManagement,
    title: 'Subscription management',
    path: '/manage-subscription',
  },
  {
    key: 'dashboard-reports',
    access: ACLType.AdministratorOnly,
    Element: views.DashboardReports,
    title: 'Reports',
    path: '/reports',
  },
  {
    key: 'unassigned-users-reports',
    access: ACLType.AdministratorOnly,
    Element: views.UnassignedUsersReports,
    title: 'Unassigned users reports',
    path: '/reports/unassigned-users',
  },
];

export default authenticatedRoutes;
