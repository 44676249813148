import { orderBy, where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';
import moment from 'moment';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { SeenNotification, TmakNotification } from '../models';
import { NotificationAtoms } from '../recoil';

const Notifications = new FirestoreService<TmakNotification>(
  Collection.Notifications,
);

const SeenNotifications = new FirestoreService<SeenNotification>(
  Collection.SeenNotifications,
);

/** 15 day minimum date limit for notifications */
const MIN_DATE = moment(new Date())
  .add(-15, 'days')
  .toDate()
  .getTime();

export function useNotificationsListener() {
  const { isLoggedIn, user } = useAuthentication();
  const setLoading = useSetRecoilState(NotificationAtoms.loading);
  const setSeen = useSetRecoilState(NotificationAtoms.seen);
  const setNotifications = useSetRecoilState(NotificationAtoms.notifications);

  /** Load notifications and listen */
  useEffect(() => {
    if (!isLoggedIn) return;

    setLoading(true);

    const listener = Notifications.listen({
      onSuccess: setNotifications,
      onError: error => console.log('Error loading notifications: ', error),
      onFinished: () => setLoading(false),
      filters: [
        where('timeCreated', '>=', MIN_DATE),
        orderBy('timeCreated', 'desc'),
      ],
    });

    return listener;
  }, [isLoggedIn]);

  /** Load seen notifications and listen */
  useEffect(() => {
    if (!user?.id) return;

    const listener = SeenNotifications.listen({
      onSuccess: setSeen,
      onError: error =>
        console.log('Error loading seen notifications: ', error),
      filters: [
        where('notificationCreatedTime', '>=', MIN_DATE),
        where('userId', '==', user.id),
        orderBy('notificationCreatedTime', 'desc'),
      ],
    });

    return listener;
  }, [user?.id]);
}
