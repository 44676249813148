import React from 'react';
import { Loading, Search } from 'components';
import {
  AudioCategoryItem,
  useAudioCategoryForm,
  useAudios,
} from 'modules/audios';
import { capitaliseFirstLetter } from 'utils';
import IconEmptyStateAudio from 'assets/empty-states/empty-state-audio.svg';
import { Role, RoleCheck } from 'modules/authorization';
import { useUrlState } from 'hooks';

export const AudioCategories: ReactFC = () => {
  const { categories, loading } = useAudios();
  const { open } = useAudioCategoryForm();
  const [query, setQuery] = useUrlState('search', '');

  const renderAudioCategories = () => {
    if (loading) {
      return (
        <div className="wrapper u-width--full">
          <Loading />
        </div>
      );
    }

    let filtered = [...categories];
    if (query.length) {
      filtered = filtered.filter(c =>
        c.title.toLowerCase().includes(query.toLowerCase()),
      );
    }

    if (!filtered.length) {
      return (
        <div className="wrapper u-width--full">
          <div className="empty-state">
            <img
              className="empty-state__visual"
              alt="empty"
              src={IconEmptyStateAudio}
            />
            <p className="empty-state__label">
              No audio categories available at the moment. Please check again
              later.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="dashboard-grid">
        <AudioCategoryItem key="all" title="All" showOptions={false} />
        {filtered.map((item: AudioCategory) => (
          <AudioCategoryItem
            key={item.id}
            title={item.title || capitaliseFirstLetter(item.id)}
            trackCount={item.trackCount}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <section className="content-page">
        <div className="wrapper">
          <div className="content-page__heading-container">
            <h1 className="content-page__title">Audios</h1>
          </div>
          <div className="content-page__controls">
            <Search
              placeholder="Search categories by name"
              changeCallback={setQuery}
              query={query}
            />

            <div className="content-page__controls-actions">
              <RoleCheck role={Role.Administrator}>
                <button
                  className="button button--primary button--medium"
                  type="button"
                  onClick={open}
                >
                  + New category
                </button>
              </RoleCheck>
            </div>
          </div>
        </div>
      </section>

      <section className="content-page__body">
        <div className="wrapper">{renderAudioCategories()}</div>
      </section>
    </>
  );
};
