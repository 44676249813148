import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Loading } from 'components';
import IconFlag from 'assets/icons/flag.svg';
import IconTicket from 'assets/icons/ticket.svg';
import IconDiamond from 'assets/icons/diamond-team.svg';
import { useEvents } from 'modules/events';

const CurrentYear = new Date().getFullYear();

export const EventStatsCard: React.FC = () => {
  const { events, pastEvents, loadPastEvents, loading } = useEvents();

  const allEvents = useMemo(() => [...events, ...pastEvents], [
    events,
    pastEvents,
  ]);

  const eventsThisYear = allEvents.filter(
    e => new Date(e.startTimestamp).getFullYear() === CurrentYear,
  );

  useEffect(() => {
    loadPastEvents();
  }, []);

  const renderRevenueStats = () => {
    return (
      <div className="dashboard-overview__events-item">
        <span>
          <p className="t-gamma t-bold">
            $
            {eventsThisYear
              .map(e => e.revenue || 0)
              .reduce((count, current) => (count += current), 0)}
          </p>
          <p className="t-zeta t-faded">Revenue</p>
        </span>
        <img
          className="dashboard-sidebar__stats__icon"
          src={IconDiamond}
          alt="Diamond"
        />
      </div>
    );
  };

  const renderUpcomingEventCount = () => {
    return (
      <div className="dashboard-overview__events-item">
        <span>
          <p className="t-gamma t-bold">{events.length}</p>
          <p className="t-zeta t-faded">Upcoming</p>
        </span>
        <img
          className="dashboard-sidebar__stats__icon"
          src={IconFlag}
          alt="Flag icon"
        />
      </div>
    );
  };

  const renderTicketsSold = () => {
    return (
      <div className="dashboard-overview__events-item">
        <span>
          <p className="t-gamma t-bold">
            {eventsThisYear
              .map(e => e.ticketsSold || 0)
              .reduce((count, current) => (count += current), 0)}
          </p>
          <p className="t-zeta t-faded">Tickets sold</p>
        </span>
        <img
          className="dashboard-sidebar__stats__icon"
          src={IconTicket}
          alt="Ticket icon"
        />
      </div>
    );
  };

  return (
    <div className="dashboard-card">
      <div className="dashboard-card__header">
        <div className="dashboard-card__container f f--center">
          <h3 className="t-zeta t-upper t-bold u-opacity--40">
            Event stats {CurrentYear}
          </h3>
          <Link to="/event-stats" className="t-zeta anchor--secondary">
            View all event stats
          </Link>
        </div>
      </div>

      <div className="dashboard-card__container dashboard-overview__event-stats">
        {loading ? (
          <Loading withoutContainer withoutPadding />
        ) : (
          <>
            {renderRevenueStats()}
            {renderUpcomingEventCount()}
            {renderTicketsSold()}
          </>
        )}
      </div>
    </div>
  );
};
