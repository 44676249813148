import React from 'react';

interface Props {
  photo: any;
  testimonial: string;
  name: string;
  position: string;
}

export const TestimonialsItem: ReactFC<Props> = ({
  photo,
  testimonial,
  name,
  position,
}) => (
  <div className="testimonials__content__container">
    <div
      className="testimonials__content__photo"
      style={{ backgroundImage: 'url(' + photo + ')' }}
    />
    <div className="testimonials__content__copy">
      <p className="testimonials__content__testimonial u-opacity--80">
        {testimonial}
      </p>
      <h1 className="t-zeta t-bold u-opacity--60">{name}</h1>
      <p className="t-theta s-bottom--med u-opacity--40">{position}</p>
    </div>
  </div>
);
