import { DashboardHeader, Loading, PageHeader, PageTitle } from 'components';
import { useAuthentication } from 'modules/authentication';
import { useEvents } from 'modules/events';
import { TicketItem, useTickets } from 'modules/tickets';
import React from 'react';
import { useParams } from 'react-router';
import { getDisplayDateTime } from 'utils';

export const DashboardTicketDetails: ReactFC = ({ ...props }) => {
  const { id } = useParams();
  const { user } = useAuthentication();
  const { events, loading } = useEvents();
  const { purchased, held, loadingHeld, loadingPurchased } = useTickets();
  const loadingTickets = loadingHeld || loadingPurchased;

  if (!user) return null;

  const getTicketsByEventId = () => {
    const eventHeldIds = held.filter(t => t.eventId === id).map(t => t.id);
    const eventPurchasedIds = purchased
      .filter(t => t.eventId === id)
      .map(t => t.id);
    const ticketIds = eventHeldIds.concat(eventPurchasedIds);
    const uniqueIds = Array.from(new Set(ticketIds));

    const results: Ticket[] = [];
    for (const id of uniqueIds) {
      const ticket =
        purchased.find(t => t.id === id) || held.find(t => t.id === id);
      if (ticket) results.push(ticket);
    }

    return results;
  };

  const getEventInfoById = () => {
    return events.find(e => e.id === id);
  };

  const renderEventInfo = () => {
    if (loading) {
      return <Loading />;
    }

    const event = getEventInfoById();
    if (!event) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="dashboard-sidebar__item dashboard-sidebar__item--sml">
          <h1 className="dashboard-events__title t-extrabold">{event.title}</h1>
        </div>
        <div className="dashboard-sidebar__item">
          <p className="event-item__time s-bottom--tny u-opacity--60">
            {getDisplayDateTime(event.startDateRaw)} &mdash; {getDisplayDateTime(event.endDateRaw)}
          </p>
        </div>
      </React.Fragment>
    );
  };

  const renderEventTickets = () => {
    if (loadingTickets || loading) {
      return <Loading />;
    }

    const event = getEventInfoById();

    if (!event) {
      return;
    }

    const tickets = getTicketsByEventId();

    return (
      <div className="dashboard-tickets__list">
        {tickets.map(t => (
          <TicketItem ticket={t} key={t.id} />
        ))}
      </div>
    );
  };

  const event = getEventInfoById();
  return (
    <React.Fragment>
      <DashboardHeader desktopOnly={true} />
      {event && (
        <PageHeader mobileOnly={true} back={true}>
          <PageTitle>Your tickets</PageTitle>
        </PageHeader>
      )}
      <div className="wrapper">
        <div className="dashboard-content">
          <div className="dashboard-content__sidebar dashboard-content__sidebar--narrow dashboard-content__sidebar--is-white">
            <div className="dashboard-content__inner dashboard-content__inner--sidebar">
              <div className="dashboard-sidebar__item desktop-only">
                <div className="dashboard-sidebar__title">
                  <h1 className="t-gamma t-bold u-opacity--20">Your tickets</h1>
                </div>
              </div>
              {renderEventInfo()}
            </div>
          </div>
          <div className="dashboard-content__main dashboard-content__main--wide">
            <div className="dashboard-content__inner dashboard-content__inner--main">
              {renderEventTickets()}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
