import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { Navigation } from './Navigation';

const excludedPaths = [
  '/events',
  '/audios',
  '/videos',
  '/contact',
  '/buy-tickets',
  '/payment-success',
  '/payment-failure',
];

export const Header: ReactFC = () => {
  const location = useLocation();
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    if (menuVisible) setMenuVisible(false);
  }, [location.pathname]);

  useEffect(() => {
    if (menuVisible) {
      document.body.classList.add('is-locked');
      document.documentElement?.classList.add('is-locked');
    } else {
      document.body.classList.remove('is-locked');
      document.documentElement?.classList.remove('is-locked');
    }
  }, [menuVisible]);

  const altStyle = useMemo(
    () => !excludedPaths.some(path => location.pathname.includes(path)),
    [location.pathname],
  );

  return (
    <header className="header">
      <div className="wrapper">
        <Navigation
          isMenuToggled={menuVisible}
          currentRoute={location.pathname}
        />

        <button
          onClick={() => setMenuVisible(!menuVisible)}
          className={`hamburger hamburger--elastic ${
            menuVisible ? 'is-active' : ''
          } ${altStyle ? 'hamburger--alt' : ''}`}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
    </header>
  );
};
