import {
  DocumentData,
  DocumentReference,
  WriteBatch,
  writeBatch,
} from 'firebase/firestore';
import { FirebaseService } from '../services';

export class SafeBatch<T> {
  private counter = 0;
  private index = 0;
  private batches: WriteBatch[] = [writeBatch(FirebaseService.Firestore)];

  private reset = () => {
    this.counter = 1;
    this.index = 0;
    this.batches = [writeBatch(FirebaseService.Firestore)];
  };

  private batch = () => {
    if (this.counter < 499) {
      this.counter = this.counter + 1;
      return this.batches[this.index];
    } else {
      const batch = writeBatch(FirebaseService.Firestore);
      this.batches.push(batch);
      this.counter = 1;
      this.index = this.index + 1;
      return this.batches[this.index];
    }
  };

  public set = (ref: DocumentReference<DocumentData>, data: Partial<T>) => {
    this.batch().set(ref, { ...data, id: ref.id }, { merge: true });
  };

  public update = <Type = T>(
    ref: DocumentReference<DocumentData>,
    data: Partial<Type>,
  ) => {
    this.batch().update(ref, { ...data, id: ref.id });
  };

  public remove = (ref: DocumentReference<DocumentData>) => {
    this.batch().delete(ref);
  };

  public commit = async () => {
    for (const batch of this.batches) {
      await batch.commit();
    }
    this.reset();
  };
}
