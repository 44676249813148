export const filterEvents = (
  events: EventInfo[],
  fromTimestamp: number | null,
  toTimestamp: number | null,
  query: string,
) => {
  let results = events;

  if (query.length) {
    results = results.filter(e =>
      e.title.toLowerCase().includes(query.toLowerCase()),
    );
  }

  if (fromTimestamp) {
    results = results.filter(e => e.startTimestamp >= fromTimestamp);
  }

  if (toTimestamp) {
    results = results.filter(e => e.startTimestamp <= toTimestamp);
  }

  return results.sort((a, b) => a.startTimestamp - b.startTimestamp);
};
