import { orderBy, where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import {
  Collection,
  FirestoreService,
  useFirebaseStorage,
} from 'modules/firebase';
import { stringToSlug } from 'modules/helpers';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VideoAtoms } from '../recoil';
import { toast } from 'react-toastify';
import { VideoInfoConstructor, VideoInfoForm } from '../models';
import { Role } from 'modules/authorization';

const VideosCollection = new FirestoreService<VideoInfo>(Collection.Videos);
const VideoCategoriesCollection = new FirestoreService<VideoCategory>(
  Collection.VideoCategories,
);

/** Hook to handle audio and audio category related actions (fetching, updating, tracking progress) */
export function useVideos() {
  const { uploadFile } = useFirebaseStorage();
  const { user } = useAuthentication();
  const [videos, setVideos] = useRecoilState(VideoAtoms.videos);
  const [currentVideo, setCurrentVideo] = useRecoilState(
    VideoAtoms.currentVideo,
  );
  const [lastPlayed, setLastPlayed] = useRecoilState(VideoAtoms.lastPlayed);
  const categories = useRecoilValue(VideoAtoms.categories);
  const [loading, setLoading] = useRecoilState(VideoAtoms.loading);
  const [listeners, setListeners] = useRecoilState(
    VideoAtoms.categoryListeners,
  );

  function getCategoryByTitleSlug(slug: string) {
    return categories.find(category => stringToSlug(category.title) === slug);
  }

  async function loadAllVideos() {
    // All audios are being listened to already
    if (listeners['all']) return;
    setLoading(true);

    // Unsubscribe all other listeners because "all" overrules all of them.
    Object.values(listeners).forEach(listener => {
      if (listener) {
        Array.isArray(listener) ? listener.forEach(l => l()) : listener();
      }
    });

    const categoryIds = categories.map(c => c.id);
    if (user?.role === Role.Administrator) {
      const listener = VideosCollection.listen({
        onError: console.error,
        onFinished: () => setLoading(false),
        onSuccess: items => {
          const map: Record<string, VideoInfo[]> = { all: items };
          for (const category of categoryIds) {
            map[category] = items.filter(a => a.categoryIds.includes(category));
          }

          setVideos(map);
        },
      });

      setListeners({ all: listener });
    } else {
      /** Listener per category */
      const listeners = categoryIds.map(categoryId =>
        VideosCollection.listen({
          onSuccess: items => {
            setVideos(current => ({
              ...current,
              all: [
                ...(current['all'] || []).filter(
                  a => !items.some(i => i.id === a.id),
                ),
                ...items,
              ],
              [categoryId]: items,
            }));
          },
          onError: console.error,
          filters: [
            where('categoryIds', 'array-contains', categoryId),
            where('status', '==', 'published'),
          ],
        }),
      );

      /** Listener for unassigned audios */
      listeners.push(
        VideosCollection.listen({
          filters: [
            where('categoryIds', '==', []),
            where('status', '==', 'published'),
          ],
          onSuccess: items => {
            setVideos(current => ({
              ...current,
              all: [...(current['all'] || []), ...items],
            }));
          },
          onError: console.error,
        }),
      );

      setListeners({ all: listeners });
      setLoading(false);
    }
  }

  async function loadCategoryVideos(categoryId: string) {
    if (listeners[categoryId] || listeners['all']) return;
    setLoading(true);

    const listener = VideosCollection.listen({
      onError: console.error,
      onFinished: () => setLoading(false),
      onSuccess: items =>
        setVideos(audios => ({ ...audios, [categoryId]: items })),
      filters:
        user?.role === 'ADMIN'
          ? [
              where('categoryIds', 'array-contains', categoryId),
              orderBy('timeCreated', 'desc'),
            ]
          : [
              where('categoryIds', 'array-contains', categoryId),
              where('status', '==', 'published'),
              orderBy('timeCreated', 'desc'),
            ],
    });

    setListeners({ ...listeners, [categoryId]: listener });
  }

  async function getVideoById(videoId: string) {
    const audiosMap = Object.values(videos).flat() || [];
    return (
      audiosMap.find(a => a.id === videoId) ||
      (await VideosCollection.getById(videoId))
    );
  }

  async function loadLastPlayed() {
    if (!user?.videoStats) return;

    const last3Ids = Array.from(
      new Set([...user.videoStats].reverse().map(stats => stats.trackId)),
    ).slice(0, 3);

    if (!last3Ids.length) return;

    /** If last played audios are different to the ones stored or none are loaded, load audios. */
    if (
      JSON.stringify(last3Ids) !==
      JSON.stringify(lastPlayed.map(audio => audio.id))
    ) {
      try {
        setLoading(true);
        const promises = last3Ids.map(VideosCollection.getById);
        const list = await Promise.all(promises);
        const filtered = list.filter(item => item !== undefined) as VideoInfo[];

        setLastPlayed(filtered);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  }

  function removeCategory(id: string) {
    return VideoCategoriesCollection.remove(id);
  }

  async function publishVideo(video: VideoInfo) {
    try {
      toast.info('Publishing video', { toastId: 'video-publish' });

      await VideosCollection.set(video.id, { status: 'published' });
      if (currentVideo?.id === video.id)
        setCurrentVideo({ ...video, status: 'published' });

      toast.dismiss('video-publish');
      toast.success('Video has been published.');
    } catch (error) {
      console.error(error);
      toast.dismiss('video-publish');
      toast.error(
        error instanceof Error ? error.message : 'Something went wrong.',
      );
    }
  }

  async function addVideo(data: VideoInfoForm) {
    const thumbnail = data.thumbnailFile
      ? await uploadFile('thumbnails', data.thumbnailFile)
      : null;

    const document = new VideoInfoConstructor(data, thumbnail);
    document.timeCreated = Date.now();

    return VideosCollection.add(document);
  }

  async function updateVideo(id: string, data: VideoInfoForm) {
    const thumbnail = data.thumbnailFile
      ? await uploadFile('thumbnails', data.thumbnailFile)
      : null;

    const document = new VideoInfoConstructor(data, thumbnail);
    return VideosCollection.set(id, document).then(() => {
      if (currentVideo?.id === id)
        setCurrentVideo({ ...currentVideo, ...document });
    });
  }

  function removeVideo(id: string) {
    return VideosCollection.remove(id);
  }

  return {
    loading,
    videos,
    categories,
    lastPlayed,
    currentVideo,
    setCurrentVideo,
    getVideoById,
    getCategoryByTitleSlug,
    loadAllVideos,
    loadCategoryVideos,
    loadLastPlayed,
    removeCategory,
    removeVideo,
    publishVideo,
    addVideo,
    updateVideo,
  };
}
