import { useAuthentication } from 'modules/authentication';
import { Role } from 'modules/authorization';
import { Collection, FirestoreService } from 'modules/firebase';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { TeamAtoms } from '../recoil';
import { useTeams } from './useTeams';

const TeamsCollection = new FirestoreService<Team>(Collection.Teams);

export function useTeamsListener() {
  const { user, isLoggedIn } = useAuthentication();
  const { mapSubteams } = useTeams();
  const [teams, setTeams] = useRecoilState(TeamAtoms.teams);
  const setLoading = useSetRecoilState(TeamAtoms.loading);
  const setUserTeams = useSetRecoilState(TeamAtoms.userTeams);

  useEffect(() => {
    setLoading(true);
    const listener = TeamsCollection.listen({
      onSuccess: setTeams,
      onError: error => console.log('Teams listener error: ', error),
      onFinished: () => setLoading(false),
    });

    return listener;
  }, [isLoggedIn]);

  /** Map by administrator role. */
  useEffect(() => {
    if (!user?.role || user.role !== Role.Administrator) return;
    setUserTeams(teams.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }, [user?.role, teams]);

  /** Fetch by team leader role */
  useEffect(() => {
    if (
      (user?.role !== Role.TeamLeader && user?.role !== Role.DiamondLeader) ||
      !teams.length
    )
      return;

    const parentTeam = teams.find(team => team.teamLeaderId === user.id);
    if (!parentTeam) {
      setUserTeams([]);
      return;
    }

    setUserTeams(
      [parentTeam, ...mapSubteams(parentTeam.id, teams)].sort((a, b) =>
        a.name > b.name ? 1 : -1,
      ),
    );
  }, [user?.role, teams]);
}
