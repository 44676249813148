import React from 'react';
import moment from 'moment';

import { NotificationType, TmakNotification } from '../models';
import IconNotificationAudio from 'assets/icons/nav-audios.svg';
import IconNotificationEvent from 'assets/icons/nav-events-global.svg';
import IconNotificationVideo from 'assets/icons/nav-videos.svg';

interface Props {
  notification: TmakNotification;
  dismissCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Icon: Record<NotificationType, string> = {
  AUDIO: IconNotificationAudio,
  VIDEO: IconNotificationVideo,
  EVENT: IconNotificationEvent,
  BLOG: IconNotificationEvent,
};

export const NotificationItem: ReactFC<Props> = ({
  notification,
  dismissCallback,
}) => (
  <div className="f f--center f--gap">
    <img src={Icon[notification.type]} alt="Notification icon" />
    <div>
      <p className="notification-item__text">{notification.text}</p>
      <p className="notification-item__time">
        {moment(notification.timeCreated).format('lll')}
      </p>
    </div>

    <button
      className="button button--unpadded notification-item__action"
      data-notification-id={notification.id}
      onClick={dismissCallback}
    >
      <svg width="16" height="17">
        <g transform="translate(0 .4161)" fill="none" fillRule="evenodd">
          <circle fill="#31263A" opacity=".2" cx="8" cy="8" r="8" />
          <g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round">
            <path d="M11.5 4.5l-7 7" />
            <path d="M4.5 4.5l7 7" />
          </g>
        </g>
      </svg>
    </button>
  </div>
);
