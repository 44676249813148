import { where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import { Role } from 'modules/authorization';
import { Collection, FirestoreService } from 'modules/firebase';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { TeamAtoms } from '../recoil';

const UserInfoCollection = new FirestoreService<CustomUserInfo>(
  Collection.UserInfo,
);

export function useTeamMembersListener() {
  const { user } = useAuthentication();
  const setUnassignedUsers = useSetRecoilState(TeamAtoms.unassignedUsers);

  useEffect(() => {
    if (!user?.role || user.role === Role.User) return;
    const listener = UserInfoCollection.listen({
      onSuccess: setUnassignedUsers,
      onError: error => console.log('Error loading unassigned users: ', error),
      filters: [where('teamId', '==', null)],
    });

    return listener;
  }, [user?.role]);
}
