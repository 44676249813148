import { useAuthentication } from 'modules/authentication';
import { TeamCore, useTeams } from 'modules/teams';
import { useParams } from 'react-router';

export const Team: ReactFC = () => {
  const { id = '' } = useParams();
  const { user } = useAuthentication();
  const { getTeamById, userTeams } = useTeams();

  /** Admin does not have to have a team ID and should be able to see all teams if no ID is specified. */
  if (user?.role === 'ADMIN') {
    const team = getTeamById(id);
    const subteams = id
      ? userTeams.filter(t => t.parentTeamId === id)
      : userTeams.filter(t => !t.parentTeamId);

    return <TeamCore team={team} subteams={subteams} />;
    /** Team leaders HAVE to have a teamId and can only see children of their team */
  } else {
    const teamId = id || user?.teamId || '';
    const team = getTeamById(teamId);
    const subteams = team
      ? userTeams.filter(t => t.parentTeamId === teamId)
      : [];

    return <TeamCore team={team} subteams={subteams} />;
  }
};
