import {
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  indexedDBLocalPersistence,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth';
import { RegistrationData } from 'modules/authentication';

import { FirebaseService } from './';

export class AuthService {
  public auth = FirebaseService.Auth;

  initAuthListener = (
    onLogin: (credential: User) => void,
    onLogout: () => void,
  ) => {
    return this.auth.onAuthStateChanged(credential => {
      // If credential is not set, trigger onLogout callback
      if (!credential) {
        onLogout();
        return;
      }

      onLogin(credential);
    });
  };

  logoutAsync = () => this.auth.signOut();

  loginWithEmailAndPasswordAsync = async (
    email: string,
    password: string,
    rememberMe: boolean,
  ) => {
    await this.auth.setPersistence(
      rememberMe ? indexedDBLocalPersistence : browserSessionPersistence,
    );

    return signInWithEmailAndPassword(this.auth, email, password);
  };

  registerWithEmailAndPasswordAsync = (data: RegistrationData) =>
    createUserWithEmailAndPassword(this.auth, data.email, data.password);

  sendPasswordResetEmail = (email: string) =>
    sendPasswordResetEmail(this.auth, email);
}
