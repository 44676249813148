import React, { PropsWithChildren, useEffect } from 'react';

import { Header } from 'modules/navigation/components';
import { Footer, ConnectionLost } from 'components';
import { AudioPlayer } from 'modules/audio-player';
import { useLocation } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AudioAtoms, AudioCategoryForm, AudioForm } from 'modules/audios';
import { VideoForm, VideoCategoryForm, VideoAtoms } from 'modules/videos';
import { useRecoilValue } from 'recoil';
import { EventAtoms, EventForm } from 'modules/events';

export const Layout: ReactFC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const audioCategoryFormOpen = useRecoilValue(AudioAtoms.categoryFormOpen);
  const audioFormOpen = useRecoilValue(AudioAtoms.audioFormOpen);
  const videoCategoryFormOpen = useRecoilValue(VideoAtoms.categoryFormOpen);
  const videoFormOpen = useRecoilValue(VideoAtoms.videoFormOpen);
  const eventFormOpen = useRecoilValue(EventAtoms.eventFormOpen);

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  }, [location.pathname]);

  return (
    <React.Fragment>
      <div className="container">
        <Header />
        <main className="container__children">{children}</main>

        <AudioPlayer />

        {audioCategoryFormOpen && <AudioCategoryForm />}
        {audioFormOpen && <AudioForm />}
        {videoCategoryFormOpen && <VideoCategoryForm />}
        {videoFormOpen && <VideoForm />}
        {eventFormOpen && <EventForm />}

        <ConnectionLost />
        <Footer />
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};
