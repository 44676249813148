import React from 'react';
import { Link } from 'react-router-dom';
import {
  getSubscriptionStatus,
  getSubscriptionBadgeClass,
} from 'modules/subscription/helpers';

interface Props {
  member: CustomUserInfo;
  checked?: boolean;
  onSelect?: VoidFunction;
}

export const DashboardMemberItem: ReactFC<Props> = ({
  member,
  checked,
  onSelect,
}) => {
  const label = getSubscriptionStatus(member.subscription);
  const badge = getSubscriptionBadgeClass(label);

  return (
    <div className="dashboard-member-item">
      <Link
        to={`/member-profile/${member.id}`}
        target="_blank"
        className="f f--center"
      >
        <img
          loading="lazy"
          className="member-list__avatar"
          src={
            member.photoURL || require('assets/images/placeholder-member.jpg')
          }
          alt="profile"
        />
        <p className="member-list__name t-bold ">{member.displayName}</p>
        {label && (
          <span className={`${badge} badge--users s-left--med`}>{label}</span>
        )}
      </Link>

      {onSelect && (
        <input
          type="checkbox"
          className="input input--check-default"
          checked={checked}
          onChange={onSelect}
        />
      )}
    </div>
  );
};
