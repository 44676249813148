import React, { useEffect, useState } from 'react';
import {
  AdministrationOptions,
  DeleteModal,
  Loading,
  MediaFilters,
  PageHeader,
  ScrollingContent,
  Search,
} from 'components';
import {
  AudioItem,
  SpecialAccessListModal,
  useAudioCategoryForm,
  useAudioForm,
  useAudios,
} from 'modules/audios';
import { Role, RoleCheck } from 'modules/authorization';
import { EmptyState } from 'modules/dashboard';
import { useNavigate, useParams } from 'react-router';
import { useAuthentication } from 'modules/authentication';
import { PlayedStatusFilter, PublishedFilter, SortFilter } from 'models';
import { useMediaFilters, useUrlState } from 'hooks';
import IconLock from 'assets/icons/lock-secondary.svg';

export const Audios: ReactFC = () => {
  const {
    audios,
    categories,
    loading,
    getCategoryByTitleSlug,
    loadCategoryAudios,
    loadAllAudios,
    removeCategory,
  } = useAudios();
  const navigate = useNavigate();
  const { user } = useAuthentication();
  const { applyFilters } = useMediaFilters('audio');
  const categoryForm = useAudioCategoryForm();
  const audioForm = useAudioForm();
  const { categoryName = '', playlist = '' } = useParams();
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /** Filter results */
  const [query, setQuery] = useUrlState('search', '');
  const [sortFilter, setSortFilter] = useUrlState<SortFilter>('sort', 'newest');
  const [playedFilter, setPlayedFilter] = useUrlState<
    PlayedStatusFilter | undefined
  >('played', undefined);
  const [publishedFilter, setPublishedFilter] = useUrlState<
    PublishedFilter | undefined
  >('status', undefined);

  const category = getCategoryByTitleSlug(categoryName || '');

  /** Load category audios if not loaded already. */
  useEffect(() => {
    /** If no categories have been loaded yet, do nothing. */
    if (!categories.length || !categoryName) return;

    /** If all category, load audios if none are loaded yet. */
    if (categoryName === 'all') {
      document.title = 'TeamMAK | Audios - All';
      loadAllAudios();
    } else {
      /** Get category ID through slug */
      const category = getCategoryByTitleSlug(categoryName);
      if (!category) return;

      document.title = `TeamMAK | Audios - ${category.title}`;
      loadCategoryAudios(category.id);
    }
  }, [categoryName, categories]);

  if (!user) return null;

  const renderAudioItem = (audio: AudioInfo) => {
    return (
      <AudioItem
        key={audio.id}
        user={user}
        audio={audio}
        parentUrl={`/audios/${playlist || categoryName}`}
      />
    );
  };

  const renderAudios = () => {
    if (loading) {
      return (
        <div className="wrapper">
          <Loading />
        </div>
      );
    }

    let filtered = applyFilters(
      [...(audios[category?.id || categoryName] || [])],
      sortFilter,
      playedFilter,
      publishedFilter,
    );

    if (query.length) {
      filtered = filtered.filter(
        item =>
          item.title.toLowerCase().includes(query.toLowerCase()) ||
          item.author.toLowerCase().includes(query.toLowerCase()),
      );
    }

    return (
      <div className="lost-flex-container s-top--med">
        <ScrollingContent
          data={filtered}
          emptyComponent={<EmptyState type="audio" />}
          renderItem={renderAudioItem}
        />
      </div>
    );
  };

  return (
    <>
      <PageHeader back mobileOnly />
      <section className="content-page">
        <div className="wrapper">
          <div className="content-page__heading-container">
            <h1 className="content-page__title">
              {category?.title || categoryName}
            </h1>

            {category && (
              <div className="content-page__heading-options">
                <AdministrationOptions
                  edit={() => categoryForm.edit(category)}
                  remove={() => setShowDeleteModal(true)}
                />
              </div>
            )}
          </div>

          <div className="content-page__controls">
            <Search
              query={query}
              placeholder="Search audios by title or author"
              changeCallback={setQuery}
            />

            <div className="content-page__controls-actions">
              <RoleCheck role={Role.Administrator} exact={true}>
                <button
                  onClick={() => audioForm.open(category?.id)}
                  type="button"
                  className="button button--primary button--medium"
                >
                  + New audio
                </button>
                {category?.isSpecialContent && (
                  <button
                    className="button button--ghost-primary button--medium"
                    onClick={() => setShowAccessModal(true)}
                  >
                    <img src={IconLock} alt="" />
                    Access control
                  </button>
                )}
              </RoleCheck>

              {category && (
                <div className="content-page__controls-options">
                  <AdministrationOptions
                    edit={() => categoryForm.edit(category)}
                    remove={() => setShowDeleteModal(true)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="content-page__body">
        <div className="wrapper">
          <MediaFilters
            sort={sortFilter}
            played={playedFilter}
            published={publishedFilter}
            onPlayedChanged={setPlayedFilter}
            onSortChanged={setSortFilter}
            onPublishedChanged={setPublishedFilter}
          />
          {renderAudios()}
        </div>
      </section>

      <RoleCheck role={Role.Administrator} exact>
        {showDeleteModal && category && (
          <DeleteModal
            title={`Delete ${category?.title}?`}
            description="Are you sure you want to delete this category? This action cannot be undone. Note: Category audios will not be deleted."
            close={() => setShowDeleteModal(false)}
            confirm={() => {
              removeCategory(category?.id);
              setShowDeleteModal(false);
              navigate(-1);
            }}
          />
        )}

        {showAccessModal && category && (
          <SpecialAccessListModal
            categoryId={category.id}
            list={category.usersWithAccess || []}
            close={() => setShowAccessModal(false)}
          />
        )}
      </RoleCheck>
    </>
  );
};
