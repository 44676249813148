import React from 'react';
import { useNavigate } from 'react-router';

export const PermissionError: ReactFC = () => {
  const navigate = useNavigate();

  return (
    <section className="subscription">
      <div className="wrapper">
        <div className="subscription__container">
          <div className="subscription__plans">
            <h1 className="subscription__title t-bold">
              You are not authorized to access this page.
            </h1>
            <p className="subscription__message u-opacity--80"></p>
            <button
              className="button button--medium button--primary"
              onClick={() => navigate(-1)}
            >
              Go back
            </button>
          </div>
          <div className="subscription__visual subscription__visual--success">
            <img
              className="subscription__visual__image"
              src={require('assets/images/subscription-expired.png')}
              alt="Subscription visual"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
