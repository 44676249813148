import React from 'react';
import { Link } from 'react-router-dom';
import { VideoItemSimple } from 'modules/videos';

interface Props {
  user: CustomUserInfo;
  videos: VideoInfo[];
}

export const LastPlayedOrAllVideos: ReactFC<Props> = ({ videos, user }) => {
  return (
    <div className="dashboard-card">
      <div className="dashboard-card__header">
        <div className="dashboard-card__container f f--center">
          <h3 className="t-zeta t-upper t-bold u-opacity--40">
            Last played videos
          </h3>
          <Link to="/videos" className="t-zeta anchor--secondary">
            View all videos
          </Link>
        </div>
      </div>
      <div className="dashboard-card__container dashboard-card__container--media f">
        {videos.length ? (
          videos.map((video: VideoInfo) => {
            const url = '/videos/direct';

            return (
              <VideoItemSimple
                key={video.id}
                video={video}
                parentUrl={url}
                user={user}
              />
            );
          })
        ) : (
          <div className="dashboard-card__empty">
            <p>Your recently played videos will appear here.</p>
          </div>
        )}
      </div>
    </div>
  );
};
