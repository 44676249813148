import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  onSnapshot,
  PartialWithFieldValue,
  query,
  QueryCompositeFilterConstraint,
  QueryConstraint,
  QueryOrderByConstraint,
  setDoc,
} from 'firebase/firestore';
import { Collection } from '../const';

import { getTypedCollectionDocument, getTypedDocument } from '../utils';
import { FirebaseService } from './FirebaseService';

export interface FirestoreCollectionListenParams<SpecificType> {
  onSuccess: (docs: Array<SpecificType>) => void;
  onError: (error?: string) => void;
  onFinished?: VoidFunction;
  filters?: Array<QueryConstraint | QueryCompositeFilterConstraint>;
}

export interface FirestoreDocumentListenParams<SpecificType> {
  /** Path without the top-level collection name (starting from document) */
  path: string;
  onSuccess: (doc: SpecificType | undefined) => void;
  onError: (error?: string) => void;
  onFinished?: VoidFunction;
}

export class FirestoreService<T extends PartialWithFieldValue<DocumentData>> {
  private reference: CollectionReference<DocumentData>;
  private path: Collection;

  constructor(path: Collection) {
    this.path = path;
    this.reference = collection(FirebaseService.Firestore, path);
  }

  public generateDocumentReference = (path?: string) => {
    if (!path) return doc(this.reference);
    return doc(this.reference, path);
  };

  private buildFilteredReference = (
    filters:
      | Array<
          | QueryConstraint
          | QueryCompositeFilterConstraint
          | QueryOrderByConstraint
        >
      | undefined,
  ) => {
    if (!filters) return this.reference;
    // Fix for type issue in Firestore
    return query(this.reference, ...(filters as any));
  };

  public get = async <Type extends PartialWithFieldValue<DocumentData> = T>(
    filters?:
      | Array<QueryConstraint | QueryCompositeFilterConstraint>
      | undefined,
  ) => {
    try {
      const snapshot = await getDocs(this.buildFilteredReference(filters));
      return snapshot.docs.map(doc => getTypedCollectionDocument<Type>(doc));
    } catch (error) {
      throw error;
    }
  };

  public getById = async <Type extends PartialWithFieldValue<DocumentData> = T>(
    id: string,
  ) => {
    try {
      const reference = this.generateDocumentReference(id);
      const document = await getDoc(reference);
      return getTypedDocument<Type>(document);
    } catch (error) {
      throw error;
    }
  };

  public listen = <Type extends PartialWithFieldValue<DocumentData> = T>({
    onError,
    onSuccess,
    onFinished,
    filters,
  }: FirestoreCollectionListenParams<Type>) => {
    const collection = this.buildFilteredReference(filters);
    return onSnapshot(
      collection,
      snapshot => {
        const docs = snapshot.docs.map(doc =>
          getTypedCollectionDocument<Type>(doc),
        );
        onSuccess(docs);
        onFinished?.();
      },
      error => {
        console.error(error);
        onError(error.message);
        onFinished?.();
        throw error;
      },
    );
  };

  public listenToId = <Type extends PartialWithFieldValue<DocumentData> = T>({
    path,
    onSuccess,
    onError,
    onFinished,
  }: FirestoreDocumentListenParams<Type>) => {
    const reference = this.generateDocumentReference(path);
    return onSnapshot(
      reference,
      snapshot => {
        const document = getTypedDocument<Type>(snapshot);
        onSuccess(document);
        onFinished?.();
      },
      error => {
        console.error(error);
        onError(error.message);
        onFinished?.();
      },
    );
  };

  public set = async <Type extends PartialWithFieldValue<DocumentData> = T>(
    id: string,
    data: Type,
  ) => {
    try {
      const document = doc(this.reference, id);
      await setDoc(document, { ...data }, { merge: true });
    } catch (error) {
      throw error;
    }
  };

  public add = async (data: Omit<T, 'id'>) => {
    try {
      const document = this.generateDocumentReference();
      await setDoc(document, { ...data, id: document.id }, { merge: true });
      return document.id;
    } catch (error) {
      throw error;
    }
  };

  public remove = async (id: string | null | undefined) => {
    try {
      if (!id) throw new Error('Document ID not specified.');
      const document = doc(this.reference, id);
      await deleteDoc(document);
    } catch (error) {
      throw error;
    }
  };
}
