import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { stringToSlug } from 'modules/helpers';
import { useVideoCategoryForm, useVideos } from '../hooks';
import { Role, RoleCheck } from 'modules/authorization';
import { AdministrationOptions, DeleteModal } from 'components';
import IconCaretRight from 'assets/icons/caret-right.svg';

interface Props {
  title: string;
  description?: string;
  trackCount?: number;
  showOptions?: boolean;
}

export const VideoCategoryItem: ReactFC<Props> = ({
  title,
  trackCount,
  showOptions = true,
}) => {
  const { getCategoryByTitleSlug, removeCategory } = useVideos();
  const { edit } = useVideoCategoryForm();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const slug = stringToSlug(title);
  const category = getCategoryByTitleSlug(slug);

  function handleEdit() {
    if (!category) return;
    edit(category);
  }

  return (
    <>
      <Link to={`/videos/${slug}`} className="categories-item card f f--column">
        <div className="f f--top f--one">
          <h1 className="categories-item__title title title--card">{title}</h1>
          {showOptions ? (
            <AdministrationOptions
              edit={handleEdit}
              remove={() => setShowDeleteModal(true)}
            />
          ) : (
            <img src={IconCaretRight} alt=">" className="s-right--tny" />
          )}
        </div>
        {!!trackCount && <p className="t-zeta t-faded">{trackCount} tracks</p>}
      </Link>

      <RoleCheck role={Role.Administrator}>
        {showDeleteModal && category && (
          <DeleteModal
            title={`Delete ${category?.title}?`}
            description="Are you sure you want to delete this category? This action cannot be undone. Note: Category videos will not be deleted."
            close={() => setShowDeleteModal(false)}
            confirm={() => {
              removeCategory(category?.id);
              setShowDeleteModal(false);
            }}
          />
        )}
      </RoleCheck>
    </>
  );
};
