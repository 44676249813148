import React from 'react';
import Slider from 'react-slick';

import { TestimonialsItem } from '.';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  arrows: false,
  dotsClass: 'testimonials__slider-dots',
};

export class Testimonials extends React.Component {
  sliderRef: React.RefObject<any> = React.createRef();

  nextSlide = () => {
    this.sliderRef.current.slickNext();
  };

  prevSlide = () => {
    this.sliderRef.current.slickPrev();
  };
  render() {
    return (
      <section className="testimonials">
        <div className="wrapper">
          <div className="testimonials__title__container">
            <h1 className="testimonials__title t-bold s-bottom--lrg">
              TeamMak success stories
            </h1>
          </div>
        </div>
        <div className="testimonials__content">
          <div className="wrapper">
            <Slider {...settings} ref={this.sliderRef}>
              <TestimonialsItem
                photo={require('../../../assets/images/testimonials-augusta-morris.jpg')}
                testimonial="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus efficitur viverra lacus nec gravida. Ut vel diam pharetra mi ultrices faucibus. Morbi non congue nunc. Nunc molestie sollicitudin urna quis tincidunt."
                name="Augusta Morris"
                position="Executive Diamond, Canada"
              />
              <TestimonialsItem
                photo={require('../../../assets/images/testimonials-peter-johnson.jpg')}
                testimonial="Some text fading ipsum dolor sit."
                name="Peter Johnson"
                position="Executive Diamond, Canada"
              />
              <TestimonialsItem
                photo={require('../../../assets/images/testimonials-ashley-spaces.jpg')}
                testimonial="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus efficitur viverra lacus nec gravida. Ut vel diam pharetra mi ultrices faucibus. Morbi non congue nunc. Nunc molestie sollicitudin urna quis tincidunt."
                name="Ashley Spaces"
                position="Executive Diamond, Canada"
              />
            </Slider>
            <div className="testimonials__slider__controls f f--center">
              <button
                className="button  testimonials__slider-arrows testimonials__slider-arrows--left"
                onClick={this.prevSlide}
              >
                <svg
                  className="desktop-only"
                  width="122"
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#09A593"
                    strokeWidth="2"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <path d="M122 9H2" />
                    <path strokeLinecap="square" d="M9 16L2 9l7-7" />
                  </g>
                </svg>
                <svg
                  className="mobile-only"
                  width="54"
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#09A593"
                    strokeWidth="2"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <path d="M54 9H2" />
                    <path strokeLinecap="square" d="M9 16L2 9l7-7" />
                  </g>
                </svg>
              </button>
              <button
                className="button  testimonials__slider-arrows testimonials__slider-arrows--right"
                onClick={this.nextSlide}
              >
                <svg
                  className="desktop-only"
                  width="122"
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#09A593"
                    strokeWidth="2"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <path d="M0 9h120" />
                    <path strokeLinecap="square" d="M113 16l7-7-7-7" />
                  </g>
                </svg>
                <svg
                  className="mobile-only"
                  width="54"
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#09A593"
                    strokeWidth="2"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <path d="M0 9h52" />
                    <path strokeLinecap="square" d="M45 16l7-7-7-7" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
