import { useRecoilValue } from 'recoil';

import { SubscriptionAtoms } from '../recoil';

export function useSubscription() {
  const subscription = useRecoilValue(SubscriptionAtoms.subscription);
  const loading = useRecoilValue(SubscriptionAtoms.loading);
  const subscribed =
    (subscription?.apple?.subscriptionExpirationTime || 0) > Date.now() ||
    (subscription?.stripe?.subscriptionExpirationTime || 0) > Date.now() ||
    subscription?.isLegacySubscription;

  return {
    subscription,
    subscribed,
    loading,
  };
}
