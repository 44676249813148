import { Link } from 'react-router-dom';
import { useTeams } from '../hooks';

interface Props {
  team: Team | undefined;
}

export const TeamBreadcrumbs: React.FC<Props> = ({ team }) => {
  const { userTeams } = useTeams();

  function mapParenthood() {
    if (!team) return [];
    const results: Team[] = [team];

    function mapParent(currentTeam: Team) {
      const parent = userTeams.find(t => t.id === currentTeam.parentTeamId);
      if (!parent) return;

      results.push(parent);
      mapParent(parent);
    }

    mapParent(team);
    return results.reverse();
  }

  function renderBreadcrumbItem(current: Team) {
    return (
      <div key={current.id} className="f f--align-center f--gap-small">
        <p className="t-bold">&rarr;</p>
        {current.id === team?.id ? (
          <p className="t-small t-bold">{current.name}</p>
        ) : (
          <Link
            to={`/team/${current.id}`}
            key={current.id}
            className="t-small t-secondary t-bold"
          >
            {current.name}
          </Link>
        )}
      </div>
    );
  }

  const breadcrumbs = mapParenthood();
  const shorten = breadcrumbs.length >= 3;

  return (
    <div className="f f--align-center f--gap-small">
      {team ? (
        <Link to="/team" className="t-small t-secondary t-extrabold">
          Overview
        </Link>
      ) : (
        <p className="t-small t-extrabold">Overview</p>
      )}

      {shorten ? (
        <>
          <div className="f f--align-center f--gap-small">
            <p className="t-bold">&rarr;</p>
            <p className="t-small t-bold">&#8230;</p>
          </div>
          {breadcrumbs.slice(breadcrumbs.length - 2).map(renderBreadcrumbItem)}
        </>
      ) : (
        breadcrumbs.map(renderBreadcrumbItem)
      )}
    </div>
  );
};
