import React from 'react';

export const ConnectionLost: ReactFC = () =>
  navigator.onLine ? null : (
    <div
      className={`${navigator.onLine ? 'offline' : 'offline offline--active'}`}
    >
      <div className="wrapper">
        <p className="offline__text">
          You have lost your Internet connection. Data syncing and
          functionalities such as audio playback will be limited.
        </p>
      </div>
    </div>
  );
