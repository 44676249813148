import { getSubscriptionStatus } from 'modules/subscription';

export function getTeamMembersCSVData(
  members: CustomUserInfo[],
  teams: Team[],
) {
  const csvData = [];

  /** Membership data */
  csvData.push(['Last name', 'First name', 'Team', 'Subscription status']);
  /** Map member data, sort by team */
  const memberData = members
    .map(member => {
      const team = teams.find(team => team.id === member.teamId);
      const subscription = getSubscriptionStatus(member.subscription);
      return [
        member.normalisedLastName,
        member.normalisedFirstName,
        team?.name || '',
        subscription,
      ];
    })
    .sort((a, b) => (a[2] < b[2] ? -1 : 1));

  csvData.push(...memberData);
  return csvData;
}
