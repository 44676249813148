import {
  CardElement,
  ReactStripeElements,
  injectStripe,
} from 'react-stripe-elements';
import React, { useState } from 'react';
import { createStripeOptions } from '../const';
import { useAuthentication } from 'modules/authentication';
import { toast } from 'react-toastify';
import { Loading } from 'components';

type Props = ReactStripeElements.InjectedStripeProps & {
  label?: string;
  onSuccess: (sourceId: string) => void;
};

export const StripeCardElement: ReactFC<Props> = ({
  stripe,
  label,
  onSuccess,
}) => {
  const { user } = useAuthentication();
  const [cardElement, setCardElement] = useState<
    ReactStripeElements.HTMLStripeElement
  >();
  const [loading, setLoading] = useState(false);

  const createSource = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !user) return;
    try {
      setLoading(true);
      const result = await stripe.createSource({ type: 'card' });

      if (!result.source || !result.source.id || result.error) {
        throw new Error(
          'Could not add payment source, please try again or with a different one.',
        );
      }

      const stripeSourceId = result.source.id;
      onSuccess(stripeSourceId);
    } catch (error) {
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Check your card details and try again.',
      );
    } finally {
      setLoading(false);
      cardElement?.clear();
    }
  };

  return (
    <form className="f stripe" role="grid" onSubmit={createSource}>
      <div className="stripe__input">
        <CardElement
          disabled={loading}
          onReady={setCardElement}
          hidePostalCode
          {...createStripeOptions()}
        />
      </div>
      {loading ? (
        <Loading withoutPadding />
      ) : (
        <input
          disabled={!cardElement || loading}
          type="submit"
          className="button s-left--med button--primary button--medium"
          value={label || 'Subscribe to TeamMak'}
        />
      )}
    </form>
  );
};

export default injectStripe(StripeCardElement);
