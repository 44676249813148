import IconFailed from 'assets/icons/checkmark-fail.svg';
import { useNavigate } from 'react-router';

export const PaymentFailure: ReactFC = () => {
  const navigate = useNavigate();
  return (
    <section className="payment-status">
      <div className="payment-status__container">
        <img
          src={IconFailed}
          alt="Payment failed"
          className="f--shrink-zero payment-status__icon"
        />
        <h1 className="t-xlarge t-bold u-maxwidth--592 t-center payment-status__title t-text-1">
          An error occured while processing your order
        </h1>
        <p className="t-base u-maxwidth--592 t-center t-text-3 payment-status__description">
          Give it another moment, then try purchasing your ticket again. If the
          issue keeps happening, reach out to our &nbsp;
          <a className="t-secondary t-bold" href="mailto:admin@myteammak.com">
            support team.{' '}
          </a>
        </p>
        <button
          className="button button--medium button--primary"
          onClick={() => navigate('/events')}
        >
          Retry
        </button>
      </div>
    </section>
  );
};
