import { useMemo } from 'react';

import { AudioPlayerAtoms } from '../recoil';
import { useRecoilState } from 'recoil';

export function useAudioPlayer() {
  const [playing, setPlaying] = useRecoilState(AudioPlayerAtoms.playing);
  const [tracks, setTracks] = useRecoilState(AudioPlayerAtoms.tracks);
  const [index, setIndex] = useRecoilState(AudioPlayerAtoms.index);

  const audio = useMemo(() => tracks?.[index], [index, tracks]);
  const showNext = !!tracks?.length && index < tracks.length - 1;
  const showPrevious = index !== 0;

  function play() {
    setPlaying(true);
  }

  function pause() {
    setPlaying(false);
  }

  function loadTracks(audios: AudioInfo[]) {
    setTracks(audios);
    setIndex(0);
    play();
  }

  function close() {
    setTracks(undefined);
    setIndex(0);
    setPlaying(false);
  }

  function next() {
    const nextTrack = tracks?.[index + 1];
    if (!nextTrack) return;
    setIndex(index + 1);
  }

  function previous() {
    const previousTrack = tracks?.[index - 1];
    if (!previousTrack) return;
    setIndex(index - 1);
  }

  return {
    audio,
    tracks,
    index,
    playing,
    showNext,
    showPrevious,
    loadTracks,
    play,
    pause,
    next,
    previous,
    close,
  };
}
