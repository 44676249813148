import React, { useEffect, useState } from 'react';

import {
  Toggler,
  DashboardHeader,
  MainModal,
  Loading,
  ScrollingContent,
  DeleteModal,
} from 'components';
import {
  PlaylistItem,
  PlaylistModalForm,
  usePlaylist,
} from 'modules/playlists';

import {
  AudioAtoms,
  AudioItem,
  DisabledAudioItem,
  useAudios,
} from 'modules/audios';
import { useNavigate, useParams } from 'react-router';
import { useAudioPlayer } from 'modules/audio-player';
import { useAuthentication } from 'modules/authentication';

import { DashboardToolbar, EmptyState } from 'modules/dashboard';
import { useRecoilValue } from 'recoil';

export const AudioPlaylists: ReactFC = () => {
  const navigate = useNavigate();
  const { playlist = '' } = useParams();
  const { user } = useAuthentication();
  const { categories } = useAudios();
  const {
    audios,
    audioPlaylists,
    disabledAudios,
    getAudioPlaylistById,
    loadPlaylistAudios,
    deletePlaylist,
    createPlaylist,
    updatePlaylist,
    removeFromPlaylist,
  } = usePlaylist('audio');
  const { loadTracks } = useAudioPlayer();
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const categoriesLoading = useRecoilValue(AudioAtoms.loading);
  const currentPlaylist = getAudioPlaylistById(playlist);
  const isFavorites = currentPlaylist?.id === 'favorites';

  /** Load playlist audios. */
  useEffect(() => {
    if (!currentPlaylist || !categories.length || categoriesLoading) return;

    setLoading(true);
    loadPlaylistAudios(currentPlaylist.id, currentPlaylist.audios, categories)
      .catch()
      .finally(() => setLoading(false));
  }, [currentPlaylist?.audios, categories, categoriesLoading]);

  if (!user) return null;

  async function handleCreatePlaylist(name: string) {
    const id = createPlaylist(name);
    setShowCreateModal(false);
    navigate(`/audio-playlists/${id}`);
  }

  function handleDeletePlaylist() {
    if (!currentPlaylist) return;
    deletePlaylist(currentPlaylist.id);
    setShowDeleteModal(false);
    navigate('/audio-playlists');
  }

  function handleEditPlaylist(name: string) {
    if (!currentPlaylist) return;
    updatePlaylist(currentPlaylist.id, name);
    setShowEditModal(false);
  }

  function handleDeleteFromPlaylist(id: string) {
    if (!currentPlaylist) return;
    removeFromPlaylist(id, currentPlaylist.id);
  }

  const playPlaylist = () => {
    if (!currentPlaylist || !audios[currentPlaylist.id]) return;
    loadTracks(audios[currentPlaylist.id]);
  };

  const renderPlaylistAudios = () => {
    if ((loading || categoriesLoading) && currentPlaylist) {
      return <Loading withoutContainer />;
    }
    if (!currentPlaylist)
      return (
        <EmptyState
          type="audio"
          message="Select a playlist to view its contents."
        />
      );

    if (
      !audios[currentPlaylist.id]?.length &&
      !disabledAudios[currentPlaylist.id]?.length
    ) {
      return (
        <EmptyState
          type="audio"
          message="No tracks have been added to this playlist yet."
        />
      );
    }

    return (
      <React.Fragment>
        <div className="playlist__dashboard__header f f--center">
          <h1 className="t-delta t-bold desktop-only">
            {currentPlaylist.name}
          </h1>
          <button
            className="button  button--outline button--outline-primary button--small"
            onClick={playPlaylist}
          >
            Play all
          </button>
        </div>
        <div className="lost-flex-container playlist__list">
          {Boolean(audios[currentPlaylist.id]?.length) && (
            <ScrollingContent
              data={audios[currentPlaylist.id]}
              renderItem={renderAudioItem}
            />
          )}
          {Boolean(disabledAudios[currentPlaylist.id]?.length) && (
            <ScrollingContent
              data={disabledAudios[currentPlaylist.id].map(id => ({ id }))}
              renderItem={renderDisabledAudioItem}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  const renderAudioItem = (audio: AudioInfo) => {
    return (
      <AudioItem
        user={user}
        key={audio.id}
        audio={audio}
        parentUrl={`/audio-playlists/${playlist}`}
      />
    );
  };

  const renderDisabledAudioItem = ({ id }: { id: string }) => {
    return (
      <DisabledAudioItem
        key={id}
        id={id}
        deleteAudio={handleDeleteFromPlaylist}
      />
    );
  };

  const renderFavorites = () => {
    if (!audioPlaylists || !audioPlaylists.length) {
      return null;
    }

    const favorites = audioPlaylists.find(p => p.id === 'favorites');
    if (!favorites) return null;

    return (
      <PlaylistItem
        {...favorites}
        trackCount={favorites.audios.length}
        type="audio"
      />
    );
  };

  const renderPlaylists = () => {
    const filtered = audioPlaylists?.filter(p => p.id !== 'favorites');
    if (!filtered || !filtered.length) {
      return (
        <p className="t-zeta u-opacity--40">
          No other playlists have been created yet.
        </p>
      );
    }

    return filtered.map(playlist => (
      <PlaylistItem
        key={playlist.id}
        {...playlist}
        trackCount={playlist.audios.length}
        type="audio"
      />
    ));
  };

  return (
    <React.Fragment>
      <DashboardHeader />
      <DashboardToolbar>
        <div className="dashboard-toolbar__content">
          <h3 className="t-delta t-extrabold">
            {currentPlaylist?.name || 'Your playlists'}
          </h3>
        </div>
        <div className="dashboard-toolbar__controls">
          {currentPlaylist && !isFavorites && (
            <>
              <button
                className="button button--small button--outline button--outline-primary"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </button>
              <button
                className="button button--small button--outline button--outline-primary"
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </button>
            </>
          )}
          <button
            className="button button--small button--primary"
            onClick={() => setShowCreateModal(true)}
          >
            Create new
          </button>
        </div>
      </DashboardToolbar>
      <div className="wrapper">
        <div className="dashboard-content playlists">
          <div className="dashboard-content__sidebar">
            <div className="dashboard-content__inner dashboard-content__inner--sidebar">
              <div className="dashboard-sidebar__item">
                <Toggler
                  leftLink="/audio-playlists"
                  leftTitle="Audios"
                  rightLink="/video-playlists"
                  rightTitle="Videos"
                />
              </div>
              <div className="dashboard-sidebar__item">
                {renderFavorites()}

                <header className="f f--center s-top--med">
                  <h1 className="t-zeta t-upper t-bold u-opacity--40">
                    My playlists
                  </h1>
                </header>

                <section>{renderPlaylists()}</section>
              </div>
            </div>
          </div>
          <div className="dashboard-content__main">
            <div className="dashboard-content__inner dashboard-content__inner--main">
              {renderPlaylistAudios()}
            </div>
          </div>
        </div>
      </div>

      {showCreateModal && (
        <MainModal
          title="Create new playlist"
          close={() => setShowCreateModal(false)}
        >
          <PlaylistModalForm type="audio" onSave={handleCreatePlaylist} />
        </MainModal>
      )}

      {showDeleteModal && (
        <DeleteModal
          confirm={handleDeletePlaylist}
          description="Are you sure you want to delete this playlist? This action cannot be undone"
          title={`Delete the ${currentPlaylist?.name} playlist?`}
          close={() => setShowDeleteModal(false)}
        />
      )}

      {showEditModal && (
        <MainModal
          title="Edit playlist name"
          close={() => setShowEditModal(false)}
        >
          <PlaylistModalForm
            onSave={handleEditPlaylist}
            defaultValue={currentPlaylist?.name}
            type="audio"
          />
        </MainModal>
      )}
    </React.Fragment>
  );
};
