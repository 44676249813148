import React from 'react';
import { ReactStripeElements } from 'react-stripe-elements';

import { StripeSourceData } from '../models';
import IconEmptyState from 'assets/empty-states/empty-state-shared.svg';

interface OwnProps {
  customerId: string | null | undefined;
  sources: StripeSourceData[];
  defaultSource: string;
  handleRemoveSource: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleSetDefaultSource: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

type Props = ReactStripeElements.InjectedStripeProps & OwnProps;

export class StripeCustomerSources extends React.Component<Props> {
  render() {
    const { sources, defaultSource } = this.props;

    return (
      <>
        {!sources.length ? (
          <div className="empty-state s-top--med">
            <img
              className="empty-state__visual"
              alt="empty"
              src={IconEmptyState}
            />
            <p className="empty-state__label">
              You have no payment sources attached to your account. Add one now?
            </p>
          </div>
        ) : (
          sources
            .sort((a, b) => a.created! - b.created!)
            .map((source, index) => (
              <div key={index} className="dashboard-card s-bottom--lrg">
                <div className="dashboard-card__header">
                  <div className="dashboard-card__container f f--center">
                    <h3 className="t-zeta t-upper t-bold u-opacity--40">
                      Card
                    </h3>
                    {defaultSource === source.id ? (
                      <p className="t-zeta t-bold u-opacity--40">Default</p>
                    ) : (
                      <button
                        onClick={this.props.handleSetDefaultSource}
                        data-source-id={source.id}
                        className="button button--xtny button--ghost button--ghost-primary"
                      >
                        Set default
                      </button>
                    )}
                  </div>
                </div>
                <div className="dashboard-card__container f f--center">
                  {source.card ? (
                    <div className="stripe-card f">
                      <div className="stripe-card__info f s-bottom--sml">
                        <p className="t-epsilon u-opacity--60">Card number:</p>
                        <p className="t-epsilon u-opacity--60">
                          <b>**** {source.card.last4}</b>
                        </p>
                      </div>
                      <div className="stripe-card__info f s-bottom--sml">
                        <p className="t-epsilon u-opacity--60">Expires:</p>
                        <p className="t-epsilon u-opacity--60">
                          <b>
                            {source.card.exp_month}/{source.card.exp_year}
                          </b>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="stripe-card f">
                      <div className="stripe-card__info f s-bottom--sml">
                        <p className="t-epsilon u-opacity--60">Card number:</p>
                        <p className="t-epsilon u-opacity--60">
                          <b>**** {source.last4}</b>
                        </p>
                      </div>
                      <div className="stripe-card__info f s-bottom--sml">
                        <p className="t-epsilon u-opacity--60">Expires:</p>
                        <p className="t-epsilon u-opacity--60">
                          <b>
                            {source.exp_month}/{source.exp_year}
                          </b>
                        </p>
                      </div>
                    </div>
                  )}
                  {sources.length > 1 && (
                    <button
                      className="button button--small button--primary"
                      data-source-id={source.id}
                      onClick={this.props.handleRemoveSource}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))
        )}
      </>
    );
  }
}
