import React from 'react';
import { Link } from 'react-router-dom';

export const Hero: ReactFC = () => (
  <section className="hero">
    <div className="wrapper">
      <div className="hero__container">
        <div className="hero__title-wrap">
          <h1 className="hero__title t-extrabold">
            Culture, <br />
            Leadership &amp;
            <br /> Legacy
          </h1>
          <Link
            to="/register"
            className="button button--primary button--medium hero__cta"
          >
            Start your journey
          </Link>
        </div>
        <div className="hero__image" />
      </div>
    </div>
  </section>
);
