import React, { useState } from 'react';
import { IntersectionTrigger } from './IntersectionTrigger';

interface Props<T extends { id: string | null }> {
  data: T[];
  renderItem: (item: T) => JSX.Element | JSX.Element[];
  emptyComponent?: JSX.Element;
}

export function ScrollingContent<T extends { id: string | null }>({
  data,
  emptyComponent,
  renderItem,
}: Props<T>) {
  const numberToShow = calculateInitialNumOfShownItems();
  const [increment, setIncrement] = useState<number>(1);
  const items = data.slice(0, numberToShow * increment);

  return (
    <>
      {items.length ? items.map(item => renderItem(item)) : emptyComponent}
      {items.length < data.length && (
        <IntersectionTrigger onIntersect={() => setIncrement(increment + 1)} />
      )}
    </>
  );

  function calculateInitialNumOfShownItems() {
    if (window.innerWidth < 544) {
      return 12;
    } else if (window.innerWidth < 992) {
      return 18;
    } else {
      return 24;
    }
  }
}
