import React from 'react';
import { CSVLink } from 'react-csv';
import { stringToSlug } from 'modules/helpers';
import { getEventCSVData } from '../utils';

interface Props {
  event: EventInfo;
  tickets: Ticket[];
  showPaymentId: boolean;
}

export const EventStatsCSVDownload: ReactFC<Props> = ({
  event,
  tickets,
  showPaymentId,
}) => {
  const fileName = `report-${stringToSlug(
    event.title,
  )}-${new Date().toDateString()}.csv`;

  if (!tickets.length) return null;

  const data = getEventCSVData(event, tickets, showPaymentId);

  return (
    <CSVLink
      data={data}
      target="_blank"
      separator=";"
      filename={fileName}
      className="button button--medium button--outline button--outline-primary"
    >
      Download event report
    </CSVLink>
  );
};
