import { useRecoilState } from 'recoil';
import { VideoAtoms } from '../recoil';

export function useVideoForm() {
  const [isOpen, setIsOpen] = useRecoilState(VideoAtoms.videoFormOpen);
  const [video, setVideo] = useRecoilState(VideoAtoms.editingVideo);
  const [categoryId, setCategoryId] = useRecoilState(
    VideoAtoms.videoFormCategory,
  );

  function open(categoryId?: string) {
    setIsOpen(true);
    setVideo(undefined);
    setCategoryId(categoryId);
  }

  function edit(video: VideoInfo) {
    setIsOpen(true);
    setVideo(video);
  }

  function close() {
    setIsOpen(false);
    setVideo(undefined);
    setCategoryId(undefined);
  }

  return {
    video,
    isOpen,
    categoryId,
    edit,
    open,
    close,
  };
}
