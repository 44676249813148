import { mapMediaStatisticsToType } from 'modules/audio-player';
import { useAuthentication } from 'modules/authentication';
import { MediaFileType } from 'modules/dashboard';
import { Collection, FirestoreService } from 'modules/firebase';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { VideoPlayerAtoms } from '../recoil';

const UserInfoCollection = new FirestoreService<CustomUserInfo>(
  Collection.UserInfo,
);

export function useVideoPlayer() {
  const { user } = useAuthentication();
  const navigate = useNavigate();
  const [playlist, setPlaylist] = useRecoilState(VideoPlayerAtoms.playlist);
  const [playlistIndex, setPlaylistIndex] = useRecoilState(
    VideoPlayerAtoms.playlistIndex,
  );

  const showNext =
    playlist.length &&
    playlistIndex !== undefined &&
    playlistIndex < playlist.length - 1;

  const showPrevious = (playlistIndex || 0) > 0;

  async function updatePlayedCount(video: VideoInfo) {
    if (!user) return;

    let videoStats: MediaStatistics[] = [...(user.videoStats || [])];
    const existing = videoStats.find(stat => stat.trackId === video.id);

    if (!existing) {
      videoStats.push({
        percentListened: 0,
        secondsListened: 0,
        trackId: video.id,
        timesPlayed: 1,
        title: video.title,
        lastPlayedDate: Date.now(),
        type: MediaFileType.Video,
      });
    } else {
      videoStats = [
        ...videoStats.filter(s => s.trackId !== video.id),
        {
          ...mapMediaStatisticsToType(MediaFileType.Video, existing),
          timesPlayed: existing.timesPlayed + 1,
          lastPlayedDate: Date.now(),
        },
      ];
    }

    UserInfoCollection.set(user.id, { videoStats });
  }

  async function updatePlayProgress(
    video: VideoInfo,
    percentListened: number,
    secondsListened: number,
  ) {
    if (!user) return;

    let videoStats: MediaStatistics[] = [...(user.videoStats || [])];
    const existing = videoStats.find(stat => stat.trackId === video.id);

    if (!existing) {
      videoStats.push({
        percentListened,
        secondsListened,
        trackId: video.id,
        timesPlayed: 1,
        title: video.title,
        type: MediaFileType.Video,
        lastPlayedDate: Date.now(),
      });
    } else {
      videoStats = [
        ...videoStats.filter(s => s.trackId !== video.id),
        {
          ...mapMediaStatisticsToType(MediaFileType.Video, existing),
          percentListened,
          secondsListened,
          lastPlayedDate: Date.now(),
        },
      ];
    }

    UserInfoCollection.set(user.id, { videoStats });
  }

  async function finishedWatching(videoId: string) {
    if (!user) return;

    const existing = user.videoStats.find(a => a.trackId === videoId);
    if (!existing) return;

    const videoStats: MediaStatistics[] = [
      ...user.videoStats.filter(a => a.trackId !== videoId),
      {
        ...existing,
        percentListened: 0,
        secondsListened: 0,
      },
    ];

    UserInfoCollection.set(user.id, { videoStats });
  }

  function loadPlaylist(tracks: string[], playlistId: string) {
    setPlaylist(tracks);
    setPlaylistIndex(0);
    navigate(`/video-playlists/${playlistId}/${tracks[0]}`);
  }

  function next(playlistSlug: string) {
    // If playlist not loaded do nothing.
    if (!playlist.length || playlistIndex === undefined) return;
    // If at the end of playlist do nothing.
    if (playlistIndex === playlist.length - 1) return;
    const index = playlistIndex + 1;

    setPlaylistIndex(index);
    navigate(`/video-playlists/${playlistSlug}/${playlist[index]}`);
  }

  function previous(playlistSlug: string) {
    // If playlist not loaded do nothing
    if (!playlist || playlistIndex === undefined) return;
    // If at the beginning of the playlist do nothing
    if (playlistIndex === 0) return;
    const index = playlistIndex - 1;

    setPlaylistIndex(index);
    navigate(`/video-playlists/${playlistSlug}/${playlist[index]}`);
  }

  function close() {
    setPlaylist([]);
    setPlaylistIndex(undefined);
  }

  return {
    playlist,
    playlistIndex,
    showNext,
    showPrevious,
    close,
    next,
    previous,
    loadPlaylist,
    updatePlayProgress,
    updatePlayedCount,
    finishedWatching,
  };
}
