import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TeamSelect } from 'modules/authentication';
import { AuthenticationHeader, Input, CountrySelect } from 'components';
import { validation as Validation } from 'modules/validation';

import { useForm } from 'hooks';
import { useAuthentication } from 'modules/authentication';
import { useTeams } from 'modules/teams';

interface FormState {
  email: string;
  password: string;
  passwordRepeat: string;
  firstName: string;
  lastName: string;
  country: CustomUserInfo['country'] | undefined;
  ibo: string;
  teamId: string | undefined;
}

export const Register: ReactFC = () => {
  const { registerAsync, loading: loadingAuth } = useAuthentication();
  const { loading: loadingTeams } = useTeams();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { state, validation, update, validateForm } = useForm<FormState>(
    {
      email: '',
      password: '',
      passwordRepeat: '',
      firstName: '',
      lastName: '',
      ibo: '',
      teamId: undefined,
      country: undefined,
    },
    {
      rules: {
        email: value => !!value && Validation.EMAIL_REGEX.test(value),
        password: value =>
          !!value &&
          value.length >= Validation.PASSWORD_LENGTH_MIN &&
          value.length <= Validation.PASSWORD_LENGTH_MAX,
        passwordRepeat: (value, state) => !!value && value === state.password,
        ibo: value => value.length >= 10,
      },
    },
  );

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      if (!validateForm()) {
        throw new Error(
          'Your registration data is not complete. Please check your inputs.',
        );
      }

      const {
        email,
        password,
        country,
        firstName,
        lastName,
        ibo,
        teamId,
      } = state;

      if (!country || !teamId)
        throw new Error(
          'Your registration data is not complete. Please check your inputs.',
        );

      await registerAsync({
        country,
        teamId,
        email,
        password,
        firstName,
        lastName,
        ibo,
      });
    } catch (error) {
      if (error instanceof Error) {
        const isAuthError = error.message.includes('auth/');
        toast.error(
          isAuthError
            ? "We weren't able to create an account with the specified e-mail. Please, try another."
            : error.message,
        );
      }
    }
  }

  return (
    <section className="auth">
      <div className="auth__form-container">
        <AuthenticationHeader />
        <div className="auth__wrapper auth__wrapper--form">
          <form onSubmit={handleSubmit}>
            <div className="field">
              <Input
                value={state.firstName}
                onChange={value => update('firstName', value)}
                label="First Name"
                type="text"
              />
            </div>

            {validation.firstName === false && (
              <p className="input--error">This is a required field.</p>
            )}
            <div className="field">
              <Input
                value={state.lastName}
                onChange={value => update('lastName', value)}
                label="Last Name"
              />
            </div>

            {validation.lastName === false && (
              <p className="input--error">This is a required field.</p>
            )}

            <div className="field">
              <Input
                value={state.email}
                onChange={value => update('email', value)}
                label="E-mail"
                type="email"
              />
            </div>

            {validation.firstName === false && (
              <p className="input--error">
                This is a required field and needs to be a valid email address.
              </p>
            )}

            <div className="field">
              <Input
                value={state.password}
                onChange={value => update('password', value)}
                label="Password"
                type="password"
              />
            </div>

            {validation.password === false && (
              <p className="input--error">
                Your password needs to be 8 - 24 characters in length.
              </p>
            )}

            <div className="field">
              <Input
                value={state.passwordRepeat}
                onChange={value => update('passwordRepeat', value)}
                label="Password repeat"
                type="password"
              />
            </div>
            {validation.passwordRepeat === false && (
              <p className="input--error">
                This must match the above password.
              </p>
            )}
            <div className="field">
              <Input
                value={state.ibo}
                onChange={value => update('ibo', value)}
                label="IBO"
                type="number"
              />
            </div>

            {validation.ibo === false && (
              <p className="input--error">A valid IBO number is required.</p>
            )}

            <div className="field">
              <CountrySelect
                onChange={option => update('country', option.value)}
                country={state.country}
              />
            </div>

            {validation.country === false && (
              <p className="input--error">
                You have to select your country of residence.
              </p>
            )}

            <div className="field">
              <TeamSelect
                onSelect={team => update('teamId', team.value)}
                teamId={state.teamId}
              />
            </div>

            {validation.teamId === false && (
              <p className="input--error">A team is required.</p>
            )}

            <div className="s-top--lrg f f--start">
              <input
                className="input input--check"
                name="termsAndConditions"
                type="checkbox"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
              <label
                htmlFor="termsAndConditions"
                className="s-left--med t-faded t-small "
                onClick={() => setTermsAccepted(!termsAccepted)}
              >
                I accept the
              </label>
              &nbsp;
              <Link
                to="/terms-and-conditions"
                className="t-small"
                target="_blank"
              >
                Terms and Conditions
              </Link>
            </div>

            <button
              type="submit"
              value="Submit"
              className="button button--medium button--primary auth__submit s-top--lrg"
              disabled={loadingAuth || loadingTeams || !termsAccepted}
            >
              Register
            </button>
          </form>
        </div>
      </div>
      <div className="auth__visual" />
    </section>
  );
};
