import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  visual: any;
  title: string;
  description: string;
  link: string;
}

export const ServicesItem: ReactFC<Props> = ({
  visual,
  title,
  description,
  link,
}) => (
  <Link to={link} className="services-item card">
    {visual}
    <div className="services-item__content">
      <h1 className="t-epsilon s-bottom--med t-extrabold">{title}</h1>
      <p className="t-zeta s-bottom--med">{description}</p>
    </div>
  </Link>
);
