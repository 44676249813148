import { httpsCallableFromURL } from 'firebase/functions';
import { FirebaseService } from '../services';

/** Workaround for v2 firebase functions not being triggered on hosting. */
export function callable<
  Payload = undefined,
  Response = Record<string, unknown>
>(functionName: string) {
  return httpsCallableFromURL<Payload, Response>(
    FirebaseService.Functions,
    `https://${functionName.toLowerCase()}-${
      process.env.REACT_APP_CALLABLE_SUFFIX
    }`,
  );
}
