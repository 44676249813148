import { useTeamMembers } from 'modules/teams';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { stringToSlug } from 'modules/helpers';
import { getTeamMembersCSVData } from '../utils';

interface Props {
  currentTeam: Team;
  teams: Team[];
  className?: string;
}

export const TeamMembersCSVDownload: ReactFC<Props> = ({
  teams,
  currentTeam,
  className,
}) => {
  const [loading, setLoading] = useState(false);
  const { getTeamMembersIncludingSubteamsById } = useTeamMembers();

  async function handleGenerateCSVData() {
    try {
      setLoading(true);
      toast.info(
        'Generating member list CSV, you will be able to download it soon.',
        { containerId: 'members-list-csv' },
      );

      const members = await getTeamMembersIncludingSubteamsById(currentTeam.id);
      const data = getTeamMembersCSVData(members, teams);

      toast(
        <>
          <p className="t-zeta">CSV generated, download it below.</p>
          <div className="s-top--med">
            <CSVLink
              data={data}
              target="_blank"
              separator=";"
              filename={fileName}
              title="This will generate the full membership tree for this team."
              className={
                'button button--primary button--small f--inline f--align-center'
              }
            >
              Download CSV
            </CSVLink>
          </div>
        </>,
        {
          containerId: 'members-list-csv',
          autoClose: false,
        },
      );
    } catch (error) {
      console.error(error);
      toast.error(
        error instanceof Error ? error.message : 'Oops, something went wrong.',
        { containerId: 'members-list-csv' },
      );
    } finally {
      setLoading(false);
    }
  }

  const fileName = `report-${stringToSlug(currentTeam.name)}-${stringToSlug(
    new Date().toDateString(),
  )}.csv`;

  return (
    <button
      disabled={loading}
      onClick={handleGenerateCSVData}
      className={
        className ||
        'button button--medium button--primary button--ghost button--ghost-primary-primary button--full-width t-regular'
      }
    >
      {loading ? 'Generating CSV...' : 'Generate full CSV'}
    </button>
  );
};
