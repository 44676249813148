import React from 'react';
import { Link } from 'react-router-dom';

import { AudioItemSimple } from 'modules/audios';

interface Props {
  user: CustomUserInfo;
  audios: AudioInfo[];
}

export const LastPlayedOrAllAudios: ReactFC<Props> = ({ audios, user }) => {
  // generate url by category name
  return (
    <div className="dashboard-card">
      <div className="dashboard-card__header">
        <div className="dashboard-card__container f f--center">
          <h3 className="t-zeta t-upper t-bold u-opacity--40">
            Last played audios
          </h3>
          <Link to="/audios" className="t-zeta anchor--secondary">
            View all audios
          </Link>
        </div>
      </div>
      <div className="dashboard-card__container dashboard-card__container--media f">
        {audios.length ? (
          audios.map((audio: AudioInfo) => {
            const url = '/audios/direct';

            return (
              <AudioItemSimple key={audio.id} audio={audio} parentUrl={url} />
            );
          })
        ) : (
          <div className="dashboard-card__empty">
            <p>Your recently played audios will appear here.</p>
          </div>
        )}
      </div>
    </div>
  );
};
