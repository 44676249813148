export const getEventCSVData = (
  event: EventInfo,
  tickets: Ticket[],
  showPaymentId: boolean,
) => {
  const csvData = [];
  const totalRevenue = tickets
    .map(t => t.ticketPrice)
    .reduce((revenue, current) => (revenue += current), 0);

  /** Header */
  csvData.push(
    ['Event name', 'Total tickets sold', 'Total revenue for selected teams'],
    [event.title, tickets.length, `$${totalRevenue}`],
  );

  const teamIds = Array.from(
    new Set(tickets.map(ticket => ticket.purchasedByTeamId)),
  );

  /** Team data */
  for (const teamId of teamIds) {
    const teamName = tickets.find(t => t.purchasedByTeamId === teamId)
      ?.purchasedByTeamName;
    const teamTickets = tickets.filter(t => t.purchasedByTeamId === teamId);

    if (!teamTickets.length) continue;

    const revenue = teamTickets
      .map(ticket => ticket.ticketPrice)
      .reduce((revenue, current) => (revenue += current), 0);

    csvData.push(
      [
        '--------------------------------------------------------------',
        '--------------------------------------------------------------',
        '--------------------------------------------------------------',
      ],
      ['Team name', 'Tickets sold in team', 'Revenue collected in team'],
      [teamName || '-', teamTickets.length, `$${revenue}`],
    );

    csvData.push(
      ['', '', '', '', '', '', '', ''],
      [
        'Ticket holder name',
        'Ticket holder e-mail',
        'Purchased by',
        'Purchaser team',
        'Ticket type',
        'Price',
        'Date purchased',
        showPaymentId ? 'Stripe PaymentID' : '',
      ],
    );

    teamTickets.forEach(ticket =>
      csvData.push([
        ticket.holderName,
        ticket.holderEmail,
        ticket.purchasedByName,
        ticket.purchasedByTeamName || '-',
        ticket.ticketType,
        ticket.ticketPrice,
        new Date(ticket.timePurchased).toDateString(),
        showPaymentId ? ticket.paymentId || '-' : '',
      ]),
    );

    /** Spacer */
    csvData.push([]);
  }

  return csvData;
};
