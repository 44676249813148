interface Props {
  label: string;
  checked: boolean;
  toggle: () => void;
}

export const TogglePin: React.FC<Props> = ({ checked, label, toggle }) => {
  return (
    <button
      type="button"
      className="button button--unpadded t-regular f f--gap"
      onClick={toggle}
    >
      <p className="t-small">{label}</p>
      <div className={`toggle-pin ${checked ? 'toggle-pin--checked' : ''}`} />
    </button>
  );
};
