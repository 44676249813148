import React, { useEffect, useState } from 'react';
import { useSharedAudios } from '../hooks';

interface Props {
  title: string;
  audio: AudioInfo;
  sharedById: string;
}

export const ShareButton: React.FC<Props> = ({ title, audio, sharedById }) => {
  const { getShareUrlId, saveSharedUrl } = useSharedAudios();
  const [loading, setLoading] = useState(true);
  const [shareId, setShareId] = useState<string>();
  const [copied, setCopied] = useState(false);

  const navigatorRef = navigator as Navigator & {
    share?: (options: { url: string; title: string }) => Promise<void>;
    canShare: () => boolean;
  };

  useEffect(() => {
    getShareUrlId(audio, sharedById)
      .then(setShareId)
      .finally(() => setLoading(false));
  }, []);

  const share = async () => {
    if (!shareId) return;

    const onIos = navigator.userAgent.match(/ipad|ipod|iphone/i);
    const shareUrl = `${window.location.origin}/shared/${shareId}`;

    if (navigatorRef.share) {
      navigatorRef
        .share({
          url: shareUrl,
          title,
        })
        .then(() => saveSharedUrl(shareId, audio, sharedById))
        .catch(error => {
          if (error.message.toLowerCase().includes('abort')) {
            return;
          }

          alert(`Something is blocking sharing: ${error}`);
        });
      return;
    }

    if (navigatorRef.clipboard && !onIos) {
      navigatorRef.clipboard
        .writeText(shareUrl)
        .then(() => {
          setCopied(true);
          saveSharedUrl(shareId, audio, sharedById);
          setTimeout(() => {
            setCopied(false);
          }, 3000);
        })
        .catch(error => console.error(error));
    }
  };

  const shareDisabled = !navigatorRef.share && !navigatorRef.clipboard;
  if (shareDisabled) return null;

  return copied ? (
    <p className="t-small s-top--sml">Link copied</p>
  ) : (
    <button
      className="button button--ghost button--ghost-primary button--medium"
      onClick={share}
      disabled={loading}
    >
      <svg
        className="button--icon"
        width="16"
        height="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          stroke="#09a593"
        >
          <path d="M12.5 5.5L8 .5l-4.5 5h3v6h3v-6z" />
          <path d="M15.5 10.5v4c0 .552-.448 1-1 1h-13c-.552 0-1-.448-1-1v-4" />
        </g>
      </svg>
      Share
    </button>
  );
};
