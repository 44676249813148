import React, { useState } from 'react';
import { CountrySelect, Input, MainModal } from 'components';
import Dropzone, { ImageFile } from 'react-dropzone';
import { useForm } from 'hooks';
import { toast } from 'react-toastify';
import { useAuthentication } from 'modules/authentication';
import { capitaliseFirstLetter } from 'utils';
import { validation as Validation } from 'modules/validation';

interface Props {
  profile: CustomUserInfo;
  close: VoidFunction;
}

interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  ibo: string;
  sponsorIbo: string;
  country: CustomUserInfo['country'];
}

const MAX_AVATAR_SIZE = 1000000;

export const EditProfileModal: ReactFC<Props> = ({ close, profile }) => {
  const { updateProfile, checkEmailExist } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState<ImageFile>();
  const { state, validation, update, validateForm } = useForm<FormState>(
    {
      country: profile.country || 'Australia',
      ibo: profile.ibo || '',
      sponsorIbo: profile.sponsorIbo || '',
      email: profile.email || '',
      firstName: capitaliseFirstLetter(profile.normalisedFirstName),
      lastName: capitaliseFirstLetter(profile.normalisedLastName),
    },
    {
      optional: ['sponsorIbo'],
      rules: {
        email: value => !!value && Validation.EMAIL_REGEX.test(value),
        ibo: value => value.length >= 10,
      },
    },
  );

  function onImageDrop(files: ImageFile[]) {
    setAvatar(files[0]);
  }

  async function submit() {
    try {
      setLoading(true);
      if (!validateForm())
        throw new Error("Your changes aren't valid. Check your inputs.");

      if (
        state.email !== profile.email &&
        (await checkEmailExist(state.email))
      ) {
        throw new Error('This email is already in use');
      }

      await updateProfile(profile.id, {
        ...state,
        avatar,
        oldEmail: profile.email,
      });

      close();
    } catch (e) {
      toast(e instanceof Error ? e.message : JSON.stringify(e), {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <MainModal title="Change profile info" close={close}>
      <div className="modal__content">
        <div className="avatar__modal">
          <img
            src={
              avatar
                ? avatar.preview
                : profile.photoURL ||
                  require('assets/images/placeholder-square.png')
            }
            alt="profile"
            className="avatar avatar--lrg"
          />
          <Dropzone
            onDropAccepted={onImageDrop}
            onDropRejected={() =>
              toast.error(
                `Image must be below ${MAX_AVATAR_SIZE / 1000000}MB in size.`,
              )
            }
            multiple={false}
            className="dropzone"
            accept="image/jpeg, image/png"
            disablePreview={false}
            maxSize={MAX_AVATAR_SIZE}
          />
        </div>
        <div className="field">
          <Input
            onChange={value => update('firstName', value)}
            value={state.firstName}
            label="First name"
          />
          {validation.firstName === false && (
            <p className="t-warning t-small">This field is required.</p>
          )}
        </div>
        <div className="field">
          <Input
            onChange={value => update('lastName', value)}
            value={state.lastName}
            label="Last name"
          />
          {validation.lastName === false && (
            <p className="t-warning t-small">This field is required.</p>
          )}
        </div>
        <div className="field">
          <Input
            value={state.email}
            type="email"
            onChange={value => update('email', value)}
            label="E-mail"
          />
          {validation.email === false && (
            <p className="t-warning t-small">
              This has to be an unique and valid email address.
            </p>
          )}
        </div>
        <div className="field">
          <Input
            value={state.ibo}
            onChange={value => update('ibo', value)}
            label="IBO"
            type="numeric"
          />
          {validation.ibo === false && (
            <p className="t-warning t-small">This field is required.</p>
          )}
        </div>
        <div className="field">
          <Input
            onChange={value => update('sponsorIbo', value)}
            value={state.sponsorIbo}
            type="numeric"
            label="Sponsor IBO"
          />
          {validation.sponsorIbo === false && (
            <p className="t-warning t-small">This field is required.</p>
          )}
        </div>
        <div>
          <CountrySelect
            onChange={option => update('country', option.value)}
            country={state.country}
          />
          {validation.country === false && (
            <p className="t-warning t-small">This field is required.</p>
          )}
        </div>
      </div>
      <div className="f f--center modal__footer">
        <button
          onClick={close}
          className="button button--medium button--ghost button--ghost-primary t-text"
        >
          Discard
        </button>
        <button
          onClick={submit}
          disabled={loading}
          className="button button--medium button--primary"
        >
          Save
        </button>
      </div>
    </MainModal>
  );
};
