import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  AdministrationOptions,
  ConfirmModal,
  DeleteModal,
  FavoriteButton,
} from 'components';
import { usePlaylist } from 'modules/playlists';
import { Role, RoleCheck } from 'modules/authorization';
import { useAudioForm, useAudios } from '../hooks';
import { useAudioStats } from 'modules/audio-stats';

interface Props {
  audio: AudioInfo;
  user: CustomUserInfo;
  parentUrl: string;
}

export const AudioItem: ReactFC<Props> = ({ user, audio, parentUrl }) => {
  const { removeAudio, publishAudio } = useAudios();
  const { stats } = useAudioStats();
  const { edit } = useAudioForm();
  const { isFavorite, toggleFavorite } = usePlaylist('audio');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const currentStats = stats.find(s => s.trackId === audio.id);

  function handlePublish() {
    publishAudio(audio);
    setShowPublishModal(false);
  }

  return (
    <>
      <Link
        key={audio.id}
        to={`${parentUrl}/${audio.id}`}
        className="card audio-item"
      >
        <div className="audio-item__content">
          <img
            loading="lazy"
            src={
              audio.thumbnailUrl ||
              require('assets/images/placeholder-square.png')
            }
            alt=""
            className="audio-item__thumbnail"
          />

          <div className="f--one">
            <p className="title title--card">{audio.title}</p>
            <p className="t-tiny t-faded t-sans">{audio.author}</p>
          </div>

          <AdministrationOptions
            edit={() => edit(audio)}
            remove={() => setShowDeleteModal(true)}
            publish={
              audio.status === 'draft'
                ? () => setShowPublishModal(true)
                : undefined
            }
          />
        </div>
        <div className="audio-item__footer">
          <p className="t-sans t-tiny">
            {audio.shortDescription.length > 100 ? (
              <>{audio.shortDescription.substring(0, 197)}&#8230;</>
            ) : (
              audio.shortDescription
            )}
          </p>

          <div className="f f--align-center f--gap s-top--sml">
            <p className="t-label t-label--sml t-faded">
              {moment(audio.timeCreated).format('ll')}
            </p>
            {audio.status === 'draft' && (
              <p className="pill pill--sml pill--info">draft</p>
            )}
          </div>
        </div>

        <div className="audio-item__favorite">
          <FavoriteButton
            isFavorite={isFavorite(audio.id)}
            toggleCallback={() => toggleFavorite(audio.id)}
          />
        </div>
        <div
          className="audio-item__progress"
          style={{ width: (currentStats?.percentListened || 0) + '%' }}
        />
      </Link>

      <RoleCheck role={Role.Administrator}>
        {showDeleteModal && audio && (
          <DeleteModal
            title={`Delete ${audio.title}?`}
            description="Are you sure you want to delete this audio? This action cannot be undone."
            close={() => setShowDeleteModal(false)}
            confirm={() => {
              removeAudio(audio.id);
              setShowDeleteModal(false);
            }}
          />
        )}

        {showPublishModal && (
          <ConfirmModal
            confirm={handlePublish}
            close={() => setShowPublishModal(false)}
            title={`Publish ${audio.title}?`}
            description="Publishing this video makes it available for all users. Do you want to proceed?"
          />
        )}
      </RoleCheck>
    </>
  );
};
