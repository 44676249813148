import { TogglerWithoutNav, Loading, ConfirmModal } from 'components';
import { useAuthentication } from 'modules/authentication';
import { Role, RoleCheck } from 'modules/authorization';
import { ProfileTab, MediaFileType } from 'modules/dashboard';
import {
  getSubscriptionStatus,
  getSubscriptionBadgeClass,
} from 'modules/subscription';
import { TeamInfo } from 'modules/teams';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChangePasswordModal } from '../change-password-modal';
import { EditProfileModal } from '../edit-profile-modal';
import IconEmptyTicket from 'assets/empty-states/empty-state-ticket.svg';
import IconEmptyShared from 'assets/empty-states/empty-state-shared.svg';
import { useUsers } from 'modules/users';
import { useStripe } from 'modules/stripe';

interface Props {
  user: CustomUserInfo;
  tickets: Ticket[];
  ticketsLoading: boolean;
  teams: Team[];
  teamsLoading: boolean;
  audioStats: AudioStats[];
  audioStatsLoading: boolean;
}

export const ProfilePage: ReactFC<Props> = ({
  user,
  tickets,
  ticketsLoading,
  teams,
  teamsLoading,
  audioStats,
  audioStatsLoading,
}) => {
  const { activateLegacySubscription, cancelLegacySubscription } = useUsers();
  const { cancelSubscription, loading: loadingStripe } = useStripe();
  const { user: authenticatedUser } = useAuthentication();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [tab, setTab] = useState<ProfileTab>(ProfileTab.Tickets);

  const enableEditing =
    authenticatedUser?.role === Role.Administrator ||
    authenticatedUser?.id === user.id;

  const isCurrentUser = authenticatedUser?.id === user.id;

  const getDateZeroHour = (time: number) =>
    new Date(moment(time).format('ll')).getTime();

  const renderPurchasedTickets = () => {
    if (ticketsLoading) {
      return <Loading />;
    }

    if (!tickets || !tickets.length) {
      return (
        <div className="empty-state">
          <img
            className="empty-state__visual"
            alt="empty"
            src={IconEmptyTicket}
          />
          <p className="empty-state__label">Nothing to display.</p>
        </div>
      );
    }

    const dates = Array.from(
      new Set(tickets.map(t => getDateZeroHour(t.timePurchased))),
    ).sort((a, b) => (a === b ? 0 : a > b ? -1 : 1));
    return (
      <>
        <div className="history-list__header history-list__header--shared">
          <span className="history-list__field__shared t-iota u-opacity--40">
            Event name
          </span>
          <span className="history-list__field__shared t-iota u-opacity--40">
            Tickets bought
          </span>
          <span className="history-list__field__shared t-iota u-opacity--40">
            Paid
          </span>
        </div>
        <div className="history-list__body">
          {dates.map(date => {
            const events = Array.from(
              new Set(
                tickets
                  .filter(t => getDateZeroHour(t.timePurchased) === date)
                  .map(t => t.eventId),
              ),
            );

            return events.map(e => {
              const eventTickets = tickets.filter(
                t =>
                  t.eventId === e && getDateZeroHour(t.timePurchased) === date,
              );
              const eventName = eventTickets[0].eventName;

              let paid = 0;
              for (const ticket of eventTickets) {
                paid = paid + ticket.ticketPrice;
              }

              return (
                <div className="history-list__item__shared t-small" key={e}>
                  <p className="history-list__field__tickets u-opacity--80 t-bold">
                    {eventName}
                  </p>
                  <p className="history-list__field__tickets u-opacity--80">
                    <span className="history-list__field__mobile-title u-opacity--40">
                      Tickets bought
                    </span>
                    {eventTickets.length}
                  </p>
                  <p className="history-list__field__tickets u-opacity--80">
                    <span className="history-list__field__mobile-title u-opacity--40">
                      Paid
                    </span>
                    ${paid.toFixed(2)}
                  </p>
                </div>
              );
            });
          })}
        </div>
      </>
    );
  };

  const renderPlaybackHistory = () => {
    const videoStats = user.videoStats || [];
    if (audioStatsLoading) return <Loading withoutContainer />;

    if (!videoStats.length && !audioStats.length) {
      return (
        <div className="empty-state">
          <img
            className="empty-state__visual"
            alt="empty"
            src={IconEmptyShared}
          />
          <p className="empty-state__label">
            This user has no content history.
          </p>
        </div>
      );
    }

    const mappedAudioStats: MediaStatistics[] = audioStats.map(s => ({
      percentListened: s.percentListened,
      secondsListened: s.secondsListened,
      title: s.title,
      trackId: s.trackId,
      lastPlayedDate: s.lastPlayed,
      type: 'audio',
      timesPlayed: 0,
    }));

    const playbackHistory = [...videoStats, ...mappedAudioStats].sort(
      (a, b) => (a.lastPlayedDate || 0) + (b.lastPlayedDate || 0),
    );

    return (
      <>
        <div className="history-list__header history-list__header--shared">
          <span className="history-list__field__shared t-iota u-opacity--40">
            Media title
          </span>
          <span className="history-list__field__shared t-iota u-opacity--40">
            Media type
          </span>
          <span className="history-list__field__shared t-iota u-opacity--40">
            Times played
          </span>
        </div>
        <div className="history-list__body">
          {playbackHistory.map(item => (
            <div
              key={item.trackId}
              className="history-list__item__shared t-small"
            >
              <p className="history-list__field__shared u-opacity--80 t-bold">
                {item.title}
              </p>
              <p className="history-list__field__shared">
                {item.type === MediaFileType.Audio ? (
                  <svg
                    width="16"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="M14.5 15.5h-13c-.552 0-1-.448-1-1v-9c0-.552.448-1 1-1h13c.552 0 1 .448 1 1v9c0 .552-.448 1-1 1zM4.5 2.5v-2h7v2"
                        stroke="#A8A4AD"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        stroke="#A8A4AD"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.5 11.5v-4h4v4"
                      />
                      <circle fill="#A8A4AD" cx="5.5" cy="11.5" r="1.5" />
                      <circle fill="#A8A4AD" cx="9.5" cy="11.5" r="1.5" />
                    </g>
                  </svg>
                ) : (
                  <svg
                    width="16"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      stroke="#A8A4AD"
                      fill="none"
                      fillRule="evenodd"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M14.5 15.5h-13c-.552 0-1-.448-1-1v-9c0-.552.448-1 1-1h13c.552 0 1 .448 1 1v9c0 .552-.448 1-1 1zM4.5 2.5v-2h7v2" />
                      <path d="M6.5 7.5L11 10l-4.5 2.5z" />
                    </g>
                  </svg>
                )}
              </p>
              <p className="history-list__field__shared u-opacity--80">
                <div className="history-list__field__mobile-title history-list__field__mobile-title--inline">
                  Times played:&nbsp;
                </div>
                {item.timesPlayed || '-'}
              </p>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderSubscriptionInfo = () => {
    if (loadingStripe) return <Loading withoutPadding />;
    const subscriptionStatus = getSubscriptionStatus(user.subscription);
    const badge = getSubscriptionBadgeClass(subscriptionStatus);

    return (
      <div className="s-top--lrg">
        <div className="f f--center">
          <h3 className="t-zeta t-upper t-bold u-opacity--40">Subscription</h3>
          <span className={badge}>{subscriptionStatus}</span>
        </div>

        <RoleCheck role={Role.Administrator} exact={true}>
          <>
            {(user.subscription?.isLegacySubscription ||
              user.subscription?.stripe?.active) && (
              <button
                className="button button--medium button--primary s-top--med"
                onClick={() => setShowCancelModal(true)}
              >
                Cancel subscription
              </button>
            )}
            {!user.subscription?.isLegacySubscription &&
              !user.subscription?.stripe?.active && (
                <button
                  className="button button--medium button--primary s-top--med"
                  onClick={() => setShowActivateModal(true)}
                >
                  Activate Legacy subscription
                </button>
              )}
          </>
        </RoleCheck>
      </div>
    );
  };

  const handleCancelSubscription = async () => {
    setShowCancelModal(false);
    if (user.subscription?.isLegacySubscription) {
      cancelLegacySubscription(user.id);
    } else if (user.subscription?.stripe?.customerId) {
      cancelSubscription(user.subscription.stripe.customerId);
    }
  };

  const handleActivateLegacySubscription = () => {
    activateLegacySubscription(user.id);
    setShowActivateModal(false);
  };

  const renderCancelSubscriptionModal = () => {
    return (
      <RoleCheck role={Role.Administrator}>
        {showCancelModal && (
          <ConfirmModal
            confirm={handleCancelSubscription}
            description="Legacy subscriptions are cancelled immediately. A stripe subscription will remain active until the current billing period expires."
            title="Are you sure you wish to cancel this user's subscription?"
            close={() => setShowCancelModal(false)}
          />
        )}
      </RoleCheck>
    );
  };

  const renderActivateSubscriptionModal = () => {
    return (
      <RoleCheck role={Role.Administrator}>
        {showActivateModal && (
          <ConfirmModal
            confirm={handleActivateLegacySubscription}
            description={'You are about to renew subscription for Legacy user.'}
            title="Are you sure you wish to activate this user's subscription?"
            close={() => setShowActivateModal(false)}
          />
        )}
      </RoleCheck>
    );
  };

  const subscriptionStatus = getSubscriptionStatus(user.subscription);
  const badge = getSubscriptionBadgeClass(subscriptionStatus);

  return (
    <React.Fragment>
      <header className="dashboard-header profile">
        <div className="wrapper">
          <h1 className="s-bottom--med t-delta t-bold t-negative is-mobile">
            Profile
          </h1>
          <div className="profile__header">
            {user && user.photoURL && (
              <img
                loading="eager"
                src={
                  user.photoURL ||
                  require('assets/images/placeholder-square.png')
                }
                alt="profile"
                className="avatar avatar--profile"
              />
            )}
            <div className="profile__info">
              <div>
                <div className="f f--start">
                  <p className="profile__name t-gamma t-negative t-extrabold">
                    {user.displayName}
                  </p>
                  <span className="s-left--lrg t-bold badge badge--users badge--blue">
                    {user.country}
                  </span>
                </div>
                <p className="t-theta t-negative u-opacity--80">{user.email}</p>
                <p className="t-theta t-negative u-opacity--80 s-top--tny">
                  {user.ibo}
                </p>
              </div>
              {enableEditing && (
                <div className="profile__actions">
                  <button
                    className="button button--ghost-primary button--unpadded button--small"
                    onClick={() => setShowEditModal(true)}
                  >
                    <svg
                      className="button--icon-light"
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M1 15.5h14M10.5.5l3 3L5 12l-4 1 1-4z" />
                      </g>
                    </svg>
                    Edit
                  </button>

                  {isCurrentUser && (
                    <button
                      className="button button--ghost-primary button--unpadded button--small"
                      onClick={() => setShowChangePasswordModal(true)}
                    >
                      <svg
                        className="button--icon-light"
                        width="12"
                        height="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          fill="none"
                          fillRule="evenodd"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M3.5 5.1V3C3.5 1.619 4.619.5 6 .5S8.5 1.619 8.5 3v2.1" />
                          <circle cx="6" cy="10" r="1.5" />
                          <circle cx="6" cy="10" r="5.5" />
                        </g>
                      </svg>
                      Change password
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <div className="wrapper">
        <div className="dashboard-content">
          <div className="dashboard-content__sidebar">
            <div className="dashboard-content__inner dashboard-content__inner--sidebar">
              <TeamInfo
                teamId={user.teamId!}
                teams={teams}
                isChangingTeams={teamsLoading}
                user={user}
              />
              {isCurrentUser ? (
                <div className="dashboard-sidebar__item">
                  <div className="f f--center">
                    <h3 className="t-zeta t-upper t-bold u-opacity--40">
                      Subscription
                    </h3>
                    <span className={badge}>{subscriptionStatus}</span>
                  </div>
                  <Link
                    to="/manage-subscription"
                    className="button button--medium button--primary s-top--med"
                  >
                    Manage subscription
                  </Link>
                </div>
              ) : (
                <>
                  {renderCancelSubscriptionModal()}
                  {renderActivateSubscriptionModal()}
                  {renderSubscriptionInfo()}
                </>
              )}
            </div>
          </div>
          <div className="dashboard-content__main">
            <div className="dashboard-content__inner dashboard-content__inner--main">
              <TogglerWithoutNav
                leftTitle="Ticket history"
                rightTitle="Content history"
                onLeftClick={() => setTab(ProfileTab.Tickets)}
                onRightClick={() => setTab(ProfileTab.History)}
                leftActive={tab === ProfileTab.Tickets}
                rightActive={tab === ProfileTab.History}
              />
              <div className="history-list history-list--no-border" key={tab}>
                {tab === ProfileTab.Tickets
                  ? renderPurchasedTickets()
                  : renderPlaybackHistory()}
              </div>
            </div>
          </div>
        </div>
      </div>

      {enableEditing && (
        <>
          {showEditModal && (
            <EditProfileModal
              profile={user}
              close={() => setShowEditModal(false)}
            />
          )}

          {showChangePasswordModal && isCurrentUser && (
            <ChangePasswordModal
              close={() => setShowChangePasswordModal(false)}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};
