import React from 'react';
import {
  VideoCategoryItem,
  useVideoCategoryForm,
  useVideos,
} from 'modules/videos';
import { Loading, Search } from 'components';
import IconEmptyStateVideo from 'assets/empty-states/empty-state-video.svg';
import { Role, RoleCheck } from 'modules/authorization';
import { useUrlState } from 'hooks';

export const VideoCategories: ReactFC = () => {
  const { categories, loading } = useVideos();
  const { open } = useVideoCategoryForm();
  const [query, setQuery] = useUrlState('search', '');

  const renderVideos = () => {
    if (loading) {
      return (
        <div className="wrapper">
          <Loading />
        </div>
      );
    }

    let filtered = [...categories];
    if (query.length) {
      filtered = filtered.filter(c =>
        c.title.toLowerCase().includes(query.toLowerCase()),
      );
    }

    if (!filtered || !filtered.length) {
      return (
        <div className="wrapper">
          <div className="empty-state">
            <img
              className="empty-state__visual"
              alt="empty"
              src={IconEmptyStateVideo}
            />
            <p className="empty-state__label">
              There are no video categories available at the moment. Please
              check again later.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="lost-flex-container">
        <VideoCategoryItem key="all" title="All" showOptions={false} />
        {filtered.map((item: VideoCategory) => {
          return (
            <VideoCategoryItem
              key={item.id}
              title={item.title}
              trackCount={item.trackCount}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <section className="content-page">
        <div className="wrapper">
          <div className="content-page__heading-container">
            <h1 className="content-page__title">Videos</h1>
          </div>
          <div className="content-page__controls">
            <Search
              placeholder="Search categories by name"
              changeCallback={setQuery}
              query={query}
            />

            <RoleCheck role={Role.Administrator}>
              <div className="content-page__controls-actions">
                <button
                  className="button button--primary button--medium"
                  type="button"
                  onClick={open}
                >
                  + Add new category
                </button>
              </div>
            </RoleCheck>
          </div>
        </div>
      </section>

      <section className="content-page__body">
        <div className="wrapper">{renderVideos()}</div>
      </section>
    </>
  );
};
