export enum Collection {
  Teams = 'teams',
  UserInfo = 'userInfo',
  Tickets = 'tickets',
  Notifications = 'notifications',
  SeenNotifications = 'seenNotifications',
  Shared = 'shared',
  Audios = 'audios',
  AudioCategories = 'audio-categories',
  VideoCategories = 'video-categories',
  Videos = 'videos',
  Events = 'events',
  AudioStats = 'audio-stats',
}
