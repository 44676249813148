import { useForm } from 'hooks';
import { EmptyState } from 'modules/dashboard';
import { stringToSlug } from 'modules/helpers';
import { usePlaylist } from 'modules/playlists';
import React from 'react';

interface OwnProps {
  type: 'audio' | 'video';
  trackId: string;
  close: VoidFunction;
}

type Props = OwnProps;

export const PlaylistSelector: ReactFC<Props> = ({ type, trackId, close }) => {
  const {
    videoPlaylists,
    audioPlaylists,
    createPlaylist,
    addToPlaylist,
    removeFromPlaylist,
  } = usePlaylist(type);

  const { state, resetState, update, validateForm } = useForm<{ name: string }>(
    { name: '' },
    {
      rules: {
        name: value =>
          !!value &&
          (type === 'audio'
            ? !audioPlaylists.some(p => p.id === stringToSlug(value))
            : !videoPlaylists.some(p => p.id === stringToSlug(value))),
      },
    },
  );

  function handleCreateNewPlaylist() {
    if (!validateForm()) return;
    createPlaylist(state.name, trackId);
    resetState();
  }

  function isAudioPlaylist(
    item: AudioPlaylist | VideoPlaylist,
  ): item is AudioPlaylist {
    return type === 'audio';
  }

  function isChecked(item: AudioPlaylist | VideoPlaylist) {
    if (isAudioPlaylist(item)) {
      return item.audios.includes(trackId);
    } else {
      return item.videos.includes(trackId);
    }
  }

  function handleToggle(playlistId: string) {
    const playlist =
      type === 'audio'
        ? audioPlaylists.find(p => p.id === playlistId)
        : videoPlaylists.find(p => p.id === playlistId);
    if (!playlist) return;

    const checked = isChecked(playlist);
    if (checked) {
      removeFromPlaylist(trackId, playlistId);
    } else {
      addToPlaylist(trackId, playlistId);
    }
  }

  function renderPlaylists() {
    const playlists = type === 'audio' ? audioPlaylists : videoPlaylists;

    return (
      <>
        {!playlists.length ? (
          <EmptyState
            type={type}
            message="No playlists have been created yet."
          />
        ) : (
          playlists.map(item => {
            const checked = isChecked(item);

            return (
              <div className="f s-bottom--med" key={item.id}>
                <input
                  className="input input--check-default"
                  onChange={() => handleToggle(item.id)}
                  id={item.id}
                  name={item.id}
                  type="checkbox"
                  checked={checked}
                />
                <label htmlFor={item.id} className="t-small s-left--med">
                  {item.name}
                </label>
              </div>
            );
          })
        )}
      </>
    );
  }

  return (
    <React.Fragment>
      <div className="modal__content playlist-selector">
        {renderPlaylists()}

        <p className="field__lbl s-top--lrg t-left">Or create a new playlist</p>

        <input
          type="text"
          className="input t-base input--text"
          placeholder="Enter playlist name"
          name="playlistName"
          value={state.name}
          onChange={({ currentTarget }) => update('name', currentTarget.value)}
        />
        <div className="f f--center-alt s-top--med">
          <button
            disabled={!state.name.length}
            onClick={handleCreateNewPlaylist}
            className="button button--outline button--outline-primary button--small"
          >
            Create and add track
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
