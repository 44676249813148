import React, { useState } from 'react';
import { NotificationList } from './NotificationList';
import { useNotifications } from '../hooks';

export const NotificationBell: ReactFC = () => {
  const [visible, setVisible] = useState(false);
  const { notifications, seen } = useNotifications();
  const unseen = notifications.length - seen.length;
  const count = unseen < 0 ? 0 : unseen;

  return (
    <div className="notification">
      <button
        onClick={() => setVisible(true)}
        className="button notification__bell"
      >
        <svg width="16" height="16">
          <g fill="none" fillRule="evenodd">
            <path fillRule="nonzero" d="M-1080-24H320v64h-1400z" />
            <g stroke="#004378" strokeLinecap="round" strokeLinejoin="round">
              <path d="M10 13.5c0 1.105-.895 2-2 2s-2-.895-2-2" />
              <path d="M.5 11.5h15" />
              <path d="M14.5 11.5h.5c-1.105 0-2.5-.895-2.5-2V5C12.5 2.515 10.485.5 8 .5S3.5 2.515 3.5 5v4.5c0 1.105-.895 2-2 2" />
            </g>
          </g>
        </svg>
        {count ? (
          <span className="is-mobile t-zeta t-primary notification__bell__mobile-text">
            You have {count} new notifications
          </span>
        ) : (
          <span className="is-mobile t-zeta t-primary notification__bell__mobile-text t-faded">
            There are no new notifications
          </span>
        )}
        <svg className="notification__bell__arrow" width="10" height="5">
          <path
            d="M.5 5L5 .5 9.5 5"
            stroke="#004378"
            fill="none"
            fillRule="evenodd"
          />
        </svg>
      </button>

      <p className={`notification__count ${count === 0 && 'u-display--none'}`}>
        {count}
      </p>
      {visible && <NotificationList close={() => setVisible(false)} />}
    </div>
  );
};
