import { useForm } from 'hooks';

import { Input, MainModal } from 'components';
import { useState } from 'react';
import { useVideoCategoryForm } from '../hooks';
import { VideoCategoryConstructor } from '../models';

type VideoCategoryState = Omit<VideoCategory, 'id' | 'trackCount'>;

export const VideoCategoryForm: React.FC = () => {
  const { category, update, add, close } = useVideoCategoryForm();
  const [loading, setLoading] = useState(false);

  const form = useForm<VideoCategoryState>(
    {
      title: category?.title || '',
    },
    { rules: { title: value => value.length <= 100 } },
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      if (!form.validateForm()) return;

      setLoading(true);
      if (category) await update(category.id, form.state);
      else await add(new VideoCategoryConstructor(form.state));

      close();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <MainModal
      dismissOutside={false}
      title={category ? 'Edit category' : 'New category'}
      close={close}
    >
      <form onSubmit={handleSubmit}>
        <div className="modal__content">
          <div className="field">
            <Input
              label="Title"
              maxLength={100}
              onChange={value => form.update('title', value)}
              value={form.state.title}
            />
            {form.validation.title === false && (
              <p className="t-warning t-small">
                A category title under 100 characters is required.
              </p>
            )}
          </div>
        </div>
        <div className="modal__footer">
          <button
            disabled={loading}
            className="button button--primary button--medium"
          >
            Save
          </button>
          <button
            type="button"
            className="button button--outline button--outline-primary button--medium"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </form>
    </MainModal>
  );
};
