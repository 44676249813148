import moment from 'moment';

export function getStringFromOffset(offset: number) {
  const prefix = offset < 0 ? '-' : '+';
  const [hours, minutesPercent] = offset
    .toString()
    .replace('-', '')
    .split('.');

  return `${prefix}${hours.padStart(2, '0')}:${String(
    (Number(minutesPercent || 0) / 10) * 60,
  ).padStart(2, '0')}`;
}

export function getTimestampFromRawDate(value: string, offset: number) {
  const offsetString = getStringFromOffset(offset);
  const date = `${value}${offsetString}`;
  return +new Date(date);
}

export function getDisplayDateTime(value: string) {
  const formattedDate = moment(value).format('ll');
  const formattedTime = moment(value).format('LT');
  return `${formattedDate}, ${formattedTime}`;
}
