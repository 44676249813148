import React from 'react';
import Select from 'react-select';

export interface SelectedOption<Value = string> {
  label: string;
  value: Value;
}

interface State {
  onChange: (country: SelectedOption<CustomUserInfo['country']>) => void;
  country: CustomUserInfo['country'] | undefined;
}

export const CountryOptions: SelectedOption<CustomUserInfo['country']>[] = [
  {
    value: 'International',
    label: 'International',
  },
  {
    value: 'Australia',
    label: 'Australia',
  },
  {
    value: 'New Zealand',
    label: 'New Zealand',
  },
];

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    // tslint:disable-next-line:object-literal-key-quotes
    border: 'none',
    // tslint:disable-next-line:object-literal-key-quotes
    boxShadow: 'none',
    // tslint:disable-next-line:object-literal-key-quotes
    backgroundColor: 'rgba(49, 38, 58, 0.05)',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
};

export const CountrySelect: ReactFC<State> = ({ country, onChange }) => {
  return (
    <div className="field">
      <label className="t-tertiary field__lbl">Select country</label>
      <Select
        options={CountryOptions}
        value={CountryOptions.find(option => option.value === country)}
        onChange={value =>
          onChange(value as SelectedOption<CustomUserInfo['country']>)
        }
        styles={customStyles}
      />
    </div>
  );
};
