import { atom } from 'recoil';

const user = atom<CustomUserInfo | undefined>({
  key: 'authentication-custom-user-info',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const isLoggedIn = atom<boolean>({
  key: 'authentication-is-logged-in',
  default: false,
});

const resetLinkSent = atom<boolean>({
  key: 'authentication-reset-link-sent',
  default: false,
});

const loading = atom<boolean>({
  key: 'authentication-loading',
  default: true,
});

export const AuthAtoms = {
  user,
  loading,
  isLoggedIn,
  resetLinkSent,
};
