import {
  DashboardHeader,
  Loading,
  MainModal,
  ScrollingContent,
} from 'components';
import { useAuthentication } from 'modules/authentication';
import { Role, RoleCheck } from 'modules/authorization';
import {
  DashboardMemberItem,
  DashboardToolbar,
  DashboardToolbarStatsItem,
  EmptyState,
  ManageTeamLeader,
  ManageTeamMembers,
} from 'modules/dashboard';
import { useTeamMembers, useTeams } from 'modules/teams';
import { useParams } from 'react-router';
import { GeneralError } from './GeneralError';
import IconDiamond from 'assets/icons/diamond-team.svg';
import IconPeopleGroup from 'assets/icons/people-group.svg';
import { useEffect, useState } from 'react';
import { TeamMembersCSVDownload } from 'modules/reports';

export const TeamMembers: ReactFC = () => {
  const { user } = useAuthentication();
  const { id = user?.teamId || '' } = useParams();
  const {
    loading,
    getTeamById,
    getTeamWithSubteamsById,
    mapSubteams,
    setTeamLeader,
  } = useTeams();
  const {
    getTeamMembersById,
    updateTeamMembers,
    unassignedUsers,
  } = useTeamMembers();

  const [members, setMembers] = useState<CustomUserInfo[]>([]);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showLeaderModal, setShowLeaderModal] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(true);

  useEffect(() => {
    if (!id) return;
    getTeamMembersById(id)
      .then(setMembers)
      .finally(() => setLoadingMembers(false));
  }, [id]);

  const team = getTeamById(id);
  if (!team || !user) return <GeneralError />;

  const subteams = mapSubteams(id);

  const handleUpdateTeamMembers = async (
    usersToRemove: CustomUserInfo[],
    usersToAdd: CustomUserInfo[],
  ) => {
    if (team) {
      await updateTeamMembers(usersToRemove, usersToAdd, team);
      getTeamMembersById(team.id).then(setMembers);
      setShowManageModal(false);
    }
  };

  const handleUpdateTeamLeader = (leader?: CustomUserInfo) => {
    if (team) {
      setTeamLeader(team, leader || null);
      setShowLeaderModal(false);
    }
  };

  return (
    <>
      <DashboardHeader />
      <DashboardToolbar>
        <div className="dashboard-toolbar__content">
          <h3 className="t-extrabold">{team.name}</h3>
        </div>
        <RoleCheck role={Role.Administrator}>
          <div className="dashboard-toolbar__controls">
            <button
              className="button button--outline button--outline-primary button--tiny button--action"
              onClick={() => setShowLeaderModal(true)}
            >
              Edit leader
            </button>
          </div>
        </RoleCheck>
      </DashboardToolbar>
      <DashboardToolbar>
        <div className="dashboard-toolbar__content">
          <DashboardToolbarStatsItem icon={IconDiamond}>
            <p className="t-zeta">{subteams.length} subteams</p>
          </DashboardToolbarStatsItem>
          <DashboardToolbarStatsItem icon={IconPeopleGroup}>
            <p className="t-zeta">{team.memberCount} members</p>
          </DashboardToolbarStatsItem>
          {team.teamLeaderName && (
            <p className="t-zeta">
              <span className="t-faded">Led by</span> {team.teamLeaderName}
            </p>
          )}
        </div>
      </DashboardToolbar>

      <div className="wrapper">
        <section key={id} className="dashboard-body">
          <div className="f f--center s-bottom--lrg">
            <h3 className="t-zeta t-upper u-opacity--40">
              Direct team members
            </h3>

            <div>
              <RoleCheck role={Role.TeamLeader}>
                <TeamMembersCSVDownload
                  className="button button--outline button--outline-primary button--small s-right--sml"
                  currentTeam={team}
                  teams={getTeamWithSubteamsById(team.id)}
                />
              </RoleCheck>
              <RoleCheck role={Role.Administrator}>
                <button
                  className="button button--primary button--small"
                  onClick={() => setShowManageModal(true)}
                >
                  Manage members
                </button>
              </RoleCheck>
            </div>
          </div>
          {loading || loadingMembers ? (
            <Loading withoutContainer />
          ) : (
            <div className="dashboard-grid">
              <ScrollingContent
                data={members}
                emptyComponent={
                  <EmptyState
                    type="user"
                    message="This team has no direct members. To get the full membership tree, generate the CSV."
                  />
                }
                renderItem={member => (
                  <DashboardMemberItem key={member.id} member={member} />
                )}
              />
            </div>
          )}
        </section>
      </div>

      <RoleCheck role={Role.Administrator}>
        {showManageModal && (
          <MainModal
            title="Manage team members"
            close={() => setShowManageModal(false)}
          >
            <ManageTeamMembers
              teamMembers={members}
              usersChanging={loadingMembers}
              unassignedUsers={unassignedUsers}
              updateMembersCallback={handleUpdateTeamMembers}
              cancelCallback={() => setShowManageModal(false)}
            />
          </MainModal>
        )}

        {showLeaderModal && (
          <MainModal
            title="Manage team leader"
            close={() => setShowLeaderModal(false)}
          >
            <ManageTeamLeader
              team={team}
              users={members}
              usersChanging={loadingMembers}
              updateLeaderCallback={handleUpdateTeamLeader}
              cancelCallback={() => setShowLeaderModal(false)}
            />
          </MainModal>
        )}
      </RoleCheck>
    </>
  );
};
