import { Unsubscribe } from 'firebase/firestore';
import { atom } from 'recoil';

const loading = atom<boolean>({
  key: 'audios-loading',
  default: false,
});

const categories = atom<AudioCategory[]>({
  key: 'audio-categories',
  default: [],
  dangerouslyAllowMutability: true,
});

const audios = atom<Record<string, AudioInfo[]>>({
  key: 'audios',
  default: {},
  dangerouslyAllowMutability: true,
});

const currentAudio = atom<AudioInfo | undefined>({
  key: 'current-audio',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const lastPlayed = atom<AudioInfo[]>({
  key: 'audios-last-played',
  default: [],
  dangerouslyAllowMutability: true,
});

const categoryFormOpen = atom<boolean>({
  key: 'audio-category-form-open',
  default: false,
});

const editingCategory = atom<AudioCategory | undefined>({
  key: 'editing-audio-category',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const audioFormOpen = atom<boolean>({
  key: 'audio-form-open',
  default: false,
});

const audioFormCategory = atom<string | undefined>({
  key: 'audio-form-category-id',
  default: undefined,
});

const editingAudio = atom<AudioInfo | undefined>({
  key: 'editing-audio',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const categoryListeners = atom<
  Record<string, Unsubscribe | Unsubscribe[] | undefined>
>({
  key: 'audios-firestore-listeners',
  default: {},
  dangerouslyAllowMutability: true,
});

export const AudioAtoms = {
  loading,
  categories,
  audios,
  lastPlayed,
  editingCategory,
  categoryFormOpen,
  audioFormOpen,
  audioFormCategory,
  editingAudio,
  currentAudio,
  categoryListeners,
};
