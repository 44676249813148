import React from 'react';
import { Link } from 'react-router-dom';
interface Props {
  children: any;
}

export const TeamStatsItem: ReactFC<Props> = ({ children }) => {
  return (
    <div className="dashboard-card">
      <div className="dashboard-card__header">
        <div className="dashboard-card__container f f--center">
          <h3 className="t-zeta t-upper t-bold u-opacity--40">Team stats</h3>
          <Link to="/team" className="t-zeta anchor--secondary">
            View all team stats
          </Link>
        </div>
      </div>
      <div className="dashboard-card__container f f--center">{children}</div>
    </div>
  );
};
