import React from 'react';
import IconEmptyStatesError from 'assets/empty-states/error.svg';

export class GeneralError extends React.Component {
  render() {
    return (
      <section className="general-error">
        <div className="general-error__content wrapper empty-state empty-state--error">
          <img
            className="empty-state__visual"
            alt="empty"
            src={IconEmptyStatesError}
          />
          <h3 className="empty-state__label t-bold u-opacity--20">
            Something went wrong. Please try again later.
          </h3>
        </div>
      </section>
    );
  }
}
