import { useAuthentication } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';
import { useRecoilValue } from 'recoil';
import { SeenNotification, TmakNotification } from '../models';
import { NotificationAtoms } from '../recoil';

const SeenNotifications = new FirestoreService<SeenNotification>(
  Collection.SeenNotifications,
);

export function useNotifications() {
  const { user } = useAuthentication();
  const loading = useRecoilValue(NotificationAtoms.loading);
  const seen = useRecoilValue(NotificationAtoms.seen);
  const notifications = useRecoilValue(NotificationAtoms.notifications);

  async function markNotificationSeen(notification: TmakNotification) {
    if (!user?.id) return;
    try {
      await SeenNotifications.add({
        notificationCreatedTime: notification.timeCreated,
        notificationId: notification.id,
        userId: user.id,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async function markAllNotificationsSeen() {
    const promises = notifications
      .filter(n => !seen.some(s => s.notificationId === n.id))
      .map(markNotificationSeen);

    Promise.all(promises).catch(console.error);
  }

  return {
    loading,
    seen,
    notifications,
    markNotificationSeen,
    markAllNotificationsSeen,
  };
}
