import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

export function useFirebaseStorage() {
  /** Uploads file and returns download URL and storage path */
  async function uploadFile(basePath: 'audios' | 'thumbnails', file: File) {
    const path = `${basePath}/${Date.now()}-${file.name}`;
    const reference = ref(getStorage(), path);
    await uploadBytes(reference, file);
    const url = await getDownloadURL(reference);
    return { path, url };
  }

  return { uploadFile };
}
