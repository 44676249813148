import { atom } from 'recoil';

const audios = atom<Record<string, AudioInfo[]>>({
  key: 'playlist-audios',
  default: {},
  dangerouslyAllowMutability: true,
});

const videos = atom<Record<string, VideoInfo[]>>({
  key: 'playlist-videos',
  default: {},
  dangerouslyAllowMutability: true,
});

export const PlaylistAtoms = {
  audios,
  videos,
};
