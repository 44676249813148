import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Loading } from 'components';
import { StripeCustomerForm, useStripe } from 'modules/stripe';
import { useSubscription } from 'modules/subscription';
import { useAuthentication } from 'modules/authentication';
import { toast } from 'react-toastify';

export const Subscription: ReactFC = () => {
  const { subscribeUser } = useStripe();
  const { user } = useAuthentication();
  const { subscription } = useSubscription();
  const [subscribing, setSubscribing] = useState(false);

  /** This means the subscription has been created, but is still being processed (waiting for charge success / failed) */
  const processing = subscription?.stripe?.processing || null;

  async function handleSuccess(sourceId: string) {
    try {
      setSubscribing(true);
      if (!user) throw new Error('Unauthorized.');
      await subscribeUser(user?.email, sourceId);
    } catch (error) {
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong and we could not subscribe you. Please try again later',
      );
    } finally {
      setSubscribing(false);
    }
  }

  const valid =
    (subscription?.stripe?.subscriptionExpirationTime || 0) >= Date.now();
  const showForm =
    !subscription?.isLegacySubscription && !subscription?.stripe?.customerId;

  const renderVisual = () => {
    const defaultStateVisual = (
      <div className="subscription__visual">
        <img
          className="subscription__visual__image"
          src={require('assets/images/subscription-first-time.png')}
          alt="Subscription visual"
        />
      </div>
    );

    const expiredStateVisual = (
      <div className="subscription__visual">
        <img
          className="subscription__visual__image"
          src={require('assets/images/subscription-expired.png')}
          alt="Subscription visual"
        />
      </div>
    );

    return processing === 'failed' ? expiredStateVisual : defaultStateVisual;
  };

  return (
    <section className="subscription">
      <div className="wrapper">
        <div className="subscription__container">
          <div className="subscription__plans">
            <h1 className="subscription__title t-bold">
              Your TeamMAK Subscription
            </h1>
            {subscribing || processing === 'pending' ? (
              <div className="f f--center--mobile f--gap f--wrap">
                {subscribing && (
                  <p className="t-zeta">
                    Processing your subscription. Please wait a moment.
                  </p>
                )}
                <Loading withoutPadding />
              </div>
            ) : (
              <>
                {showForm ? (
                  <>
                    <p className="t-zeta t-faded">
                      Available subscription plans
                    </p>
                    <div className="subscription__plan__item">
                      <p className="subscription__plan__item__label t-bold u-opacity--40">
                        Basic
                      </p>
                      <h2 className="subscription__plan__item__title t-bold u-opacity--80">
                        $49.50
                        <span className="profile__subscription-recurring t-bold u-opacity--80">
                          / month
                        </span>
                      </h2>
                    </div>
                    <StripeCustomerForm onSuccess={handleSuccess} />
                  </>
                ) : (
                  <>
                    {processing === 'failed' && (
                      <>
                        <p className="subscription__message u-opacity--80">
                          Your subscription failed to go through. Check your
                          account balance and try again{' '}
                          <Link
                            to="/manage-subscription"
                            className="t-secondary"
                          >
                            here.
                          </Link>{' '}
                          If the issue persists, try changing your default card.
                        </p>
                        <p className="subscription__message u-opacity--80 s-top--med">
                          If you think this is a mistake,{' '}
                          <Link to="/contact" className="t-secondary t-bold">
                            Contact us
                          </Link>{' '}
                          for support.
                        </p>
                      </>
                    )}
                    {!valid && processing === null && (
                      <p className="subscription__message u-opacity--80">
                        Your subscription has expired. Manage your subscription{' '}
                        <Link to="/manage-subscription" className="t-secondary">
                          here.
                        </Link>
                      </p>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {renderVisual()}
        </div>
      </div>
    </section>
  );
};
