import { atom } from 'recoil';
import { SeenNotification, TmakNotification } from '../models';

const loading = atom<boolean>({
  key: 'notifications-loading',
  default: false,
});

const notifications = atom<TmakNotification[]>({
  key: 'notifications',
  default: [],
  dangerouslyAllowMutability: true,
});

const seen = atom<SeenNotification[]>({
  key: 'notifications-seen',
  default: [],
  dangerouslyAllowMutability: true,
});

export const NotificationAtoms = {
  loading,
  notifications,
  seen,
};
