export interface AudioInfoForm
  extends Omit<
    AudioInfo,
    | 'id'
    | 'normalizedTitle'
    | 'timeCreated'
    | 'audioUrl'
    | 'audioPath'
    | 'thumbnailUrl'
    | 'thumbnailPath'
  > {
  audioUrl: string | null;
  audioPath: string | null;
  thumbnailUrl: string | null;
  thumbnailPath: string | null;
  audioFile: File | null;
  thumbnailFile: File | null;
}

export class AudioInfoForm {
  constructor(audio: AudioInfo | undefined) {
    this.title = audio?.title || '';
    this.author = audio?.author || '';
    this.description = audio?.description || '';
    this.shortDescription = audio?.shortDescription || '';
    this.categoryIds = audio?.categoryIds || [];
    this.status = audio?.status || 'draft';

    this.audioPath = audio?.audioPath || null;
    this.audioUrl = audio?.audioUrl || null;
    this.audioFile = null;

    this.thumbnailPath = audio?.thumbnailPath || null;
    this.thumbnailUrl = audio?.thumbnailUrl || null;
    this.thumbnailFile = null;
  }
}
