import * as views from 'views';
import { AppRoute } from '../models';
import { ACLType } from 'modules/authorization';

const publicRoutes: AppRoute[] = [
  {
    key: 'home',
    access: ACLType.AnonymousOnly,
    Element: views.Home,
    title: 'TeamMak',
    path: '/',
  },
  {
    key: 'contact',
    access: ACLType.Public,
    Element: views.Contact,

    title: 'TeamMak',
    path: '/contact',
  },
  {
    key: 'login',
    access: ACLType.AnonymousOnly,
    Element: views.Login,
    title: 'Login',
    path: '/login',
  },
  {
    key: 'register',
    access: ACLType.AnonymousOnly,
    Element: views.Register,
    title: 'Register',
    path: '/register',
  },
  {
    key: 'forgot-password',
    access: ACLType.AnonymousOnly,
    Element: views.ForgotPassword,
    title: 'Forgot Password',
    path: '/forgot-password',
  },
  {
    key: 'terms-and-conditions',
    access: ACLType.Public,
    Element: views.TermsAndConditions,
    title: 'Terms and conditions',
    path: '/terms-and-conditions',
  },
  {
    key: 'privacy-policy',
    access: ACLType.Public,
    Element: views.PrivacyPolicy,
    title: 'Privacy policy',
    path: '/privacy-policy',
  },
  {
    key: 'shared',
    access: ACLType.Public,
    Element: views.PublicAudioDetails,
    title: 'Shared audio',
    path: '/shared/:shareId',
  },
];

export default publicRoutes;
