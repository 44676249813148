import React, { useEffect, useState } from 'react';
import {
  Toggler,
  DashboardHeader,
  MainModal,
  Loading,
  ScrollingContent,
  DeleteModal,
} from 'components';
import {
  PlaylistItem,
  PlaylistModalForm,
  usePlaylist,
} from 'modules/playlists';
import { VideoItem } from 'modules/videos';
import { useNavigate, useParams } from 'react-router';
import { useAuthentication } from 'modules/authentication';
import { DashboardToolbar, EmptyState } from 'modules/dashboard';
import { useVideoPlayer } from 'modules/video-player/hooks';

export const VideoPlaylists: ReactFC = () => {
  const navigate = useNavigate();
  const { playlist = '' } = useParams();
  const { user } = useAuthentication();
  const {
    videos,
    videoPlaylists,
    getVideoPlaylistById,
    loadPlaylistVideos,
    deletePlaylist,
    createPlaylist,
    updatePlaylist,
  } = usePlaylist('video');
  const { loadPlaylist } = useVideoPlayer();
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const currentPlaylist = getVideoPlaylistById(playlist);
  const isFavorites = currentPlaylist?.id === 'favorites';

  /** Load playlist videos. */
  useEffect(() => {
    if (!currentPlaylist) return;

    setLoading(true);
    loadPlaylistVideos(currentPlaylist.id, currentPlaylist.videos)
      .then(() => setLoading(false))
      .catch();
  }, [currentPlaylist?.videos]);

  if (!user) return null;

  async function handleCreatePlaylist(name: string) {
    const id = await createPlaylist(name);
    setShowCreateModal(false);
    navigate(`/video-playlists/${id}`);
  }

  function handleDeletePlaylist() {
    if (!currentPlaylist) return;
    deletePlaylist(currentPlaylist.id);
    setShowDeleteModal(false);
    navigate('/video-playlists');
  }

  function handleEditPlaylist(name: string) {
    if (!currentPlaylist) return;
    updatePlaylist(currentPlaylist.id, name);
    setShowEditModal(false);
  }

  const playPlaylist = () => {
    if (!currentPlaylist || !videos[currentPlaylist.id]) return;
    loadPlaylist(
      videos[currentPlaylist.id].map(v => v.id),
      currentPlaylist.id,
    );
  };

  const renderPlaylistItems = () => {
    if (loading && currentPlaylist) return <Loading withoutContainer />;
    if (!currentPlaylist) {
      return (
        <EmptyState
          type="video"
          message="Select a playlist to view its contents."
        />
      );
    }

    if (!videos[currentPlaylist.id]?.length) {
      return (
        <EmptyState
          type="video"
          message="No tracks have been added to this playlist yet."
        />
      );
    }

    return (
      <React.Fragment>
        <div className="playlist__dashboard__header f f--center">
          <h1 className="t-delta t-bold u-opacity--20 desktop-only">
            {currentPlaylist.name}
          </h1>
          <button
            className="button  button--outline button--outline-primary button--small"
            onClick={playPlaylist}
          >
            Play all
          </button>
        </div>
        <div className="lost-flex-container playlist__list">
          <ScrollingContent
            data={videos[currentPlaylist.id]}
            renderItem={renderVideoItem}
          />
        </div>
      </React.Fragment>
    );
  };

  const renderVideoItem = (video: VideoInfo) => {
    return (
      <VideoItem
        key={video.id}
        video={video}
        parentUrl={`/video-playlists/${playlist}`}
      />
    );
  };

  const renderFavorites = () => {
    if (!videoPlaylists || !videoPlaylists.length) {
      return null;
    }

    const favorites = videoPlaylists.find(p => p.id === 'favorites');
    if (!favorites) return null;

    return (
      <PlaylistItem
        {...favorites}
        trackCount={favorites.videos.length}
        type="video"
      />
    );
  };

  const renderPlaylists = () => {
    const filtered = videoPlaylists?.filter(p => p.id !== 'favorites');
    if (!filtered || !filtered.length) {
      return (
        <p className="t-zeta u-opacity--40">
          No other playlists have been created yet.
        </p>
      );
    }

    return filtered.map(playlist => (
      <PlaylistItem
        key={playlist.id}
        {...playlist}
        trackCount={playlist.videos.length}
        type="video"
      />
    ));
  };

  return (
    <React.Fragment>
      <DashboardHeader />
      <DashboardToolbar>
        <div className="dashboard-toolbar__content">
          <h3 className="t-delta t-extrabold">
            {currentPlaylist?.name || 'Your playlists'}
          </h3>
        </div>
        <div className="dashboard-toolbar__controls">
          {currentPlaylist && !isFavorites && (
            <>
              <button
                className="button button--tiny button--outline button--outline-primary"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </button>
              <button
                className="button button--tiny button--outline button--outline-primary"
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </button>
            </>
          )}
          <button
            className="button button--small button--primary"
            onClick={() => setShowCreateModal(true)}
          >
            Create new
          </button>
        </div>
      </DashboardToolbar>
      <div className="wrapper">
        <div className="dashboard-content playlists">
          <div className="dashboard-content__sidebar">
            <div className="dashboard-content__inner dashboard-content__inner--sidebar">
              <div className="dashboard-sidebar__item">
                <Toggler
                  leftLink="/audio-playlists"
                  leftTitle="Audios"
                  rightLink="/video-playlists"
                  rightTitle="Videos"
                />
              </div>
              <div className="dashboard-sidebar__item">
                {renderFavorites()}

                <header className="f f--center s-top--med">
                  <h1 className="t-zeta t-upper t-bold u-opacity--40">
                    My playlists
                  </h1>
                </header>

                <section>{renderPlaylists()}</section>
              </div>
            </div>
          </div>
          <div className="dashboard-content__main">
            <div className="dashboard-content__inner dashboard-content__inner--main">
              {renderPlaylistItems()}
            </div>
          </div>
        </div>
      </div>

      {showCreateModal && (
        <MainModal
          title="Create new playlist"
          close={() => setShowCreateModal(false)}
        >
          <PlaylistModalForm type="video" onSave={handleCreatePlaylist} />
        </MainModal>
      )}

      {showDeleteModal && (
        <DeleteModal
          confirm={handleDeletePlaylist}
          description="Are you sure you want to delete this playlist? This action cannot be undone"
          title={`Delete the ${currentPlaylist?.name} playlist?`}
          close={() => setShowDeleteModal(false)}
        />
      )}

      {showEditModal && (
        <MainModal
          title="Edit playlist name"
          close={() => setShowEditModal(false)}
        >
          <PlaylistModalForm
            onSave={handleEditPlaylist}
            defaultValue={currentPlaylist?.name}
            type="video"
          />
        </MainModal>
      )}
    </React.Fragment>
  );
};
