import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

export function getTypedDocument<T>(
  snapshot: DocumentSnapshot<DocumentData>,
): T | undefined {
  if (!snapshot.exists()) return undefined;
  const doc = ({ id: snapshot.id, ...snapshot.data() } as unknown) as T;
  return doc;
}

export function getTypedCollectionDocument<T>(
  snapshot: QueryDocumentSnapshot<DocumentData>,
) {
  const doc = ({ id: snapshot.id, ...snapshot.data() } as unknown) as T;
  return doc;
}
