import React, { useState } from 'react';
import {
  DashboardHeader,
  Loading,
  TogglerWithoutNav,
  ScrollingContent,
  Input,
} from 'components';
import {
  DashboardMemberItem,
  DashboardToolbar,
  EmptyState,
} from 'modules/dashboard';
import { toast } from 'react-toastify';
import { useUsers } from 'modules/users';

enum FieldTab {
  LastName = 'normalisedLastName',
  NormalisedEmail = 'normalisedEmail',
}

export const DashboardUsers: ReactFC = () => {
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [field, setField] = useState<FieldTab>(FieldTab.LastName);
  const [searchResults, setSearchResults] = useState<CustomUserInfo[]>([]);
  const { searchForUsers } = useUsers();

  async function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    try {
      if (query.length < 3 || searching) return;
      setSearching(true);
      const results = await searchForUsers(query, field);
      setSearchResults(results);
    } catch (e) {
      toast.error(
        'Something went wrong when searching for users. Please try again.',
      );
    } finally {
      setSearching(false);
    }
  }

  return (
    <>
      <DashboardHeader />
      <DashboardToolbar>
        <div className="dashboard-toolbar__content">
          <h3 className="t-extrabold">Search users</h3>
        </div>
      </DashboardToolbar>
      <DashboardToolbar>
        <div className="dashboard-toolbar__content">
          <form className="f f--center" onSubmit={handleSubmit}>
            <Input
              disabled={searching}
              value={query}
              onChange={setQuery}
              name="search-users"
              placeholder={
                field === FieldTab.NormalisedEmail
                  ? 'Search users by email'
                  : 'Search users by last name'
              }
            />
            <button
              disabled={searching || query.length < 3}
              className="button button--primary button--medium"
              type="submit"
            >
              Search
            </button>
          </form>
        </div>
        <div className="dashboard-toolbar__controls">
          <TogglerWithoutNav
            leftTitle="Last name"
            rightTitle="Email"
            onLeftClick={() => setField(FieldTab.LastName)}
            onRightClick={() => setField(FieldTab.NormalisedEmail)}
            leftActive={field === FieldTab.LastName}
            rightActive={field === FieldTab.NormalisedEmail}
          />
        </div>
      </DashboardToolbar>
      <div className="dashboard-body">
        <div className="wrapper">
          {searching ? (
            <Loading withoutContainer />
          ) : (
            <ScrollingContent
              emptyComponent={<EmptyState type="user" />}
              data={searchResults}
              renderItem={user => (
                <DashboardMemberItem key={user.id} member={user} />
              )}
            />
          )}
        </div>
      </div>
    </>
  );
};
