import { orderBy, where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import {
  Collection,
  FirestoreService,
  useFirebaseStorage,
} from 'modules/firebase';
import { stringToSlug } from 'modules/helpers';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AudioAtoms } from '../recoil';
import { toast } from 'react-toastify';
import { AudioInfoConstructor, AudioInfoForm } from '../models';
import { Role } from 'modules/authorization';
import { AudioStatsAtoms } from 'modules/audio-stats';

const AudiosCollection = new FirestoreService<AudioInfo>(Collection.Audios);
const AudioCategoriesCollection = new FirestoreService<AudioCategory>(
  Collection.AudioCategories,
);

/** Hook to handle audio and audio category related actions (fetching, updating, tracking progress) */
export function useAudios() {
  const { uploadFile } = useFirebaseStorage();
  const { user } = useAuthentication();
  const stats = useRecoilValue(AudioStatsAtoms.stats);
  const [audios, setAudios] = useRecoilState(AudioAtoms.audios);
  const [currentAudio, setCurrentAudio] = useRecoilState(
    AudioAtoms.currentAudio,
  );
  const [lastPlayed, setLastPlayed] = useRecoilState(AudioAtoms.lastPlayed);
  const [loading, setLoading] = useRecoilState(AudioAtoms.loading);
  const categories = useRecoilValue(AudioAtoms.categories);
  const [listeners, setListeners] = useRecoilState(
    AudioAtoms.categoryListeners,
  );

  function getCategoryByTitleSlug(slug: string): AudioCategory | undefined {
    return categories.find(category => stringToSlug(category.title) === slug);
  }

  function addSpecialCategoryAccess(
    categoryId: string,
    usersWithAccess: Array<{ id: string; displayName: string }>,
  ) {
    return AudioCategoriesCollection.set(categoryId, { usersWithAccess });
  }

  async function loadAllAudios() {
    // All audios are being listened to already
    if (listeners['all']) return;
    setLoading(true);

    // Unsubscribe all other listeners because "all" overrules all of them.
    Object.values(listeners).forEach(listener => {
      if (listener) {
        Array.isArray(listener) ? listener.forEach(l => l()) : listener();
      }
    });

    const categoryIds = categories.map(c => c.id);
    if (user?.role === Role.Administrator) {
      const listener = AudiosCollection.listen({
        onError: console.error,
        onFinished: () => setLoading(false),
        onSuccess: items => {
          const map: Record<string, AudioInfo[]> = { all: items };
          for (const category of categoryIds) {
            map[category] = items.filter(a => a.categoryIds.includes(category));
          }

          setAudios(map);
        },
      });

      setListeners({ all: listener });
      return;
    } else {
      /** Listener per category */
      const listeners = categoryIds.map(categoryId =>
        AudiosCollection.listen({
          onSuccess: items => {
            setAudios(current => ({
              ...current,
              all: [
                ...(current['all'] || []).filter(
                  a => !items.some(i => i.id === a.id),
                ),
                ...items,
              ],
              [categoryId]: items,
            }));
          },
          onError: console.error,
          filters: [
            where('categoryIds', 'array-contains', categoryId),
            where('status', '==', 'published'),
          ],
        }),
      );

      /** Listener for unassigned audios */
      listeners.push(
        AudiosCollection.listen({
          filters: [
            where('categoryIds', '==', []),
            where('status', '==', 'published'),
          ],
          onSuccess: items => {
            setAudios(current => ({
              ...current,
              all: [...(current['all'] || []), ...items],
            }));
          },
          onError: console.error,
        }),
      );

      setListeners({ all: listeners });
      setLoading(false);
    }
  }

  async function loadCategoryAudios(categoryId: string) {
    if (listeners[categoryId] || listeners['all']) return;
    setLoading(true);

    const listener = AudiosCollection.listen({
      onError: console.error,
      onFinished: () => setLoading(false),
      onSuccess: items =>
        setAudios(audios => ({ ...audios, [categoryId]: items })),
      filters:
        user?.role === 'ADMIN'
          ? [
              where('categoryIds', 'array-contains', categoryId),
              orderBy('timeCreated', 'desc'),
            ]
          : [
              where('categoryIds', 'array-contains', categoryId),
              where('status', '==', 'published'),
              orderBy('timeCreated', 'desc'),
            ],
    });

    setListeners({ ...listeners, [categoryId]: listener });
  }

  async function getAudioById(audioId: string) {
    const audiosMap = Object.values(audios).flat() || [];
    return (
      audiosMap.find(a => a.id === audioId) ||
      (await AudiosCollection.getById(audioId))
    );
  }

  async function loadLastPlayed() {
    const trackIds = stats.map(s => s.trackId).slice(0, 3);
    /** If last played audios are different to the ones stored or none are loaded, load audios. */
    if (trackIds.some(id => !lastPlayed.some(lp => lp.id !== id))) {
      try {
        setLoading(true);
        const promises = trackIds.map(AudiosCollection.getById);
        const list = await Promise.all(promises);
        const filtered = list.filter(item => item !== undefined) as AudioInfo[];

        setLastPlayed(filtered);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  }

  function removeCategory(id: string) {
    return AudioCategoriesCollection.remove(id);
  }

  function removeAudio(id: string) {
    return AudiosCollection.remove(id);
  }

  async function publishAudio(audio: AudioInfo) {
    try {
      toast.info('Publishing audio', { toastId: 'audio-publish' });

      await AudiosCollection.set(audio.id, { status: 'published' });
      if (currentAudio?.id === audio.id)
        setCurrentAudio({ ...audio, status: 'published' });

      toast.dismiss('audio-publish');
      toast.success('Audio has been published.');
    } catch (error) {
      console.error(error);
      toast.dismiss('audio-publish');
      toast.error(
        error instanceof Error ? error.message : 'Something went wrong.',
      );
    }
  }

  async function addAudio(data: AudioInfoForm) {
    if (!data.audioFile) return;
    const audio = await uploadFile('audios', data.audioFile);
    const thumbnail = data.thumbnailFile
      ? await uploadFile('thumbnails', data.thumbnailFile)
      : null;

    const document = new AudioInfoConstructor(data, audio, thumbnail);
    document.timeCreated = Date.now();

    return AudiosCollection.add(document);
  }

  async function updateAudio(id: string, data: AudioInfoForm) {
    const audio = data.audioFile
      ? await uploadFile('audios', data.audioFile)
      : null;
    const thumbnail = data.thumbnailFile
      ? await uploadFile('thumbnails', data.thumbnailFile)
      : null;

    const document = new AudioInfoConstructor(data, audio, thumbnail);
    return AudiosCollection.set(id, document).then(() => {
      if (currentAudio?.id === id)
        setCurrentAudio({ ...currentAudio, ...document });
    });
  }

  return {
    loading,
    audios,
    categories,
    lastPlayed,
    currentAudio,
    setCurrentAudio,
    getAudioById,
    getCategoryByTitleSlug,
    loadAllAudios,
    loadCategoryAudios,
    loadLastPlayed,
    addSpecialCategoryAccess,
    removeCategory,
    removeAudio,
    publishAudio,
    addAudio,
    updateAudio,
  };
}
