import React from 'react';

import { ValidatedInput, CustomSelect } from 'components';
import { TeamTypeEnum } from '../models';

interface Props {
  id: string;
  type: TeamType;
  teamName: string;
  parentId: string | null;
  teams: Team[];
  onSubmit: (
    name: string,
    id: string,
    type: TeamType,
    parentId: string | null,
  ) => void;
}
interface State {
  newTeamName: string;
  newParentTeam: string | undefined;
  selectedTeamType: string;
  [prop: string]: string | boolean | undefined;
}

export class EditTeam extends React.Component<Props, State> {
  state = {
    newTeamName: this.props.teamName,
    selectedTeamType: this.props.type,
    newParentTeam: this.props.parentId ? this.props.parentId : undefined,
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.teamName !== this.props.teamName) {
      this.setState({ newTeamName: nextProps.teamName });
    }

    if (nextProps.parentId && nextProps.parentId !== this.props.parentId) {
      this.setState({ newParentTeam: nextProps.parentId });
    }

    if (nextProps.type !== this.props.type) {
      this.setState({ selectedTeamType: nextProps.type });
    }
  }

  handleSave = () =>
    this.props.onSubmit(
      this.state.newTeamName,
      this.props.id,
      this.state.selectedTeamType,
      this.state.newParentTeam || null,
    );

  onInputChange = (prop: string, value: string) => {
    this.setState({
      [prop]: value,
    });
  };

  selectTeam = (propName: string, value: string) =>
    this.setState({ [propName]: value });

  selectDiamond = () =>
    this.setState({ selectedTeamType: TeamTypeEnum.Diamond });
  selectPlatinum = () =>
    this.setState({ selectedTeamType: TeamTypeEnum.Platinum });

  getSubteams = (teamId: string) => {
    const { teams } = this.props;

    // remove team with passed teamId and his subteams from teams
    let filtered = teams.filter(
      t => t.parentTeamId !== teamId && t.id !== teamId,
    );

    // create array of subteam ids
    let subTeamsIds = teams
      .filter(t => t.parentTeamId === teamId)
      .map(i => i.id);

    while (subTeamsIds.length) {
      for (const subTeamId of subTeamsIds) {
        // create array of subteams subteams
        const subTeamsSubTeamsIds = filtered
          .filter(t => t.parentTeamId === subTeamId)
          .map(i => i.id);

        // remove subteams and add array of next level subteams
        subTeamsIds = subTeamsIds.filter(t => t !== subTeamId);
        subTeamsIds = [...subTeamsIds, ...subTeamsSubTeamsIds];

        filtered = filtered.filter(t => t.id !== subTeamId);
      }
    }

    return filtered.filter(t => t.teamType !== TeamTypeEnum.Platinum);
  };

  render() {
    const { newTeamName, newParentTeam } = this.state;
    const { id } = this.props;
    const withoutCurrentAndSubTeams = this.getSubteams(id);

    return (
      <React.Fragment>
        <div className="modal__content">
          {/* <TogglerWithoutNav
            leftActive={selectedTeamType === TeamType.Diamond}
            rightActive={selectedTeamType === TeamType.Platinum}
            leftTitle="Diamond"
            rightTitle="Platiunm"
            onLeftClick={this.selectDiamond}
            onRightClick={this.selectPlatinum}
          /> */}
          <ValidatedInput
            label="Team name"
            propName="newTeamName"
            changeCallback={this.onInputChange}
            value={newTeamName}
            emptyErrorText="Enter team name"
          />
          {/* show only on top level teams */}
          <CustomSelect
            handleChange={this.selectTeam}
            propName="newParentTeam"
            selectedValue={newParentTeam}
            optionsArray={withoutCurrentAndSubTeams}
            disabledOption="Top level team"
            label="Change parent team"
            noItemsInArrayMessage="No teams to display"
          />
        </div>
        <div className="modal__footer">
          <button
            className="button  modal__submit"
            onClick={this.handleSave}
            disabled={!newTeamName.length}
          >
            Save changes
          </button>
        </div>
      </React.Fragment>
    );
  }
}
