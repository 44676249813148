import { orderBy } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { VideoAtoms } from '../recoil';
import { useSubscription } from 'modules/subscription';
import { Role } from 'modules/authorization';

const VideoCategories = new FirestoreService<VideoCategory>(
  Collection.VideoCategories,
);

export function useVideosListener() {
  const { user } = useAuthentication();
  const { subscribed } = useSubscription();
  const setLoading = useSetRecoilState(VideoAtoms.loading);
  const setCategories = useSetRecoilState(VideoAtoms.categories);

  /** Load all audio categories if administrator */
  useEffect(() => {
    if (!user?.id || (user.role !== Role.Administrator && !subscribed)) return;
    const listener = VideoCategories.listen({
      filters: [orderBy('title', 'asc')],
      onError: error =>
        console.log(`Failed to load video categories: ${error}`),
      onSuccess: setCategories,
      onFinished: () => setLoading(false),
    });

    return listener;
  }, [user?.id, user?.role, subscribed]);
}
