import React from 'react';
import { Link } from 'react-router-dom';
import { Role, RoleCheck } from 'modules/authorization';

interface Props {
  event?: EventInfo;
  linkOverride?: string;
}

export const DashboardEventItem: ReactFC<Props> = ({ event, linkOverride }) => {
  if (!event) return null;

  return (
    <Link
      to={linkOverride || `/event-report/${event.id}`}
      className="dashboard-events__item card card--dashboard"
    >
      <div className="card__content">
        <h1 className="card__title t-extrabold">{event.title}</h1>

        <RoleCheck role={Role.Administrator} exact={true}>
          <div className="card__info">
            <div className="card__info-half">
              <p className="card__label u-opacity--40">Total revenue</p>
              <p className="t-zeta u-opacity--80">
                {event.revenue ? (
                  `$${event.revenue.toFixed(2)}`
                ) : (
                  <span className="u-opacity--60">No revenue yet</span>
                )}
              </p>
            </div>
            <div className="card__info-half">
              <p className="t-zeta t-faded">Tickets sold</p>
              <p className="t-zeta u-opacity--80">
                {event.ticketsSold || 0}
                <span className="u-opacity--60">
                  <span> /</span> {event.numberOfTickets}
                </span>
              </p>
            </div>
          </div>
        </RoleCheck>
      </div>
    </Link>
  );
};
