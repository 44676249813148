import { where } from 'firebase/firestore';
import { Collection, FirestoreService, SafeBatch } from 'modules/firebase';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TeamAtoms } from '../recoil';
import { useTeams } from './useTeams';

const UserInfoCollection = new FirestoreService<CustomUserInfo>(
  Collection.UserInfo,
);

export function useTeamMembers() {
  const { getTeamWithSubteamsById, setTeamLeader } = useTeams();
  const unassignedUsers = useRecoilValue(TeamAtoms.unassignedUsers);
  const [loading, setLoading] = useRecoilState(TeamAtoms.loadingMembers);

  async function getTeamMembersById(teamId: string) {
    try {
      setLoading(true);
      return UserInfoCollection.get([where('teamId', '==', teamId)]);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function getTeamMembersIncludingSubteamsById(teamId: string) {
    try {
      const teamIds = getTeamWithSubteamsById(teamId).map(team => team.id);
      const promises: Promise<CustomUserInfo[]>[] = [];
      const chunks = Math.ceil(teamIds.length / 30);

      for (let i = 0; i < chunks; i++) {
        const startIndex = i * 30;
        const ids = teamIds.slice(startIndex, startIndex + 30);
        promises.push(UserInfoCollection.get([where('teamId', 'in', ids)]));
      }

      const results = await Promise.all(promises);
      return results.flat();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function updateTeamMembers(
    removeUsers: CustomUserInfo[],
    addUsers: CustomUserInfo[],
    team: Team,
  ) {
    try {
      setLoading(true);
      const batch = new SafeBatch<CustomUserInfo>();

      for (const user of removeUsers) {
        /** Remove team leader */
        if (team.teamLeaderId === user.id) setTeamLeader(team, null);

        batch.update(UserInfoCollection.generateDocumentReference(user.id), {
          teamId: null,
        });
      }

      for (const user of addUsers) {
        batch.update(UserInfoCollection.generateDocumentReference(user.id), {
          teamId: team.id,
        });
      }

      await batch.commit();
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    unassignedUsers,
    getTeamMembersById,
    getTeamMembersIncludingSubteamsById,
    updateTeamMembers,
  };
}
