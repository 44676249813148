import React from 'react';
import { Link } from 'react-router-dom';
import { DivWithBackgroundImage } from 'components';

interface Props {
  audio: AudioInfo;
  parentUrl: string;
}

export class AudioItemSimple extends React.Component<Props> {
  render() {
    const { audio, parentUrl } = this.props;

    return (
      <Link to={`${parentUrl}/${audio.id}`} className="dashboard-list-item">
        <DivWithBackgroundImage
          mainImageUrl={audio.thumbnailUrl}
          fallbackImageUrl={require('assets/images/placeholder-square.png')}
          classes="dashboard-list-item__thumbnail"
        />
        <div className="dashboard-list-item__content">
          <h1 className="title title--card-dashboard">{audio.title}</h1>
        </div>
      </Link>
    );
  }
}
