import { orderBy, where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import { Role } from 'modules/authorization';
import { Collection, FirestoreService } from 'modules/firebase';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { AudioAtoms } from '../recoil';
import { useSubscription } from 'modules/subscription';

const AudioCategories = new FirestoreService<AudioCategory>(
  Collection.AudioCategories,
);

export function useAudiosListener() {
  const { user } = useAuthentication();
  const { subscribed } = useSubscription();
  const setLoading = useSetRecoilState(AudioAtoms.loading);
  const setCategories = useSetRecoilState(AudioAtoms.categories);

  /** Load all audio categories if administrator */
  useEffect(() => {
    if (!user?.id || user.role !== Role.Administrator) return;
    const listener = AudioCategories.listen({
      filters: [where('title', '!=', 'All'), orderBy('title', 'asc')],
      onError: error =>
        console.log(`Failed to load audio categories: ${error}`),
      onSuccess: setCategories,
      onFinished: () => setLoading(false),
    });

    return listener;
  }, [user?.id, user?.role]);

  /** Load only public categories and categories with access if not administrator */
  useEffect(() => {
    if (!user?.id || user.role === Role.Administrator || !subscribed) return;

    const listener = AudioCategories.listen({
      filters: [
        where('title', '!=', 'All'),
        where('isSpecialContent', '==', true),
        where('usersWithAccess', 'array-contains', {
          id: user.id,
          displayName: user.displayName,
        }),
      ],
      onError: error =>
        console.log(`Failed to load audio categories: ${error}`),
      onFinished: () => setLoading(false),
      onSuccess: locked => {
        AudioCategories.get([
          where('isSpecialContent', 'in', [false, null]),
          where('title', '!=', 'All'),
        ]).then(regular =>
          setCategories(
            [...regular, ...locked].sort((a, b) =>
              a.title >= b.title ? 1 : -1,
            ),
          ),
        );
      },
    });

    return listener;
  }, [user?.id, user?.role, subscribed]);
}
