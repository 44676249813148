import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  leftLink: string;
  rightLink: string;
  leftTitle: string;
  rightTitle: string;
}

export const Toggler: ReactFC<Props> = ({
  leftLink,
  leftTitle,
  rightLink,
  rightTitle,
}) => {
  const location = useLocation();
  const leftActive = location.pathname.includes(leftLink);
  const rightActive = location.pathname.includes(rightLink);
  return (
    <div className="toggler">
      <Link
        to={leftLink}
        className={`button button--small button--ghost toggler__item ${
          leftActive ? 'active' : ''
        }`}
      >
        {leftTitle}
      </Link>
      <Link
        to={rightLink}
        className={`button button--small button--ghost toggler__item ${
          rightActive ? 'active' : ''
        }`}
      >
        {rightTitle}
      </Link>
    </div>
  );
};
