import { getDisplayDateTime } from 'utils';

interface Props {
  event: EventInfo;
}

export const BuyTicketsHeader: ReactFC<Props> = ({ event }) => {
  return (
    <>
      <h1 className="t-left t-xxxlarge t-bold t-text-1">Buy tickets</h1>
      <p className="t-xlarge buy-tickets__title t-bold">{event.title}</p>
      <div className="f f--column buy-tickets__details__container">
        <p className="t-base t-text-3">
          {getDisplayDateTime(event.startDateRaw)} &mdash;{' '}
          {getDisplayDateTime(event.endDateRaw)}
        </p>

        <p className="t-base t-text-3">
          {event.venue && <>{event.venue}</>}
          {event.location && (
            <>
              <span className="s-left--sml s-right--sml">&bull;</span>{' '}
              {event.location}
            </>
          )}
        </p>
      </div>
    </>
  );
};
