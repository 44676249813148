import IconInfo from 'assets/icons/notice-info.svg';
import {
  AdministrationOptions,
  ConfirmModal,
  PageHeader,
  PageLoading
} from 'components';
import { Role, RoleCheck } from 'modules/authorization';
import { useEventForm, useEvents } from 'modules/events';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getDisplayDateTime } from 'utils';
import { GeneralError } from './GeneralError';

export const EventDetails: ReactFC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const {
    loading,
    allEvents,
    loadPastEvents,
    publishEvent,
    isTicketPurchaseEnabled,
    isSoldOut,
  } = useEvents();
  const { edit } = useEventForm();
  const [, setCurrentTime] = useState(Date.now());
  const [showPublishModal, setShowPublishModal] = useState(false);

  const historic = useMemo(
    () => location.pathname.includes('/events-history'),
    [location.pathname],
  );

  useEffect(() => {
    if (historic) loadPastEvents();
  }, [historic]);

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(Date.now()), 1000);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <PageLoading />;

  const event = allEvents.find(e => e.id === params.id);
  if (!event) return <GeneralError />;

  const daysUntilStart = moment(event.startTimestamp).diff(Date.now(), 'days');
  const showStartPill = daysUntilStart >= 0 && daysUntilStart <= 15;
  const soldOut = isSoldOut(event);

  return (
    <>
      <RoleCheck role={Role.Administrator}>
        {showPublishModal && (
          <ConfirmModal
            confirm={() => {
              publishEvent(event);
              setShowPublishModal(false);
            }}
            close={() => setShowPublishModal(false)}
            title={`Publish ${event.title}?`}
            description="Publishing this event makes it available for all users. Do you want to proceed?"
          />
        )}
      </RoleCheck>

      <PageHeader mobileOnly back />

      <section className="event-details">
        <div className="event-details__header">
          <div className="event-details__banner-container wrapper">
            <img
              alt=""
              loading="eager"
              className="event-details__banner"
              src={
                event.featuredImageUrl ||
                require('assets/images/placeholder-16-9.png')
              }
            />
          </div>
        </div>
        <div className="event-details__metadata">
          <div className="wrapper__narrow">
            <div className="f f--gap f--justify-between">
              <div className="f--one">
                <h1 className="title title--main t-left">{event.title}</h1>
                <p className="t-label t-label--sml t-faded">
                  {getDisplayDateTime(event.startDateRaw)} &mdash;{' '}
                  {getDisplayDateTime(event.endDateRaw)}
                  {event.venue && (
                    <>
                      <span className="s-left--sml s-right--sml">&bull;</span>
                      {event.venue}
                    </>
                  )}
                  {event.location && (
                    <>
                      <span className="s-left--sml s-right--sml">&bull;</span>{' '}
                      {event.location}
                    </>
                  )}
                </p>
              </div>

              <AdministrationOptions
                edit={() => edit(event)}
                publish={
                  event.status === 'draft'
                    ? () => setShowPublishModal(true)
                    : undefined
                }
              />
            </div>

            {event.status === 'draft' && (
              <RoleCheck role={Role.Administrator}>
                <div className="notice notice--info s-top--med">
                  <img src={IconInfo} alt="i" />
                  <div className="f f--gap">
                    <p className="t-small">
                      Content is in draft mode. Other users will not be able to
                      see it until it is published.
                    </p>

                    <button
                      type="button"
                      onClick={() => setShowPublishModal(true)}
                      className="button button--tiny button--outline button--outline-info s-left--med"
                    >
                      Publish
                    </button>
                  </div>
                </div>
              </RoleCheck>
            )}

            <div className="event-details__controls s-top--med">
              {soldOut && <p className="pill pill--warning">sold out</p>}

              {showStartPill && (
                <p className="pill pill__full--note">
                  {daysUntilStart === 0
                    ? 'Today'
                    : `in ${daysUntilStart} day(s)`}
                </p>
              )}

              {isTicketPurchaseEnabled(event) && (
                <button
                  type="button"
                  className="button button--medium button--primary"
                  onClick={() => navigate(`/buy-tickets/${event.id}`)}
                >
                  Get tickets
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="event-details__content">
          <div className="wrapper__narrow">
            {event.description && (
              <>
                <p className="t-bold t-medium">Description</p>
                <div
                  className="wysiwyg-content s-top--med"
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
