export interface AudioCategoryConstructor extends AudioCategory {}
export class AudioCategoryConstructor implements AudioCategory {
  constructor(
    data: Omit<AudioCategory, 'id' | 'usersWithAccess' | 'trackCount'>,
  ) {
    this.title = data.title;
    this.trackCount = 0;
    this.isSpecialContent = data.isSpecialContent;
    this.usersWithAccess = data.isSpecialContent ? [] : null;
  }
}
