import {
  DashboardHeader,
  Loading,
  ScrollingContent,
  MainModal,
  DeleteModal,
} from 'components';
import { RoleCheck, Role } from 'modules/authorization';
import {
  DashboardToolbar,
  DashboardToolbarStatsItem,
  EmptyState,
} from 'modules/dashboard';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTeams } from '../hooks';
import { AddNewTeam } from './AddNewTeam';
import { EditTeam } from './EditTeam';
import { TeamItem } from './TeamItem';
import IconDiamond from 'assets/icons/diamond-team.svg';
import IconPeopleGroup from 'assets/icons/people-group.svg';
import { TeamBreadcrumbs } from './TeamBreadcrumbs';

interface Props {
  team: Team | undefined;
  subteams: Team[];
}

export const TeamCore: ReactFC<Props> = ({ team, subteams }) => {
  const navigate = useNavigate();
  const { loading, userTeams, createTeam, updateTeam, deleteTeam } = useTeams();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCreateTeam = (teamType: TeamType, teamName: string) => {
    const parentId = team?.id ? team.id : null;
    createTeam(teamName, teamType, parentId);
    setShowAddModal(false);
  };

  const handleEditTeam = (
    teamName: string,
    teamId: string,
    teamType: TeamType,
    parentId: string | null,
  ) => {
    updateTeam(teamId, teamName, teamType, parentId);
    setShowEditModal(false);
  };

  const handleDeleteTeam = () => {
    if (!team) return;

    deleteTeam(team.id);
    setShowDeleteModal(false);

    if (team.parentTeamId) {
      navigate(`/team/${team.parentTeamId}`);
      return;
    }

    navigate('/team');
  };

  return (
    <>
      <DashboardHeader />
      <DashboardToolbar>
        <div className="dashboard-toolbar__content">
          <TeamBreadcrumbs team={team} />
        </div>
        {!team && (
          <div className="dashboard-toolbar__controls">
            <button
              className="button button--primary button--small"
              onClick={() => setShowAddModal(true)}
            >
              Add a team
            </button>
          </div>
        )}
      </DashboardToolbar>

      {team && (
        <DashboardToolbar>
          <div className="dashboard-toolbar__content">
            <p className="t-small u-opacity--40">
              {team.teamType === 'diamondTeam' ? 'Diamond' : 'Platinum'}
            </p>
            <DashboardToolbarStatsItem icon={IconDiamond}>
              <p className="t-zeta">{subteams.length} subteams</p>
            </DashboardToolbarStatsItem>

            {!!team?.memberCount && (
              <DashboardToolbarStatsItem icon={IconPeopleGroup}>
                <p className="t-zeta">{team.memberCount} members</p>
              </DashboardToolbarStatsItem>
            )}
            {team?.teamLeaderName && (
              <p className="t-zeta">
                <span className="t-faded">Led by</span> {team.teamLeaderName}
              </p>
            )}
          </div>
          <RoleCheck role={Role.Administrator}>
            <div className="dashboard-toolbar__controls">
              <button
                className="button button--outline button--outline-primary button--small"
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </button>
              <button
                className="button button--outline button--outline-primary button--small"
                onClick={() => setShowDeleteModal(true)}
              >
                Remove
              </button>
              <button
                className="button button--primary button--small"
                onClick={() => setShowAddModal(true)}
              >
                Add a team
              </button>
            </div>
          </RoleCheck>
        </DashboardToolbar>
      )}

      <div className="wrapper">
        <section key={team?.id} className="dashboard-body">
          <div className="f f--center s-bottom--lrg">
            <h3 className="t-zeta t-upper u-opacity--40">Subteams</h3>

            {team && (
              <button
                className="button button--primary button--small s-right--sml"
                onClick={() => navigate(`/team/${team?.id}/members`)}
              >
                View members
              </button>
            )}
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="dashboard-grid">
              <ScrollingContent
                data={subteams}
                emptyComponent={<EmptyState type="team" />}
                renderItem={team => (
                  <TeamItem
                    key={team.id}
                    team={team}
                    parentLeader={team.teamLeaderName}
                  />
                )}
              />
            </div>
          )}
        </section>
      </div>

      {showAddModal && (
        <MainModal title="Add new team" close={() => setShowAddModal(false)}>
          <AddNewTeam
            isCreatingTeam={false}
            typeSelect={true}
            createTeamCallback={handleCreateTeam}
          />
        </MainModal>
      )}

      <RoleCheck role={Role.Administrator}>
        {showDeleteModal && (
          <DeleteModal
            confirm={handleDeleteTeam}
            description="Are you sure you want to delete this team? This action cannot be undone"
            title="Delete team?"
            close={() => setShowDeleteModal(false)}
          />
        )}

        {showEditModal && team && (
          <MainModal title="Edit team" close={() => setShowEditModal(false)}>
            <EditTeam
              id={team.id}
              type={team.teamType}
              teamName={team.name}
              parentId={team.parentTeamId}
              onSubmit={handleEditTeam}
              teams={userTeams}
            />
          </MainModal>
        )}
      </RoleCheck>
    </>
  );
};
