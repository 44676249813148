import { EmptyState } from 'modules/dashboard';
import { useMemo } from 'react';
import { TempTicket } from '../models';
import { TicketPurchaseItem } from './TicketPurchaseItem';

interface Props {
  tempTickets: TempTicket[];
  removeTicket: (index: number) => void;
}

export const TicketPurchaseList: ReactFC<Props> = ({
  tempTickets,
  removeTicket,
}) => {
  const subtotal = useMemo(
    () => tempTickets.reduce((acc, ticket) => acc + ticket.price, 0),
    [tempTickets],
  );
  return (
    <div className="purchase-tickets__content">
      {tempTickets.length ? (
        <div className="u-bg--white f f--column">
          <span className="t-bold t-text-1 t-large purchase-tickets__items__title">
            Added tickets
          </span>
          <div className="purchase-tickets__cards">
            {tempTickets.map((ticket, index) => (
              <TicketPurchaseItem
                key={`temp-ticket-${index}`}
                index={index}
                ticket={ticket}
                removeTicket={removeTicket}
              />
            ))}
          </div>

          <div className="divider__dashed purchase-tickets__items__divider" />
          <div className="purchase-tickets__subtotal__container">
            <p className="t-base t-text-3 s-top--sml">Subtotal</p>
            <div className="f f--column g-4">
              <p className="t-xlarge t-bold t-text-1">
                {new Intl.NumberFormat('en-AU', {
                  style: 'currency',
                  currency: 'AUD',
                }).format(subtotal)}
              </p>
              <p className="t-text-4 t-small f--end-self--alt">VAT excl.</p>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState
          type="event"
          message="No tickets added so far"
          showIcon={false}
        />
      )}
    </div>
  );
};
