import IconOptionsMenu from 'assets/icons/options-menu.svg';
import { PropsWithChildren } from 'react';
import { DropdownMenu } from './DropdownMenu';

export const OptionsMenu: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <DropdownMenu control={<img src={IconOptionsMenu} alt="..." />}>
      {children}
    </DropdownMenu>
  );
};
