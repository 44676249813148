import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ProfileMenu } from '.';
import { ConfirmModal } from 'components';
import { NotificationBell } from 'modules/notifications';
import { RoleCheck, Role } from 'modules/authorization';
import { useAuthentication } from 'modules/authentication';
import IconNavEventsGlobal from 'assets/icons/nav-events-global.svg';
import IconNavVideos from 'assets/icons/nav-videos.svg';
import IconNavAudios from 'assets/icons/nav-audios.svg';
import IconNavTeams from 'assets/icons/nav-teams.svg';
import IconNavTickets from 'assets/icons/nav-tickets.svg';
import IconNavPlaylists from 'assets/icons/nav-playlists.svg';
import IconNavShared from 'assets/icons/nav-shared.svg';

interface OwnProps {
  isMenuToggled: boolean;
  currentRoute: string;
}

export const Navigation: ReactFC<OwnProps> = ({
  currentRoute,
  isMenuToggled,
}) => {
  const { loading, isLoggedIn, logoutAsync } = useAuthentication();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const homeRoute = isLoggedIn ? '/dashboard' : '/';
  const mobileOnlyHeader =
    currentRoute === '/login' || currentRoute === '/register';

  function renderHeaderMenu() {
    return (
      <React.Fragment>
        <div className="is-mobile">
          <NavLink
            to={homeRoute}
            className={({ isActive }) =>
              `nav__link ${isActive ? 'active' : ''}`
            }
          >
            TeamMak
          </NavLink>
        </div>
        <li
          key="events"
          className={`nav__links__item ${mobileOnlyHeader && 'is-mobile'}`}
        >
          <NavLink
            to="/events"
            className={({ isActive }) =>
              `nav__link ${isActive ? 'active' : ''}`
            }
          >
            <img
              className="s-right--med s-top--negative is-mobile"
              src={IconNavEventsGlobal}
              alt="Events icon"
            />
            Events
          </NavLink>
        </li>
        <li
          key="videos"
          className={`nav__links__item ${mobileOnlyHeader && 'is-mobile'}`}
        >
          <NavLink
            to="/videos"
            className={({ isActive }) =>
              `nav__link ${isActive ? 'active' : ''}`
            }
          >
            <img
              className="s-right--med s-top--negative is-mobile"
              src={IconNavVideos}
              alt="Videos icon"
            />
            Videos
          </NavLink>
        </li>
        <li
          key="audios"
          className={`nav__links__item ${mobileOnlyHeader && 'is-mobile'}`}
        >
          <NavLink
            to="/audios"
            className={({ isActive }) =>
              `nav__link ${isActive ? 'active' : ''}`
            }
          >
            <img
              className="s-right--med s-top--negative is-mobile"
              src={IconNavAudios}
              alt="Audios icon"
            />
            Audios
          </NavLink>
        </li>
      </React.Fragment>
    );
  }

  function renderAuthMenu() {
    return (
      <React.Fragment>
        <li
          key="login"
          className={`nav__links__item ${mobileOnlyHeader && 'is-mobile'}`}
        >
          <NavLink
            to="/login"
            className={({ isActive }) =>
              `nav__link nav__link--auth ${isActive ? 'active' : ''}`
            }
          >
            Login
          </NavLink>
        </li>
        {!mobileOnlyHeader && (
          <span className="nav__link nav__link--pipe">|</span>
        )}
        <li
          key="register"
          className={`nav__links__item ${mobileOnlyHeader && 'is-mobile'}`}
        >
          <NavLink
            to="/register"
            className={({ isActive }) =>
              `nav__link nav__link--auth ${isActive ? 'active' : ''}`
            }
          >
            Register
          </NavLink>
        </li>
      </React.Fragment>
    );
  }

  function renderDashboardMenu() {
    return (
      <ul className="nav__dashboard-menu is-mobile">
        <RoleCheck role={Role.TeamLeader}>
          <li key="teams" className="nav__links__item">
            <NavLink to="/team" className="nav__link ">
              <img
                className="s-right--med s-top--negative"
                src={IconNavTeams}
                alt="Teams icon"
              />
              Teams
            </NavLink>
          </li>
        </RoleCheck>
        <RoleCheck role={Role.Administrator}>
          <li key="users" className="nav__links__item">
            <NavLink
              to="/users"
              className={({ isActive }) =>
                `nav__link ${isActive ? 'active' : ''}`
              }
            >
              <img
                className="s-right--med s-top--negative"
                src={IconNavTeams}
                alt="Users icon"
              />
              Users
            </NavLink>
          </li>
        </RoleCheck>
        <RoleCheck role={Role.DiamondLeader}>
          <li key="event-stats" className="nav__links__item">
            <NavLink
              to="/event-stats"
              className={({ isActive }) =>
                `nav__link ${isActive ? 'active' : ''}`
              }
            >
              <img
                className="s-right--med s-top--negative"
                src={IconNavEventsGlobal}
                alt="Events icon"
              />
              Event stats
            </NavLink>
          </li>
        </RoleCheck>
        <li key="tickets" className="nav__links__item">
          <NavLink
            to="/tickets"
            className={({ isActive }) =>
              `nav__link ${isActive ? 'active' : ''}`
            }
          >
            <img
              className="s-right--med s-top--negative"
              src={IconNavTickets}
              alt="Tickets icon"
            />
            Tickets
          </NavLink>
        </li>
        <li key="playlists" className="nav__links__item">
          <NavLink
            to="/audio-playlists"
            className={({ isActive }) =>
              `nav__link ${isActive ? 'active' : ''}`
            }
          >
            <img
              className="s-right--med s-top--negative"
              src={IconNavPlaylists}
              alt="Playlists icon"
            />
            Playlists
          </NavLink>
        </li>
        <li key="shared-items" className="nav__links__item">
          <NavLink
            to="/shared-items"
            className={({ isActive }) =>
              `nav__link ${isActive ? 'active' : ''}`
            }
          >
            <img
              className="s-right--med s-top--negative"
              src={IconNavShared}
              alt="Shared icon"
            />
            Shared items
          </NavLink>
        </li>
      </ul>
    );
  }

  const logoutUser = () => {
    logoutAsync();
    closeConfirmModal();
  };

  const closeConfirmModal = () => {
    setConfirmModalVisible(false);
  };

  const openConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  function renderUserMenu() {
    return (
      <div className="nav__other">
        <ProfileMenu onLogout={openConfirmModal} />
        <NotificationBell />
      </div>
    );
  }

  return (
    <nav className={`nav ${isMenuToggled ? 'is-visible' : ''}`}>
      {isMenuToggled && <h1 className="nav__mobile-title t-extrabold">Menu</h1>}
      <ul className={`nav__links ${mobileOnlyHeader && 'is-mobile'}`}>
        {!mobileOnlyHeader && (
          <NavLink to={homeRoute} className="nav__link nav__link--home t-text}">
            <img
              className="nav__link__logo"
              src={require('assets/images/logo.png')}
              alt="Logo"
            />
            <span className="t-bold">Team</span>Mak
          </NavLink>
        )}
        {!loading && renderHeaderMenu()}
      </ul>
      {isLoggedIn ? (
        <React.Fragment>
          {renderUserMenu()}
          {renderDashboardMenu()}
        </React.Fragment>
      ) : (
        <ul className={`nav__links ${mobileOnlyHeader && 'is-mobile'}`}>
          {!loading && renderAuthMenu()}
        </ul>
      )}
      {confirmModalVisible && (
        <ConfirmModal
          title="Confirm logout"
          description="You're about to log out from TeamMak. Are you sure?"
          confirm={logoutUser}
          close={closeConfirmModal}
        />
      )}
    </nav>
  );
};
