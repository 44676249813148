import React, { useEffect, useMemo, useState } from 'react';
import {
  StripeSubscriptionStatus,
  StripeCustomerInfo,
  StripeResubscribe,
  useStripe,
  StripeCustomerSimple,
  StripeCancelSubscription,
} from 'modules/stripe';
import { DeleteModal, Loading } from 'components';
import {
  getSubscriptionStatus,
  getSubscriptionBadgeClass,
} from 'modules/subscription/helpers';
import { useAuthentication } from 'modules/authentication';
import { useSubscription } from 'modules/subscription';
import IconEmptyStateShared from 'assets/empty-states/empty-state-shared.svg';

interface State {
  confirmModalIsVisible: boolean;
  showRenewModal: boolean;
  sourceId: string | undefined;
}

export const SubscriptionManagement: ReactFC = props => {
  const { user } = useAuthentication();
  const {
    getCustomerByIdAsync,
    addSourceToCustomer,
    removeSourceFromCustomer,
    setDefaultSource,
  } = useStripe();
  const { subscription, loading } = useSubscription();
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [customer, setCustomer] = useState<StripeCustomerSimple>();
  const [state, setState] = useState<State>({
    confirmModalIsVisible: false,
    sourceId: undefined,
    showRenewModal: false,
  });

  useEffect(() => {
    if (subscription) getCustomerInfoFromStripe(subscription);
  }, [subscription]);

  const getCustomerInfoFromStripe = (subscription: UserSubscription) => {
    if (!subscription?.stripe?.customerId) {
      return;
    }

    setLoadingCustomer(true);
    getCustomerByIdAsync(subscription?.stripe?.customerId)
      .then(setCustomer)
      .finally(() => setLoadingCustomer(false));
  };

  const isLegacyUser = () => {
    return subscription?.isLegacySubscription;
  };

  const handleAddSource = (sourceId: string) => {
    if (!subscription || typeof subscription?.stripe?.customerId !== 'string') {
      return;
    }

    setLoadingCustomer(true);
    addSourceToCustomer(subscription?.stripe?.customerId, sourceId)
      .then(setCustomer)
      .finally(() => setLoadingCustomer(false));
  };

  const handleConfirmRemove = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    const { sourceId } = event.currentTarget.dataset;

    if (!sourceId || !subscription || !subscription?.stripe?.customerId) {
      return;
    }

    setState({ ...state, confirmModalIsVisible: true, sourceId });
  };

  const handleRemoveSource = () => {
    const { sourceId } = state;

    if (!sourceId || !subscription || !subscription?.stripe?.customerId) {
      return;
    }

    setState({ ...state, confirmModalIsVisible: false });
    setLoadingCustomer(true);
    removeSourceFromCustomer(subscription?.stripe?.customerId, sourceId)
      .then(setCustomer)
      .finally(() => setLoadingCustomer(false));
  };

  const closeConfirmModal = () => {
    setState({ ...state, confirmModalIsVisible: false });
  };

  const handleSetDefaultSource = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const { sourceId } = event.currentTarget.dataset;
    if (!sourceId || !subscription || !subscription?.stripe?.customerId) {
      return;
    }

    setLoadingCustomer(true);
    setDefaultSource(subscription?.stripe?.customerId, sourceId)
      .then(setCustomer)
      .finally(() => setLoadingCustomer(false));
  };

  const { confirmModalIsVisible } = state;
  const isLegacy = isLegacyUser();
  const sources = useMemo(() => customer?.sources?.data || [], [customer]);
  const status = useMemo(() => getSubscriptionStatus(subscription), [
    subscription,
  ]);

  const badge = useMemo(() => getSubscriptionBadgeClass(status), [status]);

  if (!user) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <header className="dashboard-header profile">
        <div className="wrapper">
          <h1 className="s-bottom--med t-delta t-bold t-negative is-mobile">
            Subscription management
          </h1>
          <div className="profile__header">
            <img
              alt="profile"
              src={
                user?.photoURL ||
                require('assets/images/placeholder-square.png')
              }
              className="avatar avatar--profile"
            />
            <div className="profile__info">
              <div>
                <p className="profile__name t-gamma t-negative t-extrabold">
                  {user?.displayName}
                </p>
                <p className="t-theta t-negative u-opacity--80">
                  {user?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="wrapper">
        <div className="dashboard-content">
          <div className="dashboard-content__sidebar">
            <div className="dashboard-content__inner dashboard-content__inner--sidebar">
              <div className="dashboard-sidebar__item">
                <div className="f f--center">
                  <h3 className="t-zeta t-upper t-bold u-opacity--40">
                    Subscription
                  </h3>
                  <span className={badge}>{status}</span>
                </div>
              </div>

              {!isLegacy ? (
                <>
                  <StripeSubscriptionStatus subscription={subscription} />
                  <StripeResubscribe
                    customerId={subscription?.stripe?.customerId || undefined}
                    canResubscribe={
                      !subscription?.stripe?.active && Boolean(sources?.length)
                    }
                  />
                  <StripeCancelSubscription
                    customerId={subscription?.stripe?.customerId || undefined}
                    canCancel={Boolean(
                      subscription?.stripe?.active &&
                        customer?.subscriptions?.data.length,
                    )}
                  />
                </>
              ) : (
                <div className="dashboard-sidebar__item">
                  <p className="t-epsilon u-opacity--60">Legacy subscription</p>
                </div>
              )}
            </div>
          </div>
          <div className="dashboard-content__main">
            <div className="dashboard-content__inner dashboard-content__inner--main">
              {isLegacy ? (
                <div className="empty-state s-top--med">
                  <img
                    className="empty-state__visual"
                    alt="empty"
                    src={IconEmptyStateShared}
                  />
                  <p className="empty-state__label">
                    You are activated as a legacy user and have full access to
                    all media content!
                  </p>
                </div>
              ) : (
                <>
                  <StripeCustomerInfo
                    defaultSource={customer?.default_source}
                    customer={customer}
                    sources={sources}
                    isLoadingSources={loadingCustomer}
                    isLoadingCustomer={loadingCustomer}
                    isChanging={loadingCustomer}
                    handleRemoveSource={handleConfirmRemove}
                    handleSetDefaultSource={handleSetDefaultSource}
                    addSourceToCustomer={handleAddSource}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {confirmModalIsVisible && (
        <DeleteModal
          title="Confirm source remove"
          description="You're about to remove payment source. Are you sure?"
          confirm={handleRemoveSource}
          close={closeConfirmModal}
        />
      )}
    </>
  );
};
