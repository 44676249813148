import React, { useEffect, useState } from 'react';
import { FavoriteButton, MainModal, PlaylistSelector } from 'components';

import { AudioPlayerCore, useAudioPlayer } from '..';
import { usePlaylist } from 'modules/playlists';
import IconPlaylist from 'assets/icons/playlist-add.svg';
import IconDownload from 'assets/icons/download.svg';
import { useLocation } from 'react-router';

export const AudioPlayer: ReactFC = () => {
  const location = useLocation();
  const { audio, tracks, close } = useAudioPlayer();
  const { isFavorite, toggleFavorite } = usePlaylist('audio');
  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isSharedAudio] = useState(location.pathname.includes('/shared/'));

  useEffect(() => {
    return close;
  }, []);

  useEffect(() => {
    document.title = audio?.title ? `TeamMAK | ${audio.title}` : document.title;
  }, [audio]);

  const toggleExpandingPlayer = () => setExpanded(!expanded);
  const openPlaylistModal = () => setShowPlaylistModal(true);
  const closePlaylistModal = () => setShowPlaylistModal(false);

  const renderFavoriteButton = () => {
    if (!audio || isSharedAudio) return null;
    return (
      <FavoriteButton
        className="button button--ghost button--unpadded"
        isFavorite={isFavorite(audio.id)}
        toggleCallback={() => toggleFavorite(audio.id)}
      />
    );
  };

  const renderPlaylistButton = () => {
    if (isSharedAudio) return null;
    return (
      <button
        className="button button--ghost button--unpadded"
        onClick={openPlaylistModal}
      >
        <img src={IconPlaylist} alt="+=" />
      </button>
    );
  };

  const renderDownloadButton = () => {
    if (isSharedAudio) return null;
    return (
      <button
        className="button button--ghost button--unpadded"
        onClick={() => setShowDownloadModal(true)}
      >
        <img src={IconDownload} alt="⇓" />
      </button>
    );
  };

  const renderExpandButton = () => (
    <button
      onClick={toggleExpandingPlayer}
      className={`${
        expanded
          ? 'persistent-player__expand active'
          : 'persistent-player__expand'
      }`}
    >
      <svg width="12" height="6">
        <path
          d="M11.625 4.219l-5-4a1 1 0 0 0-1.25 0l-5 4c-.4313.3452-.5012.9747-.156 1.406.3452.4313.9747.5012 1.406.156L6 2.281l4.375 3.5c.4313.3452 1.0608.2753 1.406-.156.3452-.4313.2753-1.0608-.156-1.406z"
          fill="#444"
          fillRule="nonzero"
        />
      </svg>
    </button>
  );

  const renderDownloadModal = () => {
    if (!audio?.audioUrl || !showDownloadModal || isSharedAudio) return null;

    return (
      <MainModal close={() => setShowDownloadModal(false)}>
        <div className="modal__content f f--column f--center">
          <p className="t-zeta t-center">
            Right click on the button below and click "Save Link As..."
          </p>
          <p className="t-zeta t-center s-bottom--lrg">
            or press and hold and press "Download Link" on mobile devices to
            download audio file.
          </p>
          <a
            className="button button--medium button--primary s-bottom--lrg"
            href={audio.audioUrl}
            download={audio.author + ' ' + audio.title}
          >
            Download
          </a>
        </div>
      </MainModal>
    );
  };

  if (!audio) return <audio />;

  return (
    <React.Fragment>
      <AudioPlayerCore
        isPlaylist={(tracks?.length || 0) > 1}
        audio={audio}
        downloadButton={renderDownloadButton()}
        expandButton={renderExpandButton()}
        favoriteButton={renderFavoriteButton()}
        playlistButton={renderPlaylistButton()}
        playerIsExpanded={expanded}
      />

      {renderDownloadModal()}
      {showPlaylistModal && (
        <MainModal title="Edit playlist" close={closePlaylistModal}>
          <PlaylistSelector
            trackId={audio!.id}
            type="audio"
            close={closePlaylistModal}
          />
        </MainModal>
      )}
    </React.Fragment>
  );
};
