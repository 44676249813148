import { useCallback, useEffect, useRef } from 'react';

export function useClickOutsideElement<T extends HTMLElement>(
  callback: VoidFunction,
  enabled: boolean = true,
) {
  const ref = useRef<T>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        event.preventDefault();
        callback();
      }
    },
    [callback],
  );

  useEffect(() => {
    if (!enabled) return;
    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [enabled]);

  return ref;
}
