import React from 'react';
import { DashboardHeader, Loading } from 'components';
import { ShareListItem, useSharedAudios } from 'modules/share';
import IconEmptyStateShared from 'assets/empty-states/empty-state-shared.svg';

export const SharedItems: ReactFC = () => {
  const { shared, loading } = useSharedAudios();

  const renderSharedItems = () => {
    if (loading) {
      return <Loading withoutContainer />;
    }

    if (!shared.length) {
      return (
        <div className="empty-state">
          <img
            className="empty-state__visual"
            alt="empty"
            src={IconEmptyStateShared}
          />
          <p className="empty-state__label">
            No shared items available at the moment. Please check again later.
          </p>
        </div>
      );
    }

    return shared.map(item => <ShareListItem key={item.id!} item={item} />);
  };

  return (
    <React.Fragment>
      <DashboardHeader />
      <div className="wrapper">
        <div className="content--dashboard">
          <div className="shared">
            <div className="history-list__header history-list__header--shared">
              <span className="history-list__field__shared t-iota u-opacity--40">
                Media title
              </span>
              <span className="history-list__field__shared t-iota u-opacity--40 t-center">
                Link
              </span>
              <span className="history-list__field__shared t-iota u-opacity--40">
                First shared
              </span>
            </div>
            <div className="history-list__body">{renderSharedItems()}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
