import React from 'react';

import { DashboardTicketItem, EmptyState } from '.';
import { Loading, ScrollingContent } from 'components';
import { getMemberTicketData } from '..';
import { useAuthentication } from 'modules/authentication';

interface Props {
  tickets: Ticket[];
  loading: boolean;
}

export const RenderTicketList: ReactFC<Props> = ({ tickets, loading }) => {
  const { user } = useAuthentication();

  if (loading) return <Loading />;

  const memberTicketData = getMemberTicketData({
    user,
    tickets,
  });

  if (!memberTicketData.length) {
    return (
      <EmptyState
        type="team"
        message="No ticket history available at the moment."
      />
    );
  }

  return (
    <ScrollingContent
      data={memberTicketData}
      renderItem={td => <DashboardTicketItem key={td.id} ticketData={td} />}
    />
  );
};
