import { useRecoilState } from 'recoil';
import { AudioAtoms } from '../recoil';
import { Collection, FirestoreService } from 'modules/firebase';

const AudioCategoryCollection = new FirestoreService<AudioCategory>(
  Collection.AudioCategories,
);

export function useAudioCategoryForm() {
  const [isOpen, setIsOpen] = useRecoilState(AudioAtoms.categoryFormOpen);
  const [category, setCategory] = useRecoilState(AudioAtoms.editingCategory);

  function open() {
    setIsOpen(true);
    setCategory(undefined);
  }

  function edit(category: AudioCategory) {
    setIsOpen(true);
    setCategory(category);
  }

  function close() {
    setIsOpen(false);
    setCategory(undefined);
  }

  return {
    category,
    isOpen,
    edit,
    open,
    close,
    add: AudioCategoryCollection.add,
    update: AudioCategoryCollection.set,
    remove: AudioCategoryCollection.remove,
  };
}
