import { Role } from 'modules/authorization';
import { MemberTicketData } from 'modules/tickets';

interface Props {
  user?: CustomUserInfo;
  tickets: Ticket[];
}

export const getMemberTicketData = ({
  user,
  tickets,
}: Props): MemberTicketData[] => {
  if (user?.role === Role.User) return [];

  const memberIds = Array.from(
    new Set(tickets.map(ticket => ticket.purchasedById)),
  );

  return memberIds.map(id => {
    const memberName =
      tickets.find(t => t.purchasedById === id)?.purchasedByName || null;
    const memberTickets = tickets.filter(t => t.purchasedById === id);
    const revenue = memberTickets
      .map(t => t.ticketPrice)
      .reduce((revenue, current) => (revenue += current), 0);

    return {
      id,
      memberName,
      revenue,
      ticketCount: memberTickets.length,
    };
  });
};
