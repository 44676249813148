import IconEmptyStateTeam from 'assets/empty-states/empty-state-team.svg';
import IconEmptyStateAudio from 'assets/empty-states/empty-state-audio.svg';
import IconEmptyStateEvent from 'assets/empty-states/empty-state-event.svg';

interface Props {
  message?: string;
  showIcon?: boolean;
  type: 'team' | 'event' | 'user' | 'audio' | 'video';
}

const Icons: Record<Props['type'], string> = {
  team: IconEmptyStateTeam,
  event: IconEmptyStateEvent,
  user: IconEmptyStateTeam,
  audio: IconEmptyStateAudio,
  video: IconEmptyStateTeam,
};

export const EmptyState: ReactFC<Props> = ({
  type,
  message,
  showIcon = true,
}) => (
  <div className="empty-state u-width--full">
    {showIcon && (
      <img className="empty-state__visual" alt="empty" src={Icons[type]} />
    )}
    <p className="empty-state__label">
      {message || 'Nothing to show here at the moment'}
    </p>
  </div>
);
