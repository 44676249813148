import { Unsubscribe } from 'firebase/auth';
import { atom } from 'recoil';

const loading = atom<boolean>({
  key: 'videos-loading',
  default: false,
});

const categories = atom<VideoCategory[]>({
  key: 'videos-categories',
  default: [],
  dangerouslyAllowMutability: true,
});

const videos = atom<Record<string, VideoInfo[]>>({
  key: 'videos',
  default: {},
  dangerouslyAllowMutability: true,
});

const lastPlayed = atom<VideoInfo[]>({
  key: 'videos-last-played',
  default: [],
  dangerouslyAllowMutability: true,
});

const categoryFormOpen = atom<boolean>({
  key: 'video-category-form-open',
  default: false,
});

const editingCategory = atom<VideoCategory | undefined>({
  key: 'editing-video-category',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const currentVideo = atom<VideoInfo | undefined>({
  key: 'current-video',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const videoFormCategory = atom<string | undefined>({
  key: 'video-form-category',
  default: undefined,
});

const videoFormOpen = atom<boolean>({
  key: 'video-form-open',
  default: false,
});

const editingVideo = atom<VideoInfo | undefined>({
  key: 'editing-video',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const categoryListeners = atom<
  Record<string, Unsubscribe | Unsubscribe[] | undefined>
>({
  key: 'videos-category-listeners',
  default: {},
  dangerouslyAllowMutability: true,
});

export const VideoAtoms = {
  loading,
  videos,
  lastPlayed,
  categories,
  categoryFormOpen,
  editingCategory,
  currentVideo,
  videoFormCategory,
  editingVideo,
  videoFormOpen,
  categoryListeners,
};
