import { useAuthentication } from 'modules/authentication';
import { useEvents } from 'modules/events';
import { useStripeCheckout } from 'modules/stripe';
import {
  containsDuplicateEmails,
  useTicketPurchase,
  validateTicketHolder
} from 'modules/tickets';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props {
  event: EventInfo;
  tempTickets: TempTicket[];
}

export const BuyTicketsActions: ReactFC<Props> = ({ event, tempTickets }) => {
  const navigate = useNavigate();
  const { loading } = useEvents();
  const { user } = useAuthentication();
  const { createCheckout } = useStripeCheckout();
  const { checkIfTicketExists } = useTicketPurchase(event);

  const [checkoutLoading, setCheckoutLoading] = useState(false);

  function navigateBack() {
    navigate(-1);
    window.scrollTo(0, 0);
  }

  async function handleCheckout() {
    try {
      if (!event) return;
      setCheckoutLoading(true);
      if (!tempTickets.every(validateTicketHolder)) {
        toast.error(
          'Some ticket holder information is invalid. Please check and try again.',
        );
        return;
      }

      if (containsDuplicateEmails(tempTickets)) {
        toast.error(
          'Some ticket holders have the same email address. Please check and try again.',
        );
        return;
      }

      for (const ticket of tempTickets) {
        const exists = await checkIfTicketExists(ticket.email.toUpperCase());
        if (exists) {
          toast.error(
            `A ticket has already been purchased with the email address: ${ticket.email}. Please use a different email.`,
          );
          return;
        }
      }

      const checkoutSessionData = await createCheckout({
        tickets: tempTickets,
        event,
        email: user?.email || '',
      });
      if (!checkoutSessionData.url) throw new Error('No URL returned');
      window.open(checkoutSessionData.url, '_self');
    } catch (error) {
      toast.error(
        'Something went wrong with the purchase. Please try again or contact us if the problem persists.',
      );
    } finally {
      setCheckoutLoading(false);
    }
  }

  return (
    <>
      <div className="buy-tickets__terms__container">
        <p className="t-small t-text-3 buy-tickets__terms__content">
          By purchasing these tickets you accept and agree to the following
          &nbsp;
          <a
            href="/terms-and-conditions#ticketing"
            className="t-small t-bold t-primary"
            target="_blank"
          >
            Terms and Conditions
          </a>
        </p>
      </div>
      <div className="buy-tickets__buttons">
        <button
          className="button button--medium button--outline"
          onClick={navigateBack}
        >
          Back
        </button>
        <button
          className="button button--medium button--primary"
          onClick={handleCheckout}
          disabled={
            !tempTickets.every(validateTicketHolder) ||
            loading ||
            !tempTickets.length ||
            checkoutLoading
          }
        >
          Continue to checkout
        </button>
      </div>
    </>
  );
};
