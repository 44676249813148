import React from 'react';
import { Loading, PagedContent, TogglerWithoutNav, Search } from 'components';
import { DashboardMemberItem } from './DashboardMemberItem';

enum MemberTab {
  Members = 'members',
  Unassigned = 'unassigned',
}

interface Props {
  teamMembers: CustomUserInfo[];
  unassignedUsers: CustomUserInfo[];
  usersChanging: boolean;
  updateMembersCallback: (
    usersToRemove: CustomUserInfo[],
    usersToAdd: CustomUserInfo[],
  ) => void;
  cancelCallback: () => void;
}

interface State {
  usersToRemove: CustomUserInfo[];
  usersToAdd: CustomUserInfo[];
  hasChanged: boolean;
  tab: MemberTab;
  query: string;
}

export class ManageTeamMembers extends React.PureComponent<Props, State> {
  state: State = {
    usersToRemove: [],
    usersToAdd: [],
    hasChanged: false,
    tab: MemberTab.Members,
    query: '',
  };

  resetState = () =>
    this.setState({
      usersToRemove: [],
      usersToAdd: [],
      hasChanged: false,
    });

  cancelChanges = () => {
    this.resetState();
    this.props.cancelCallback();
  };

  submitChanges = () => {
    const { usersToRemove, usersToAdd } = this.state;
    const { updateMembersCallback } = this.props;

    updateMembersCallback(usersToRemove, usersToAdd);

    this.setState({
      hasChanged: false,
    });
  };

  switchToMembers = () =>
    this.setState({ tab: MemberTab.Members }, this.resetState);
  switchToUnassigned = () =>
    this.setState({ tab: MemberTab.Unassigned }, this.resetState);

  handleSelectedMembers = (member: CustomUserInfo) => {
    const checked = this.state.usersToRemove.some(u => u.id === member.id);
    const selectedMembers = checked
      ? this.state.usersToRemove.filter(m => m.id !== member.id)
      : [...this.state.usersToRemove, member];

    this.setState({ usersToRemove: selectedMembers, hasChanged: true });
  };

  handleSelectedUnassigned = (user: CustomUserInfo) => {
    const checked = this.state.usersToAdd.some(u => u.id === user.id);
    const selectedUnassigned = checked
      ? this.state.usersToAdd.filter(u => u.id !== user.id)
      : [...this.state.usersToAdd, user];

    this.setState({ usersToAdd: selectedUnassigned, hasChanged: true });
  };

  renderTabs = () => {
    const { tab } = this.state;
    return (
      <React.Fragment>
        <TogglerWithoutNav
          leftTitle="Remove members"
          rightTitle="Add members"
          onLeftClick={this.switchToMembers}
          onRightClick={this.switchToUnassigned}
          leftActive={tab === MemberTab.Members}
          rightActive={tab === MemberTab.Unassigned}
        />
      </React.Fragment>
    );
  };

  renderList = (users: CustomUserInfo[]) => {
    const { usersChanging } = this.props;

    if (usersChanging) {
      return <Loading />;
    }

    if (!users.length) {
      return this.renderEmptyState();
    }

    return (
      <PagedContent
        data={users}
        renderItem={this.renderMemberItem}
        pageSize={15}
      />
    );
  };

  renderMemberItem = (member: CustomUserInfo) => {
    const { tab, usersToRemove, usersToAdd } = this.state;
    const selected = tab === MemberTab.Members ? usersToRemove : usersToAdd;
    const callback =
      tab === MemberTab.Members
        ? this.handleSelectedMembers
        : this.handleSelectedUnassigned;

    const isChecked = selected.find(m => m.id === member.id) !== undefined;

    return (
      <DashboardMemberItem
        key={member.id}
        member={member}
        checked={isChecked}
        onSelect={() => callback(member)}
      />
    );
  };

  renderMembers = () => {
    const filterUsers = this.filterUsers(this.props.teamMembers);
    return this.renderList([...filterUsers]);
  };

  renderUnassigned = () => {
    const filterUsers = this.filterUsers(this.props.unassignedUsers);
    return this.renderList([...filterUsers]);
  };

  renderEmptyState = () => (
    <p className="s-top--lrg">
      {this.state.query.length > 0
        ? 'No users match the search query.'
        : this.state.tab === MemberTab.Members
        ? 'There are no members added yet'
        : 'No unassigned users have been found.'}
    </p>
  );

  renderActionButtons = () => {
    const { hasChanged } = this.state;
    return (
      <div className="modal__footer">
        <div className="f f--gap">
          <button
            disabled={!hasChanged}
            onClick={this.submitChanges}
            className="button button--primary button--medium "
          >
            Confirm
          </button>
          <button
            onClick={this.cancelChanges}
            className="button button--medium button--ghost button--ghost-primary"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };
  setQuery = (query: string) => {
    this.setState({ query });
  };

  filterUsers = (users: CustomUserInfo[]) => {
    const { query } = this.state;
    let filteredUsers = users;
    if (query.length) {
      filteredUsers = filteredUsers.filter(
        u =>
          u.displayName &&
          u.displayName.toUpperCase().includes(query.toUpperCase()),
      );
    }
    return filteredUsers;
  };

  render() {
    const { tab, query } = this.state;
    return (
      <React.Fragment>
        <Search query={query} changeCallback={this.setQuery} />
        <div className="modal__content">
          <br />
          {this.renderTabs()}
          {tab === MemberTab.Members
            ? this.renderMembers()
            : this.renderUnassigned()}
        </div>
        {this.renderActionButtons()}
      </React.Fragment>
    );
  }
}
