import { where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';
import { useTeams } from 'modules/teams';
import { EventTicketInfo } from '../models';

const TicketsCollection = new FirestoreService<Ticket>(Collection.Tickets);

export const useEventStats = () => {
  const { user } = useAuthentication();
  const { userTeams } = useTeams();
  function mapTicketsToRevenueAndSoldCount(tickets: Ticket[]): EventTicketInfo {
    return {
      sold: tickets.length,
      revenue: tickets
        .map(t => t.ticketPrice)
        .reduce((total, current) => (total += current), 0),
    };
  }

  /** If user is administrator, this will also include purchases of users without a team.
   * If not, get only ticket purchases from teams user has access to.
   * This is not available to regular users on the UI.*/
  async function getEventTicketsByTeamIds(eventId: string) {
    try {
      /** If user is administrator, get all tickets for event regardless of team ID */
      if (user?.role === 'ADMIN') {
        return await TicketsCollection.get([where('eventId', '==', eventId)]);
      } else {
        const teamIds = userTeams.map(t => t.id);
        const promises: Promise<Ticket[]>[] = [];
        const chunks = Math.ceil(teamIds.length / 30);

        for (let i = 0; i < chunks; i++) {
          const startIndex = i * 30;
          const ids = teamIds.slice(startIndex, startIndex + 30);
          promises.push(
            TicketsCollection.get([
              where('eventId', '==', eventId),
              where('purchasedByTeamId', 'in', ids),
            ]),
          );
        }

        const results = await Promise.all(promises);
        return results.flat();
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return {
    mapTicketsToRevenueAndSoldCount,
    getEventTicketsByTeamIds,
  };
};
