import React, { useCallback, useRef } from 'react';
import { Input } from './Input';

type BaseProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
interface Props extends Omit<BaseProps, 'type' | 'value' | 'onChange'> {
  type: 'date' | 'datetime-local';
  value: string;
  label?: string;
  onChange: (value: string) => void;
}

export const InputDate: ReactFC<Props> = props => {
  const ref = useRef<HTMLInputElement>(null);
  const { value, placeholder } = props;

  const handleFocus = useCallback(() => {
    ref.current?.focus();
    ref.current?.showPicker();
  }, [ref.current]);

  return (
    <div className="input-date__container">
      <Input ref={ref as any} {...props} onReset={() => props.onChange('')} />

      {!value && placeholder && (
        <button
          type="button"
          className="button button--ghost input-date__placeholder-container"
          onClick={handleFocus}
        >
          <p className="t-base t-regular t-faded t-left">{placeholder}</p>
        </button>
      )}
    </div>
  );
};
