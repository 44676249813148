import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface Props {
  event: EventInfo;
  numberOfTickets: number;
}

export const DashboardEventTicketItem: ReactFC<Props> = ({
  event,
  numberOfTickets,
}) => {
  return (
    <Link to={`/tickets/${event.id}`} className="event-ticket-item card">
      <img
        src={
          event.featuredImageUrl ||
          require('assets/images/placeholder-16-9.png')
        }
        className="event-ticket-item__thumbnail"
        alt=""
      />

      <div className="event-ticket-item__content">
        <h1 className="title title--card">{event.title}</h1>

        <div>
          <div className="f f--center">
            <p className="card__label u-opacity--40">No. of tickets</p>
            <p className="t-zeta u-opacity--80">{numberOfTickets || 0}</p>
          </div>

          <div className="f f--center">
            <p className="card__label u-opacity--40">Date</p>
            <p className="t-zeta u-opacity--80">
              {moment(event.startTimestamp).format('ll')}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};
