export function containsDuplicateEmails(tickets: TempTicket[]): boolean {
  const emailSet = new Set<string>();
  for (const ticket of tickets) {
    if (emailSet.has(ticket.email)) {
      return true;
    }
    emailSet.add(ticket.email);
  }
  return false;
}
