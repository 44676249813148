import React from 'react';
import { Link } from 'react-router-dom';

import { DivWithBackgroundImage } from 'components';

interface Props {
  video: VideoInfo;
  parentUrl: string;
  user?: CustomUserInfo;
}

export class VideoItemSimple extends React.Component<Props> {
  getLastPlayedPercentage = () => {
    const { user, video } = this.props;
    if (!user || !user.videoStats) {
      return 0;
    }

    const stats = user.videoStats.find(s => s.trackId === video.id);
    if (!stats) {
      return 0;
    }

    return stats.percentListened;
  };

  render() {
    const { video, parentUrl } = this.props;
    const watchedPercentage = this.getLastPlayedPercentage();

    return (
      <Link to={`${parentUrl}/${video.id}`} className="dashboard-list-item">
        <DivWithBackgroundImage
          mainImageUrl={video.thumbnailUrl}
          fallbackImageUrl={require('assets/images/placeholder-square.png')}
          classes="dashboard-list-item__thumbnail"
          watchedPercentage={watchedPercentage}
        />

        <div className="dashboard-list-item__content">
          <h1 className="title title--card-dashboard">{video.title}</h1>
        </div>
      </Link>
    );
  }
}
