import React, { useState } from 'react';
import { PageHeader, Input, PageTitle } from 'components';
import { validation as Validation } from 'modules/validation';
import { useForm } from 'hooks';
import { useContact } from 'modules/contact';
import { toast } from 'react-toastify';

interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

export const Contact: ReactFC = () => {
  const { sendContactEmail } = useContact();
  const { state, validation, update, validateForm, resetState } = useForm<
    FormState
  >(
    { firstName: '', lastName: '', email: '', message: '' },
    {
      rules: { email: value => !!value && Validation.EMAIL_REGEX.test(value) },
    },
  );
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  async function handleSubmit() {
    try {
      if (!validateForm())
        throw new Error(
          'Some of your data is invalid. Please check your inputs.',
        );
      setLoading(true);
      await sendContactEmail(state);
      resetState();

      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 5000);
    } catch (error) {
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Check your inptus and try again.',
      );
    } finally {
      setLoading(false);
    }
  }

  const { firstName, lastName, email, message } = state;

  return (
    <section>
      <PageHeader>
        <PageTitle>Contact</PageTitle>
      </PageHeader>
      <section className="content">
        <div className="wrapper">
          <div className="field">
            <Input
              value={firstName}
              onChange={value => update('firstName', value)}
              label="First Name"
              type="text"
            />
          </div>
          {validation.firstName === false && (
            <p className="t-warning t-small">We'll need your first name.</p>
          )}
          <div className="field">
            <Input
              value={lastName}
              onChange={value => update('lastName', value)}
              label="Last Name"
              type="text"
            />
          </div>
          {validation.firstName === false && (
            <p className="t-warning t-small">We'll need your last name.</p>
          )}
          <div className="field">
            <Input
              value={email}
              onChange={value => update('email', value)}
              label="E-mail"
              type="email"
            />
          </div>
          {validation.firstName === false && (
            <p className="t-warning t-small">We'll need a valid e-mail.</p>
          )}
          <div className="field">
            <Input
              value={message}
              onChange={value => update('message', value)}
              label="Message"
              type="textarea"
            />
          </div>
          {validation.firstName === false && (
            <p className="t-warning t-small">
              What did you want to talk about?
            </p>
          )}

          {showSuccessMessage ? (
            <p className="u-color--success s-top--lrg s-bottom--lrg t-center t-bold">
              Your message was sent successfully. You'll hear from us shortly.
            </p>
          ) : (
            <button
              type="button"
              value="Submit"
              className="button button--medium button--primary s-top--lrg"
              onClick={handleSubmit}
              disabled={loading}
            >
              Send
            </button>
          )}
        </div>
      </section>
    </section>
  );
};
