import { PropsWithChildren } from 'react';

interface Props {
  onSelect: () => void;
  icon?: string;
}

export const DropdownMenuItem: React.FC<PropsWithChildren<Props>> = ({
  onSelect,
  children,
  icon,
}) => {
  function handlePress(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    onSelect();
  }

  return (
    <button className="dropdown-menu__item" onClick={handlePress}>
      {icon && <img src={icon} alt="" className="dropdown-menu__item__icon" />}
      {children}
    </button>
  );
};
