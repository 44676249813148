import { Role, RoleCheck } from 'modules/authorization';
import { OptionsMenu } from './OptionsMenu';
import { DropdownMenuSeparator } from './DropdownMenuSeparator';
import IconOptionsEdit from 'assets/icons/options-edit.svg';
import IconOptionsDelete from 'assets/icons/options-delete.svg';
import { DropdownMenuItem } from './DropdownMenuItem';

interface Props {
  publish?: VoidFunction;
  remove?: VoidFunction;
  edit: VoidFunction;
}

export const AdministrationOptions: React.FC<Props> = ({
  publish,
  edit,
  remove,
}) => {
  return (
    <RoleCheck role={Role.Administrator}>
      <OptionsMenu>
        {publish && (
          <>
            <DropdownMenuItem onSelect={publish}>Publish</DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        )}
        <DropdownMenuItem icon={IconOptionsEdit} onSelect={edit}>
          Edit
        </DropdownMenuItem>
        {remove && (
          <DropdownMenuItem icon={IconOptionsDelete} onSelect={remove}>
            Delete
          </DropdownMenuItem>
        )}
      </OptionsMenu>
    </RoleCheck>
  );
};
