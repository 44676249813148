import { atom } from 'recoil';

const tracks = atom<AudioInfo[] | undefined>({
  key: 'audio-player-tracks',
  default: undefined,
});

const index = atom<number>({ key: 'audio-player-index', default: 0 });

const playing = atom<boolean>({
  key: 'audio-player-playing',
  default: undefined,
});

export const AudioPlayerAtoms = {
  playing,
  tracks,
  index,
};
