import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router';

interface Props {
  title?: string | JSX.Element;
  close: VoidFunction;
  size?: 'large' | 'extra-large';
  dismissOutside?: boolean;
}

export const MainModal: ReactFC<Props> = ({
  close,
  title,
  size,
  dismissOutside = true,
  children,
}) => {
  const location = useLocation();
  const [initialPathName] = useState(location.pathname);

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        close();
      }
    }

    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  useEffect(() => {
    const locked = document.body.classList.contains('is-locked');
    if (!locked) document.body.classList.add('is-locked');

    return () => {
      document.body.classList.remove('is-locked');
    };
  }, []);

  /** Close modal on route change */
  useEffect(() => {
    if (location.pathname !== initialPathName) close();
  }, [location.pathname]);

  function handleOutsideClick() {
    if (dismissOutside) close();
  }

  return createPortal(
    <div
      onClick={handleOutsideClick}
      className={`modal ${size === 'large' ? 'modal--large' : ''} ${
        size === 'extra-large' ? 'modal--extra-large' : ''
      }`}
    >
      <div onClick={e => e.stopPropagation()} className="modal__wrapper">
        <header className="modal__header">
          {typeof title === 'string' ? (
            <h1 className="modal__title">{title}</h1>
          ) : (
            title
          )}
          <button
            type="button"
            onClick={close}
            className="button button--unpadded modal__close"
          >
            <svg width="24" height="24">
              <path
                fill="black"
                d="M16.95 8.464L15.536 7.05 12 10.586 8.464 7.05 7.05 8.464 10.586 12 7.05 15.536l1.414 1.414L12 13.414l3.536 3.536 1.414-1.414L13.414 12l3.536-3.536z"
              />
            </svg>
          </button>
        </header>
        {children}
      </div>
    </div>,
    document.body,
  );
};
