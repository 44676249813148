import React from 'react';
import { useNavigate } from 'react-router';

export const SubscriptionSuccess: ReactFC = () => {
  const navigate = useNavigate();
  return (
    <section className="subscription">
      <div className="wrapper">
        <div className="subscription__container">
          <div className="subscription__plans">
            <h1 className="subscription__title t-bold">
              Your TeamMAK Subscription
            </h1>
            <p className="subscription__message u-opacity--80">
              Your TeamMAK subscription is active! <br />
              All of the features and media content are available to you now.
              Enjoy!
            </p>
            <button
              className="button button--medium button--primary"
              onClick={() => navigate('/dashboard')}
            >
              Go to dashboard
            </button>
          </div>
          <div className="subscription__visual subscription__visual--success">
            <img
              className="subscription__visual__image"
              src={require('assets/images/subscription-success.png')}
              alt="Subscription visual"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
