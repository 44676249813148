import { increment, where } from 'firebase/firestore';
import { Collection, FirestoreService } from 'modules/firebase';
import { useRecoilValue } from 'recoil';
import { SharedAudio } from '../models';
import { SharedAtoms } from '../recoil';

const SharedCollection = new FirestoreService<SharedAudio>(Collection.Shared);

export function useSharedAudios() {
  const shared = useRecoilValue(SharedAtoms.shared);
  const loading = useRecoilValue(SharedAtoms.loading);

  const getExisting = async (audioId: string, sharedBy: string) => {
    const result = await SharedCollection.get([
      where('sharedBy', '==', sharedBy),
      where('audioId', '==', audioId),
    ]);

    return result.length ? result[0] : null;
  };

  const getShareUrlId = async (audio: AudioInfo, sharedBy: string) => {
    // If audio has already been shared by this user, return the shared doc id, no need to create a new one.
    const existing = await getExisting(audio.id, sharedBy);
    return existing?.id || SharedCollection.generateDocumentReference().id;
  };

  const saveSharedUrl = async (
    id: string,
    audio: AudioInfo,
    sharedBy: string,
  ) => {
    const existing = await getExisting(audio.id, sharedBy);

    const shared: SharedAudio = {
      id,
      audio,
      audioId: audio.id,
      sharedBy,
      date: existing ? existing.date : Date.now(),
      timesOpened: existing ? existing.timesOpened : 0,
    };

    return SharedCollection.set(id, shared);
  };

  const getSharedContentById = async (id: string) => {
    try {
      const content = await SharedCollection.getById(id);
      if (!content) throw new Error('No document found with specified ID.');
      return content;
    } catch (e) {
      console.error(e);
    }
  };

  const updatePlayedCount = (sharedId: string) => {
    SharedCollection.set(sharedId, { timesOpened: increment(1) });
  };

  return {
    shared,
    loading,
    updatePlayedCount,
    getShareUrlId,
    getSharedContentById,
    saveSharedUrl,
  };
}
