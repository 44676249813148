import {
  Loading,
  MainModal,
  ScrollingContent,
  TogglerWithoutNav,
} from 'components';
import { useUsers } from 'modules/users';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAudios } from '../hooks';
import Select from 'react-select';
import IconDelete from 'assets/icons/options-delete-secondary.svg';

interface Props {
  categoryId: string;
  list: SpecialContentAccessUser[];
  close: VoidFunction;
}

type SearchField = 'normalisedLastName' | 'normalisedEmail';

export const SpecialAccessListModal: ReactFC<Props> = ({
  list,
  categoryId,
  close,
}) => {
  const { addSpecialCategoryAccess } = useAudios();
  const [usersWithAccess, setUsersWithAccess] = useState<
    SpecialContentAccessUser[]
  >(list || []);

  function reset() {
    setUsersWithAccess(list);
  }

  async function updateCategoryAccessList() {
    try {
      await addSpecialCategoryAccess(categoryId, usersWithAccess);
      reset();
      close();
    } catch (error) {
      toast.error(
        `Failed to update category access list. Please, try again or contact support. ${
          error instanceof Error ? error.message : ''
        }`,
      );
    }
  }

  function addUsers(list: SpecialContentAccessUser[]) {
    setUsersWithAccess([...usersWithAccess, ...list]);
  }

  function removeUser(id: string) {
    setUsersWithAccess(usersWithAccess.filter(u => u.id !== id));
  }

  return (
    <MainModal
      close={close}
      title={
        <div>
          <h1 className="modal__title">Category access</h1>
          <p className="t-faded t-small s-bottom--med">
            Make content visible only to users on the list
          </p>
        </div>
      }
    >
      <div className="modal__content">
        <SearchForm add={addUsers} />
        <UserList state={usersWithAccess} remove={removeUser} />
      </div>

      <div className="modal__footer">
        <div className="f f--gap">
          <button
            onClick={updateCategoryAccessList}
            className="button button--primary button--medium "
          >
            Confirm
          </button>
          <button
            onClick={close}
            className="button button--medium button--ghost button--ghost-primary"
          >
            Cancel
          </button>
        </div>
      </div>
    </MainModal>
  );
};

interface SearchFormProps {
  add: (list: SpecialContentAccessUser[]) => void;
}

const SearchForm: ReactFC<SearchFormProps> = ({ add }) => {
  const [query, setQuery] = useState('');
  const [field, setField] = useState<SearchField>('normalisedLastName');
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<CustomUserInfo[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<CustomUserInfo[]>([]);
  const { searchForUsers } = useUsers();

  async function handleSearchUsers(query: string, field: SearchField) {
    try {
      setSearching(true);
      const result = await searchForUsers(query, field);
      setSearchResults(result);
    } catch (error) {
      console.error(error);
    } finally {
      setSearching(false);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (query.length < 3) return;
      handleSearchUsers(query, field);
    }, 750);

    return () => clearTimeout(timeout);
  }, [query, field]);

  function handleFieldToggle(value: SearchField) {
    setSearchResults([]);
    setField(value);
  }

  function handleSelectChange(data: CustomUserInfo[] | null) {
    setSelectedUsers(data || []);
  }

  function handleAddSelection() {
    add(selectedUsers.map(u => ({ id: u.id, displayName: u.displayName })));
    setSelectedUsers([]);
  }

  return (
    <>
      <div className="field u-width--content s-bottom--med">
        <p className="t-small t-faded field__lbl">Search by</p>
        <TogglerWithoutNav
          leftTitle="Last name"
          rightTitle="Email address"
          onLeftClick={() => handleFieldToggle('normalisedLastName')}
          onRightClick={() => handleFieldToggle('normalisedEmail')}
          leftActive={field === 'normalisedLastName'}
          rightActive={field === 'normalisedEmail'}
        />
      </div>

      <p className="t-small t-faded field__lbl">Grant access to</p>
      <div className="special-access-list__search-container">
        <Select<CustomUserInfo>
          placeholder={
            field === 'normalisedEmail'
              ? 'Find users by email'
              : 'Find users by last name'
          }
          classNamePrefix="react-select"
          isDisabled={searching}
          isLoading={searching}
          options={searchResults}
          value={selectedUsers}
          getOptionLabel={user => user.displayName}
          getOptionValue={user => user.id}
          /** Crucial property, otherwise using @ would render an empty options menu. */
          filterOption={null}
          onInputChange={setQuery}
          onChange={data => handleSelectChange(data as CustomUserInfo[] | null)}
          hideSelectedOptions
          isMulti
          isSearchable
        />

        <button
          type="button"
          className="button button--medium button--outline button--outline-primary"
          disabled={!selectedUsers.length}
          onClick={handleAddSelection}
        >
          Add
        </button>
      </div>
    </>
  );
};

interface UserListTabProperties {
  remove: (id: string) => void;
  state: SpecialContentAccessUser[];
  loading?: boolean;
}

const UserList: ReactFC<UserListTabProperties> = ({
  remove,
  state,
  loading,
}) => {
  return (
    <div className="s-top--lrg f f--column f--gap">
      {loading ? (
        <Loading withoutContainer />
      ) : (
        <ScrollingContent
          data={state}
          emptyComponent={
            <p className="t-center t-faded">No users have been added yet.</p>
          }
          renderItem={user => (
            <div key={`list-${user.id}`} className="f f--center">
              <p className="t-faded">{user.displayName}</p>
              <button
                type="button"
                onClick={() => remove(user.id)}
                className="button button--ghost button--unpadded"
              >
                <img src={IconDelete} alt="x" />
              </button>
            </div>
          )}
        />
      )}
    </div>
  );
};
