import React from 'react';

import { ServicesItem } from '.';

const audioVisual = (
  <svg
    className="services-item__visual"
    width="94"
    height="115"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="a">
        <stop stopColor="#09A593" stopOpacity="0" offset="0%" />
        <stop stopColor="#09A593" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M72.9845668 20.308088c7.5315347 9.4602639 13.6197439 17.6413935 17.451117 27.9544136 3.831373 10.3396687 5.4321522 22.8112278.3936342 31.1256006C85.7908 87.702475 74.1129849 91.88631 61.3067517 99.4811697c-12.8062332 7.5948593-26.7408845 18.6007443-34.4823575 14.7100443-7.7152306-3.917349-9.2372829-22.7312821-14.1445895-36.9083536C7.77249811 63.1324375-.5725473 53.645525.03102517 44.9580714.60835535 36.2706177 10.1080611 28.4092717 19.5027978 19.29544 28.8975344 10.1816083 38.1610597-.1580604 47.3196158.00183138 56.4519297.16172317 65.4530321 10.8744727 72.9845668 20.308088z"
        fill="url(#a)"
        fillRule="nonzero"
        opacity=".40000001"
      />
      <g
        transform="translate(31 46)"
        stroke="#5A3B96"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M5 0h22v24H5zM1 3v18M31 3v18" />
        <path d="M13 17V6h9v9" />
        <circle cx="11" cy="17" r="2" />
        <circle cx="20" cy="15" r="2" />
      </g>
    </g>
  </svg>
);

const videoVisual = (
  <svg
    className="services-item__visual"
    width="94"
    height="115"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="a">
        <stop stopColor="#09A593" stopOpacity="0" offset="0%" />
        <stop stopColor="#09A593" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M89.1742053 5.15960321c7.735708 7.87368619 4.2082252 25.82680769 2.7229692 40.17813649-1.4852559 14.3513288-.9592278 25.1567067-4.0225682 37.9165458-3.0633404 12.7877599-9.7160493 27.5299805-20.7626404 30.9363275-11.0465911 3.406346-26.4561216-4.551102-38.1525122-12.45271C17.2940059 93.8362964 9.37264088 86.0184519 4.70027321 77.2233769.05884837 68.4283018-1.36452191 58.6839171 1.42033299 50.5031012c2.81579774-8.1808158 9.83982071-14.7980627 17.54458591-22.671749 7.735708-7.9016071 16.1521584-17.0875744 29.8288903-22.75551161C62.5014839-.59209666 81.46944-2.7420039 89.1742053 5.15960321z"
        fill="url(#a)"
        fillRule="nonzero"
        opacity=".40000001"
      />
      <g
        stroke="#5A3B96"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M32 42h30v30H32z" />
        <path d="M44 52l8 5-8 5zM32 47h30M32 67h30M37 42v5M42 42v5M47 42v5M52 42v5M57 42v5M37 67v5M42 67v5M47 67v5M52 67v5M57 67v5" />
      </g>
    </g>
  </svg>
);

const eventsVisual = (
  <svg
    className="services-item__visual"
    width="94"
    height="115"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="a">
        <stop stopColor="#09A593" stopOpacity="0" offset="0%" />
        <stop stopColor="#09A593" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M76.7225478 12.6433636c7.8148228 10.1393137 9.6309436 24.6198854 12.7403625 39.4500886 3.1094189 14.8302031 7.5121359 29.9809019 1.6785358 37.1774838-5.8336 7.2257178-21.9035172 6.4681829-35.662008 11.275616-13.7584907 4.778297-25.205555 15.092427-35.4969061 14.4223C9.7186979 114.298725.61057702 102.615205.0327204 90.9025497-.57265317 79.1898941 7.3522375 67.4189666 13.0207357 58.9986745c5.6409812-8.4202922 8.9980529-13.4899491 13.345736-24.2993899 4.3201661-10.7803049 9.6309435-27.30039367 19.1793361-32.66141015 9.5483926-5.36101647 23.3619173.43703939 31.17674 10.60548915z"
        fill="url(#a)"
        fillRule="nonzero"
        opacity=".40000001"
      />
      <g
        stroke="#5A3B96"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M62 54H32M32 48h30v24H32zM40 44v4M54 44v4M36 63h22M43 58v10M51 58v10" />
      </g>
    </g>
  </svg>
);

export const Services: ReactFC = () => (
  <section className="services">
    <div className="wrapper">
      <div className="services__container">
        <h1 className="services__title t-bold">Our Services</h1>

        <ServicesItem
          visual={audioVisual}
          title="Audio library"
          description="Entire TeamMak audio library available for streaming."
          link="/audios"
        />
        <ServicesItem
          visual={videoVisual}
          title="Video training"
          description="Hundreds of hours of graining from leaders across the industry."
          link="/videos"
        />
        <ServicesItem
          visual={eventsVisual}
          title="Events"
          description="Join thousands of people in their journey of self-improvement."
          link="/events"
        />
      </div>
    </div>
  </section>
);
