import { Unsubscribe, User } from 'firebase/auth';
import { useRecoilClear } from 'hooks';
import { AudioPlayerAtoms } from 'modules/audio-player';
import { AudioAtoms } from 'modules/audios';
import { EventAtoms } from 'modules/events';
import { AuthService, Collection, FirestoreService } from 'modules/firebase';
import { NotificationAtoms } from 'modules/notifications';
import { SubscriptionAtoms } from 'modules/subscription';
import { VideoPlayerAtoms } from 'modules/video-player';
import { VideoAtoms } from 'modules/videos';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AuthAtoms } from '../recoil';
import { TicketAtoms } from 'modules/tickets';
import { TeamAtoms } from 'modules/teams';
import { UserAtoms } from 'modules/users';
import { StripeAtoms } from 'modules/stripe';
import { AudioStatsAtoms } from 'modules/audio-stats';

const Auth = new AuthService();
const UserInfoCollection = new FirestoreService<CustomUserInfo>(
  Collection.UserInfo,
);

export function useAuthenticationListener() {
  const setIsLoggedIn = useSetRecoilState(AuthAtoms.isLoggedIn);
  const setUser = useSetRecoilState(AuthAtoms.user);
  const setLoading = useSetRecoilState(AuthAtoms.loading);
  const audioCategoryListeners = useRecoilValue(AudioAtoms.categoryListeners);
  const videoCategoryListeners = useRecoilValue(VideoAtoms.categoryListeners);

  const resetAuth = useRecoilClear(AuthAtoms);
  const resetEvents = useRecoilClear(EventAtoms);
  const resetNotifications = useRecoilClear(NotificationAtoms);
  const resetVideos = useRecoilClear(VideoAtoms);
  const resetAudios = useRecoilClear(AudioAtoms);
  const resetAudioPlayer = useRecoilClear(AudioPlayerAtoms);
  const resetVideoPlayer = useRecoilClear(VideoPlayerAtoms);
  const resetAudioStats = useRecoilClear(AudioStatsAtoms);
  const resetSubscription = useRecoilClear(SubscriptionAtoms);
  const resetTickets = useRecoilClear(TicketAtoms);
  const resetTeams = useRecoilClear(TeamAtoms);
  const resetUsers = useRecoilClear(UserAtoms);
  const resetStripe = useRecoilClear(StripeAtoms);

  useEffect(() => {
    let profileListener: Unsubscribe | undefined = undefined;

    function onLogin(credential: User) {
      profileListener = UserInfoCollection.listenToId({
        path: credential.uid,
        onError: error => {
          setLoading(false);
          console.log('Error loading profile listener: ', error);
        },
        onSuccess: profile => {
          setUser(profile);
          setIsLoggedIn(!!profile);
          setLoading(false);
        },
      });
    }

    function onLogout() {
      function unsubscribeListeners(
        listeners: Record<string, Unsubscribe | Unsubscribe[] | undefined>,
      ) {
        Object.values(listeners).forEach(listener => {
          if (listener)
            Array.isArray(listener) ? listener.forEach(l => l()) : listener();
        });
      }

      /** Unsubscribe all listeners */
      unsubscribeListeners(audioCategoryListeners);
      unsubscribeListeners(videoCategoryListeners);

      /** Reset all recoil state values */
      resetAuth();
      resetEvents();
      resetNotifications();
      resetAudios();
      resetVideos();
      resetAudioPlayer();
      resetVideoPlayer();
      resetSubscription();
      resetTickets();
      resetTeams();
      resetUsers();
      resetStripe();
      resetAudioStats();

      setLoading(false);
      profileListener?.();
    }

    setLoading(true);
    const unsubscribe = Auth.initAuthListener(onLogin, onLogout);
    return unsubscribe;
  }, []);
}
