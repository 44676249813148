import { where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import { Role } from 'modules/authorization';
import { Collection, FirestoreService } from 'modules/firebase';
import { stringToSlug } from 'modules/helpers';
import { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { PlaylistType } from '../models';
import { PlaylistAtoms } from '../recoil';

const VideoCollection = new FirestoreService<VideoInfo>(Collection.Videos);
const AudioCollection = new FirestoreService<AudioInfo>(Collection.Audios);
const UserInfoCollection = new FirestoreService<CustomUserInfo>(
  Collection.UserInfo,
);

export function usePlaylist(type: PlaylistType) {
  const { user } = useAuthentication();
  const [audios, setAudios] = useRecoilState(PlaylistAtoms.audios);
  const [videos, setVideos] = useRecoilState(PlaylistAtoms.videos);
  const [disabledAudios, setDisabledAudios] = useState<
    Record<string, string[]>
  >({});

  const audioPlaylists = useMemo(() => {
    return [...(user?.audioPlaylists || [])];
  }, [user?.audioPlaylists]);
  const videoPlaylists = useMemo(() => {
    return [...(user?.videoPlaylists || [])];
  }, [user?.videoPlaylists]);

  function isFavorite(trackId: string) {
    if (type === 'audio') {
      const favorites = audioPlaylists.find(p => p.id === 'favorites');
      return !!favorites?.audios.includes(trackId);
    } else {
      const favorites = videoPlaylists.find(p => p.id === 'favorites');
      return !!favorites?.videos.includes(trackId);
    }
  }

  async function loadPlaylistAudios(
    id: string,
    trackIds: string[],
    audioCategories: AudioCategory[],
  ) {
    if (!user) return;
    const promises = trackIds.map(trackId =>
      AudioCollection.get(
        user.role === 'ADMIN'
          ? [where('id', '==', trackId)]
          : [where('status', '==', 'published'), where('id', '==', trackId)],
      ),
    );

    const results = (await Promise.all(promises))
      .map(audio => audio[0])
      .filter(audio => !!audio);

    /** Filter final list by special category access rights if necessary. */
    const tracks: AudioInfo[] = [];
    /** Filter out tracks that have not been fetched by this filter (either deleted or drafted). */
    const tracksToRemove: string[] = trackIds.filter(
      track => !results.some(a => a.id === track),
    );

    for (const track of results) {
      // /** If there are no categories for this track available to the user, that means that the user does not have access to this audio anymore. */
      const matched = track.categoryIds.filter(id =>
        audioCategories.some(c => c.id === id),
      );

      // /** Allow tracks with no category ids or those whose categories can be opened by the user. */
      if (!track.categoryIds.length || !!matched.length) {
        tracks.push(track);
      } else {
        /** otherwise remove the audio from the playlist */
        tracksToRemove.push(track.id);
      }
    }

    setDisabledAudios({ ...disabledAudios, [id]: tracksToRemove });
    setAudios({ ...audios, [id]: tracks });
  }

  async function loadPlaylistVideos(id: string, trackIds: string[]) {
    const promises = trackIds.map(trackId =>
      VideoCollection.get(
        user?.role === Role.Administrator
          ? [where('id', '==', trackId)]
          : [where('status', '==', 'published'), where('id', '==', trackId)],
      ),
    );

    const results = (await Promise.all(promises))
      .map(audio => audio[0])
      .filter(audio => !!audio);

    setVideos({ ...videos, [id]: results });
  }

  /** If track id is not null, the track will be automatically added to the playlist */
  function createPlaylist(title: string, trackId?: string) {
    if (!user) throw new Error('Unauthorized.');

    const id = stringToSlug(title);
    const tracks = trackId ? [trackId] : [];
    if (type === 'audio') {
      UserInfoCollection.set(user.id, {
        audioPlaylists: [
          ...audioPlaylists,
          { id, name: title, audios: tracks },
        ],
      });
    } else {
      UserInfoCollection.set(user.id, {
        videoPlaylists: [
          ...videoPlaylists,
          { id, name: title, videos: tracks },
        ],
      });
    }

    return id;
  }

  async function deletePlaylist(id: string) {
    if (!user) return;
    if (type === 'audio') {
      await UserInfoCollection.set(user.id, {
        audioPlaylists: audioPlaylists.filter(p => p.id !== id),
      });
    } else {
      await UserInfoCollection.set(user.id, {
        videoPlaylists: videoPlaylists.filter(p => p.id !== id),
      });
    }
  }

  async function updatePlaylist(id: string, name: string) {
    if (!user) return;
    if (type === 'audio') {
      const playlists = [...audioPlaylists];
      let playlistIndex = playlists.findIndex(p => p.id === id);
      if (playlistIndex < 0) return;

      playlists[playlistIndex].name = name;
      await UserInfoCollection.set(user.id, { audioPlaylists: playlists });
    } else {
      const playlists = [...videoPlaylists];
      let playlistIndex = playlists.findIndex(p => p.id === id);
      if (playlistIndex < 0) return;

      playlists[playlistIndex].name = name;
      await UserInfoCollection.set(user.id, { videoPlaylists: playlists });
    }
  }

  async function addToPlaylist(
    trackId: string,
    playlistId: AudioPlaylist['id'],
  ) {
    if (!user) return;

    if (type === 'audio') {
      let playlistIndex = audioPlaylists.findIndex(p => p.id === playlistId);
      if (playlistIndex < 0) return;

      audioPlaylists[playlistIndex].audios.push(trackId);
      await UserInfoCollection.set(user.id, { audioPlaylists });
    } else {
      let playlistIndex = videoPlaylists.findIndex(p => p.id === playlistId);
      if (playlistIndex < 0) return;

      videoPlaylists[playlistIndex].videos.push(trackId);
      await UserInfoCollection.set(user.id, { videoPlaylists });
    }
  }

  async function removeFromPlaylist(
    trackId: string,
    playlistId: AudioPlaylist['id'],
  ) {
    if (!user) return;

    if (type === 'audio') {
      const playlistIndex = audioPlaylists.findIndex(p => p.id === playlistId);
      if (playlistIndex < 0) return;

      audioPlaylists[playlistIndex].audios = audioPlaylists[
        playlistIndex
      ].audios.filter(id => id !== trackId);

      await UserInfoCollection.set(user.id, { audioPlaylists });
    } else {
      const playlistIndex = videoPlaylists.findIndex(p => p.id === playlistId);
      if (playlistIndex < 0) return;

      videoPlaylists[playlistIndex].videos = videoPlaylists[
        playlistIndex
      ].videos.filter(id => id !== trackId);

      await UserInfoCollection.set(user.id, { videoPlaylists });
    }
  }

  async function toggleFavorite(trackId: string) {
    if (!user) return;

    if (type === 'audio') {
      const favoritesIndex = audioPlaylists.findIndex(
        p => p.id === 'favorites',
      );
      if (favoritesIndex < 0) return; // Create favorites playlist

      const exists = audioPlaylists[favoritesIndex].audios.includes(trackId);
      if (exists) {
        audioPlaylists[favoritesIndex].audios = audioPlaylists[
          favoritesIndex
        ].audios.filter(id => id !== trackId);
      } else {
        audioPlaylists[favoritesIndex].audios.push(trackId);
      }
      await UserInfoCollection.set(user.id, { audioPlaylists });
    } else {
      const favoritesIndex = videoPlaylists.findIndex(
        p => p.id === 'favorites',
      );
      if (favoritesIndex < 0) return; // Create favorites playlist

      const exists = videoPlaylists[favoritesIndex].videos.includes(trackId);
      if (exists) {
        videoPlaylists[favoritesIndex].videos = videoPlaylists[
          favoritesIndex
        ].videos.filter(id => id !== trackId);
      } else {
        videoPlaylists[favoritesIndex].videos.push(trackId);
      }
      await UserInfoCollection.set(user.id, { videoPlaylists });
    }
  }

  function getAudioPlaylistById(id: string) {
    return audioPlaylists.find(p => p.id === id);
  }

  function getVideoPlaylistById(id: string) {
    return videoPlaylists.find(p => p.id === id);
  }

  return {
    disabledAudios,
    audios,
    videos,
    audioPlaylists,
    videoPlaylists,
    createPlaylist,
    deletePlaylist,
    updatePlaylist,
    addToPlaylist,
    removeFromPlaylist,
    toggleFavorite,
    isFavorite,
    loadPlaylistAudios,
    loadPlaylistVideos,
    getAudioPlaylistById,
    getVideoPlaylistById,
  };
}
