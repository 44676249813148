import { Input, InputDate, MainModal, TogglePin } from 'components';
import { useEventForm, useEvents } from '../hooks';
import { useForm } from 'hooks';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { EventInfoForm } from '../models';
import IconClose from 'assets/icons/close.svg';
import IconDelete from 'assets/icons/options-delete-secondary.svg';
import IconDropzoneImage from 'assets/icons/dropzone-attachment-image.svg';
import Dropzone from 'react-dropzone';
import { useTimezoneSelect } from 'react-timezone-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function convertStringToTime(value: string) {
  if (!value) return new Date().getTime();
  return new Date(value).getTime();
}

const MAX_FEATURED_IMAGE_SIZE = 1000000;

export const EventForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { options, parseTimezone } = useTimezoneSelect({});
  const { addEvent, updateEvent } = useEvents();
  const { editingEvent, close } = useEventForm();
  const initialState = useMemo(() => new EventInfoForm(editingEvent), [
    editingEvent,
  ]);

  const form = useForm<EventInfoForm>(initialState, {
    optional: [
      'maxTicketsPerBuyer',
      'venue',
      'timezoneOffset',
      'featuredImagePath',
      'featuredImageUrl',
    ],
    rules: {
      title: value => !!value && value.length <= 100,
      featuredImageFile: value =>
        !value || value.size <= MAX_FEATURED_IMAGE_SIZE,
      numberOfTickets: value => value > 0,
      maxTicketsPerBuyer: value => Number(value) >= 0,
      shortDescription: value => !!value && value.length <= 100,
      startDateRaw: (value, state) =>
        convertStringToTime(value) < convertStringToTime(state.endDateRaw),
      endDateRaw: (value, state) =>
        convertStringToTime(value) > convertStringToTime(state.startDateRaw),
      cutoffDateRaw: (value, state) =>
        convertStringToTime(value) < convertStringToTime(state.startDateRaw),
      ticketTypes: value =>
        !value.some(value => !value.name || value.price <= 0),
    },
  });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      if (!form.validateForm())
        throw new Error(
          'Some of your inputs are not valid. Check your entries.',
        );

      setLoading(true);
      toast.info('Saving event, please wait', { toastId: 'event-form' });
      if (editingEvent) {
        await updateEvent(editingEvent.id, form.state);
      } else {
        await addEvent(form.state);
      }

      close();
      toast.dismiss('event-form');
      toast.success('Event successfully saved.');
    } catch (error) {
      console.error(error);
      toast.dismiss('event-form');
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Try again.',
        { toastId: 'event-form' },
      );
    } finally {
      setLoading(false);
    }
  }

  function handleTicketNameUpdate(index: number, name: string) {
    const ticketTypes = [...form.state.ticketTypes];
    ticketTypes[index].name = name;
    form.update('ticketTypes', ticketTypes);
  }

  function handleTicketPriceUpdate(index: number, price: number) {
    const ticketTypes = [...form.state.ticketTypes];
    ticketTypes[index].price = price;
    form.update('ticketTypes', ticketTypes);
  }

  function handleThumbnailFile(files: File[]) {
    const [file] = files;
    form.update('featuredImageFile', file, true);
  }

  function handleRemoveThumbnail() {
    if (form.state.featuredImageFile) {
      form.update('featuredImageFile', null);
    } else {
      form.update('featuredImagePath', null);
      form.update('featuredImageUrl', null);
    }
  }

  return (
    <MainModal
      size="large"
      dismissOutside={false}
      title={editingEvent ? 'Edit event' : 'New event'}
      close={close}
    >
      <form onSubmit={handleSubmit}>
        <div className="modal__content">
          <div className="field">
            <TogglePin
              checked={form.state.status === 'published'}
              label="Published"
              toggle={() =>
                form.update(
                  'status',
                  form.state.status === 'published' ? 'draft' : 'published',
                )
              }
            />
            <p className="t-faded t-small">
              If the event is not published, it will not appear to non-admin
              users.
            </p>
          </div>

          <div className="field">
            <Input
              label="Title"
              maxLength={100}
              value={form.state.title}
              onChange={value => form.update('title', value)}
            />

            {form.validation.title === false && (
              <p className="t-warning t-small">
                An event title under 100 characters is required.
              </p>
            )}
          </div>

          <div className="field">
            <Input
              label="Location"
              value={form.state.location}
              onChange={value => form.update('location', value)}
            />

            {form.validation.location === false && (
              <p className="t-warning t-small">
                Users need to know where the event is being held.
              </p>
            )}
          </div>

          <div className="field">
            <Input
              label="Venue name (optional)"
              value={form.state.venue}
              onChange={value => form.update('venue', value)}
            />

            {form.validation.venue === false && (
              <p className="t-warning t-small">
                Needs to be a valid venue name.
              </p>
            )}
          </div>

          <p className="event-form__section">Time</p>

          <div className="field">
            <p className="t-small t-faded field__lbl">Event timezone</p>
            <select
              className="input input--select t-base"
              value={parseTimezone(form.state.timezone).value}
              onChange={({ currentTarget: { value } }) => {
                form.update('timezone', value);
                form.update('timezoneOffset', parseTimezone(value).offset || 0);
              }}
            >
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="field">
            <InputDate
              label="Start date"
              type="datetime-local"
              placeholder="Select date & time"
              value={form.state.startDateRaw}
              onChange={value => form.update('startDateRaw', value)}
            />

            {form.validation.startDateRaw === false && (
              <p className="t-warning t-small">
                Needs to be a valid future date.
              </p>
            )}
          </div>

          <div className="field">
            <InputDate
              label="End date"
              type="datetime-local"
              placeholder="Select date & time"
              value={form.state.endDateRaw}
              onChange={value => form.update('endDateRaw', value)}
            />

            {form.validation.endDateRaw === false && (
              <p className="t-warning t-small">
                Needs to be a valid date after the start date.
              </p>
            )}
          </div>

          <div className="field">
            <InputDate
              label="Ticket purchase cutoff date"
              type="datetime-local"
              placeholder="Select date & time"
              value={form.state.cutoffDateRaw}
              onChange={value => form.update('cutoffDateRaw', value)}
            />

            {form.validation.cutoffDateRaw === false && (
              <p className="t-warning t-small">
                Needs to be a valid date before the start date.
              </p>
            )}
          </div>

          <p className="event-form__section">Description</p>

          <div className="field">
            <p className="t-small t-faded field__lbl">Full description</p>
            <ReactQuill
              theme="snow"
              value={form.state.description}
              onChange={value => form.update('description', value)}
            />

            {form.validation.description === false && (
              <p className="t-warning t-small">
                A description must be provided.
              </p>
            )}
          </div>

          <div className="field">
            <p className="t-small t-faded field__lbl">
              Short description (80 characters)
            </p>
            <textarea
              className="input t-base input--textarea input--textarea--sml"
              maxLength={100}
              value={form.state.shortDescription}
              onChange={({ currentTarget }) =>
                form.update('shortDescription', currentTarget.value)
              }
            />

            {form.validation.shortDescription === false && (
              <p className="t-warning t-small">
                A short description up to 80 characters in length must be
                provided.
              </p>
            )}
          </div>

          <p className="event-form__section">Tickets</p>

          <div className="field">
            <Input
              label="Available tickets"
              type="number"
              value={String(form.state.numberOfTickets)}
              onChange={value => form.update('numberOfTickets', Number(value))}
            />

            {form.validation.numberOfTickets === false && (
              <p className="t-warning t-small">
                Needs to be a valid number larger than 0.
              </p>
            )}
          </div>

          <div className="field">
            <Input
              label="Max tickets per buyer"
              type="number"
              value={String(form.state.maxTicketsPerBuyer)}
              onChange={value =>
                form.update('maxTicketsPerBuyer', value ? Number(value) : null)
              }
            />

            {form.validation.maxTicketsPerBuyer === false && (
              <p className="t-warning t-small">
                Needs to be a valid number, 0+.
              </p>
            )}

            <p className="t-faded t-small">
              0 = unlimited number of tickets per buyer.
            </p>
          </div>

          {form.state.ticketTypes.map((type, index) => (
            <div
              className="field f f--align-end f--gap"
              key={`ticket-type-${index}`}
            >
              <div className="f--three">
                <Input
                  label="Ticket name"
                  value={type.name}
                  onChange={value => handleTicketNameUpdate(index, value)}
                />
              </div>

              <div className="f--one">
                <Input
                  type="number"
                  label="$AUD"
                  value={String(type.price)}
                  onChange={value =>
                    handleTicketPriceUpdate(index, Number(value))
                  }
                />
              </div>

              {index !== 0 ? (
                <button
                  type="button"
                  className="button event-form__remove-ticket-type"
                  onClick={() =>
                    form.update(
                      'ticketTypes',
                      form.state.ticketTypes.filter(
                        (_, currentIndex) => index !== currentIndex,
                      ),
                    )
                  }
                >
                  <img src={IconDelete} alt="&times;" />
                </button>
              ) : (
                <div className="event-form__remove-ticket-type" />
              )}
            </div>
          ))}

          {form.validation.ticketTypes === false && (
            <p className="t-warning t-small">
              One of your ticket types is missing a name or a valid price.
            </p>
          )}

          <button
            type="button"
            className="button button--medium button--ghost button--ghost-primary s-top--med t-small event-form__add-ticket-type"
            onClick={() =>
              form.update('ticketTypes', [
                ...form.state.ticketTypes,
                { name: '', price: 0 },
              ])
            }
          >
            <span className="s-right--med t-base">+</span>Add ticket type
          </button>

          <p className="event-form__section">Thumbnail (optional)</p>

          <p className="t-small t-faded field__lbl">
            Upload an image that shows what the audio is about.
          </p>

          {form.state.featuredImageUrl || form.state.featuredImageFile ? (
            <>
              <div className="dropzone__container">
                <button
                  type="button"
                  onClick={handleRemoveThumbnail}
                  className="button button--ghost button--ghost-primary dropzone__remove-preview"
                >
                  <img src={IconClose} alt="&times;" />
                </button>
                <img
                  className="dropzone__area-image"
                  src={
                    form.state.featuredImageFile
                      ? URL.createObjectURL(form.state.featuredImageFile)
                      : form.state.featuredImageUrl || undefined
                  }
                  alt=""
                />
              </div>

              {form.validation.featuredImageFile === false && (
                <p className="t-small t-warning">
                  File must be an image under{' '}
                  {MAX_FEATURED_IMAGE_SIZE / 1000000} megabytes in size.
                </p>
              )}
            </>
          ) : (
            <div className="dropzone__container">
              <div className="dropzone__label">
                <img src={IconDropzoneImage} alt="" />
                <p className="t-small t-faded s-top--sml">
                  Drop an image here to upload
                </p>
                <p className="t-secondary t-small">or select a file manually</p>
              </div>

              <Dropzone
                onDropAccepted={handleThumbnailFile}
                onDropRejected={() =>
                  toast.error(
                    `Unsupported or invalid file type. File must be an image type under ${MAX_FEATURED_IMAGE_SIZE /
                      1000000}MB.`,
                  )
                }
                multiple={false}
                className="dropzone__area dropzone__area-image"
                disablePreview={false}
                accept="image/png, image/jpeg"
                maxSize={MAX_FEATURED_IMAGE_SIZE}
              />
            </div>
          )}
        </div>

        <div className="modal__footer">
          <button
            disabled={loading}
            className="button button--primary button--medium"
          >
            Save
          </button>
          <button
            type="button"
            disabled={loading}
            className="button button--outline button--outline-primary button--medium"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </form>
    </MainModal>
  );
};
