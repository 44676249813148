import { where } from 'firebase/firestore';
import { Collection, FirestoreService } from 'modules/firebase';
import { useRecoilValue } from 'recoil';
import { TicketAtoms } from '../recoil';

const TicketsCollection = new FirestoreService<Ticket>(Collection.Tickets);

export function useTickets() {
  const held = useRecoilValue(TicketAtoms.held);
  const purchased = useRecoilValue(TicketAtoms.purchased);
  const loadingHeld = useRecoilValue(TicketAtoms.loadingHeld);
  const loadingPurchased = useRecoilValue(TicketAtoms.loadingPurchased);

  function getEventTickets(eventId: string) {
    return TicketsCollection.get([where('eventId', '==', eventId)]);
  }

  function getUserPurchasedTickets(purchasedById: string) {
    return TicketsCollection.get([where('purchasedById', '==', purchasedById)]);
  }

  return {
    held,
    purchased,
    loadingHeld,
    loadingPurchased,
    getEventTickets,
    getUserPurchasedTickets,
  };
}
