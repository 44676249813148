import { useRecoilState } from 'recoil';
import { Collection, FirestoreService } from 'modules/firebase';
import { VideoAtoms } from '../recoil';

const VideoCategoryCollection = new FirestoreService<VideoCategory>(
  Collection.VideoCategories,
);

export function useVideoCategoryForm() {
  const [isOpen, setIsOpen] = useRecoilState(VideoAtoms.categoryFormOpen);
  const [category, setCategory] = useRecoilState(VideoAtoms.editingCategory);

  function open() {
    setIsOpen(true);
    setCategory(undefined);
  }

  function edit(category: VideoCategory) {
    setIsOpen(true);
    setCategory(category);
  }

  function close() {
    setIsOpen(false);
    setCategory(undefined);
  }

  return {
    category,
    isOpen,
    edit,
    open,
    close,
    add: VideoCategoryCollection.add,
    update: VideoCategoryCollection.set,
    remove: VideoCategoryCollection.remove,
  };
}
