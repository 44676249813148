import { EventInfoForm } from './EventInfoForm';
import { getTimestampFromRawDate } from 'utils';

export interface EventInfoConstructor extends EventInfo {}
export class EventInfoConstructor {
  constructor(data: EventInfoForm, featuredImage: FileReference | null) {
    this.title = data.title;
    this.description = data.description;
    this.shortDescription = data.shortDescription;
    this.location = data.location;
    this.venue = data.venue;
    this.status = data.status;

    this.ticketTypes = data.ticketTypes;
    this.numberOfTickets = data.numberOfTickets;
    this.maxTicketsPerBuyer = data.maxTicketsPerBuyer
      ? Number(data.maxTicketsPerBuyer)
      : null;

    this.startDateRaw = data.startDateRaw;
    this.endDateRaw = data.endDateRaw;
    this.cutoffDateRaw = data.cutoffDateRaw;
    this.timezone = data.timezone;
    this.timezoneOffset = data.timezoneOffset;

    this.startTimestamp = getTimestampFromRawDate(
      data.startDateRaw,
      data.timezoneOffset,
    );
    this.endTimestamp = getTimestampFromRawDate(
      data.endDateRaw,
      data.timezoneOffset,
    );
    this.cutoffTimestamp = getTimestampFromRawDate(
      data.cutoffDateRaw,
      data.timezoneOffset,
    );

    this.featuredImagePath = featuredImage?.path || data.featuredImagePath;
    this.featuredImageUrl = featuredImage?.url || data.featuredImageUrl;
  }
}
