export enum ACLType {
    Public = 'Public',
    AnonymousOnly = 'AnonymousOnly',
    Authenticated = 'Authenticated',
    DiamondOnly = 'DiamondOnly',
    AdministratorOnly = 'AdministratorOnly',
    TeamLeaders = 'TeamLeaders',
    TeamLeadersAndAdmin = 'TeamLeadersAndAdmin',
    DiamondAndAdmin = 'DiamondAndAdmin'
}
