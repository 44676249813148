import React from 'react';

interface Props {
  withoutContainer?: boolean;
  withoutPadding?: boolean;
}

export const Loading: ReactFC<Props> = ({
  withoutContainer,
  withoutPadding,
}) => {
  if (withoutPadding) {
    return (
      <div className="s-top--med s-bottom--med f f--center-alt">
        <img
          src={require('../assets/loaders/teammak-loader.gif')}
          alt="Diamond loader"
          className="loading__loader--small"
        />
      </div>
    );
  }

  return (
    <div className={withoutContainer ? '' : 'container container--empty'}>
      <div className="loading loading--content t-center">
        <img
          src={require('../assets/loaders/teammak-loader.gif')}
          alt="Diamond loader"
          className="loading__loader--small"
        />
      </div>
    </div>
  );
};
