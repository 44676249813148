import React from 'react';
import { Link } from 'react-router-dom';
import IconFacebook from 'assets/icons/icon-facebook.svg';

const year = new Date().getFullYear();

export const Footer: ReactFC = () => (
  <footer className="footer">
    <div className="wrapper">
      <div className="footer__main s-top--lrg">
        <h1 className="t-zeta s-bottom--med">
          Team<span className="t-bold">Mak</span>
        </h1>
        <div className="social s-bottom--med">
          <a href="https://www.facebook.com/myteammak/">
            <img src={IconFacebook} alt="facebook icon" />
          </a>
        </div>
        <Link to="/contact">
          <h2 className="t-small t-secondary">Contact us</h2>
        </Link>
      </div>

      <p className="s-top--lrg t-center t-small u-opacity--60">
        &copy; {year}. Copyrights by TeamMak. All rights reserved.
      </p>
      <div className="f-f--center">
        <Link className="t-center s-top--lrg" to="/terms-and-conditions">
          <h2 className="t-small t-secondary">Terms &amp; Conditions</h2>
        </Link>
        <a
          className="t-center s-top--lrg"
          href="/terms-and-conditions#ticketing"
        >
          <h2 className="t-small t-secondary">
            Registration/Ticketing Terms &amp; Conditions
          </h2>
        </a>
        <Link className="t-center s-top--lrg" to="/privacy-policy">
          <h2 className="t-small t-secondary">Privacy Policy</h2>
        </Link>
      </div>
    </div>
  </footer>
);
