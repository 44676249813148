import React, { useCallback, useEffect, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useVideoForm, useVideos } from 'modules/videos';
import {
  AdministrationOptions,
  ConfirmModal,
  DeleteModal,
  FavoriteButton,
  Loading,
  MainModal,
  PageHeader,
  PageTitle,
  PlaylistSelector,
} from 'components';

import { usePlaylist } from 'modules/playlists';
import { useNavigate, useParams } from 'react-router';
import { useAuthentication } from 'modules/authentication';
import { useVideoPlayer } from 'modules/video-player/hooks';
import { GeneralError } from './GeneralError';
import moment from 'moment';
import { RoleCheck, Role } from 'modules/authorization';
import IconPlaylist from 'assets/icons/playlist-add.svg';
import IconInfo from 'assets/icons/notice-info.svg';

export const VideoDetails: ReactFC = () => {
  const { id = '', playlist: playlistParam = '' } = useParams();
  const { user } = useAuthentication();
  const {
    loading,
    currentVideo,
    getVideoById,
    setCurrentVideo,
    removeVideo,
    publishVideo,
  } = useVideos();
  const navigate = useNavigate();
  const { edit } = useVideoForm();
  const { toggleFavorite, isFavorite } = usePlaylist('video');
  const {
    playlist,
    playlistIndex,
    updatePlayProgress,
    updatePlayedCount,
    finishedWatching,
    close,
    next,
  } = useVideoPlayer();
  const [onPlayCounted, setOnPlayCounted] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);

  useEffect(() => {
    if (!playlistParam) close();
  }, [playlistParam]);

  useEffect(() => {
    if (id) getVideoById(id).then(setCurrentVideo);
  }, [id]);

  const updateProgress = useCallback(() => {
    if (!currentVideo) return;
    updatePlayProgress(currentVideo, percentage, seconds);
  }, [percentage, seconds, currentVideo]);

  const getLastProgressTime = (videoId: string) => {
    if (!user || !user.videoStats) {
      return 0;
    }

    const stats = user.videoStats.find(s => s.trackId === videoId);
    if (!stats) {
      return 0;
    }

    return stats.secondsListened;
  };

  const setLastProgressTime = (data: any) => {
    setPercentage(0);
    setSeconds(0);
  };

  const updateVideoPlayedCounter = () => {
    if (!currentVideo || !user) {
      return;
    }

    if (!onPlayCounted) {
      setOnPlayCounted(true);
      updatePlayedCount(currentVideo);
    }
  };

  const onFinishedWatching = () => {
    if (!currentVideo || !user) {
      return;
    }

    finishedWatching(currentVideo.id);

    /** No playlist loaded, do nothing */
    if (playlistIndex === undefined) {
      return;
    }

    /** Playlist finished */
    if (playlistIndex === playlist.length - 1) close();
    else next(playlistParam);
  };

  useEffect(() => {
    return updateProgress;
  }, [currentVideo]);

  const onPauseHandler = () => {
    if (!user) return;
    updateProgress();
  };

  if (loading) {
    return <Loading />;
  }

  if (!currentVideo) {
    return <GeneralError />;
  }

  return (
    <>
      <RoleCheck role={Role.Administrator}>
        {showDeleteModal && (
          <DeleteModal
            title={`Delete ${currentVideo.title}?`}
            description="Are you sure you want to delete this video? This action cannot be undone."
            close={() => setShowDeleteModal(false)}
            confirm={() => {
              removeVideo(currentVideo.id);
              setShowDeleteModal(false);
              navigate(-1);
            }}
          />
        )}
        {showPublishModal && (
          <ConfirmModal
            confirm={() => {
              publishVideo(currentVideo);
              setShowPublishModal(false);
            }}
            close={() => setShowPublishModal(false)}
            title={`Publish ${currentVideo.title}?`}
            description="Publishing this video makes it available for all users. Do you want to proceed?"
          />
        )}
      </RoleCheck>

      {showPlaylistModal && (
        <MainModal
          title="Add to playlist"
          close={() => setShowPlaylistModal(false)}
        >
          <PlaylistSelector
            close={() => setShowPlaylistModal(false)}
            trackId={currentVideo.id}
            type="video"
          />
        </MainModal>
      )}

      <PageHeader back={true} mobileOnly>
        <PageTitle>Videos</PageTitle>
      </PageHeader>
      <section className="video-details">
        <div>
          <div className="video-details__video-container">
            <div className="wrapper__narrow">
              <Vimeo
                autoplay={!!playlist.length}
                onTimeUpdate={setLastProgressTime}
                onPlay={updateVideoPlayedCounter}
                video={currentVideo.videoSource}
                className="video-details__video"
                style={{}}
                start={
                  !playlist ? getLastProgressTime(currentVideo.id) : undefined
                }
                showTitle={false}
                showByline={false}
                onEnd={onFinishedWatching}
                onPause={onPauseHandler}
                color="#23D5C1"
              />
            </div>
          </div>
          <div className="video-details__metadata wrapper__narrow">
            <div className="f f--gap f--justify-between">
              <div className="f--one">
                <h1 className="title title--main t-left">
                  {currentVideo.title}
                </h1>
                <p className="t-small t-faded">
                  {currentVideo.author}{' '}
                  <span className="s-left--sml s-right--sml">&bull;</span>{' '}
                  {moment(currentVideo.timeCreated).format('ll')}
                </p>
              </div>
              <AdministrationOptions
                edit={() => edit(currentVideo)}
                remove={() => setShowDeleteModal(true)}
                publish={
                  currentVideo.status === 'draft'
                    ? () => setShowPublishModal(true)
                    : undefined
                }
              />
            </div>

            {currentVideo.status === 'draft' && (
              <RoleCheck role={Role.Administrator}>
                <div className="notice notice--info s-top--med">
                  <img src={IconInfo} alt="i" />
                  <div className="f f--gap">
                    <p className="t-small">
                      Content is in draft mode. Other users will not be able to
                      see it until it is published.
                    </p>

                    <button
                      type="button"
                      onClick={() => setShowPublishModal(true)}
                      className="button button--tiny button--outline button--outline-info s-left--med"
                    >
                      Publish
                    </button>
                  </div>
                </div>
              </RoleCheck>
            )}

            <div className="video-details__controls s-top--med">
              <FavoriteButton
                isFavorite={isFavorite(currentVideo.id)}
                toggleCallback={() => toggleFavorite(currentVideo.id)}
                className="button button--ghost button--ghost-primary button--medium"
                showText
              />
              <button
                className="button button--ghost button--ghost-primary button--medium"
                onClick={() => setShowPlaylistModal(true)}
              >
                <img src={IconPlaylist} alt="" />
                Add to playlist
              </button>
            </div>
          </div>
        </div>
        <div className="video-details__content">
          <div className="wrapper__narrow">
            {currentVideo.description && (
              <>
                <p className="t-bold t-medium">Description</p>
                <div
                  className="wysiwyg-content s-top--med"
                  dangerouslySetInnerHTML={{ __html: currentVideo.description }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
