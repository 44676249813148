import { orderBy, where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { SharedAudio } from '../models';
import { SharedAtoms } from '../recoil';

const SharedCollection = new FirestoreService<SharedAudio>(Collection.Shared);

export function useSharedAudiosListener() {
  const { user } = useAuthentication();
  const setSharedAudios = useSetRecoilState(SharedAtoms.shared);
  const setLoading = useSetRecoilState(SharedAtoms.loading);

  useEffect(() => {
    if (!user?.id) return;

    setLoading(true);
    const listener = SharedCollection.listen({
      onSuccess: setSharedAudios,
      onError: error => console.log('Error loading shared audios: ', error),
      onFinished: () => setLoading(false),
      filters: [where('sharedBy', '==', user.id), orderBy('date', 'desc')],
    });

    return listener;
  }, [user?.id]);
}
