import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';

interface Props {
  back?: boolean;
  mobileOnly?: boolean;
}

export const PageHeader: ReactFC<PropsWithChildren<Props>> = ({
  back,

  mobileOnly,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <header
      className={`${
        mobileOnly ? 'page-header page-header--mobile-only' : 'page-header'
      }`}
    >
      {back && (
        <button
          onClick={() => navigate(-1)}
          className="button  page-header__back"
        >
          <svg width="24" height="24">
            <path
              d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"
              fill="#6b727d"
            />
          </svg>
        </button>
      )}
      <div className="wrapper">
        <div className="page-header__container">{children}</div>
      </div>
    </header>
  );
};
