import React from 'react';

interface Props {
  mainImageUrl?: string | null;
  fallbackImageUrl: string;
  classes?: string;
  watchedPercentage?: number;
  subcomponent?: JSX.Element | null;
}

export const DivWithBackgroundImage: ReactFC<Props> = ({
  mainImageUrl,
  fallbackImageUrl,
  classes,
  watchedPercentage,
  subcomponent,
}) => {
  return (
    <div
      className={classes}
      style={{
        backgroundImage: `url(${mainImageUrl || fallbackImageUrl})`,
        backgroundSize: 'cover',
      }}
    >
      {!watchedPercentage ? null : (
        <span
          className="audio-item-simple__percentage"
          style={{ maxWidth: watchedPercentage + '%' }}
        />
      )}
      {subcomponent}
    </div>
  );
};
