import React, { useState } from 'react';
import { Loading, PageHeader, PageTitle, ValidatedInput } from 'components';
import { ValidationMethod } from 'modules/validation';
import { toast } from 'react-toastify';
import { useAuthentication } from 'modules/authentication';

interface State {
  email: string;
  emailValid: boolean;
  [param: string]: string | boolean | undefined;
}

export const ForgotPassword: ReactFC = () => {
  const { sendResetEmail } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<State>({
    email: '',
    emailValid: false,
  });

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    const { email } = state;
    e.preventDefault();
    try {
      setLoading(true);
      await sendResetEmail(email);
      toast('Reset link sent successfully', { type: 'success' });
    } catch (e) {
      toast(e instanceof Error ? e.message : JSON.stringify(e), {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (prop: string, value: string, isValid?: boolean) =>
    setState({ ...state, [prop]: value, [`${prop}Valid`]: isValid });

  const renderPageHeader = () => {
    return (
      <React.Fragment>
        <PageHeader mobileOnly={true} back={true}>
          <PageTitle>Forgot password</PageTitle>
        </PageHeader>
      </React.Fragment>
    );
  };

  const { email, emailValid } = state;
  const formValid = emailValid;

  return (
    <section>
      {renderPageHeader()}
      <div className="content content--dashboard">
        <div className="wrapper">
          <div className="forgot-password__content">
            <h1 className="t-gamma s-bottom--lrg t-extrabold desktop-only">
              Forgot password?
            </h1>
            <form onSubmit={handleSubmit}>
              <ValidatedInput
                type="email"
                validationType={ValidationMethod.Email}
                value={email}
                propName="email"
                label="Email address"
                changeCallback={onInputChange}
              />

              {loading ? (
                <Loading />
              ) : (
                <button
                  type="button"
                  value="Submit"
                  className="button button--medium button--primary s-top--med forgot-password__button"
                  onClick={handleSubmit}
                  disabled={loading || !formValid}
                >
                  Send reset link
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
