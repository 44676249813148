export interface VideoInfoForm
  extends Omit<
    VideoInfo,
    'id' | 'normalizedTitle' | 'timeCreated' | 'thumbnailUrl' | 'thumbnailPath'
  > {
  thumbnailUrl: string | null;
  thumbnailPath: string | null;
  thumbnailFile: File | null;
}

export class VideoInfoForm {
  constructor(video: VideoInfo | undefined) {
    this.title = video?.title || '';
    this.author = video?.author || '';
    this.description = video?.description || '';
    this.shortDescription = video?.shortDescription || '';
    this.categoryIds = video?.categoryIds || [];
    this.status = video?.status || 'draft';

    /** Should never be an empty string provided validation is properly done. If you end up with videoSource = "", check VideoForm validation. */
    this.videoSource = video?.videoSource || '';

    this.thumbnailPath = video?.thumbnailPath || null;
    this.thumbnailUrl = video?.thumbnailUrl || null;
    this.thumbnailFile = null;
  }
}
