import { DropdownMenu } from './DropdownMenu';
import IconDropdownCaret from 'assets/icons/caret-right.svg';
import { DropdownMenuItem } from './DropdownMenuItem';
import { PublishedFilter, PlayedStatusFilter, SortFilter } from 'models';
import { Role, RoleCheck } from 'modules/authorization';

const SORT_LABELS: Record<SortFilter, string> = {
  newest: 'Newest first',
  oldest: 'Oldest first',
  title: 'Title',
};

const MEDIA_STATUS_LABELS: Record<PlayedStatusFilter, string> = {
  'not-played': "Haven't played",
  played: 'Played',
};

const PUBLISHED_FILTER_LABELS: Record<PublishedFilter, string> = {
  draft: 'Draft only',
  published: 'Published only',
};

interface Props {
  sort: SortFilter;
  onSortChanged: (sort: SortFilter) => void;
  played: PlayedStatusFilter | undefined;
  onPlayedChanged: (played: PlayedStatusFilter | undefined) => void;
  published: PublishedFilter | undefined;
  onPublishedChanged: (published: PublishedFilter | undefined) => void;
}

export const MediaFilters: React.FC<Props> = ({
  sort,
  played,
  published,
  onSortChanged,
  onPlayedChanged,
  onPublishedChanged,
}) => {
  return (
    <div className="media-filters">
      <div className="f f--center f--gap-small u-width--content">
        <p className="t-small t-faded">Sort by</p>
        <DropdownMenu
          control={
            <div className="media-filters__control">
              <p className="t-small">{SORT_LABELS[sort]}</p>
              <img
                src={IconDropdownCaret}
                className="media-filters__caret"
                alt=">"
              />
            </div>
          }
        >
          {Object.keys(SORT_LABELS).map(key => {
            const value = key as SortFilter;
            return (
              <DropdownMenuItem
                key={`sort-${value}`}
                onSelect={() => onSortChanged(value)}
              >
                {SORT_LABELS[value]}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenu>
      </div>

      <div className="f f--center f--gap-small u-width--content">
        <p className="t-small t-faded">Played</p>
        <DropdownMenu
          control={
            <div className="media-filters__control">
              <p className="t-small">
                {played ? MEDIA_STATUS_LABELS[played] : 'All'}
              </p>
              <img
                src={IconDropdownCaret}
                className="media-filters__caret"
                alt=">"
              />
            </div>
          }
        >
          <DropdownMenuItem onSelect={() => onPlayedChanged(undefined)}>
            All
          </DropdownMenuItem>
          {Object.keys(MEDIA_STATUS_LABELS).map(key => {
            const value = key as PlayedStatusFilter;
            return (
              <DropdownMenuItem
                key={value}
                onSelect={() => onPlayedChanged(value)}
              >
                {MEDIA_STATUS_LABELS[value]}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenu>
      </div>

      <RoleCheck role={Role.Administrator}>
        <div className="f f--center f--gap-small u-width--content">
          <p className="t-small t-faded">Status</p>
          <DropdownMenu
            control={
              <div className="media-filters__control">
                <p className="t-small">
                  {published ? PUBLISHED_FILTER_LABELS[published] : 'All'}
                </p>
                <img
                  src={IconDropdownCaret}
                  className="media-filters__caret"
                  alt=">"
                />
              </div>
            }
          >
            <DropdownMenuItem onSelect={() => onPublishedChanged(undefined)}>
              All
            </DropdownMenuItem>

            {Object.keys(PUBLISHED_FILTER_LABELS).map(key => {
              const value = key as PublishedFilter;
              return (
                <DropdownMenuItem
                  key={value}
                  onSelect={() => onPublishedChanged(value)}
                >
                  {PUBLISHED_FILTER_LABELS[value]}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenu>
        </div>
      </RoleCheck>
    </div>
  );
};
