import { atom } from 'recoil';
import { SharedAudio } from '../models';

const shared = atom<SharedAudio[]>({
  key: 'shared-audios',
  default: [],
  dangerouslyAllowMutability: true,
});

const loading = atom<boolean>({
  key: 'shared-loading',
  default: false,
});

export const SharedAtoms = {
  shared,
  loading,
};
