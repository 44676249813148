import React from 'react';

interface Props {
  subscription: UserSubscription | undefined;
}

const today = Date.now();
export const StripeSubscriptionStatus: ReactFC<Props> = ({ subscription }) => {
  if (
    !subscription ||
    !subscription.stripe ||
    !subscription.stripe.subscriptionExpirationTime
  ) {
    return null;
  }

  const active = subscription.stripe.active;
  const expired = (subscription.stripe.subscriptionExpirationTime || 0) < today;

  return (
    <div className="dashboard-sidebar__item f f--top">
      <p className="t-epsilon u-opacity--60">
        {active ? 'Renews on:' : expired ? 'Ended on' : 'Ends on:'}
      </p>
      {subscription.stripe.subscriptionExpirationTime && (
        <p className="t-epsilon u-opacity--60">
          <b>
            {new Date(
              subscription.stripe.subscriptionExpirationTime,
            ).toLocaleDateString()}
          </b>
        </p>
      )}
    </div>
  );
};
