import { atom } from 'recoil';

const loading = atom<boolean>({
  key: 'events-loading',
  default: false,
});

const events = atom<EventInfo[]>({
  key: 'events',
  default: [],
  dangerouslyAllowMutability: true,
});

const pastEvents = atom<EventInfo[]>({
  key: 'events-past',
  default: [],
  dangerouslyAllowMutability: true,
});

const editingEvent = atom<EventInfo | undefined>({
  key: 'editing-event',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const eventFormOpen = atom<boolean>({
  key: 'event-form-update',
  default: false,
});

export const EventAtoms = {
  events,
  pastEvents,
  loading,
  editingEvent,
  eventFormOpen,
};
