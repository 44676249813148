import { useForm } from 'hooks';
import { stringToSlug } from 'modules/helpers';
import React from 'react';
import { usePlaylist } from '../hooks';

interface Props {
  onSave: (name: string) => void;
  defaultValue?: string;
  type: 'audio' | 'video';
}

export const PlaylistModalForm: ReactFC<Props> = ({
  onSave,
  defaultValue,
  type,
}) => {
  /** Playlist type is not important in this case. */
  const { audioPlaylists, videoPlaylists } = usePlaylist(type);
  const { state, validation, update, validateForm } = useForm<{ name: string }>(
    {
      name: defaultValue || '',
    },
    {
      rules: {
        name: value =>
          !!value &&
          (type === 'audio'
            ? !audioPlaylists.some(p => p.id === stringToSlug(value))
            : !videoPlaylists.some(p => p.id === stringToSlug(value))),
      },
    },
  );

  function handleSave() {
    if (!validateForm()) return;
    onSave(state.name);
  }

  return (
    <React.Fragment>
      <div className="modal__content">
        <div className="field">
          <input
            id="playlist-name"
            name="playlist-name"
            type="text"
            value={state.name}
            className="input t-base input--text"
            placeholder="Enter new playlist name"
            onChange={({ currentTarget }) =>
              update('name', currentTarget.value)
            }
          />
          {validation.name === false && (
            <p className="t-warning t-small">Playlist name is not valid.</p>
          )}
        </div>
      </div>
      <div className="modal__footer">
        <button
          onClick={handleSave}
          disabled={state.name === defaultValue}
          className="button button--small  modal__submit"
        >
          Save changes
        </button>
      </div>
    </React.Fragment>
  );
};
