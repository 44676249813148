import React from 'react';
import IconOptionsDelete from 'assets/icons/options-delete.svg';

interface Props {
  id: string;
  deleteAudio: (id: string) => void;
}

export const DisabledAudioItem: ReactFC<Props> = ({ id, deleteAudio }) => {
  return (
    <div key={id} className="card audio-item">
      <div className="audio-item__content">
        <img
          loading="lazy"
          src={require('assets/images/placeholder-square.png')}
          alt=""
          className="audio-item__thumbnail u-opacity--20"
        />

        <div className="f--one">
          <p className="title title--card t-base u-opacity--60">
            This audio is no longer available.
          </p>
        </div>
      </div>
      <div className="audio-item__footer f">
        <button
          onClick={() => deleteAudio(id)}
          className="button button--ghost-warning button--small s-left--auto audio-item__button"
        >
          <img src={IconOptionsDelete} alt="" />
          <span>Remove item</span>
        </button>
      </div>
    </div>
  );
};
