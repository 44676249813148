import { useAudioStatsListener } from 'modules/audio-stats';
import { useAudiosListener } from 'modules/audios';
import { useAuthenticationListener } from 'modules/authentication';
import { useEventsListener } from 'modules/events';
import { useNotificationsListener } from 'modules/notifications';
import { useSharedAudiosListener } from 'modules/share';
import { useSubscriptionListener } from 'modules/subscription';
import { useTeamsListener } from 'modules/teams';
import { useTeamMembersListener } from 'modules/teams';
import { useTicketsListener } from 'modules/tickets';
import { useVideosListener } from 'modules/videos';
import React from 'react';

export const ListenerProvider: ReactFC = () => {
  useAuthenticationListener();
  useSubscriptionListener();
  useNotificationsListener();
  useEventsListener();
  useAudiosListener();
  useVideosListener();
  useTicketsListener();
  useTeamsListener();
  useTeamMembersListener();
  useSharedAudiosListener();
  useAudioStatsListener();

  return <React.Fragment />;
};
