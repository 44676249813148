import React from 'react';

interface Props {
  toggleCallback: () => void;
  isFavorite: boolean;
  showText?: boolean;
  className?: string;
  svgClassName?: string;
}

export const FavoriteButton: ReactFC<Props> = ({
  toggleCallback,
  isFavorite,
  showText,
  className,
  svgClassName,
}) => (
  <button
    className={
      className || 'button button--ghost button--ghost-primary button--medium'
    }
    onClick={e => {
      e.stopPropagation();
      e.preventDefault();
      toggleCallback();
    }}
  >
    {isFavorite ? (
      <svg
        width="17"
        height="15"
        xmlns="http://www.w3.org/2000/svg"
        className={svgClassName}
      >
        <path
          d="M14.827809 2.17245c-1.561922-1.56194-4.094796-1.56194-5.656718 0-.279986.27999-.490975.59798-.670966.92897-.179991-.33099-.39098-.64998-.671966-.92997-1.561922-1.56193-4.094796-1.56193-5.656718 0-1.561921 1.56194-1.561921 4.09485 0 5.65679L8.500125 14l6.327684-6.17176c1.562921-1.56194 1.562921-4.09385 0-5.65579z"
          fill="#09A593"
          stroke="#09A593"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        width="17"
        height="15"
        xmlns="http://www.w3.org/2000/svg"
        className={svgClassName}
      >
        <path
          d="M14.827809 2.172455c-1.561922-1.56194-4.094796-1.56194-5.656718 0-.279986.279989-.490975.597977-.670966.928964-.179991-.330987-.39098-.649975-.671966-.929964-1.561922-1.56194-4.094796-1.56194-5.656718 0-1.561921 1.56194-1.561921 4.094842 0 5.656782L8.500125 14l6.327684-6.171763c1.562921-1.56194 1.562921-4.093842 0-5.655782z"
          stroke="#09A593"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {showText && <span className="">Favorite</span>}
  </button>
);
