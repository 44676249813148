import React, { useEffect, useState } from 'react';
import {
  VideoItem,
  useVideoCategoryForm,
  useVideoForm,
  useVideos,
} from 'modules/videos';
import {
  Loading,
  MediaFilters,
  Search,
  ScrollingContent,
  DeleteModal,
  AdministrationOptions,
  PageHeader,
} from 'components';
import { useNavigate, useParams } from 'react-router';
import IconEmptyStateVideo from 'assets/empty-states/empty-state-video.svg';
import { Role, RoleCheck } from 'modules/authorization';
import { useMediaFilters, useUrlState } from 'hooks';
import { PlayedStatusFilter, PublishedFilter, SortFilter } from 'models';

export const Videos: ReactFC = () => {
  const { playlist = '', categoryName = '' } = useParams();
  const { applyFilters } = useMediaFilters('video');
  const {
    videos,
    loading,
    categories,
    getCategoryByTitleSlug,
    loadAllVideos,
    loadCategoryVideos,
    removeCategory,
  } = useVideos();
  const navigate = useNavigate();
  const categoryForm = useVideoCategoryForm();
  const videoForm = useVideoForm();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [query, setQuery] = useUrlState('search', '');
  const [sortFilter, setSortFilter] = useUrlState<SortFilter>('sort', 'newest');
  const [playedFilter, setPlayedFilter] = useUrlState<
    PlayedStatusFilter | undefined
  >('played', undefined);
  const [publishedFilter, setPublishedFilter] = useUrlState<
    PublishedFilter | undefined
  >('status', undefined);

  const category = getCategoryByTitleSlug(categoryName);

  /** Load category videos if not loaded already. */
  useEffect(() => {
    /** If no categories have been loaded yet, do nothing. */
    if (!categories.length || !categoryName) return;

    /** If all category, load audios if none are loaded yet. */
    if (categoryName === 'all') {
      document.title = 'TeamMAK | Videos - All';
      loadAllVideos();
    } else {
      /** Get category ID through slug */
      const category = getCategoryByTitleSlug(categoryName);
      if (!category) return;

      document.title = `TeamMAK | Videos - ${category.title}`;
      loadCategoryVideos(category.id);
    }
  }, [categoryName, categories]);

  const getParentUrl = () => {
    return `/videos/${playlist || categoryName}`;
  };

  const renderVideos = () => {
    let filtered = applyFilters(
      [...(videos[category?.id || categoryName] || [])],
      sortFilter,
      playedFilter,
      publishedFilter,
    );

    if (query.length) {
      filtered = filtered.filter(
        item =>
          item.title.toLowerCase().includes(query.toLowerCase()) ||
          item.author.toLowerCase().includes(query.toLowerCase()),
      );
    }

    if (loading) {
      return (
        <div className="wrapper">
          <Loading />
        </div>
      );
    }

    if (!filtered || !filtered.length) {
      return (
        <div className="wrapper">
          <div className="empty-state">
            <img
              className="empty-state__visual"
              alt="empty"
              src={IconEmptyStateVideo}
            />
            <p className="empty-state__label">
              No videos available at the moment. Please check again later.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="lost-flex-container s-top--med">
        <ScrollingContent
          data={filtered}
          renderItem={video => (
            <VideoItem
              key={video.id}
              video={video}
              parentUrl={getParentUrl()}
            />
          )}
        />
      </div>
    );
  };

  return (
    <>
      <PageHeader back mobileOnly />
      <section className="content-page">
        <div className="wrapper">
          <div className="content-page__heading-container">
            <h1 className="content-page__title">
              {category?.title || categoryName}
            </h1>

            {category && (
              <div className="content-page__heading-options">
                <AdministrationOptions
                  edit={() => categoryForm.edit(category)}
                  remove={() => setShowDeleteModal(true)}
                />
              </div>
            )}
          </div>

          <div className="content-page__controls">
            <Search
              query={query}
              placeholder="Search videos by title or author"
              changeCallback={setQuery}
            />

            <div className="content-page__controls-actions">
              <RoleCheck role={Role.Administrator} exact={true}>
                <button
                  onClick={() => videoForm.open(category?.id)}
                  type="button"
                  className="button button--primary button--medium"
                >
                  + New video
                </button>
              </RoleCheck>

              {category && (
                <div className="content-page__controls-options">
                  <AdministrationOptions
                    edit={() => categoryForm.edit(category)}
                    remove={() => setShowDeleteModal(true)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="content-page__body">
        <div className="wrapper">
          <MediaFilters
            sort={sortFilter}
            played={playedFilter}
            published={publishedFilter}
            onPlayedChanged={setPlayedFilter}
            onSortChanged={setSortFilter}
            onPublishedChanged={setPublishedFilter}
          />
          {renderVideos()}
        </div>
      </section>

      {showDeleteModal && category && (
        <DeleteModal
          title={`Delete ${category?.title}?`}
          description="Are you sure you want to delete this category? This action cannot be undone. Note: Category videos will not be deleted."
          close={() => setShowDeleteModal(false)}
          confirm={() => {
            removeCategory(category?.id);
            setShowDeleteModal(false);
            navigate(-1);
          }}
        />
      )}
    </>
  );
};
