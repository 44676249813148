import { AdministrationOptions, ConfirmModal } from 'components';
import { Role, RoleCheck } from 'modules/authorization';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDisplayDateTime } from 'utils';
import { useEventForm, useEvents } from '../hooks';

interface Props {
  event: EventInfo;
  isPastEvent?: boolean;
}

export const EventItem: ReactFC<Props> = ({ event, isPastEvent }) => {
  const { publishEvent, isTicketPurchaseEnabled, isSoldOut } = useEvents();
  const { edit } = useEventForm();
  const navigate = useNavigate();
  const [showPublishModal, setShowPublishModal] = useState(false);

  const daysUntilStart = moment(event.startTimestamp).diff(Date.now(), 'days');
  const showStartPill = daysUntilStart >= 0 && daysUntilStart <= 15;
  const soldOut = isSoldOut(event);

  const eventUrl = isPastEvent
    ? `/events-history/${event.id}`
    : `/events/${event.id}`;

  function handlePublish() {
    publishEvent(event);
    setShowPublishModal(false);
  }

  function handleGetTickets(
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    evt.preventDefault();
    evt.stopPropagation();
    navigate(`/buy-tickets/${event.id}`);
  }

  return (
    <>
      <div
        onClick={() => navigate(eventUrl)}
        key={event.id}
        className="event-item card s-bottom--lrg"
      >
        <img
          loading="lazy"
          src={
            event.featuredImageUrl ||
            require('assets/images/placeholder-16-9.png')
          }
          className="event-item__thumbnail"
          alt=""
        />

        <div className="f f--one f--gap f--justify-between">
          <div className="f--one">
            <p className="t-label t-label--sml t-faded">
              {getDisplayDateTime(event.startDateRaw)}
            </p>
            <h1 className="title title--card">{event.title}</h1>
            <p className="t-faded t-small t-sans">
              {event.location}{' '}
              {event.venue && (
                <>
                  <span className="s-left--tny s-right--tny">&bull;</span>
                  {event.venue}
                </>
              )}
            </p>

            <div className="f f--align-center f--gap s-top--sml">
              {event.status === 'draft' && (
                <p className="pill pill--info pill--sml">draft</p>
              )}

              {soldOut ? (
                <p className="pill pill--faded pill--sml">sold out</p>
              ) : null}

              {showStartPill && (
                <p className="pill pill__full--note pill--sml">
                  {daysUntilStart === 0
                    ? 'Today'
                    : `in ${daysUntilStart} day(s)`}
                </p>
              )}
            </div>

            <p className="t-small t-faded t-sans s-top--sml">
              {event.shortDescription.length > 100 ? (
                <>{event.shortDescription.substring(0, 197)}&#8230;</>
              ) : (
                event.shortDescription
              )}
            </p>

            <div className="desktop-only">
              {isTicketPurchaseEnabled(event) && (
                <button
                  type="button"
                  className="button button--small button--primary s-top--med"
                  onClick={evt => handleGetTickets(evt)}
                >
                  Get tickets
                </button>
              )}
            </div>
          </div>

          <AdministrationOptions
            edit={() => edit(event)}
            publish={
              event.status === 'draft'
                ? () => setShowPublishModal(true)
                : undefined
            }
          />
        </div>

        <div className="mobile-only u-width--full">
          {isTicketPurchaseEnabled(event) && (
            <button
              type="button"
              className="button button--full-width button--small button--primary s-top--med"
              onClick={evt => handleGetTickets(evt)}
            >
              Get tickets
            </button>
          )}
        </div>
      </div>

      <RoleCheck role={Role.Administrator}>
        {showPublishModal && (
          <ConfirmModal
            confirm={handlePublish}
            close={() => setShowPublishModal(false)}
            title={`Publish ${event.title}?`}
            description="Publishing this event makes it available for all users. Do you want to proceed?"
          />
        )}
      </RoleCheck>
    </>
  );
};
