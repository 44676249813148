import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthenticationHeader, Input } from 'components';
import { useForm } from 'hooks';
import { useAuthentication } from 'modules/authentication';
import { toast } from 'react-toastify';
import { validation as Validation } from 'modules/validation';

interface State {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const Login: ReactFC = () => {
  const { loginAsync } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const { state, validation, validateForm, update } = useForm<State>(
    {
      email: '',
      password: '',
      rememberMe: true,
    },
    {
      rules: { email: value => !!value && Validation.EMAIL_REGEX.test(value) },
    },
  );

  async function submit() {
    try {
      if (!validateForm()) throw new Error();

      setLoading(true);
      await loginAsync(state.email, state.password, state.rememberMe);
    } catch (error) {
      toast.error(
        'We were not able to log you in. Please check your data and try again.',
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <section className="auth">
      <div className="auth__form-container">
        <AuthenticationHeader />
        <div className="auth__wrapper auth__wrapper--form">
          <div className="field">
            <Input
              value={state.email}
              disabled={loading}
              onChange={value => update('email', value)}
              label="E-mail"
              type="email"
            />
          </div>
          {validation.email === false && (
            <p className="t-warning t-zeta">Must be a valid e-mail address.</p>
          )}
          <div className="field">
            <Input
              value={state.password}
              disabled={loading}
              onChange={value => update('password', value)}
              label="Password"
              type="password"
            />
          </div>
          {validation.password === false && (
            <p className="t-warning t-zeta">We'll need your password.</p>
          )}

          <div className="auth__footer f f--center s-bottom--lrg">
            <div className="field">
              <input
                className="input input--check"
                id="rememberMe"
                name="rememberMe"
                checked={state.rememberMe}
                type="checkbox"
                onChange={() => update('rememberMe', !state.rememberMe)}
              />
              <label htmlFor="rememberMe">Remember me</label>
            </div>

            <Link className="auth__forgot" to="/forgot-password">
              Forgot password?
            </Link>
          </div>

          <button
            type="button"
            value="Submit"
            className="button button--medium button--primary auth__submit"
            onClick={submit}
            disabled={loading}
          >
            Login
          </button>
        </div>
      </div>
      <div className="auth__visual" />
    </section>
  );
};
