import React, { useEffect, useState } from 'react';

import { Loading } from 'components';
import { AudioDetailsCore, useAudios } from 'modules/audios';

import { useParams } from 'react-router';
import { GeneralError } from './GeneralError';

const CandidatesCategoryId = '0J9sY7lJdCRwcHk3qtGJ';

export const AudioDetails: ReactFC = () => {
  const { id = '' } = useParams();
  const { getAudioById, currentAudio, setCurrentAudio } = useAudios();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const isInCandidates = currentAudio?.categoryIds.includes(
    CandidatesCategoryId,
  );

  /** Fetch audio. */
  useEffect(() => {
    setError(false);
    setLoading(true);

    getAudioById(id)
      .then(setCurrentAudio)
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [id]);

  if (error) {
    return <GeneralError />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!currentAudio) {
    return <p>Audio could not be found</p>;
  }

  return <AudioDetailsCore audio={currentAudio} shareable={isInCandidates} />;
};
