import { useRecoilValue } from 'recoil';
import { AuthAtoms } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';

import { AudioStatsAtoms } from '../recoil';
import { AudioStatsConstructor } from '../models';
import { orderBy, where } from 'firebase/firestore';

const AudioStatsCollection = new FirestoreService<AudioStats>(
  Collection.AudioStats,
);

export function useAudioStats() {
  const loading = useRecoilValue(AudioStatsAtoms.loading);
  const stats = useRecoilValue(AudioStatsAtoms.stats);
  const user = useRecoilValue(AuthAtoms.user);

  function getStatsByUserId(userId: string) {
    return AudioStatsCollection.get([
      where('userId', '==', userId),
      orderBy('lastPlayed', 'desc'),
    ]);
  }

  async function updatePlayProgress(
    track: AudioInfo,
    percentListened: number,
    secondsListened: number,
  ) {
    if (!user) return;
    const existing = stats.find(stat => stat.trackId === track.id);

    if (!existing) {
      const stat = new AudioStatsConstructor(user.id, track.id, track.title);
      stat.percentListened = percentListened;
      stat.secondsListened = secondsListened;
      await AudioStatsCollection.add(stat);
    } else {
      await AudioStatsCollection.set(existing.id, {
        percentListened,
        secondsListened,
        lastPlayed: +new Date(),
      });
    }
  }

  function onTrackCompleted(track: AudioInfo) {
    return updatePlayProgress(track, 0, 0);
  }

  return {
    stats,
    loading,
    updatePlayProgress,
    onTrackCompleted,
    getStatsByUserId,
  };
}
