import { RecoilState, useResetRecoilState } from 'recoil';

export function useRecoilClear(state: Record<string, RecoilState<any>>) {
  const resetters = Object.keys(state).map(key =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useResetRecoilState(state[key]),
  );

  function reset() {
    resetters.forEach(reset => reset());
  }

  return reset;
}
