import IconSuccess from 'assets/icons/checkmark-success.svg';
import { useNavigate } from 'react-router';

export const PaymentSuccess: ReactFC = () => {
  const navigate = useNavigate();
  return (
    <section className="payment-status">
      <div className="payment-status__container">
        <img
          src={IconSuccess}
          alt="Payment succeeded"
          className="f--shrink-zero payment-status__icon"
        />
        <h1 className="t-xlarge t-bold u-maxwidth--592 t-center payment-status__title t-text-1">
          Your ticket purchase was successful
        </h1>
        <p className="t-base u-maxwidth--592 t-center t-text-3 payment-status__description">
          We've sent a copy of your ticket to your email. You can also access it
          anytime from the Tickets tab in the Overview page.
        </p>
        <button
          className="button button--medium button--primary"
          onClick={() => navigate('/tickets')}
        >
          Go to tickets
        </button>
      </div>
    </section>
  );
};
