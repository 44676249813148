import React from 'react';

interface Props {
  pageNumber: number;
  totalPages: number;
  onGoToNextPage: VoidFunction;
  onGoToPreviousPage: VoidFunction;
  onGoToPage: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onGoToFirstPage: VoidFunction;
  onGoToLastPage: VoidFunction;
}

export const Pagination: ReactFC<Props> = ({
  pageNumber,
  totalPages,
  onGoToNextPage,
  onGoToPreviousPage,
  onGoToPage,
  onGoToFirstPage,
  onGoToLastPage,
}) => (
  <section className="pagination">
    <div className="pagination__container">
      <button
        onClick={onGoToPreviousPage}
        disabled={pageNumber === 1}
        className="button pagination__item t-bold"
      >
        <svg width="6" height="12" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.625.219c-.20710411-.16583589-.47162221-.2425711-.73532205-.2133128C4.62597812.0349455 4.3847145.16779875 4.219.375l-4 5c-.29250033.3653328-.29250033.8846672 0 1.25l4 5c.34517797.4313344.97466561.501178 1.406.156.43133439-.345178.50117797-.9746656.156-1.406L2.281 6l3.5-4.375c.16583589-.20710411.2425711-.47162221.2133128-.73532205C5.9650545.62597812 5.83220125.3847145 5.625.219z"
            fill="#004378"
            fillRule="nonzero"
          />
        </svg>
      </button>

      <div className="pagination__numbers">
        {pageNumber > 2 && (
          <button
            onClick={onGoToFirstPage}
            className="button pagination__item pagination__dots"
          >
            ...
          </button>
        )}

        {pageNumber > 1 && (
          <button
            onClick={onGoToPreviousPage}
            className="button pagination__item"
          >
            {pageNumber - 1}
          </button>
        )}

        <button
          disabled
          data-page-number={pageNumber}
          onClick={onGoToPage}
          className="button pagination__item pagination__item--active"
        >
          {pageNumber}
        </button>

        {pageNumber < totalPages && (
          <button onClick={onGoToNextPage} className="button pagination__item">
            {pageNumber + 1}
          </button>
        )}

        {totalPages - 1 > pageNumber && (
          <button onClick={onGoToLastPage} className="button pagination__item">
            ...
          </button>
        )}
      </div>

      <button
        onClick={onGoToNextPage}
        disabled={pageNumber === totalPages}
        className="button pagination__item t-bold"
      >
        <svg width="6" height="12" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M.37504.219c.20710411-.16583589.47162221-.2425711.73532205-.2133128C1.37406188.0349455 1.6153255.16779875 1.78104.375l4 5c.29250033.3653328.29250033.8846672 0 1.25l-4 5c-.34517797.4313344-.97466561.501178-1.406.156-.43133439-.345178-.50117797-.9746656-.156-1.406l3.5-4.375-3.5-4.375C.05320411 1.41789589-.0235311 1.15337779.0057272.88967795.0349855.62597812.16783875.3847145.37504.219z"
            fill="#004378"
            fillRule="nonzero"
          />
        </svg>
      </button>
    </div>
  </section>
);
