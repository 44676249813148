import React from 'react';
import { Input } from './Input';

interface Props {
  query: string;
  label?: string;
  placeholder?: string;
  changeCallback: (query: string) => void;
  submitCallback?: () => void;
}

export class Search extends React.PureComponent<Props> {
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    this.props.changeCallback(value);
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { submitCallback } = this.props;
    if (submitCallback && event.key === 'Enter') {
      submitCallback();
    }
  };

  submit = () => this.props.submitCallback && this.props.submitCallback();

  render() {
    const { query, placeholder, label } = this.props;
    return (
      <div className="search">
        <Input
          type="text"
          label={label}
          className="input t-base input--text input--search"
          value={query}
          placeholder={placeholder}
          onChange={this.props.changeCallback}
          onKeyDown={this.onKeyDown}
        />
      </div>
    );
  }
}
