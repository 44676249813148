export interface ValidationSettings {
    EMAIL_REGEX: RegExp;
    PASSWORD_REGEX: RegExp;
    PASSWORD_LENGTH_MIN: number;
    PASSWORD_LENGTH_MAX: number;
    PAYMENT_CARD_REGEX: RegExp;
}

export const validation: ValidationSettings = {
    EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD_REGEX: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, // {8, } stands for minimal password lenght, change it also when changing PASSWORD_LENGHT_MIN
    PASSWORD_LENGTH_MIN: 8,
    PASSWORD_LENGTH_MAX: 24,
    PAYMENT_CARD_REGEX: /^(\d{4}[- ]){3}\d{4}|\d{16}$/
};
