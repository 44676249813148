import React from 'react';

import { DashboardHeader } from 'components';
import { ReportCategory } from 'modules/reports';
import { RoleCheck, Role } from 'modules/authorization';

export const DashboardReports: ReactFC = () => {
  return (
    <>
      <DashboardHeader />
      <section className="wrapper dashboard-content">
        <div className="dashboard-content__sidebar dashboard-content__sidebar--narrow">
          <div className="dashboard-content__inner dashboard-content__inner--sidebar">
            <h3 className="dashboard-sidebar__title t-extrabold">Reports</h3>
          </div>
        </div>
        <div className="dashboard-content__main dashboard-content__main--wide">
          <div className="dashboard-content__inner dashboard-content__inner--main f--wrap">
            <RoleCheck role={Role.Administrator}>
              <ReportCategory
                title="Unassigned users"
                description="See reports about unassigned users"
                route="/reports/unassigned-users"
              />
            </RoleCheck>
          </div>
        </div>
      </section>
    </>
  );
};
