import React, { PropsWithChildren } from 'react';
import { Loading } from 'components';

import { Role } from '../enums';
import { useAuthentication } from 'modules/authentication';

interface OwnProps {
  role: Role;
  exact?: boolean;
}

type Props = OwnProps;

export const RoleCheck: ReactFC<PropsWithChildren<Props>> = ({
  role,
  exact,
  children,
}) => {
  const { loading, user } = useAuthentication();
  const checkUserRoleExact = () => {
    return role === user!.role;
  };

  const checkUserRoleHierarchically = () => {
    if (role === Role.Administrator) {
      return user!.role === Role.Administrator;
    }

    if (role === Role.DiamondLeader) {
      return (
        user!.role === Role.DiamondLeader || user!.role === Role.Administrator
      );
    }

    if (role === Role.TeamLeader) {
      return (
        user!.role === Role.TeamLeader ||
        user!.role === Role.DiamondLeader ||
        user!.role === Role.Administrator
      );
    }

    return true;
  };

  // If auth state is changing, wait
  if (loading) {
    return <Loading withoutContainer />;
  }

  // If user is not logged in, there is no role to compare
  if (!user) {
    return null;
  }

  // If user is within the appropriate role, render child elements
  const withinRole = exact
    ? checkUserRoleExact()
    : checkUserRoleHierarchically();

  return withinRole ? <React.Fragment>{children}</React.Fragment> : null;
};
