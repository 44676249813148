import React from 'react';
import { Link } from 'react-router-dom';

import { MemberTicketData } from 'modules/tickets';

interface Props {
  ticketData: MemberTicketData;
}

export const DashboardTicketItem: ReactFC<Props> = ({ ticketData }) => (
  <div className="history-list__item">
    <Link
      className="history-list__field t-bold"
      to={`/member-profile/${ticketData.id}`}
    >
      <p className="history-list__title">{ticketData.memberName}</p>
    </Link>
    <span className="history-list__field">
      <span className="history-list__field__mobile-title u-opacity--40">
        Tickets bought
      </span>
      <p className="t-zeta u-opacity--80">{ticketData.ticketCount || 0}</p>
    </span>
    <span className="history-list__field">
      <span className="history-list__field__mobile-title u-opacity--40">
        Paid
      </span>
      <p className="t-zeta u-opacity--80">${ticketData.revenue.toFixed(2)}</p>
    </span>
  </div>
);
