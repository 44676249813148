import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationItem } from '.';
import {
  TmakNotification,
  NotificationType,
} from 'modules/notifications/models';
import { Loading } from 'components';
import { useAuthentication } from 'modules/authentication';
import { useNotifications } from '../hooks';
import { useClickOutsideElement } from 'hooks';

interface Props {
  close: VoidFunction;
}

export const NotificationList: ReactFC<Props> = ({ close }) => {
  const ref = useClickOutsideElement<HTMLDivElement>(close);
  const { user } = useAuthentication();
  const {
    notifications,
    seen,
    loading,
    markNotificationSeen,
    markAllNotificationsSeen,
  } = useNotifications();

  const handleMarkSeen = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const id = event.currentTarget.dataset.notificationId;
    const notification = notifications.find(n => n.id === id);
    if (notification) {
      markNotificationSeen(notification);
      close();
    }
  };

  const getNotificationRoute = (notification: TmakNotification) => {
    switch (notification.type) {
      case NotificationType.Audio:
        return '/audios/direct';
      case NotificationType.Video:
        return '/videos/direct';
      case NotificationType.Event:
        return '/events';
      default:
        return null;
    }
  };

  const renderEmptyState = () => (
    <p className="t-zeta t--faded notification__list__empty">
      <svg width="46" height="46">
        <g
          fill="none"
          fillRule="evenodd"
          opacity=".2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M28.8235 39.0147c0 3.2175-2.606 5.8235-5.8235 5.8235-3.2175 0-5.8235-2.606-5.8235-5.8235h11.647z"
            stroke="#31263A"
            fill="#31263A"
          />
          <path d="M1.1618 33.1912h43.6764" stroke="#004378" />
          <path
            d="M41.9265 33.1912h1.4559c-3.2175 0-7.2795-2.606-7.2795-5.8236V14.2647c0-7.2357-5.8672-13.103-13.1029-13.103S9.897 7.029 9.897 14.2648v13.103c0 3.2174-2.606 5.8235-5.8235 5.8235"
            stroke="#31263A"
            fill="#31263A"
          />
        </g>
      </svg>
      There are no new notifications
    </p>
  );

  const renderNotifications = () => {
    if (loading) {
      return <Loading withoutContainer />;
    }

    // If no notifications are available return empty state.
    if (!notifications || !notifications.length) {
      return renderEmptyState();
    }

    const unseenNotifications = notifications.filter(
      n => !seen.some(sn => sn.notificationId === n.id),
    );

    return unseenNotifications.length
      ? unseenNotifications.map(n => (
          <Link
            key={n.id}
            to={`${getNotificationRoute(n)}/${n.contentId}`}
            onClick={handleMarkSeen}
            data-notification-id={n.id}
            className="notification-item"
          >
            <NotificationItem
              notification={n}
              dismissCallback={handleMarkSeen}
            />
          </Link>
        ))
      : renderEmptyState();
  };

  const renderMarkAllSeen = () => {
    if (seen.length === notifications.length || !user) {
      return null;
    }

    return (
      <button
        className="button  button--small notification__clear"
        onClick={markAllNotificationsSeen}
      >
        Clear all
      </button>
    );
  };

  return (
    <section ref={ref} className="notification__list is-visible">
      <header className="notification__list__header">
        <h1 className="notification__list__title">Notifications</h1>
        {renderMarkAllSeen()}
      </header>
      {renderNotifications()}
    </section>
  );
};
