export interface AudioStatsConstructor extends AudioStats {}
export class AudioStatsConstructor {
  constructor(userId: string, trackId: string, title: string) {
    this.userId = userId;
    this.trackId = trackId;
    this.title = title;
    this.percentListened = 0;
    this.secondsListened = 0;
    this.lastPlayed = +new Date();
  }
}
