import React from 'react';

interface Props {
  position: number;
  duration: number;
  onSlide: (position: number) => void;
}

export class SeekBar extends React.Component<Props> {
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onSlide } = this.props;
    const value = Number(e.currentTarget.value);

    onSlide(value);
  };

  getFormattedMinutes = (time: number) => {
    const minutesRough = time / 60;
    const minutes = Math.floor(minutesRough);
    const leftover = minutesRough - minutes;
    const seconds = Math.floor(leftover * 60);

    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };

  render() {
    const { position, duration } = this.props;
    const percentage = duration > 0 ? (position / duration) * 100 + '%' : '0%';

    return (
      <React.Fragment>
        <div className="seekbar">
          {duration > 0 && (
            <React.Fragment>
              <input
                type="range"
                min={0}
                max={duration}
                step={1}
                className="seekbar__slider"
                value={position.toString()}
                onChange={this.handleChange}
              />
              <div className="seekbar__background" />
              <div
                className="seekbar__progress"
                style={{ width: percentage }}
              />
            </React.Fragment>
          )}
          {duration > 0 && (
            <div className="f f--center seekbar__duration">
              <p className="seekbar__time u-opacity--60">
                {this.getFormattedMinutes(position)}
              </p>
              <p className="seekbar__remaining u-opacity--40">
                {this.getFormattedMinutes(duration)}
              </p>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
