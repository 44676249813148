import { useForm } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { Input, MainModal, SelectedOption, TogglePin } from 'components';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import Select from 'react-select';

import IconClose from 'assets/icons/close.svg';
import IconDropzoneImage from 'assets/icons/dropzone-attachment-image.svg';
import { useVideoForm, useVideos } from '../hooks';
import { VideoInfoForm } from '../models';
import ReactQuill from 'react-quill';

const MAX_THUMBNAIL_SIZE = 500000;

export const VideoForm: React.FC = () => {
  const { categories, updateVideo, addVideo } = useVideos();
  const { close, video, categoryId } = useVideoForm();
  const [loading, setLoading] = useState(false);
  const initialState = useMemo(() => new VideoInfoForm(video), [video]);

  /** NEED TO FIX USE FORM TO VALIDATE NULL VALUES */

  const form = useForm<VideoInfoForm>(initialState, {
    rules: {
      title: value => !!value && value.length <= 100,
      author: value => !!value && value.length <= 100,
      shortDescription: value => !!value && value.length <= 100,
      videoSource: value => value?.includes('https://vimeo.com/'),
      thumbnailFile: value => !value || value.size <= MAX_THUMBNAIL_SIZE,
    },
    optional: ['thumbnailPath', 'thumbnailUrl'],
  });

  const selectedCategories = useMemo(
    () =>
      categories
        .filter(c => form.state.categoryIds.includes(c.id))
        .map(category => ({ label: category.title, value: category.id })),
    [form.state.categoryIds],
  );

  const categoryOptions: SelectedOption[] = useMemo(
    () =>
      categories.map(category => ({
        label: category.title,
        value: category.id,
      })),
    [categories],
  );

  useEffect(() => {
    if (!categoryId || form.state.categoryIds.includes(categoryId)) return;
    form.update('categoryIds', [...form.state.categoryIds, categoryId]);
  }, []);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      if (!form.validateForm())
        throw new Error(
          'Some of your inputs are not valid. Check your entries.',
        );

      setLoading(true);
      toast.info('Saving video, please wait', { toastId: 'video-form' });
      if (video) {
        await updateVideo(video.id, form.state);
      } else {
        await addVideo(form.state);
      }

      close();
      toast.dismiss('video-form');
      toast.success('Audio successfully saved.');
    } catch (error) {
      console.error(error);
      toast.dismiss('video-form');
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Try again.',
        { toastId: 'video-form' },
      );
    } finally {
      setLoading(false);
    }
  }

  function handleCategoryUpdate(data: SelectedOption[] | null) {
    form.update(
      'categoryIds',
      (data || []).map(data => data.value),
    );
  }

  function handleThumbnailFile(files: File[]) {
    const [file] = files;
    form.update('thumbnailFile', file, true);
  }

  function handleRemoveThumbnail() {
    if (form.state.thumbnailFile) {
      form.update('thumbnailFile', null);
    } else {
      form.update('thumbnailPath', null);
      form.update('thumbnailUrl', null);
    }
  }

  return (
    <MainModal
      dismissOutside={false}
      title={video ? 'Edit video' : 'New video'}
      close={close}
    >
      <form onSubmit={handleSubmit}>
        <div className="modal__content">
          <div className="field">
            <TogglePin
              checked={form.state.status === 'published'}
              label="Published"
              toggle={() =>
                form.update(
                  'status',
                  form.state.status === 'published' ? 'draft' : 'published',
                )
              }
            />
            <p className="t-faded t-small">
              If the video is not published, it will not appear to non-admin
              users.
            </p>
          </div>

          <div className="field">
            <Input
              label="Vimeo source"
              onChange={value => form.update('videoSource', value)}
              value={form.state.videoSource}
            />

            {form.validation.videoSource === false && (
              <p className="t-warning t-small">
                A Vimeo video source must be provided and fit the
                "https://vimeo.com/[video-id-number]" format.
              </p>
            )}
          </div>

          <div className="field">
            <Input
              label="Title"
              maxLength={100}
              onChange={value => form.update('title', value)}
              value={form.state.title}
            />

            {form.validation.title === false && (
              <p className="t-warning t-small">
                A title under 100 characters must be added.
              </p>
            )}
          </div>

          <div className="field">
            <p className="t-small t-faded field__lbl">Categories</p>
            <Select
              placeholder="Select categories"
              classNamePrefix="react-select"
              options={categoryOptions}
              value={selectedCategories}
              onChange={value =>
                handleCategoryUpdate(value as SelectedOption[])
              }
              isMulti
              isSearchable
            />
            {!form.state.categoryIds.length && (
              <p className="t-small t-faded s-top--tny">
                Not selecting a category means the video will only be found
                under "All" once published.
              </p>
            )}
          </div>

          <div className="field">
            <Input
              label="Author"
              maxLength={100}
              onChange={value => form.update('author', value)}
              value={form.state.author}
            />
            {form.validation.author === false && (
              <p className="t-warning t-small">
                An author name must be provided.
              </p>
            )}
          </div>

          <div className="field">
            <p className="t-small t-faded field__lbl">Full description</p>
            <ReactQuill
              theme="snow"
              value={form.state.description}
              onChange={value => form.update('description', value)}
            />

            {form.validation.description === false && (
              <p className="t-warning t-small">
                A description must be provided.
              </p>
            )}
          </div>
          <div className="field">
            <p className="t-small t-faded field__lbl">
              Short description (80 characters)
            </p>
            <textarea
              className="input t-base input--textarea input--textarea--sml"
              maxLength={100}
              value={form.state.shortDescription}
              onChange={({ currentTarget }) =>
                form.update('shortDescription', currentTarget.value)
              }
            />

            {form.validation.shortDescription === false && (
              <p className="t-warning t-small">
                A short description up to 80 characters in length must be
                provided.
              </p>
            )}
          </div>

          <p className="event-form__section">Thumbnail (optional)</p>
          <p className="t-small t-faded field__lbl">
            Upload an image that shows what the video is about.
          </p>

          {form.state.thumbnailUrl || form.state.thumbnailFile ? (
            <div className="dropzone__container">
              <button
                type="button"
                onClick={handleRemoveThumbnail}
                className="button button--ghost button--ghost-primary dropzone__remove-preview"
              >
                <img src={IconClose} alt="&times;" />
              </button>
              <img
                className="dropzone__area-image"
                src={
                  form.state.thumbnailFile
                    ? URL.createObjectURL(form.state.thumbnailFile)
                    : form.state.thumbnailUrl || undefined
                }
                alt=""
              />

              {form.validation.thumbnailFile === false && (
                <p className="t-small t-warning">
                  File must be an image under {MAX_THUMBNAIL_SIZE / 1000000}{' '}
                  megabytes in size.
                </p>
              )}
            </div>
          ) : (
            <div className="dropzone__container">
              <div className="dropzone__label">
                <div className="dropzone__label">
                  <img src={IconDropzoneImage} alt="" />
                  <p className="t-small t-faded s-top--sml">
                    Drop an image here to upload
                  </p>
                  <p className="t-secondary t-small">
                    or select a file manually
                  </p>
                </div>
              </div>

              <Dropzone
                onDropAccepted={handleThumbnailFile}
                onDropRejected={() =>
                  toast.error(
                    `Unsupported or invalid file type. File must be an image type under ${MAX_THUMBNAIL_SIZE /
                      1000000}MB.`,
                  )
                }
                multiple={false}
                className="dropzone__area dropzone__area-image"
                disablePreview={false}
                accept="image/png, image/jpeg"
                maxSize={MAX_THUMBNAIL_SIZE}
              />
            </div>
          )}
        </div>

        <div className="modal__footer">
          <button
            disabled={loading}
            className="button button--primary button--medium"
          >
            Save
          </button>
          <button
            type="button"
            disabled={loading}
            className="button button--outline button--outline-primary button--medium"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </form>
    </MainModal>
  );
};
