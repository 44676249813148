import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from 'assets/images/logo.svg';

export class AuthenticationHeader extends React.PureComponent {
  render() {
    return (
      <header className="auth__header">
        <div className="auth__wrapper auth__wrapper--header">
          <img
            className="auth__logo s-bottom--med"
            src={Logo}
            alt="Team Mak Logo"
          />
          <h1 className="auth__header__title">
            Welcome to <span className="t-extrabold">Team</span>Mak
          </h1>

          <div className="s-top--lrg f f--start">
            <NavLink className="auth__header__link t-bold" to="/login">
              Login
            </NavLink>
            <NavLink className="auth__header__link t-bold" to="/register">
              Register
            </NavLink>
          </div>
        </div>
      </header>
    );
  }
}
