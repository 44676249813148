import React, { useState } from 'react';
import {
  AdministrationOptions,
  ConfirmModal,
  DeleteModal,
  FavoriteButton,
  MainModal,
  PageHeader,
  PlaylistSelector,
} from 'components';

import moment from 'moment';
import { useAudioPlayer } from 'modules/audio-player';
import IconPlay from 'assets/icons/audio-button-play.svg';
import IconPlaylist from 'assets/icons/playlist-add.svg';
import IconInfo from 'assets/icons/notice-info.svg';
import { usePlaylist } from 'modules/playlists';
import { useAuthentication } from 'modules/authentication';
import { ShareButton } from 'modules/share';
import { Role, RoleCheck } from 'modules/authorization';
import { useAudioForm, useAudios } from '../hooks';
import { useNavigate } from 'react-router';

interface OwnProps {
  audio: AudioInfo;
  hideControls?: boolean;
  shareable?: boolean;
}

type Props = OwnProps;

export const AudioDetailsCore: ReactFC<Props> = ({
  audio,
  hideControls,
  shareable,
}) => {
  const player = useAudioPlayer();
  const { publishAudio, removeAudio } = useAudios();
  const { user } = useAuthentication();
  const { edit } = useAudioForm();
  const { isFavorite, toggleFavorite } = usePlaylist('audio');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const loaded = player.audio?.id === audio.id;
  const navigate = useNavigate();

  function handlePublish() {
    publishAudio(audio);
    setShowPublishModal(false);
  }

  return (
    <>
      <RoleCheck role={Role.Administrator}>
        {showDeleteModal && (
          <DeleteModal
            title={`Delete ${audio.title}?`}
            description="Are you sure you want to delete this audio? This action cannot be undone."
            close={() => setShowDeleteModal(false)}
            confirm={() => {
              removeAudio(audio.id);
              setShowDeleteModal(false);
              navigate(-1);
            }}
          />
        )}

        {showPublishModal && (
          <ConfirmModal
            confirm={handlePublish}
            close={() => setShowPublishModal(false)}
            title={`Publish ${audio.title}?`}
            description="Publishing this audio makes it available for all users. Do you want to proceed?"
          />
        )}
      </RoleCheck>

      {showPlaylistModal && (
        <MainModal
          title="Add to playlist"
          close={() => setShowPlaylistModal(false)}
        >
          <PlaylistSelector
            close={() => setShowPlaylistModal(false)}
            trackId={audio.id}
            type="audio"
          />
        </MainModal>
      )}

      <PageHeader back={true} mobileOnly />

      <div className="audio-details">
        <div className="wrapper__narrow">
          <section className="audio-details__header">
            <img
              alt=""
              loading="eager"
              src={
                audio.thumbnailUrl ||
                require('assets/images/placeholder-square.png')
              }
              className="audio-details__thumbnail"
            />

            <div className="audio-details__metadata">
              <div className="f f--gap f--justify-between">
                <div className="f--one">
                  <h1 className="t-bold t-xlarge">{audio.title}</h1>
                  <p className="t-sans--alt t-small t-faded">
                    {audio.author}{' '}
                    <span className="s-left--sml s-right--sml">&bull;</span>{' '}
                    {moment(audio.timeCreated).format('ll')}
                  </p>
                </div>
                <AdministrationOptions
                  edit={() => edit(audio)}
                  remove={() => setShowDeleteModal(true)}
                  publish={
                    audio.status === 'draft'
                      ? () => setShowPublishModal(true)
                      : undefined
                  }
                />
              </div>

              {audio.status === 'draft' && (
                <RoleCheck role={Role.Administrator}>
                  <div className="notice notice--info s-top--med">
                    <img src={IconInfo} alt="i" />
                    <div className="f f--gap">
                      <p className="t-small">
                        Content is in draft mode. Other users will not be able
                        to see it until it is published.
                      </p>

                      <button
                        type="button"
                        onClick={() => setShowPublishModal(true)}
                        className="button button--tiny button--outline button--outline-info s-left--med"
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                </RoleCheck>
              )}

              <div className="audio-details__controls">
                <div className="f f--align-center">
                  <button
                    className="button button--primary button--medium audio-details__play"
                    onClick={() => player.loadTracks([audio])}
                    disabled={!navigator.onLine || loaded}
                  >
                    <img alt="play" src={IconPlay} />
                  </button>

                  {!hideControls && (
                    <>
                      <FavoriteButton
                        isFavorite={isFavorite(audio.id)}
                        toggleCallback={() => toggleFavorite(audio.id)}
                        showText
                      />

                      <button
                        className="button button--medium button--ghost button--ghost-primary"
                        onClick={() => setShowPlaylistModal(true)}
                      >
                        <img src={IconPlaylist} alt="" />
                        Add to playlist
                      </button>

                      {user && shareable && (
                        <ShareButton
                          audio={audio}
                          sharedById={user.id}
                          title={audio.title}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="audio-details__content">
            <div>
              {audio.description && (
                <>
                  <p className="t-bold t-medium">Description</p>
                  <div
                    className="wysiwyg-content s-top--med"
                    dangerouslySetInnerHTML={{ __html: audio.description }}
                  />
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
