import { useClickOutsideElement } from 'hooks';
import { PropsWithChildren, useState } from 'react';

interface Props {
  control: JSX.Element;
}

export const DropdownMenu: React.FC<PropsWithChildren<Props>> = ({
  children,
  control,
}) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useClickOutsideElement<HTMLDivElement>(
    () => setExpanded(false),
    expanded,
  );

  return (
    <div ref={ref} className="dropdown-menu">
      <button
        type="button"
        className="dropdown-menu__control"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setExpanded(!expanded);
        }}
      >
        {control}
      </button>

      {expanded && (
        <div
          className="dropdown-menu__content"
          onClick={() => setExpanded(false)}
        >
          {children}
        </div>
      )}
    </div>
  );
};
