import { useRecoilState } from 'recoil';
import { AudioAtoms } from '../recoil';

export function useAudioForm() {
  const [isOpen, setIsOpen] = useRecoilState(AudioAtoms.audioFormOpen);
  const [audio, setAudio] = useRecoilState(AudioAtoms.editingAudio);
  const [categoryId, setCategoryId] = useRecoilState(
    AudioAtoms.audioFormCategory,
  );

  function open(categoryId?: string) {
    setIsOpen(true);
    setAudio(undefined);
    setCategoryId(categoryId);
  }

  function edit(audio: AudioInfo) {
    setIsOpen(true);
    setAudio(audio);
  }

  function close() {
    setIsOpen(false);
    setAudio(undefined);
    setCategoryId(undefined);
  }

  return {
    audio,
    isOpen,
    categoryId,
    edit,
    open,
    close,
  };
}
