import React from 'react';
import moment from 'moment';

import { ShareButton } from '.';

import { SharedAudio } from '../models';

interface Props {
  item: SharedAudio;
}

export const ShareListItem: ReactFC<Props> = ({ item }) => {
  return (
    <div className="history-list__item__shared t-small">
      <div className="history-list__field__shared u-opacity--80 t-bold">
        {item.audio.author} - {item.audio.title}{' '}
        <p className="t-iota u-opacity--80">Times opened: {item.timesOpened}</p>
      </div>
      <div className="history-list__field__shared u-opacity--80 t-center">
        <ShareButton
          title={item.audio.title}
          audio={item.audio}
          sharedById={item.sharedBy!}
        />
      </div>
      <p className="history-list__field__shared u-opacity--60">
        {moment(item.date).format('ll')}
      </p>
    </div>
  );
};
