import { useRecoilState } from 'recoil';
import { EventAtoms } from '../recoil';

export function useEventForm() {
  const [isOpen, setIsOpen] = useRecoilState(EventAtoms.eventFormOpen);
  const [editingEvent, setEditingEvent] = useRecoilState(
    EventAtoms.editingEvent,
  );

  function open() {
    setIsOpen(true);
    setEditingEvent(undefined);
  }

  function edit(event: EventInfo) {
    setIsOpen(true);
    setEditingEvent(event);
  }

  function close() {
    setIsOpen(false);
    setEditingEvent(undefined);
  }

  return {
    isOpen,
    editingEvent,
    open,
    edit,
    close,
  };
}
