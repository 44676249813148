import { where } from 'firebase/firestore';
import { Collection, FirestoreService } from 'modules/firebase';
import { useTickets } from './useTickets';

const TicketsCollection = new FirestoreService<Ticket>(Collection.Tickets);

export function useTicketPurchase(event?: EventInfo) {
  const { purchased } = useTickets();

  async function checkIfTicketExists(normalisedEmail: string) {
    if (!event) return false;
    /** Check locally first */
    const exists = purchased
      .filter(t => t.eventId === event.id)
      .some(t => t.normalisedHolderEmail === normalisedEmail);

    if (exists) return true;

    const result = await TicketsCollection.get([
      where('normalisedHolderEmail', '==', normalisedEmail),
      where('eventId', '==', event.id),
    ]);

    return !!result.length;
  }

  return {
    checkIfTicketExists,
  };
}
