import * as views from 'views';
import { AppRoute } from '../models';
import { ACLType } from 'modules/authorization';

const errorRoutes: AppRoute[] = [
  {
    key: 'unauthorized',
    access: ACLType.Public,
    Element: views.PermissionError,
    title: 'Unauthorized',
    path: '/not-authorized',
  },
  {
    key: 'general-error',
    access: ACLType.Public,
    Element: views.GeneralError,
    title: '404',
    path: '*',
  },
];

export default errorRoutes;
