import React from 'react';
import { Loading, PagedContent, Search } from 'components';
import { DashboardMemberItem } from './DashboardMemberItem';

interface Props {
  team: Team;
  users: CustomUserInfo[];
  diamondLeader?: CustomUserInfo;
  usersChanging: boolean;
  updateLeaderCallback: (leader?: CustomUserInfo) => void;
  cancelCallback: () => void;
}

interface State {
  selectedLeader: CustomUserInfo | undefined;
  hasChanged: boolean;
  query: string;
}

export class ManageTeamLeader extends React.PureComponent<Props, State> {
  state = {
    selectedLeader:
      this.props.users.find(m => m.id === this.props.team.teamLeaderId) ||
      this.props.diamondLeader,
    hasChanged: false,
    query: '',
  };

  componentDidUpdate(prevProps: Props) {
    const { users, team, diamondLeader } = this.props;
    if (!prevProps.users.length && users.length) {
      let user = users.find(u => u.id === team.teamLeaderId);
      if (!user) {
        user = diamondLeader;
      }

      this.setState({
        selectedLeader: user,
      });
    }
  }

  resetState = () =>
    this.setState(
      {
        selectedLeader:
          this.props.users.find(u => u.id === this.props.team.teamLeaderId) ||
          this.props.diamondLeader,
        hasChanged: false,
      },
      this.props.cancelCallback,
    );

  submitChanges = () => {
    const { selectedLeader } = this.state;
    const { updateLeaderCallback } = this.props;

    updateLeaderCallback(selectedLeader);

    this.setState({
      hasChanged: false,
    });
  };

  handleSelectedLeader = (member: CustomUserInfo) => {
    this.setState({
      selectedLeader:
        member.id !== this.state.selectedLeader?.id ? member : undefined,
      hasChanged: true,
    });
  };

  renderUsers = () => {
    const { usersChanging, diamondLeader } = this.props;
    const filteredUsers = this.filterUsers();

    if (usersChanging) {
      return <Loading />;
    }

    if (!filteredUsers.length) {
      return this.renderEmptyState();
    }

    return (
      <PagedContent
        data={
          diamondLeader ? [...filteredUsers, diamondLeader] : [...filteredUsers]
        }
        renderItem={this.renderMemberItem}
        pageSize={15}
      />
    );
  };

  renderMemberItem = (member: CustomUserInfo) => {
    const { selectedLeader } = this.state;
    const isChecked = selectedLeader ? selectedLeader.id === member.id : false;

    return (
      <DashboardMemberItem
        key={member.id}
        member={member}
        checked={isChecked}
        onSelect={() => this.handleSelectedLeader(member)}
      />
    );
  };

  filterUsers = () => {
    const { query } = this.state;
    const { users } = this.props;
    let filteredUsers = users;
    if (query.length) {
      filteredUsers = filteredUsers.filter(
        u =>
          u.displayName &&
          u.displayName.toUpperCase().includes(query.toUpperCase()),
      );
    }
    return filteredUsers;
  };

  renderEmptyState = () => (
    <p>
      This team has no members to select from. Add members first by clicking
      "Manage team members".
    </p>
  );

  renderActionButtons = () => {
    const { hasChanged } = this.state;
    return (
      <div className="modal__footer">
        <div className="f f--gap">
          <button
            onClick={this.submitChanges}
            disabled={!hasChanged}
            className="button button--primary button--medium "
          >
            Confirm
          </button>
          <button
            onClick={this.resetState}
            className="button button--medium button--ghost button--ghost-primary"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };
  setQuery = (query: string) => {
    this.setState({ query });
  };

  render() {
    const { query } = this.state;
    return (
      <React.Fragment>
        <Search query={query} changeCallback={this.setQuery} />
        <div className="modal__content">{this.renderUsers()}</div>
        {this.renderActionButtons()}
      </React.Fragment>
    );
  }
}
