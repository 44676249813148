import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  AdministrationOptions,
  ConfirmModal,
  DeleteModal,
  FavoriteButton,
} from 'components';
import { usePlaylist } from 'modules/playlists';
import { useVideoForm, useVideos } from '../hooks';
import { Role, RoleCheck } from 'modules/authorization';

interface Props {
  video: VideoInfo;
  parentUrl: string;
}

export const VideoItem: ReactFC<Props> = ({ video, parentUrl }) => {
  const { publishVideo, removeVideo } = useVideos();
  const { edit } = useVideoForm();
  const { isFavorite, toggleFavorite } = usePlaylist('video');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);

  const renderCreatedDate = () => {
    if (!video.timeCreated) return null;
    return moment(video.timeCreated).format('ll');
  };

  function handlePublish() {
    publishVideo(video);
    setShowPublishModal(false);
  }

  return (
    <>
      <Link to={`${parentUrl}/${video.id}`} className="video-list-item">
        <div className="video-list-item__favorite">
          <FavoriteButton
            isFavorite={isFavorite(video.id)}
            toggleCallback={() => toggleFavorite(video.id)}
          />
        </div>

        <img
          loading="lazy"
          src={
            video.thumbnailUrl || require('assets/images/placeholder-16-9.png')
          }
          className="video-list-item__thumbnail s-bottom--tny"
          alt=""
        />

        <div className="s-top--med">
          <div className="f f--gap f--justify-between">
            <div className="f--one">
              <p className="t-faded t-tiny t-sans">{video.author}</p>
              <h1 className="title title--card">{video.title}</h1>
            </div>

            <AdministrationOptions
              edit={() => edit(video)}
              remove={() => setShowDeleteModal(true)}
              publish={
                video.status === 'draft'
                  ? () => setShowPublishModal(true)
                  : undefined
              }
            />
          </div>

          {video.status === 'draft' && (
            <p className="pill pill--info pill--sml s-top--sml">draft</p>
          )}

          <p className="s-top--sml t-small t-sans">
            {video.shortDescription.length > 100 ? (
              <>{video.shortDescription.substring(0, 197)}&#8230;</>
            ) : (
              video.shortDescription
            )}
          </p>
          <div className="f f--center">
            <p className="t-faded t-label t-label--sml s-top--sml">
              {renderCreatedDate()}
            </p>
          </div>
        </div>
      </Link>

      <RoleCheck role={Role.Administrator}>
        {showDeleteModal && video && (
          <DeleteModal
            title={`Delete ${video.title}?`}
            description="Are you sure you want to delete this video? This action cannot be undone."
            close={() => setShowDeleteModal(false)}
            confirm={() => {
              removeVideo(video.id);
              setShowDeleteModal(false);
            }}
          />
        )}

        {showPublishModal && (
          <ConfirmModal
            confirm={handlePublish}
            close={() => setShowPublishModal(false)}
            title={`Publish ${video.title}?`}
            description="Publishing this video makes it available for all users. Do you want to proceed?"
          />
        )}
      </RoleCheck>
    </>
  );
};
