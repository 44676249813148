import { atom } from 'recoil';

const loading = atom<boolean>({
  key: 'users-profile-loading',
  default: false,
});

const profile = atom<CustomUserInfo | undefined>({
  key: 'users-profile',
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const UserAtoms = {
  loading,
  profile,
};
