import { Link } from 'react-router-dom';
import React from 'react';

type Props = {
  title: string;
  description: string;
  route: string;
};

export const ReportCategory: ReactFC<Props> = ({
  title,
  description,
  route,
}) => {
  return (
    <div className="card report-category__container">
      <Link to={route} className="report-category__link" />
      <p className="t-epsilon t-primary">{title}</p>
      <p className="s-top--sml t-faded t-small">{description}</p>
      <p className="report-category__pointer">&rarr;</p>
    </div>
  );
};
