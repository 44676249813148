import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AuthAtoms } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';
import { useEffect } from 'react';

import { AudioStatsAtoms } from '../recoil';
import { orderBy, where } from 'firebase/firestore';

const AudioStatsCollection = new FirestoreService<AudioStats>(
  Collection.AudioStats,
);

export function useAudioStatsListener() {
  const user = useRecoilValue(AuthAtoms.user);
  const setLoading = useSetRecoilState(AudioStatsAtoms.loading);
  const setStats = useSetRecoilState(AudioStatsAtoms.stats);

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    const listener = AudioStatsCollection.listen({
      filters: [where('userId', '==', user.id), orderBy('lastPlayed', 'desc')],
      onSuccess: setStats,
      onError: error => console.log('Error loading audio stats: ', error),
      onFinished: () => setLoading(false),
    });

    return listener;
  }, [user]);
}
