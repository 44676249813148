import React from 'react';

import { getPageCount } from 'modules/helpers';
import { PAGE_SIZE } from 'consts';

import { Pagination } from './Pagination';

interface Props<T> {
  data: T[];
  renderItem: (item: T) => JSX.Element | JSX.Element[];
  pageSize?: number;
}

interface State {
  page: number;
}

export class PagedContent<T> extends React.PureComponent<Props<T>, State> {
  state = {
    page: 1,
  };

  scrollToTop = () =>
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });

  goToFirst = () => {
    this.setState({ page: 1 });
    this.scrollToTop();
  };
  goToLast = () => {
    this.setState({
      page: getPageCount(this.props.data.length, this.props.pageSize),
    });
    this.scrollToTop();
  };
  goToNextPage = () => {
    this.setState({ page: this.state.page + 1 });
    this.scrollToTop();
  };
  goToPreviousPage = () => {
    this.setState({ page: this.state.page - 1 });
    this.scrollToTop();
  };
  goToPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ page: Number(event.currentTarget.dataset.pageNumber) });
    this.scrollToTop();
  };

  renderData = () => {
    const { data, renderItem, pageSize } = this.props;
    const { page } = this.state;

    // Set item count from props or global default
    const size = pageSize || PAGE_SIZE;

    const start = (page - 1) * size;
    const end = page * size;

    return data.slice(start, end).map(item => renderItem(item));
  };

  renderPagination = () => {
    const { data, pageSize } = this.props;
    const { page } = this.state;

    const pageCount = getPageCount(data.length, pageSize);

    if (pageCount <= 1) {
      return;
    }

    return (
      <Pagination
        pageNumber={page}
        totalPages={pageCount}
        onGoToFirstPage={this.goToFirst}
        onGoToPage={this.goToPage}
        onGoToLastPage={this.goToLast}
        onGoToNextPage={this.goToNextPage}
        onGoToPreviousPage={this.goToPreviousPage}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderData()}
        {this.renderPagination()}
      </React.Fragment>
    );
  }
}
