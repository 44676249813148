import { useClickOutsideElement } from 'hooks';
import { useAuthentication } from 'modules/authentication';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  onLogout: VoidFunction;
}

export const ProfileMenu: ReactFC<Props> = ({ onLogout }) => {
  const { user } = useAuthentication();
  const [expanded, setExpanded] = useState(false);
  const ref = useClickOutsideElement<HTMLDivElement>(
    () => setExpanded(false),
    expanded,
  );

  function handleToggle(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setExpanded(!expanded);
  }

  function handleNavlinkEvent(
    e:
      | React.MouseEvent<HTMLAnchorElement>
      | React.FocusEvent<HTMLAnchorElement>,
  ) {
    e.stopPropagation();
    setExpanded(false);
  }

  function handleLogout(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onLogout();
  }

  return (
    <div
      ref={ref}
      className={`${
        expanded ? 'profile-menu profile-menu--open' : 'profile-menu'
      }`}
    >
      {user && (
        <button
          className="button  t-extrabold profile-menu__name"
          onClick={handleToggle}
        >
          {user && user.photoURL && navigator.onLine && (
            <img
              className="profile-menu__image"
              src={user.photoURL}
              alt="profile"
            />
          )}
          {user && user.displayName
            ? user.displayName
            : 'No display name added'}

          <svg className="profile-menu__arrow" width="10" height="5">
            <path
              d="M.5 5L5 .5 9.5 5"
              stroke="#004378"
              fill="none"
              fillRule="evenodd"
            />
          </svg>
        </button>
      )}
      <ul className="profile-menu--other">
        <li>
          <Link
            onBlur={handleNavlinkEvent}
            onClick={handleNavlinkEvent}
            to="/dashboard"
            className="button f--start nav__link profile-menu__link"
          >
            <svg width="16" height="16">
              <path
                d="M.5.5h6v8h-6zm0 11h6v4h-6zm9-11h6v5h-6zm0 8h6v7h-6z"
                stroke="#847D89"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Overview
          </Link>
        </li>
        <li>
          <Link
            onBlur={handleNavlinkEvent}
            onClick={handleNavlinkEvent}
            to="/profile"
            className="button f--start nav__link profile-menu__link"
          >
            <svg width="14" height="16">
              <g
                stroke="#847D89"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M8.5 10.5h-3c-2.7614 0-5 2.2386-5 5h13a5 5 0 0 0-5-5z" />
                <circle cx="7" cy="4" r="3.5" />
              </g>
            </svg>
            Profile
          </Link>
        </li>
        <li>
          <button
            className="button f--start nav__link profile-menu__link"
            onClick={handleLogout}
          >
            <svg width="15" height="16">
              <path
                d="M11.83 3c1.626 1.282 2.67 3.269 2.67 5.5 0 3.866-3.134 7-7 7s-7-3.134-7-7C.5 6.269 1.544 4.282 3.17 3M7.5.5v7"
                stroke="#847D89"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Log out
          </button>
        </li>
      </ul>
    </div>
  );
};
