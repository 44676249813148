import { orderBy, where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';
import moment from 'moment';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { EventAtoms } from '../recoil';
import { Role } from 'modules/authorization';
import { useSubscription } from 'modules/subscription';

const EventsCollection = new FirestoreService<EventInfo>(Collection.Events);

export function useEventsListener() {
  const { user } = useAuthentication();
  const { subscribed } = useSubscription();
  const setLoading = useSetRecoilState(EventAtoms.loading);
  const setEvents = useSetRecoilState(EventAtoms.events);

  useEffect(() => {
    if (!user || (user.role !== Role.Administrator && !subscribed)) return;

    setLoading(true);
    /** Set end date 1 day in the past so there is enough buffer to show tickets. */
    const endTimestamp = +moment()
      .add({ days: -1 })
      .toDate();

    const listener = EventsCollection.listen({
      onSuccess: setEvents,
      onError: error => console.log('Events listener error: ', error),
      onFinished: () => setLoading(false),
      filters:
        user?.role !== Role.Administrator
          ? [
              where('status', '==', 'published'),
              where('endTimestamp', '>=', endTimestamp),
              orderBy('endTimestamp', 'desc'),
            ]
          : [
              where('endTimestamp', '>=', endTimestamp),
              orderBy('endTimestamp', 'desc'),
            ],
    });

    return listener;
  }, [user, subscribed]);
}
