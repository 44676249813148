import React from 'react';

export const PageLoading: ReactFC = () => (
  <div className="container container--empty">
    <div className="loading loading--page">
      <img
        src={require('../assets/loaders/teammak-loader.gif')}
        alt="Diamond loader"
        className="loading__loader"
      />
      <p className="s-top--sml">
        <span className="t-bold">Team</span>Mak
      </p>
    </div>
  </div>
);
