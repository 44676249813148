import React, { useEffect, useState } from 'react';
import { EventStatsCore, useEvents, useEventStats } from 'modules/events';
import { useParams } from 'react-router';
import { useTeams } from 'modules/teams';
import { GeneralError } from './GeneralError';

/** Page showing all user teams */
export const EventStatsOverview: ReactFC = () => {
  const { id = '' } = useParams();
  const { allEvents } = useEvents();
  const { getEventTicketsByTeamIds } = useEventStats();
  const { loading: loadingTeams } = useTeams();
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [tickets, setTickets] = useState<Ticket[]>([]);

  /** Fetch tickets for all teams the user has access to. */
  useEffect(() => {
    setLoadingTickets(true);
    getEventTicketsByTeamIds(id)
      .then(setTickets)
      .finally(() => setLoadingTickets(false));
  }, []);

  const event = allEvents.find(e => e.id === id);
  if (!event) return <GeneralError />;

  return (
    <EventStatsCore
      event={event}
      currentTeam={undefined}
      loading={loadingTeams || loadingTickets}
      tickets={tickets}
    />
  );
};
