import { DashboardHeader, PageHeader, PageTitle } from 'components';
import { DashboardEventInfo } from 'modules/dashboard';
import { EventTeamMembers } from './EventTeamMembers';

interface Props {
  event: EventInfo;
  tickets: Ticket[];
  currentTeam: Team | undefined;
  loading: boolean;
}

export const EventStatsCore: ReactFC<Props> = ({
  event,
  tickets,
  currentTeam,
  loading,
}) => {
  return (
    <>
      <DashboardHeader desktopOnly={true} />
      <PageHeader mobileOnly={true} back={true}>
        <PageTitle>{event.title}</PageTitle>
      </PageHeader>

      <div className="wrapper">
        <div className="dashboard-content">
          <div className="dashboard-content__sidebar">
            <div className="dashboard-content__inner dashboard-content__inner--sidebar">
              <DashboardEventInfo
                event={event}
                currentTeam={currentTeam}
                tickets={tickets}
                loading={loading}
              />
            </div>
          </div>
          <div className="dashboard-content__main">
            <div className="dashboard-content__inner dashboard-content__inner--main">
              <EventTeamMembers tickets={tickets} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
