import { PlayedStatusFilter, PublishedFilter, SortFilter } from 'models';
import { AudioStatsAtoms } from 'modules/audio-stats';
import { useAuthentication } from 'modules/authentication';
import { useRecoilValue } from 'recoil';

export function useMediaFilters<T extends 'audio' | 'video'>(type: T) {
  type Items = T extends 'audio' ? AudioInfo : VideoInfo;
  const { user } = useAuthentication();
  const audioStats = useRecoilValue(AudioStatsAtoms.stats);

  function sortItems(filter: SortFilter, items: Items[]) {
    switch (filter) {
      case 'newest':
        return items.sort((a, b) => (a.timeCreated < b.timeCreated ? 1 : -1));
      case 'oldest':
        return items.sort((a, b) => (a.timeCreated < b.timeCreated ? -1 : 1));
      case 'title':
        return items.sort((a, b) => (a.title < b.title ? -1 : 1));
    }
  }

  function filterByPlayedStatus(filter: PlayedStatusFilter, items: Items[]) {
    const stats = type === 'audio' ? audioStats : user?.videoStats;
    if (!stats) return items;

    switch (filter) {
      case 'not-played':
        return items.filter(i => !stats.some(s => s.trackId === i.id));
      case 'played':
        return items.filter(i => stats.some(s => s.trackId === i.id));
    }
  }

  function filterByPublishedStatus(filter: PublishedFilter, items: Items[]) {
    switch (filter) {
      case 'draft':
        return items.filter(i => i.status === 'draft');
      case 'published':
        return items.filter(i => i.status === 'published');
    }
  }

  function applyFilters(
    items: Items[],
    sort: SortFilter,
    played: PlayedStatusFilter | undefined,
    published: PublishedFilter | undefined,
  ) {
    let filtered = sortItems(sort, items);
    if (played) filtered = filterByPlayedStatus(played, filtered);
    if (published) filtered = filterByPublishedStatus(published, filtered);
    return filtered;
  }

  return {
    applyFilters,
    filterByPlayedStatus,
    filterByPublishedStatus,
    sortItems,
  };
}
