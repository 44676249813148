import { atom } from 'recoil';

const teams = atom<Team[]>({
  key: 'teams',
  default: [],
  dangerouslyAllowMutability: true,
});

const loading = atom<boolean>({
  key: 'teams-loading',
  default: false,
});

const userTeams = atom<Team[]>({
  key: 'teams-user',
  default: [],
  dangerouslyAllowMutability: true,
});

const unassignedUsers = atom<CustomUserInfo[]>({
  key: 'teams-unassigned-users',
  default: [],
  dangerouslyAllowMutability: true,
});

const loadingMembers = atom<boolean>({
  key: 'team-members-loading',
  default: false,
});

export const TeamAtoms = {
  teams,
  loading,
  userTeams,
  unassignedUsers,
  loadingMembers,
};
