export interface TeamConstructor extends Team {}

export class TeamConstructor {
  constructor(name: string, type: TeamType, parentTeamId: string | null) {
    this.name = name;
    this.teamType = type;
    this.memberCount = 0;
    this.parentTeamId = parentTeamId || null;
    this.teamLeaderId = null;
    this.teamLeaderName = null;
  }
}
