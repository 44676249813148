import React from 'react';
import { Link } from 'react-router-dom';
import { TeamTypeEnum } from '../models';

interface Props {
  team: Team;
  parentLeader: string | null;
  teamUrlOverride?: string;
}

export const TeamItem: ReactFC<Props> = ({
  team,
  parentLeader,
  teamUrlOverride,
}) => {
  return (
    <Link
      to={teamUrlOverride || `/team/${team.id}`}
      className="team-item card card--dashboard"
    >
      <div className="card__content">
        <h1 className="card__title t-extrabold">{team.name}</h1>
        <div className="card__info">
          <div className="card__info-two-thirds">
            <p className="card__label u-opacity--40">Team leader</p>
            <p className="t-zeta u-opacity--80">
              {team.teamLeaderName
                ? team.teamLeaderName
                : team.teamType === TeamTypeEnum.Platinum
                ? parentLeader
                  ? parentLeader
                  : 'No team leader set.'
                : 'No team leader set.'}
            </p>
          </div>
          <div className="card__info-third">
            <p className="card__label u-opacity--40">Members</p>
            <p className="t-zeta u-opacity--80">{team.memberCount}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};
