import React, { useState, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { DashboardMemberItem, EmptyState } from 'modules/dashboard';
import { DashboardHeader, ScrollingContent, Search } from 'components';
import { getSubscriptionStatus } from 'modules/subscription/helpers';
import { useTeamMembers } from 'modules/teams';

export const UnassignedUsersReports: ReactFC = () => {
  const { unassignedUsers } = useTeamMembers();
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState<
    'not-subscribed' | 'legacy' | 'subscribed' | undefined
  >();

  const fileName = `unassigned-users${filter ? `-${filter}` : ''}${query &&
    `-${query}`}-${new Date().toLocaleDateString()}.csv`;

  const filteredList = useMemo(() => {
    switch (filter) {
      case 'legacy':
        return unassignedUsers.filter(
          u => u.subscription?.isLegacySubscription,
        );
      case 'subscribed':
        return unassignedUsers.filter(
          u =>
            (u.subscription?.stripe?.subscriptionExpirationTime || 0) >
            Date.now(),
        );
      case 'not-subscribed':
        return unassignedUsers.filter(
          u =>
            !u.subscription?.isLegacySubscription &&
            (!u.subscription?.stripe?.subscriptionExpirationTime ||
              u.subscription.stripe.subscriptionExpirationTime < Date.now()),
        );
      default:
        return unassignedUsers;
    }
  }, [filter, unassignedUsers]);

  const finalList =
    query.length >= 2
      ? filteredList.filter(user => {
          const q = query.toUpperCase();
          return (
            user.email?.toUpperCase().includes(q) ||
            user.displayName?.toUpperCase().includes(q) ||
            user.normalisedFirstName?.includes(q) ||
            user.normalisedLastName?.includes(q)
          );
        })
      : filteredList;

  function generateCSVReportData() {
    const csv = [['TeamMAK ID', 'Full name', 'E-mail', 'Subscription']];

    finalList.forEach(user =>
      csv.push([
        user.id,
        user.displayName || '',
        user.email || '',
        getSubscriptionStatus(user.subscription),
      ]),
    );

    return csv;
  }

  return (
    <>
      <DashboardHeader />
      <div className="wrapper">
        <div className="dashboard-content">
          <div className="dashboard-content__sidebar dashboard-content__sidebar--narrow">
            <div className="dashboard-content__inner dashboard-content__inner--sidebar">
              <h3 className="t-alpha">{unassignedUsers.length}</h3>
              <h4 className="t-faded dashboard-sidebar__title">
                <Link to="/reports/">Reports &rarr;</Link> Unassigned users
              </h4>

              <p className="t-zeta t-faded t-upper s-top--lrg s-bottom--sml">
                Filters
              </p>
              <div>
                <input
                  name="filter-all"
                  type="radio"
                  checked={!filter}
                  onChange={() => setFilter(undefined)}
                />
                <label
                  htmlFor="filter-all"
                  className={`t-small t-upper s-left--med ${
                    !filter ? 't-primary' : 't-faded'
                  }`}
                >
                  All
                </label>
              </div>
              <div>
                <input
                  name="filter-not-subscribed"
                  type="radio"
                  checked={filter === 'not-subscribed'}
                  onChange={() => setFilter('not-subscribed')}
                />
                <label
                  htmlFor="filter-not-subscribed"
                  className={`t-small t-upper s-left--med ${
                    filter === 'not-subscribed' ? 't-primary' : 't-faded'
                  }`}
                >
                  Not subscribed
                </label>
              </div>
              <div>
                <input
                  name="filter-legacy"
                  type="radio"
                  checked={filter === 'legacy'}
                  onChange={() => setFilter('legacy')}
                />
                <label
                  htmlFor="filter-legacy"
                  className={`t-small t-upper s-left--med ${
                    filter === 'legacy' ? 't-primary' : 't-faded'
                  }`}
                >
                  Legacy
                </label>
              </div>
              <div>
                <input
                  name="filter-subscribed"
                  type="radio"
                  checked={filter === 'subscribed'}
                  onChange={() => setFilter('subscribed')}
                />
                <label
                  htmlFor="filter-subscribed"
                  className={`t-small t-upper s-left--med ${
                    filter === 'subscribed' ? 't-primary' : 't-faded'
                  }`}
                >
                  Subscribed
                </label>
              </div>

              <div className="s-top--lrg">
                <p className="t-zeta  t-upper s-bottom--med">Generate report</p>
                <CSVLink
                  data={generateCSVReportData()}
                  target="_blank"
                  separator=";"
                  filename={fileName}
                  className="button button--medium button--outline button--outline-primary"
                >
                  Download report
                </CSVLink>
                <p className="t-faded s-top--sml">
                  <small>
                    The report will include selected filters and search query.
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="dashboard-content__main dashboard-content__main--wide">
            <div className="dashboard-content__inner dashboard-content__inner--main">
              <Search
                query={query}
                changeCallback={setQuery}
                placeholder="Search for an unassigned user by name or e-mail"
              />

              <div className="s-top--lrg">
                <ScrollingContent
                  data={finalList}
                  emptyComponent={
                    <EmptyState
                      type="user"
                      message="There are no unassigned users."
                    />
                  }
                  renderItem={user => (
                    <DashboardMemberItem key={user.id} member={user} />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
