import { atom } from 'recoil';

const purchased = atom<Ticket[]>({
  key: 'tickets-purchased',
  default: [],
  dangerouslyAllowMutability: true,
});

const held = atom<Ticket[]>({
  key: 'tickets-held',
  default: [],
  dangerouslyAllowMutability: true,
});

const loadingHeld = atom<boolean>({
  key: 'tickets-loading-held',
  default: false,
});

const loadingPurchased = atom<boolean>({
  key: 'tickets-loading-purchased',
  default: false,
});

export const TicketAtoms = {
  purchased,
  held,
  loadingHeld,
  loadingPurchased,
};
