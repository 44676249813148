import { useForm } from 'hooks';
import { useAuthentication } from 'modules/authentication';
import { validation as Validation } from 'modules/validation';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTicketPurchase } from './useTicketPurchase';
import { useTickets } from './useTickets';

export function useTicketPurchaseForm(
  tempTickets: TempTicket[],
  event: EventInfo,
  addTicket: (ticket: TempTicket) => void,
) {
  const { purchased } = useTickets();
  const { user } = useAuthentication();
  const { checkIfTicketExists } = useTicketPurchase(event);

  const [loading, setLoading] = useState(false);

  const purchasedForEvent = useMemo(
    () => purchased.filter(t => t.eventId === event?.id),
    [purchased, event?.id],
  );
  const prefill = useMemo(
    () =>
      !purchasedForEvent.some(t => t.holderEmail === user?.email) &&
      !tempTickets.length,
    [purchasedForEvent, user?.email, tempTickets.length],
  );
  const ticketsAvailable = useMemo(
    () => event.numberOfTickets - (event.ticketsSold || 0),
    [event.numberOfTickets, event.ticketsSold],
  );
  /** If number of available tickets has been reached, cannot show form. */
  const ticketLimitReached = useMemo(
    () => ticketsAvailable <= tempTickets.length,
    [ticketsAvailable, tempTickets.length],
  );
  /** If maximum ticket count per buyer has been reached, do not render component */
  const maxTicketsBought = useMemo(
    () =>
      event.maxTicketsPerBuyer &&
      event.maxTicketsPerBuyer <=
        purchasedForEvent?.length + tempTickets.length,
    [event.maxTicketsPerBuyer, purchasedForEvent?.length, tempTickets.length],
  );

  const form = useForm<TempTicket>(
    {
      type: event.ticketTypes[0].name,
      price: event.ticketTypes[0].price,
      name: prefill ? user?.displayName || '' : '',
      email: prefill ? user?.email || '' : '',
    },
    {
      rules: { email: value => !!value && Validation.EMAIL_REGEX.test(value) },
    },
  );
  const { state, validateForm, resetState } = form;

  function checkIfExistsInTemp(email: string) {
    return tempTickets.some(t => t.email === email);
  }

  async function handleAddTicket() {
    try {
      if (!validateForm()) {
        throw new Error('Some of your inputs are not valid.');
      }

      if (checkIfExistsInTemp(state.email)) {
        throw new Error('You have already added a ticket for this person.');
      }

      setLoading(true);
      const exists = await checkIfTicketExists(state.email.toUpperCase());
      if (exists) throw new Error('User already has a ticket.');

      addTicket(state);
      resetState();
    } catch (error) {
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong, check your inputs and try again.',
      );
    } finally {
      setLoading(false);
    }
  }

  function getTicketPrice(ticketName: string) {
    return event.ticketTypes.find(t => t.name === ticketName)?.price || 0;
  }

  return {
    loading,
    form,
    maxTicketsBought,
    ticketLimitReached,
    getTicketPrice,
    handleAddTicket,
  };
}
