import TrashIcon from 'assets/icons/trash-12.svg';
import { DeleteModal } from 'components';
import { TempTicket } from 'modules/tickets';
import { useState } from 'react';

interface Props {
  index: number;
  ticket: TempTicket;
  removeTicket: (index: number) => void;
}

export const TicketPurchaseItem: ReactFC<Props> = ({
  index,
  ticket,
  removeTicket,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <div className="card card--dashboard purchase-tickets__item">
        <div className="purchase-tickets__item__title__container">
          <div className="purchase-tickets__item__title t-medium">
            <p className="t-bold t-text-1">#{index + 1}</p>
            <p className="t-bold t-text-1">{ticket.type}</p>
          </div>

          <p className="t-medium t-text-3">
            {new Intl.NumberFormat('en-AU', {
              style: 'currency',
              currency: 'AUD',
            }).format(ticket.price)}
          </p>
        </div>

        <div className="f f--column g-20">
          <div className="f f--column g-4">
            <p className="t-faded t-small">Ticket holder</p>
            <p className="t-text-1 t-base">{ticket.name}</p>
          </div>
          <div className="f f--column g-4">
            <p className="t-faded t-small">Email address</p>
            <p className="t-text-1 t-base">{ticket.email}</p>
          </div>
        </div>

        <button
          className="button purchase-tickets__remove"
          onClick={() => setShowDeleteModal(true)}
        >
          <img alt="Trash Icon" src={TrashIcon} />{' '}
        </button>
      </div>
      {showDeleteModal && (
        <DeleteModal
          title={`Delete ticket?`}
          description={`Are you sure you want to delete this ticket holder? This action cannot be undone.`}
          close={() => setShowDeleteModal(false)}
          confirm={() => {
            removeTicket(index);
            setShowDeleteModal(false);
          }}
        />
      )}
    </>
  );
};
