import React from 'react';
import { Link } from 'react-router-dom';

export const MembershipOptions: ReactFC = () => (
  <section className="membership-options">
    <div className="wrapper">
      <div className="membership-options__content">
        <div className="membership-options__general">
          <h1 className="membership-options__general__title t-negative t-bold">
            Membership options
          </h1>
          <p className="membership-options__general__claim t-negative u-opacity--80">
            Subscribe to TeamMak to get access to hundreds of hours of exclusive
            audio and video content. Gain helpful insights from industry leaders
            and unlock your full potential.
          </p>
        </div>
        <div className="membership-options__pricing">
          <div className="membership-options__pricing__inner">
            <header className="membership-options__pricing__title">
              <h2 className="u-opacity--40 t-bold">Basic</h2>
            </header>

            <ul className="membership-options__list t-zeta u-opacity--80">
              <li className="membership-options__list-item">
                Access to TeamMak website
              </li>
              <li className="membership-options__list-item">
                Access to TeamMak app
              </li>
              <li className="membership-options__list-item">
                Access to video content
              </li>
              <li className="membership-options__list-item">
                Access to audio content
              </li>
            </ul>
          </div>
          <Link to="/register">
            <button className="button button--medium button--primary button--full-width button--blocky">
              Start subscription
            </button>
          </Link>
        </div>
      </div>
    </div>
  </section>
);
