import { callable } from 'modules/firebase';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import {
  CallableResult,
  StripeCustomerSimple,
  StripeSourceData,
} from '../models';
import { StripeAtoms } from '../recoil';

export function useStripe() {
  const [loading, setLoading] = useRecoilState(StripeAtoms.loading);

  async function subscribeUser(email: string, source: string) {
    try {
      setLoading(true);
      const callableFn = callable<
        { email: string; source: string },
        | { success: true; payload: UserSubscription }
        | { success: false; message: { raw: { message: string } } }
      >('subscriptionCreate');
      const response = await callableFn({ email, source });

      if (!response.data.success) {
        throw new Error(response.data.message.raw.message);
      }

      return response.data.success;
    } catch (error) {
      console.error(error);
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
  }

  /** This is used only by admins, fetch profile again on success. */
  async function cancelSubscription(customerId: string) {
    try {
      setLoading(true);
      const callableFn = callable<{ customerId: string }, CallableResult>(
        'subscriptionCancel',
      );
      const response = await callableFn({ customerId });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Something went wrong');
      }

      return response.data.success;
    } catch (error) {
      console.error(error);
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
  }

  async function getCustomerByIdAsync(customerId: string) {
    try {
      setLoading(true);
      const callableFn = callable<
        { customerId: string },
        StripeCustomerSimple | undefined
      >('getCustomerById');

      const { data } = await callableFn({ customerId });
      if (!data)
        throw new Error('No stripe customer associated with customer ID');

      return data;
    } catch (error) {
      console.error(error);
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
  }

  async function renewStripeSubscription(customerId: string) {
    const callableFn = callable<{ customerId: string }, CallableResult>(
      'subscriptionRenew',
    );

    const response = await callableFn({ customerId });
    if (!response.data.success) {
      throw new Error(response.data.message);
    }
  }

  async function addSourceToCustomer(customerId: string, sourceId: string) {
    try {
      const callableFn = callable<
        { customerId: string; sourceId: string },
        CallableResult<StripeSourceData, { raw: { message: string } }>
      >('addCustomerSource');
      const result = await callableFn({ customerId, sourceId });

      if (!result.data.success) {
        throw new Error(result.data.message.raw.message);
      }

      return await getCustomerByIdAsync(customerId);
    } catch (error) {
      console.error(error);
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
  }

  /** Cannot remove default source. */
  async function removeSourceFromCustomer(
    customerId: string,
    sourceId: string,
  ) {
    try {
      const callableFn = callable<
        { customerId: string; sourceId: string },
        CallableResult<StripeSourceData, { raw: { message: string } }>
      >('deleteCustomerSource');
      const result = await callableFn({ customerId, sourceId });

      if (!result.data.success) {
        throw new Error(result.data.message.raw.message);
      }

      return await getCustomerByIdAsync(customerId);
    } catch (error) {
      console.error(error);
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
  }

  async function setDefaultSource(customerId: string, sourceId: string) {
    try {
      const callableFn = callable<
        { customerId: string; sourceId: string },
        CallableResult<StripeCustomerSimple, { raw: { message: string } }>
      >('setCustomerDefaultSource');
      const result = await callableFn({ customerId, sourceId });
      if (!result.data.success) {
        throw new Error(result.data.message.raw.message);
      }

      return await getCustomerByIdAsync(customerId);
    } catch (error) {
      console.error(error);
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
  }

  async function cancelStripeSubscription(localeDate: string) {
    try {
      setLoading(true);
      const callableFn = callable<{ localeDate: string }, CallableResult>(
        'userSubscriptionCancel',
      );
      const response = await callableFn({
        localeDate,
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Something went wrong');
      }

      toast.success('Subscription cancelled.');
    } catch (error) {
      console.error(error, 'cancel error');
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    getCustomerByIdAsync,
    subscribeUser,
    removeSourceFromCustomer,
    setDefaultSource,
    addSourceToCustomer,
    renewStripeSubscription,
    cancelSubscription,
    cancelStripeSubscription,
  };
}
