import { useForm } from 'hooks';
import { useAudioCategoryForm } from '../hooks';
import { Input, MainModal, TogglePin } from 'components';
import { AudioCategoryConstructor } from '../models';
import { useState } from 'react';

type AudioCategoryState = Omit<
  AudioCategory,
  'id' | 'trackCount' | 'usersWithAccess'
>;

export const AudioCategoryForm: React.FC = () => {
  const { category, update, add, close } = useAudioCategoryForm();
  const [loading, setLoading] = useState(false);

  const form = useForm<AudioCategoryState>(
    {
      title: category?.title || '',
      isSpecialContent: category?.isSpecialContent || false,
    },
    { rules: { title: value => value.length <= 100 } },
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      if (!form.validateForm()) return;

      setLoading(true);
      if (category) await update(category.id, form.state);
      else await add(new AudioCategoryConstructor(form.state));

      close();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <MainModal
      dismissOutside={false}
      title={category ? 'Edit category' : 'New category'}
      close={close}
    >
      <form onSubmit={handleSubmit}>
        <div className="modal__content">
          <div className="field">
            <Input
              label="Title"
              maxLength={100}
              onChange={value => form.update('title', value)}
              value={form.state.title}
            />
            {form.validation.title === false && (
              <p className="t-warning t-small">
                A category title under 100 characters is required.
              </p>
            )}
          </div>
          <div className="field">
            <TogglePin
              checked={form.state.isSpecialContent}
              label="Restrict access?"
              toggle={() =>
                form.update('isSpecialContent', !form.state.isSpecialContent)
              }
            />
            <p className="t-faded t-small">
              Restricting access means that only users you add permissions to
              will be able to see this category. You can add permissions via a
              separate control on the category details page.
            </p>
          </div>
        </div>
        <div className="modal__footer">
          <button
            disabled={loading}
            className="button button--primary button--medium"
          >
            Save
          </button>
          <button
            type="button"
            className="button button--outline button--outline-primary button--medium"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </form>
    </MainModal>
  );
};
