import React, { useEffect, useState } from 'react';
import { PageLoading } from 'components';
import { useParams } from 'react-router';
import { ProfilePage } from 'modules/authentication';
import { useTickets } from 'modules/tickets';
import { useTeams } from 'modules/teams';
import { useUsers } from 'modules/users';
import { useAudioStats } from 'modules/audio-stats';

export const DashboardMemberDetails: ReactFC = () => {
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [loadingAudioStats, setLoadingAudioStats] = useState(false);
  const [audioStats, setAudioStats] = useState<AudioStats[]>([]);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const { memberId } = useParams();
  const { getUserPurchasedTickets } = useTickets();
  const { userTeams, loading: loadingTeams } = useTeams();
  const { profile, loadUserProfile } = useUsers();
  const { getStatsByUserId } = useAudioStats();

  useEffect(() => {
    if (!memberId) return;
    const listener = loadUserProfile(memberId);
    return listener;
  }, [memberId]);

  useEffect(() => {
    if (!profile) return;
    setLoadingTickets(true);
    getUserPurchasedTickets(profile.id)
      .then(setTickets)
      .finally(() => setLoadingTickets(false));

    getStatsByUserId(profile.id)
      .then(setAudioStats)
      .finally(() => setLoadingAudioStats(false));
  }, [profile]);

  if (!profile) return <PageLoading />;

  return (
    <ProfilePage
      user={profile}
      teams={userTeams}
      teamsLoading={loadingTeams}
      tickets={tickets}
      ticketsLoading={loadingTickets}
      audioStats={audioStats}
      audioStatsLoading={loadingAudioStats}
    />
  );
};
