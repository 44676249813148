import React, { useMemo } from 'react';
import moment from 'moment';

import {
  StripeCustomerSimple,
  StripeCustomerForm,
  StripeCustomerSources,
  StripeSourceData,
} from 'modules/stripe';
import { Loading } from 'components';
import IconEmptyStateShared from 'assets/empty-states/empty-state-shared.svg';

interface Props {
  customer: StripeCustomerSimple | undefined;
  sources: StripeSourceData[];
  defaultSource: string | undefined;
  isLoadingSources: boolean;
  isLoadingCustomer: boolean;
  isChanging: boolean;
  handleRemoveSource: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleSetDefaultSource: (event: React.MouseEvent<HTMLButtonElement>) => void;
  addSourceToCustomer: (id: string) => void;
}

export const StripeCustomerInfo: ReactFC<Props> = ({
  customer,
  sources,
  isLoadingSources,
  isLoadingCustomer,
  handleRemoveSource,
  handleSetDefaultSource,
  addSourceToCustomer,
  defaultSource,
}) => {
  const today = new Date().getTime();
  const subscribed = useMemo(
    () => customer && customer?.subscriptions?.data?.length > 0,
    [customer],
  );
  const subscription = useMemo(
    () => (subscribed ? customer?.subscriptions?.data?.[0] : null),
    [customer],
  );

  if (isLoadingCustomer) {
    return <Loading withoutContainer />;
  }

  return (
    <>
      {!customer && (
        <div className="empty-state">
          <img
            className="empty-state__visual"
            alt="empty"
            src={IconEmptyStateShared}
          />
          <p className="empty-state__label">
            You are not currently subscribed to TeamMak and are missing out on
            hundreds of our audio and video content we have in store for you.
          </p>
        </div>
      )}

      <>
        {subscription && subscription?.current_period_end * 1000 <= today && (
          <div className="empty-state">
            <img
              className="empty-state__visual"
              alt="empty"
              src={IconEmptyStateShared}
            />
            <p className="empty-state__label">
              Your subscription has expired on{' '}
              {moment(subscription?.current_period_end * 1000).format('lll')}. If
              your subscription is inactive, you will have to resubscribe to
              regain access to our media content.
            </p>
          </div>
        )}

        {customer && (
          <>
            <h3 className="dashboard-content__title t-zeta t-upper t-bold u-opacity--40">
              Payment Sources
            </h3>
            <div className="s-bottom--lrg">
              <StripeCustomerForm
                onSuccess={addSourceToCustomer}
                label="Add payment source"
              />
            </div>
            {isLoadingSources ? (
              <Loading />
            ) : (
              <StripeCustomerSources
                customerId={customer?.id}
                sources={sources}
                defaultSource={defaultSource!}
                handleRemoveSource={handleRemoveSource}
                handleSetDefaultSource={handleSetDefaultSource}
                // These load through injection
                stripe={null}
                elements={null}
              />
            )}
          </>
        )}
      </>
    </>
  );
};
