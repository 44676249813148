import React from 'react';

interface Props {
  onLeftClick: VoidFunction;
  onRightClick: VoidFunction;
  leftTitle: string;
  rightTitle: string;
  leftActive: boolean;
  rightActive: boolean;
}

export const TogglerWithoutNav: ReactFC<Props> = ({
  onLeftClick,
  onRightClick,
  leftTitle,
  rightTitle,
  leftActive,
  rightActive,
}) => (
  <div className="toggler">
    <button
      type="button"
      onClick={onLeftClick}
      className={`button button--small button--ghost toggler__item ${leftActive &&
        'active'}`}
    >
      {leftTitle}
    </button>
    <button
      type="button"
      onClick={onRightClick}
      className={`button button--small button--ghost toggler__item ${rightActive &&
        'active'}`}
    >
      {rightTitle}
    </button>
  </div>
);
