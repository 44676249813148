import React from 'react';

import { SelectOptionsModel } from 'models';

interface Props<T> {
  handleChange?: (propName: string, value: string) => void;
  handleChangeValueOnly?: (value: string) => void;
  selectedValue: string | undefined;
  optionsArray: T[];
  propName: string;
  label: string;
  disabledOption: string;
  noItemsInArrayMessage: string;
}

export class CustomSelect<
  T extends { id: string; name?: string; displayName?: string }
> extends React.Component<Props<T>> {
  selectOptionsFormating = (teamArray: T[]) =>
    teamArray.map(item => ({
      label: item.name || item.displayName || item.id,
      value: item.id,
    }));

  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { handleChange, handleChangeValueOnly, propName } = this.props;
    if (handleChange) {
      handleChange(propName, event.target.value);
    }

    if (handleChangeValueOnly) {
      handleChangeValueOnly(event.target.value);
    }
  };

  render() {
    const {
      selectedValue,
      optionsArray,
      label,
      disabledOption,
      noItemsInArrayMessage,
    } = this.props;

    const formatOptions = this.selectOptionsFormating(optionsArray);
    const isEmptyArrayMessage = formatOptions.length
      ? disabledOption
      : noItemsInArrayMessage;
    formatOptions.unshift({ label: isEmptyArrayMessage, value: '' });

    return (
      <div className="field">
        <label className="field__lbl">{label}</label>
        <select
          className="input t-base input--select"
          onChange={this.handleChange}
          value={selectedValue}
        >
          {formatOptions.map((item: SelectOptionsModel) => (
            <option
              key={item.value}
              value={item.value}
              disabled={item.value === ''}
              label={item.label}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
