import { Input, MainModal } from 'components';
import { useForm } from 'hooks';
import { useAuthentication } from 'modules/authentication';
import { validation as Validation } from 'modules/validation';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

interface Props {
  close: VoidFunction;
}

interface FormState {
  currentPassword: string;
  password: string;
  repeat: string;
}

export const ChangePasswordModal: ReactFC<Props> = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const { changePassword } = useAuthentication();
  const { state, validation, validateForm, update } = useForm<FormState>(
    { currentPassword: '', password: '', repeat: '' },
    {
      rules: {
        repeat: (value, state) => value === state.password,
        password: value =>
          !!value &&
          value.length >= Validation.PASSWORD_LENGTH_MIN &&
          value.length <= Validation.PASSWORD_LENGTH_MAX,
      },
    },
  );

  async function submit() {
    try {
      setLoading(true);
      if (!validateForm()) throw new Error('Your form has validation errors.');
      await changePassword(state.currentPassword, state.password);

      close();
    } catch (error) {
      toast(error instanceof Error ? error.message : JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  return (
    <MainModal title="Change password" close={close}>
      <div className="modal__content">
        <div className="field">
          <Input
            onChange={value => update('currentPassword', value)}
            label="Current password"
            type="password"
            value={state.currentPassword}
          />
        </div>

        <div className="field">
          <Input
            onChange={value => update('password', value)}
            label="New password"
            type="password"
            value={state.password}
          />
        </div>

        {validation.password === false && (
          <p className="t-warning t-small">
            Your password must be {Validation.PASSWORD_LENGTH_MIN}-
            {Validation.PASSWORD_LENGTH_MAX} characters in length.
          </p>
        )}

        <div className="field">
          <Input
            onChange={value => update('repeat', value)}
            label="Repeat new password"
            type="password"
            value={state.repeat}
          />
        </div>

        {validation.repeat === false && (
          <p className="t-warning t-small">
            This must be the same as your new password.
          </p>
        )}
      </div>
      <div className="f f--center modal__footer">
        <button
          onClick={close}
          className="button button--medium button--ghost button--ghost-primary t-text"
        >
          Cancel
        </button>
        <button
          onClick={submit}
          disabled={loading}
          className="button button--medium button--primary"
        >
          Submit
        </button>
      </div>
    </MainModal>
  );
};
