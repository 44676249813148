import { PAGE_SIZE } from 'consts';

export const stringToSlug = (text: string) =>
  text
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const request = async (
  endpoint: string,
  body: string,
): Promise<{ error?: string; success: boolean; payload?: any }> => {
  const headers = new Headers({
    // tslint:disable-next-line:object-literal-key-quotes
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body,
    });

    const responseJson = await response.json();

    // If error occurred
    if (response.status !== 200) {
      const error = responseJson.error;

      console.log(`Error occurred in request at endpoint: '${endpoint}'\n
            and status code: ${response.status}\n
            with parameters:\n${body}\n
            and response:\n${error}`);

      return { success: false, error };
    }

    // Successful request
    return { success: true, payload: responseJson.payload };
  } catch (error) {
    console.log(`Exeption occurred in stripe request at endpoint: '${endpoint}'\n
        with parameters:\n${body}\n
        and exception:\n${error}`);

    return {
      success: false,
      error: error instanceof Error ? error.message : JSON.stringify(error),
    };
  }
};

export const getPageCount = (collectionLength: number, pageSize?: number) =>
  Math.ceil(collectionLength / (pageSize || PAGE_SIZE));
