import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useEvents } from 'modules/events';
import { useTeams } from 'modules/teams';
import { useAudios } from 'modules/audios';
import { DashboardHeader, Loading, PageLoading } from 'components';
import { useTickets } from 'modules/tickets';
import {
  LastPlayedOrAllVideos,
  LastPlayedOrAllAudios,
  TeamStatsItem,
  DashboardEventTicketItem,
  EventStatsCard,
} from 'modules/dashboard';
import { RoleCheck, Role } from 'modules/authorization';
import { useAuthentication } from 'modules/authentication';
import { useVideos } from 'modules/videos';
import IconDiamond from 'assets/icons/diamond-team.svg';
import IconPeopleGroup from 'assets/icons/people-group.svg';
import { useAudioStats } from 'modules/audio-stats';

export const Dashboard: ReactFC = ({ ...props }) => {
  const { user } = useAuthentication();
  const { userTeams, loading: loadingTeams } = useTeams();
  const { purchased, held, loadingHeld, loadingPurchased } = useTickets();
  const {
    lastPlayed: lastPlayedVideos,
    loading: videosLoading,
    loadLastPlayed: loadLastPlayedVideos,
  } = useVideos();
  const {
    lastPlayed: lastPlayedAudios,
    loading: audiosLoading,
    loadLastPlayed: loadLastPlayedAudios,
  } = useAudios();
  const { events, loading: eventsLoading } = useEvents();
  const { stats } = useAudioStats();
  const loadingTickets = loadingHeld || loadingPurchased;

  useEffect(() => {
    loadLastPlayedVideos();
  }, []);

  useEffect(() => {
    if (stats.length) loadLastPlayedAudios();
  }, [stats]);

  if (!user) return <PageLoading />;

  const renderTeamsCount = () => {
    return (
      <div className="dashboard-overview__teams__card">
        <span>
          <p className="t-gamma t-bold">{userTeams.length}</p>
          <p className="t-zeta t-faded">Teams</p>
        </span>
        <img
          src={IconDiamond}
          alt="Teams icon"
          className="dashboard-sidebar__stats__icon"
        />
      </div>
    );
  };

  const renderMemberCount = () => {
    if (loadingTeams) {
      return <Loading />;
    }

    let count = 0;
    if (user.role === Role.Administrator) {
      /** Get parent level teams and sum their member count */
      count = userTeams
        .filter(t => !t.parentTeamId)
        ?.map(t => t.memberCount)
        .reduce((count, current) => (count += current));
    } else {
      count = userTeams.find(t => t.id === user.teamId)?.memberCount || 0;
    }

    return (
      <div className="dashboard-overview__teams__card">
        <span>
          <p className="t-gamma t-bold">{count}</p>
          <p className="t-zeta t-faded">Members</p>
        </span>
        <img
          className="dashboard-sidebar__stats__icon"
          src={IconPeopleGroup}
          alt="Teams icon"
        />
      </div>
    );
  };

  const getEventIds = () => {
    const now = new Date().getTime();
    const userTicketEventIds = held
      .filter(t => t.validUntil > now)
      .map(t => t.eventId);
    const purchasedTicketEventIds = purchased
      .filter(t => t.validUntil > now)
      .map(t => t.eventId);

    return Array.from(
      new Set(userTicketEventIds.concat(purchasedTicketEventIds)),
    );
  };

  const renderEvents = () => {
    if (loadingTickets || eventsLoading) return <Loading />;
    const eventIds = getEventIds();

    return (
      <React.Fragment>
        <div className="dashboard-card">
          <div className="dashboard-card__header">
            <div className="dashboard-card__container f f--center">
              <h3 className="t-zeta t-upper t-bold u-opacity--40">
                Your tickets
              </h3>
              <Link to="/tickets" className="t-zeta anchor--secondary">
                View all events
              </Link>
            </div>
          </div>
          <div className="dashboard-card__container dashboard-card__container--tickets f">
            {!eventIds.length && (
              <div className="dashboard-card__empty">
                <p>You have no tickets for upcoming events.</p>
              </div>
            )}
            {eventIds.map((id, i) => {
              const event = events.find(e => e.id === id);

              if (!event) {
                return null;
              }

              const numberOfTickets = held
                .filter(e => e.eventId === id)
                .concat(
                  purchased.filter(
                    t =>
                      t.eventId === id &&
                      t.normalisedHolderEmail !== user?.normalisedEmail,
                  ),
                ).length;

              if (i > 2) {
                return null;
              }

              return (
                <DashboardEventTicketItem
                  event={event}
                  key={id}
                  numberOfTickets={numberOfTickets}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <DashboardHeader />
      {loadingTeams ? (
        <Loading />
      ) : (
        <div className="wrapper">
          <div className="content--dashboard">
            <React.Fragment>
              <div className="dashboard-overview__container">
                <div className="dashboard-overview__teams">
                  <RoleCheck role={Role.TeamLeader}>
                    <TeamStatsItem>
                      {renderTeamsCount()}
                      {renderMemberCount()}
                    </TeamStatsItem>
                  </RoleCheck>
                </div>

                <div className="dashboard-overview__events">
                  <RoleCheck role={Role.Administrator}>
                    <EventStatsCard />
                  </RoleCheck>
                </div>

                <div className="dashboard-overview__videos">
                  {videosLoading ? (
                    <Loading withoutContainer />
                  ) : (
                    <LastPlayedOrAllVideos
                      videos={lastPlayedVideos}
                      user={user}
                    />
                  )}
                </div>
                <div className="dashboard-overview__audios">
                  {audiosLoading ? (
                    <Loading withoutContainer />
                  ) : (
                    <LastPlayedOrAllAudios
                      audios={lastPlayedAudios}
                      user={user}
                    />
                  )}
                </div>
                <div className="dashboard-overview__tickets">
                  {renderEvents()}
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
