import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { ApplicationRoutes } from 'modules/navigation';
import { Routes } from 'react-router';
import { AccessControlElement } from 'modules/authorization';
import { Layout } from 'components';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

export const Router: ReactFC = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Layout>
          <Routes>
            {ApplicationRoutes.map(({ key, ...route }) => (
              <Route
                path={route.path}
                key={key}
                id={key}
                index
                element={<AccessControlElement key={key} {...route} />}
              />
            ))}
          </Routes>
        </Layout>
      </QueryParamProvider>
    </BrowserRouter>
  );
};
