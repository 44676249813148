export interface EventInfoForm
  extends Omit<
    EventInfo,
    | 'id'
    | 'revenue'
    | 'ticketsSold'
    | 'timeCreated'
    | 'startTimestamp'
    | 'endTimestamp'
    | 'cutoffTimestamp'
  > {
  featuredImageFile: File | null;
}

export class EventInfoForm {
  constructor(event: EventInfo | undefined) {
    this.title = event?.title || '';
    this.description = event?.description || '';
    this.shortDescription = event?.shortDescription || '';

    /** x -1 because timezone offset is calculated differently by default, select value counts from ISO to local, Date function counts from local to ISO. */
    this.timezoneOffset =
      event?.timezoneOffset || (new Date().getTimezoneOffset() / 60) * -1;
    this.timezone =
      event?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.startDateRaw = event?.startDateRaw || '';
    this.endDateRaw = event?.endDateRaw || '';
    this.cutoffDateRaw = event?.cutoffDateRaw || '';

    this.ticketTypes = event?.ticketTypes || [{ name: 'Standard', price: 0 }];

    this.featuredImageFile = null;
    this.featuredImagePath = event?.featuredImagePath || null;
    this.featuredImageUrl = event?.featuredImageUrl || null;

    this.maxTicketsPerBuyer = event?.maxTicketsPerBuyer || 0;
    this.numberOfTickets = event?.numberOfTickets || 0;
    this.location = event?.location || '';
    this.venue = event?.venue || '';
    this.status = event?.status || 'draft';
  }
}
