import React from 'react';
import { useAuthentication } from 'modules/authentication';
import { ProfilePage } from 'modules/authentication';
import { GeneralError } from './GeneralError';
import { useTickets } from 'modules/tickets';
import { useTeams } from 'modules/teams';
import { useAudioStats } from 'modules/audio-stats';

export const Profile: ReactFC = () => {
  const { teams, loading: loadingTeams } = useTeams();
  const { user } = useAuthentication();
  const { purchased, loadingPurchased } = useTickets();
  const { stats, loading } = useAudioStats();

  if (!user) return <GeneralError />;

  return (
    <ProfilePage
      user={user}
      teams={teams}
      teamsLoading={loadingTeams}
      tickets={purchased}
      ticketsLoading={loadingPurchased}
      audioStatsLoading={loading}
      audioStats={stats}
    />
  );
};
