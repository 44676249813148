import React, { useEffect, useState } from 'react';
import { AudioDetailsCore } from 'modules/audios';
import { Loading } from 'components';
import { SharedAudio, useSharedAudios } from 'modules/share';
import { useParams } from 'react-router';

export const PublicAudioDetails: ReactFC = () => {
  const { shareId = '' } = useParams();
  const { updatePlayedCount, getSharedContentById } = useSharedAudios();
  const [shared, setShared] = useState<SharedAudio>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSharedContentById(shareId)
      .then(setShared)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (shared) updatePlayedCount(shareId);
  }, [shared]);

  if (loading) {
    return <Loading />;
  }

  if (!shared) {
    return <p>Audio file not found</p>;
  }

  return (
    <AudioDetailsCore audio={shared.audio} shareable={false} hideControls />
  );
};
