import { useQueryParam } from 'use-query-params';

export function useUrlState<Type>(
  key: string,
  initialValue: Type,
): [Type, (newValue: Type) => void] {
  const [paramValue, setParamValue] = useQueryParam<Type>(key);
  const value = paramValue || initialValue;

  return [
    value,
    (value: Type) =>
      setParamValue(value, !!paramValue ? 'pushIn' : 'replaceIn'),
  ];
}
