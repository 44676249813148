import React from 'react';

interface Props {
  ticket: Ticket;
}

export const TicketItem: ReactFC<Props> = ({ ticket }) => {
  return (
    <div className="dashboard-tickets__item">
      <div className="dashboard-tickets__item__qr-container t-center">
        <p className="t-zeta t-bold">{ticket.holderName}</p>
        <p className="t-small t-faded">{ticket.holderEmail}</p>
        <p className="s-top--med t-small">{ticket.ticketType}</p>
        <p className="t-epsilon">${ticket.ticketPrice}</p>
      </div>
      <div className="dashboard-tickets__item__holder-container">
        <p className="t-small">{ticket.eventName}</p>
        <p className="s-top--tny t-iota t-faded">
          Valid until: {new Date(ticket.validUntil).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};
