import { where } from 'firebase/firestore';
import { useAuthentication } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { TicketAtoms } from '../recoil';

const TicketsCollection = new FirestoreService<Ticket>(Collection.Tickets);

export function useTicketsListener() {
  const { user } = useAuthentication();
  const setHeld = useSetRecoilState(TicketAtoms.held);
  const setPurchased = useSetRecoilState(TicketAtoms.purchased);
  const setHeldLoading = useSetRecoilState(TicketAtoms.loadingHeld);
  const setPurchasedLoading = useSetRecoilState(TicketAtoms.loadingPurchased);

  /** Load purchased tickets */
  useEffect(() => {
    if (!user?.id) return;

    setPurchasedLoading(true);
    const listener = TicketsCollection.listen({
      onSuccess: setPurchased,
      onError: error => console.log('Error loading purchased tickets: ', error),
      onFinished: () => setPurchasedLoading(false),
      filters: [where('purchasedById', '==', user.id)],
    });

    return listener;
  }, [user?.id]);

  /** Load held tickets */
  useEffect(() => {
    if (!user?.normalisedEmail) return;

    setHeldLoading(true);
    const listener = TicketsCollection.listen({
      onSuccess: setHeld,
      onError: error => console.log('Error loading held tickets: ', error),
      onFinished: () => setHeldLoading(false),
      filters: [
        where('normalisedHolderEmail', '==', user.normalisedEmail),
        where('validUntil', '>=', Date.now()),
      ],
    });

    return listener;
  }, [user?.normalisedEmail]);
}
