import { PageHeader, PageLoading } from 'components';
import { useEvents } from 'modules/events';
import {
  BuyTicketsActions,
  BuyTicketsHeader,
  TicketPurchaseForm,
  TicketPurchaseList
} from 'modules/tickets';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GeneralError } from './GeneralError';

export const BuyTickets: React.FC = () => {
  const params = useParams();
  const { loading, allEvents } = useEvents();

  const [tempTickets, setTempTickets] = useState<TempTicket[]>([]);

  const event = useMemo(() => allEvents.find(e => e.id === params.id), [
    allEvents,
    params.id,
  ]);

  function handleRemoveTicket(index: number) {
    const list = [...tempTickets];
    list.splice(index, 1);
    setTempTickets(list);
  }

  function handleAddTicket(ticket: TempTicket) {
    setTempTickets(prev => [...prev, ticket]);
  }

  if (loading) return <PageLoading />;
  if (!event) return <GeneralError />;

  return (
    <>
      <PageHeader mobileOnly back />
      <section className="buy-tickets">
        <div className="buy-tickets__container wrapper__narrow">
          <BuyTicketsHeader event={event} />
          <TicketPurchaseForm
            tempTickets={tempTickets}
            addTicket={ticket => handleAddTicket(ticket)}
            event={event}
          />
          <div className="divider__dashed" />
          <TicketPurchaseList
            tempTickets={tempTickets}
            removeTicket={handleRemoveTicket}
          />

          <BuyTicketsActions tempTickets={tempTickets} event={event} />
        </div>
      </section>
    </>
  );
};
