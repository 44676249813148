import React, { useMemo } from 'react';
import { DashboardHeader, Loading } from 'components';
import { useEvents } from 'modules/events';
import { DashboardEventTicketItem } from 'modules/dashboard';
import { useTickets } from 'modules/tickets';

export const DashboardTicketOverview: ReactFC = props => {
  const { events, loading: loadingEvents } = useEvents();
  const { purchased, held, loadingHeld, loadingPurchased } = useTickets();
  const loadingTickets = loadingPurchased || loadingHeld;

  const eventIds = useMemo(() => {
    const heldEventIds = held.map(t => t.eventId);
    const purchasedEventIds = purchased.map(t => t.eventId);
    return Array.from(new Set(heldEventIds.concat(purchasedEventIds)));
  }, [held, purchased]);

  const tickets = useMemo(() => {
    const allTickets = held.concat(purchased);
    const filtered: Ticket[] = [];

    for (const ticket of allTickets) {
      if (filtered.some(t => t.id === ticket.id)) continue;
      filtered.push(ticket);
    }

    return filtered;
  }, [held, purchased]);

  const renderTitle = () => {
    if (loadingTickets) {
      return <Loading />;
    }

    return <span>{eventIds.length} event registrations</span>;
  };

  const renderEvents = () => {
    if (loadingEvents || loadingTickets) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        {eventIds.map(id => {
          const event = events.find(e => e.id === id);
          if (!event) return null;

          return (
            <DashboardEventTicketItem
              key={id}
              event={event}
              numberOfTickets={tickets.filter(t => t?.eventId === id)?.length}
            />
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <DashboardHeader />
      <div className="wrapper">
        <div className="dashboard-content__inner dashboard-content__inner--small">
          <h3 className="dashboard-content__title t-zeta t-upper t-bold u-opacity--40">
            {renderTitle()}
          </h3>
          <div className="dashboard-tickets__card-list">{renderEvents()}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
