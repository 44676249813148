import React, { useEffect } from 'react';
import { DashboardHeader, Loading, ScrollingContent } from 'components';
import { useEvents } from 'modules/events';
import { DashboardEventItem, EmptyState } from 'modules/dashboard';

export const EventStatsList: ReactFC = () => {
  const { allEvents, loadPastEvents, loading } = useEvents();

  useEffect(() => {
    loadPastEvents();
  }, []);

  const renderEvents = () => {
    if (loading) {
      return <Loading />;
    }

    if (!allEvents.length) {
      return (
        <EmptyState
          type="event"
          message="No events to show, check again later."
        />
      );
    }

    // Sort by date, descending
    const sorted = allEvents.sort((a, b) =>
      a.startTimestamp > b.startTimestamp ? -1 : 1,
    );

    return (
      <div className="dashboard-grid">
        <ScrollingContent data={sorted} renderItem={renderEventItem} />
      </div>
    );
  };

  const renderEventItem = (event: EventInfo) => (
    <DashboardEventItem
      event={event}
      key={event.id}
      linkOverride={`/event-stats/${event.id}`}
    />
  );

  return (
    <React.Fragment>
      <DashboardHeader />
      <div className="wrapper">
        <div className="dashboard-body">
          <h3 className="dashboard-content__title t-zeta t-upper t-bold u-opacity--40">
            Events
          </h3>

          {renderEvents()}
        </div>
      </div>
    </React.Fragment>
  );
};
