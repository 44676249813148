import React from 'react';
import { RenderTicketList } from 'modules/dashboard';

interface Props {
  tickets: Ticket[];
  loading: boolean;
}

export const EventTeamMembers: ReactFC<Props> = ({ tickets, loading }) => {
  return (
    <React.Fragment>
      <h3 className="dashboard-content__title t-zeta t-upper t-bold u-opacity--40 s-top--med">
        Ticket history
      </h3>
      <section className="history-list">
        <header className="history-list__header">
          <span className="history-list__name t-iota u-opacity--40">
            Member name
          </span>
          <span className="history-list__type t-iota u-opacity--40">
            Tickets bought
          </span>
          <span className="history-list__count t-iota u-opacity--40">Paid</span>
        </header>
        <div className="history-list__body">
          <RenderTicketList tickets={tickets} loading={loading} />
        </div>
      </section>
    </React.Fragment>
  );
};
