import { UserCredential } from 'firebase/auth';
import { Role } from 'modules/authorization';
import { RegistrationData } from './RegistrationData';
import { UpdateProfileData } from './UpdateProfileData';

export interface UserProfileConstructor extends CustomUserInfo {}

export class UserProfileConstructor implements CustomUserInfo {
  constructor(credential: UserCredential, registration: RegistrationData) {
    this.id = credential.user.uid;
    this.displayName = `${registration.firstName} ${registration.lastName}`;
    this.email = registration.email;
    this.teamId = registration.teamId;
    this.ibo = registration.ibo;
    this.country = registration.country;

    // Normalised fields for search purposes.
    this.normalisedEmail = registration.email.toUpperCase();
    this.normalisedFirstName = registration.firstName.toUpperCase();
    this.normalisedLastName = registration.lastName.toUpperCase();

    // Seed Favorites playlists.
    this.audioPlaylists = [{ id: 'favorites', name: 'Favorites', audios: [] }];
    this.videoPlaylists = [{ id: 'favorites', name: 'Favorites', videos: [] }];
    this.videoStats = [];

    // Every user begins as a regular user, team assignments update roles to leaders.
    this.role = Role.User;
  }
}
export interface UserProfileUpdateConstructor extends CustomUserInfo {}
export class UserProfileUpdateConstructor implements CustomUserInfo {
  constructor({
    email,
    country,
    firstName,
    lastName,
    ibo,
    sponsorIbo,
  }: UpdateProfileData) {
    this.email = email;
    this.displayName = `${firstName} ${lastName}`;

    this.normalisedEmail = email.toUpperCase();
    this.normalisedFirstName = firstName.toUpperCase();
    this.normalisedLastName = lastName.toUpperCase();
    this.ibo = ibo;
    this.sponsorIbo = sponsorIbo;
    this.country = country;
  }
}
