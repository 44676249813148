import React from 'react';
import { Link } from 'react-router-dom';
import { Loading } from 'components';
import { TeamTypeEnum } from 'modules/teams';
import { Role } from 'modules/authorization';

interface Props {
  // title: string;
  teamId: string;
  teams: Team[];
  isChangingTeams: boolean;
  user: CustomUserInfo | CustomUserInfo;
}

export const TeamInfo: ReactFC<Props> = ({
  teamId,
  teams,
  isChangingTeams,
  user,
}) => {
  if (isChangingTeams) {
    return <Loading />;
  }

  if (!teams || !teams.length) {
    return null;
  }

  const team = teams.find(t => t.id === teamId);
  if (!team) {
    return null;
  }

  const teamsArray = [team];

  let parentTeamId = team.parentTeamId || '';

  while (parentTeamId.length) {
    // eslint-disable-next-line
    const subTeam = teams.find(t => t.id === parentTeamId);
    if (!subTeam) {
      // eslint-disable-next-line
      parentTeamId = '';
    }
    if (subTeam) {
      teamsArray.unshift(subTeam);
      // eslint-disable-next-line
      parentTeamId = subTeam.parentTeamId ? subTeam.parentTeamId : '';
    }
  }
  const renderBody = (t: Team) => {
    return (
      <React.Fragment>
        <p className="t-bold s-bottom--tny">{t.name}</p>
        <p className="u-opacity--60">
          {t.teamType === TeamTypeEnum.Diamond
            ? 'Diamond team'
            : 'Platinum team'}
        </p>
      </React.Fragment>
    );
  };

  const renderLinkOrDiv = (t: Team) => {
    if (user.role === Role.Administrator) {
      return (
        <Link
          to={`/team/${t.id}`}
          key={t.id}
          className="profile__team-info__item"
        >
          {renderBody(t)}
        </Link>
      );
    }

    return (
      <div key={t.id} className="profile__team-info__item">
        {renderBody(t)}{' '}
      </div>
    );
  };

  return (
    <div className="dashboard-sidebar__item">
      <h3 className="dashboard-content__title t-zeta t-upper t-bold u-opacity--40">
        Team info
      </h3>
      <div className="profile__team-info">
        {teamsArray.map(t => renderLinkOrDiv(t))}
      </div>
    </div>
  );
};
