import React, { useMemo } from 'react';
import Select from 'react-select';
import { Loading, SelectedOption } from 'components';
import { useTeams } from 'modules/teams';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    // tslint:disable-next-line:object-literal-key-quotes
    border: 'none',
    // tslint:disable-next-line:object-literal-key-quotes
    boxShadow: 'none',
    // tslint:disable-next-line:object-literal-key-quotes
    backgroundColor: 'rgba(49, 38, 58, 0.05)',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
};

interface Props {
  teamId: string | undefined;
  onSelect: (option: SelectedOption) => void;
}

export const TeamSelect: ReactFC<Props> = ({ teamId, onSelect }) => {
  const { teams, loading } = useTeams();

  const options: SelectedOption[] = useMemo(
    () => teams.map(team => ({ label: team.name, value: team.id })),
    [teams],
  );

  if (loading) {
    return (
      <div className="field">
        <Loading />
      </div>
    );
  }

  const value = options.find(option => option.value === teamId);

  return (
    <div className="field">
      <label className="t-tertiary field__lbl">Select a team</label>
      <Select
        value={value}
        onChange={value => onSelect(value as SelectedOption)}
        styles={customStyles}
        options={options}
      />
    </div>
  );
};
