import { Role } from 'modules/authorization';
import { Collection, FirestoreService } from 'modules/firebase';
import { TeamConstructor, TeamTypeEnum } from 'modules/teams';

export class TeamService {
  private teamsCollection = new FirestoreService<Team>(Collection.Teams);
  private usersCollection = new FirestoreService<CustomUserInfo>(
    Collection.UserInfo,
  );

  public setTeamLeader = async (team: Team, user: CustomUserInfo | null) => {
    const currentLeaderId = team.teamLeaderId;

    // Remove Team Leader role from current user.
    if (currentLeaderId) {
      this.usersCollection.set(currentLeaderId, { role: Role.User });
    }

    // Update Team leader on Team document
    await this.teamsCollection.set(team.id, {
      teamLeaderId: user?.id || null,
      teamLeaderName: user?.displayName || null,
    });

    // Update new Team Leader role
    if (user?.id) {
      this.usersCollection.set(user.id, {
        role:
          team.teamType === TeamTypeEnum.Diamond
            ? Role.DiamondLeader
            : Role.TeamLeader,
      });
    }
  };

  public createTeam = (
    name: string,
    type: TeamType,
    parentTeamId: string | null,
  ) => {
    const team = new TeamConstructor(name, type, parentTeamId);
    return this.teamsCollection.add(team);
  };

  public deleteTeam = (id: string) => {
    return this.teamsCollection.remove(id);
  };

  public updateTeam = (
    id: string,
    name: string,
    type: TeamType,
    parentTeamId: string | null,
  ) => {
    return this.teamsCollection.set(id, { name, teamType: type, parentTeamId });
  };
}
