import React, { useState } from 'react';

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'onChange' | 'value'
  > {
  itemRight?: JSX.Element;
  value: string;
  label?: string;
  onChange: (value: string) => void;
}

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    { label, name, id, itemRight, value, onChange, className, type, ...rest },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);

    return (
      <div>
        {label !== undefined && (
          <label
            htmlFor={id || name}
            className={`t-small ${focused ? 't-text' : 't-faded'} field__lbl `}
          >
            {label}
          </label>
        )}

        <input
          ref={ref}
          className={`input t-base ${
            type === 'textarea' ? 'input--textarea' : 'input--text'
          } ${className || ''}`}
          value={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onSubmit={() => setFocused(false)}
          onChange={({ currentTarget: { value } }) => onChange(value)}
          id={id || name}
          type={type || 'text'}
          {...rest}
        />
      </div>
    );
  },
);
