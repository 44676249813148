import { useEffect, useRef } from 'react';

interface Props {
  onIntersect: VoidFunction;
}

export const IntersectionTrigger: ReactFC<Props> = ({ onIntersect }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      ([observerEntry]: IntersectionObserverEntry[]) => {
        if (observerEntry.isIntersecting) onIntersect();
      },
      { root: null, rootMargin: '0px', threshold: 1.0 },
    );

    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref.current, onIntersect]);

  return <div ref={ref} />;
};
