import { useRecoilValue } from 'recoil';
import { TeamAtoms } from '../recoil';
import { TeamService } from '../services';

const Service = new TeamService();

export function useTeams() {
  const teams = useRecoilValue(TeamAtoms.teams);
  const userTeams = useRecoilValue(TeamAtoms.userTeams);
  const loading = useRecoilValue(TeamAtoms.loading);

  function mapSubteams(parentId: string, list: Team[] = userTeams): Team[] {
    const subteams = list.filter(t => t.parentTeamId === parentId);
    let results: Team[] = subteams;
    for (const team of subteams) {
      results = [...results, ...mapSubteams(team.id, list)];
    }

    return results;
  }

  function getTeamById(teamId: string) {
    return userTeams.find(team => team.id === teamId);
  }

  function getTeamByTeamLeaderId(leaderId: string) {
    return userTeams.find(team => team.teamLeaderId === leaderId);
  }

  function getTeamWithSubteamsById(teamId: string): Team[] {
    const team = getTeamById(teamId);
    if (!team) return [];
    const subteams = mapSubteams(team.id);
    return [team, ...subteams];
  }

  return {
    ...Service,
    teams,
    userTeams,
    loading,
    mapSubteams,
    getTeamById,
    getTeamByTeamLeaderId,
    getTeamWithSubteamsById,
  };
}
