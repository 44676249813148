import { where } from 'firebase/firestore';
import { Collection, FirestoreService } from 'modules/firebase';
import { useRecoilState } from 'recoil';
import { UserAtoms } from '../recoil';

const UserInfoCollection = new FirestoreService<CustomUserInfo>(
  Collection.UserInfo,
);

export function useUsers() {
  const [profile, setProfile] = useRecoilState(UserAtoms.profile);
  const [loading, setLoading] = useRecoilState(UserAtoms.loading);

  function loadUserProfile(userId: string) {
    return UserInfoCollection.listenToId({
      path: userId,
      onSuccess: setProfile,
      onError: console.error,
      onFinished: () => setLoading(false),
    });
  }

  function searchForUsers(
    query: string,
    field: 'normalisedEmail' | 'normalisedLastName',
  ) {
    const normalisedQuery = query.trim().toUpperCase();
    return UserInfoCollection.get([
      where(field, '>=', normalisedQuery),
      where(field, '<', normalisedQuery + '~'),
    ]);
  }

  function activateLegacySubscription(userId: string) {
    return UserInfoCollection.set<UserSubscription>(
      `${userId}/private/subscription`,
      {
        isLegacySubscription: true,
      },
    );
  }

  function cancelLegacySubscription(userId: string) {
    return UserInfoCollection.set<UserSubscription>(
      `${userId}/private/subscription`,
      {
        isLegacySubscription: false,
      },
    );
  }

  return {
    loading,
    profile,
    searchForUsers,
    loadUserProfile,
    activateLegacySubscription,
    cancelLegacySubscription,
  };
}
