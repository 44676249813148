import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { StripeCardElement } from '.';

interface OwnProps {
  label?: string;
  onSuccess: (sourceId: string) => void;
}

type Props = OwnProps;

export const StripeCustomerForm: React.FC<Props> = props => {
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY || ''}>
      <Elements>
        <StripeCardElement {...props} />
      </Elements>
    </StripeProvider>
  );
};
