import { MainModal } from './MainModal';

interface Props {
  confirm: VoidFunction;
  close: VoidFunction;
  title: string;
  description: string;
}

export const ConfirmModal: React.FC<Props> = ({
  close,
  confirm,
  title,
  description,
}) => {
  return (
    <MainModal title={title} close={close}>
      <div className="modal__content">
        <p className="t-zeta">{description}</p>
      </div>
      <div className="modal__footer">
        <button
          type="button"
          onClick={confirm}
          className="button button--medium button--primary"
        >
          Confirm
        </button>
        <button
          type="button"
          onClick={close}
          className="button button--medium button--ghost button--ghost-primary t-text"
        >
          Cancel
        </button>
      </div>
    </MainModal>
  );
};
