import React from 'react';
import { Loading } from 'components';
import { getStartDate } from '..';
import { Role, RoleCheck } from 'modules/authorization';
import IconDate from 'assets/icons/date.svg';
import IconTicket from 'assets/icons/ticket.svg';
import { EventStatsCSVDownload } from 'modules/reports';
import { useEventStats } from 'modules/events';
import { useAuthentication } from 'modules/authentication';

interface Props {
  event: EventInfo;
  tickets: Ticket[];
  currentTeam: Team | undefined;
  loading: boolean;
}

export const DashboardEventInfo: ReactFC<Props> = ({
  event,
  tickets,
  loading,
}) => {
  const { user } = useAuthentication();
  const { mapTicketsToRevenueAndSoldCount } = useEventStats();
  if (loading) return <Loading />;

  const { revenue, sold } = mapTicketsToRevenueAndSoldCount(tickets);

  return (
    <>
      <div className="dashboard-sidebar__item desktop-only">
        <div className="dashboard-sidebar__title">
          <h1 className="t-gamma t-bold u-opacity--20">{event.title}</h1>
        </div>
      </div>

      <div className="dashboard-sidebar__item">
        <div className="dashboard-sidebar__stats">
          <RoleCheck role={Role.Administrator}>
            <div className="dashboard-sidebar__stats__item dashboard-sidebar__stats__item--main">
              <p className="dashboard-sidebar__stats__value dashboard-sidebar__stats__value--price t-bold">
                <span className="dashboard-sidebar__stats__currency">$</span>
                {revenue.toFixed(2)}
              </p>
              <p className="dashboard-sidebar__stats__label u-opacity--40">
                Total revenue
              </p>
            </div>
            <div className="dashboard-sidebar__stats__item">
              <div className="dashboard-sidebar__stats__item__row">
                <div className="dashboard-sidebar__stats__icon-container desktop-only">
                  <img src={IconDate} alt="Date icon" />
                </div>
                <span className="dashboard-sidebar__stats__copy-container">
                  <p className="dashboard-sidebar__stats__value dashboard-sidebar__stats__value--small t-bold">
                    {getStartDate(event.startTimestamp)}
                  </p>
                  <p className="dashboard-sidebar__stats__label u-opacity--40">
                    Event date
                  </p>
                </span>
              </div>
            </div>
            <div className="dashboard-sidebar__stats__item">
              <div className="dashboard-sidebar__stats__item__row">
                <div className="dashboard-sidebar__stats__icon-container desktop-only">
                  <img src={IconTicket} alt="Ticket icons" />
                </div>
                <span className="dashboard-sidebar__stats__copy-container">
                  <p className="dashboard-sidebar__stats__value dashboard-sidebar__stats__value--small t-bold">
                    {sold}
                  </p>
                  <p className="dashboard-sidebar__stats__label u-opacity--40">
                    Tickets sold
                  </p>
                </span>
              </div>
            </div>
          </RoleCheck>
        </div>
      </div>

      <RoleCheck role={Role.TeamLeader}>
        <div className="dashboard-sidebar__item">
          <EventStatsCSVDownload
            event={event}
            tickets={tickets}
            showPaymentId={user?.role === 'ADMIN'}
          />
        </div>
      </RoleCheck>
    </>
  );
};
