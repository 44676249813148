import { atom } from 'recoil';
import { SubscriptionType } from '../models';

const types = atom<SubscriptionType[]>({
  key: 'subscription-types',
  default: [],
  dangerouslyAllowMutability: true,
});

const subscription = atom<UserSubscription | undefined>({
  key: 'subscription-document',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const loading = atom<boolean>({
  key: 'subscription-loading',
  default: false,
});

export const SubscriptionAtoms = {
  loading,
  subscription,
  types,
};
