import { useForm } from 'hooks';
import { useAudioForm } from '../hooks/useAudioForm';
import { AudioInfoForm } from '../models';
import { useEffect, useMemo, useState } from 'react';
import { Input, MainModal, SelectedOption, TogglePin } from 'components';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import { useAudios } from '../hooks';
import IconClose from 'assets/icons/close.svg';
import IconHeadphones from 'assets/icons/headphones.svg';
import IconDropzoneImage from 'assets/icons/dropzone-attachment-image.svg';
import ReactQuill from 'react-quill';

const MAX_THUMBNAIL_SIZE = 1000000;
const MAX_AUDIO_SIZE = 100000000;

export const AudioForm: React.FC = () => {
  const { categories, updateAudio, addAudio } = useAudios();
  const { close, audio, categoryId } = useAudioForm();
  const [loading, setLoading] = useState(false);
  const initialState = useMemo(() => new AudioInfoForm(audio), [audio]);

  /** NEED TO FIX USE FORM TO VALIDATE NULL VALUES */

  const form = useForm<AudioInfoForm>(initialState, {
    rules: {
      shortDescription: value => !!value && value.length <= 100,
      title: value => !!value && value.length <= 100,
      author: value => !!value && value.length <= 100,
      audioUrl: (value, state) => Boolean(value || state.audioFile),
      audioFile: (value, state) =>
        value ? value.size <= MAX_AUDIO_SIZE : !!state.audioUrl,
      thumbnailFile: value => !value || value.size <= MAX_THUMBNAIL_SIZE,
    },
    optional: ['thumbnailPath', 'thumbnailUrl'],
  });

  const isRestricted = useMemo(
    () =>
      categories
        .filter(c => form.state.categoryIds.includes(c.id))
        .some(c => c.isSpecialContent),
    [form.state.categoryIds],
  );

  const selectedCategories = useMemo(
    () =>
      categories
        .filter(c => form.state.categoryIds.includes(c.id))
        .map(category => ({ label: category.title, value: category.id })),
    [form.state.categoryIds],
  );

  const categoryOptions: SelectedOption[] = useMemo(
    () =>
      categories.map(category => ({
        label: category.title,
        value: category.id,
      })),
    [categories],
  );

  useEffect(() => {
    if (!categoryId || form.state.categoryIds.includes(categoryId)) return;
    form.update('categoryIds', [...form.state.categoryIds, categoryId]);
  }, []);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      if (!form.validateForm())
        throw new Error(
          'Some of your inputs are not valid. Check your entries.',
        );

      setLoading(true);
      toast.info('Saving audio, please wait', { toastId: 'audio-form' });
      if (audio) {
        await updateAudio(audio.id, form.state);
      } else {
        await addAudio(form.state);
      }

      close();
      toast.dismiss('audio-form');
      toast.success('Audio successfully saved.');
    } catch (error) {
      console.error(error);
      toast.dismiss('audio-form');
      toast.error(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Try again.',
        { toastId: 'audio-form' },
      );
    } finally {
      setLoading(false);
    }
  }

  function handleCategoryUpdate(data: SelectedOption[] | null) {
    form.update(
      'categoryIds',
      (data || []).map(data => data.value),
    );
  }

  function handleThumbnailFile(files: File[]) {
    const [file] = files;
    form.update('thumbnailFile', file, true);
  }

  function handleAudioFile(files: File[]) {
    const [file] = files;
    form.update('audioFile', file, true);
    form.update('audioPath', file.name);
  }

  function handleRemoveAudioFile() {
    if (form.state.audioFile) {
      form.update('audioFile', null);
    } else {
      form.update('audioPath', null);
      form.update('audioUrl', null);
    }
  }

  function handleRemoveThumbnail() {
    if (form.state.thumbnailFile) {
      form.update('thumbnailFile', null);
    } else {
      form.update('thumbnailPath', null);
      form.update('thumbnailUrl', null);
    }
  }

  return (
    <MainModal
      dismissOutside={false}
      title={audio ? 'Edit audio' : 'New audio'}
      close={close}
    >
      <form onSubmit={handleSubmit}>
        <div className="modal__content">
          <div className="field">
            <TogglePin
              checked={form.state.status === 'published'}
              label="Published"
              toggle={() =>
                form.update(
                  'status',
                  form.state.status === 'published' ? 'draft' : 'published',
                )
              }
            />
            <p className="t-faded t-small">
              If the audio is not published, it will not appear to non-admin
              users.
            </p>
          </div>

          <div className="field">
            <Input
              label="Title"
              maxLength={100}
              onChange={value => form.update('title', value)}
              value={form.state.title}
            />

            {form.validation.title === false && (
              <p className="t-warning t-small">
                A title under 100 characters must be added.
              </p>
            )}
          </div>

          <div className="field">
            <p className="t-small t-faded field__lbl">Audio file</p>

            {form.state.audioFile || form.state.audioPath ? (
              <>
                <div className="dropzone__container">
                  <div className="dropzone__area dropzone__area-audio">
                    <button
                      type="button"
                      className="button button--unpadded button--ghost button--ghost-primary dropzone__remove-preview"
                      onClick={handleRemoveAudioFile}
                    >
                      <img src={IconClose} alt="&times;" />
                    </button>

                    <img src={IconHeadphones} alt="" />
                    <p className="t-small u-height--full">
                      {form.state.audioFile?.name || form.state.audioPath}
                    </p>
                  </div>
                </div>
                {form.validation.audioFile === false && (
                  <p className="t-small t-warning">
                    File must be an audio file under {MAX_AUDIO_SIZE / 1000000}{' '}
                    megabytes in size.
                  </p>
                )}
              </>
            ) : (
              <div className="dropzone__container">
                <div className="dropzone__label">
                  <p className="t-small t-faded field__lbl">
                    Select an audio file
                  </p>
                </div>

                <Dropzone
                  onDropAccepted={handleAudioFile}
                  onDropRejected={() =>
                    toast.error(
                      `Unsupported or invalid file type. File must be an audio type under ${MAX_AUDIO_SIZE /
                        1000000}MB.`,
                    )
                  }
                  multiple={false}
                  className="dropzone__area"
                  disablePreview={false}
                  accept="audio/mp3, audio/mpeg, audio/x-m4a"
                  maxSize={MAX_AUDIO_SIZE}
                />
              </div>
            )}

            {/** Validation of audio file and audio url is handled by the audio URL field. */
            form.validation.audioUrl === false && (
              <p className="t-warning t-small">An audio file must be added.</p>
            )}
          </div>

          <div className="field">
            <p className="t-small t-faded field__lbl">Categories</p>
            <Select
              placeholder="Select categories"
              classNamePrefix="react-select"
              options={categoryOptions}
              value={selectedCategories}
              onChange={value =>
                handleCategoryUpdate(value as SelectedOption[])
              }
              isMulti
              isSearchable
            />
            {!form.state.categoryIds.length && (
              <p className="t-small t-faded s-top--tny">
                Not selecting a category means the audio will only be found
                under "All" once published.
              </p>
            )}

            {isRestricted && (
              <p className="t-small t-faded s-top--tny">
                Some of the categories you selected have restricted access.
                Modify the access list by navigating to the category and using
                the "Manage access" control.
              </p>
            )}
          </div>

          <div className="field">
            <Input
              label="Author"
              maxLength={100}
              onChange={value => form.update('author', value)}
              value={form.state.author}
            />

            {form.validation.author === false && (
              <p className="t-warning t-small">
                An author name must be provided.
              </p>
            )}
          </div>

          <div className="field">
            <p className="t-small t-faded field__lbl">Full description</p>
            <ReactQuill
              theme="snow"
              value={form.state.description}
              onChange={value => form.update('description', value)}
            />

            {form.validation.description === false && (
              <p className="t-warning t-small">
                A description must be provided.
              </p>
            )}
          </div>

          <div className="field">
            <p className="t-small t-faded field__lbl">
              Short description (80 characters)
            </p>
            <textarea
              className="input t-base input--textarea input--textarea--sml"
              maxLength={100}
              value={form.state.shortDescription}
              onChange={({ currentTarget }) =>
                form.update('shortDescription', currentTarget.value)
              }
            />

            {form.validation.shortDescription === false && (
              <p className="t-warning t-small">
                A short description up to 80 characters in length must be
                provided.
              </p>
            )}
          </div>

          <p className="event-form__section">Thumbnail (optional)</p>
          <p className="t-small t-faded field__lbl">
            Upload an image that shows what the audio is about.
          </p>

          {form.state.thumbnailUrl || form.state.thumbnailFile ? (
            <>
              <div className="dropzone__container">
                <button
                  type="button"
                  onClick={handleRemoveThumbnail}
                  className="button button--ghost button--ghost-primary dropzone__remove-preview"
                >
                  <img src={IconClose} alt="&times;" />
                </button>
                <img
                  className="dropzone__area-image"
                  src={
                    form.state.thumbnailFile
                      ? URL.createObjectURL(form.state.thumbnailFile)
                      : form.state.thumbnailUrl || undefined
                  }
                  alt=""
                />
              </div>
              {form.validation.thumbnailFile === false && (
                <p className="t-small t-warning">
                  File must be an image under {MAX_THUMBNAIL_SIZE / 1000000}{' '}
                  megabytes in size.
                </p>
              )}
            </>
          ) : (
            <div className="dropzone__container">
              <div className="dropzone__label">
                <img src={IconDropzoneImage} alt="" />
                <p className="t-small t-faded s-top--sml">
                  Drop an image here to upload
                </p>
                <p className="t-secondary t-small">or select a file manually</p>
              </div>

              <Dropzone
                onDropAccepted={handleThumbnailFile}
                onDropRejected={() =>
                  toast.error(
                    `Unsupported or invalid file type. File must be an image type under ${MAX_THUMBNAIL_SIZE /
                      1000000}MB.`,
                  )
                }
                multiple={false}
                className="dropzone__area dropzone__area-image"
                disablePreview={false}
                accept="image/png, image/jpeg"
                maxSize={MAX_THUMBNAIL_SIZE}
              />
            </div>
          )}
        </div>

        <div className="modal__footer">
          <button
            disabled={loading}
            className="button button--primary button--medium"
          >
            Save
          </button>
          <button
            type="button"
            disabled={loading}
            className="button button--outline button--outline-primary button--medium"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </form>
    </MainModal>
  );
};
