import { callable } from 'modules/firebase';

interface ContactData {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

interface SendContactEmailParams {
  name: string;
  email: string;
  message: string;
}

export function useContact() {
  function sendContactEmail({
    firstName,
    lastName,
    email,
    message,
  }: ContactData) {
    const callableFn = callable<SendContactEmailParams, boolean>(
      'sendContactEmail',
    );

    return callableFn({ name: `${firstName} ${lastName}`, email, message });
  }

  return { sendContactEmail };
}
