import React from 'react';
import { Link } from 'react-router-dom';
import { stringToSlug } from 'modules/helpers';

interface Props
  extends Omit<AudioPlaylist | VideoPlaylist, 'audios' | 'videos'> {
  type: 'audio' | 'video';
  trackCount: number;
}

export const PlaylistItem: ReactFC<Props> = ({
  id,
  name,
  type,
  trackCount,
}) => {
  const isFavorites = id === 'favorites';
  const playlistType =
    type === 'audio' ? '/audio-playlists/' : '/video-playlists/';
  const url = `${playlistType}${stringToSlug(id)}`;

  return (
    <React.Fragment>
      <div
        className={`playlist s-top--med s-bottom--med ${
          isFavorites ? 'playlist--favorite' : ''
        }`}
      >
        <div className="playlist__header">
          <Link to={url}>
            <h1 className="t-bold t-primary t-epsilon">{name}</h1>
            <p className="t-zeta t-faded">
              {trackCount} {trackCount === 1 ? 'track' : 'tracks'}
            </p>
          </Link>
        </div>

        <Link to={url}>
          <div className="playlist__actions">
            <svg width="24" height="24">
              <path
                d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"
                fill="#6b727d"
              />
            </svg>
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
};
