import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'normalize.css';
import './css/app.css';
import { Router } from 'modules/navigation';
import { RecoilRoot } from 'recoil';
import { ListenerProvider } from 'modules/firebase';

// App itself
const App: ReactFC = () => {
  return (
    <RecoilRoot>
      <Router />
      <ListenerProvider />
    </RecoilRoot>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

serviceWorkerRegistration.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if ((event.target as any).state === 'activated') {
          window.location.reload();
        }
      });

      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
