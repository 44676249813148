import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AppRoute } from 'modules/navigation';

import { ACLType } from '../models';
import { Role } from '../enums';
import { useAuthentication } from 'modules/authentication';
import { PageLoading } from 'components';
import { useSubscription } from 'modules/subscription';

export const AccessControlElement: ReactFC<Omit<AppRoute, 'key'>> = ({
  Element,
  access,
  title,
}) => {
  const location = useLocation();
  const { user, isLoggedIn, loading: loadingAuth } = useAuthentication();
  const { subscription, loading: loadingSubscription } = useSubscription();

  useEffect(() => {
    document.title = `TeamMAK | ${title}`;
  }, []);

  if (loadingAuth || loadingSubscription) return <PageLoading />;

  const role = user?.role || Role.User;
  const subscriptionValid =
    subscription?.isLegacySubscription ||
    (subscription?.stripe?.subscriptionExpirationTime || 0) >= Date.now() ||
    (subscription?.apple?.subscriptionExpirationTime || 0) >= Date.now();

  // Redirect user to permission error page if anonymous user tries to access authenticated route
  if (
    !isLoggedIn &&
    access !== ACLType.AnonymousOnly &&
    access !== ACLType.Public
  ) {
    return <Navigate to="/login" />;
  }

  // // Redirect user to dashboard if he is on unauthorized error page but logged in
  // if (isLoggedIn && location.pathname === '/not-authorized') {
  //   return <Navigate to="/dashboard" />;
  // }

  // Redirect user to dashboard if authenticated user tries to access AnonymousOnly route
  if (isLoggedIn && access === ACLType.AnonymousOnly) {
    return <Navigate to="/dashboard" replace />;
  }

  // Redirect user if he accessed a route without the proper administrator authorization
  if (
    isLoggedIn &&
    access === ACLType.AdministratorOnly &&
    role !== Role.Administrator
  ) {
    return <Navigate to="/not-authorized" replace />;
  }

  // Redirect user if he accessed a route without the proper diamond leader authorization
  if (
    isLoggedIn &&
    access === ACLType.DiamondOnly &&
    role !== Role.DiamondLeader
  ) {
    return <Navigate to="/not-authorized" replace />;
  }

  // Redirect user if route is only accessible to admin and diamond roles and user is neither
  if (isLoggedIn && access === ACLType.DiamondAndAdmin) {
    if (role !== Role.DiamondLeader && role !== Role.Administrator) {
      return <Navigate to="/not-authorized" replace />;
    }
  }

  // Redirect user if route is only accessible to team leader roles and user is neither
  if (isLoggedIn && access === ACLType.TeamLeaders) {
    if (role !== Role.DiamondLeader && role !== Role.TeamLeader) {
      return <Navigate to="/not-authorized" replace />;
    }
  }

  // If route is not accessible to users and user has no admin privileges, redirect
  if (isLoggedIn && access === ACLType.TeamLeadersAndAdmin) {
    if (role === Role.User) {
      return <Navigate to="/not-authorized" replace />;
    }
  }

  // Redirect authenticated user to payment screen if he has not paid yet, or subscription is expired.
  // When payment is finished, add (subscriptionStatus !== SubscriptionStatus.Active) for check.
  if (isLoggedIn && access === ACLType.Authenticated) {
    // If subscription is valid and user is on the subscription screen, redirect to subscription success
    if (subscriptionValid && location.pathname === '/subscription') {
      return <Navigate to={'/subscription-success'} replace />;
    }

    // If subscription is invalid and user is not on the subscription screen, redirect to subscription screen
    if (
      !subscriptionValid &&
      location.pathname !== '/subscription' &&
      location.pathname !== '/manage-subscription' &&
      location.pathname !== '/profile'
    ) {
      return <Navigate to="/subscription" replace />;
    }
  }

  return <Element />;
};
