import React from 'react';

import { ValidatedInput, TogglerWithoutNav } from 'components';
import { TeamTypeEnum } from 'modules/teams';

interface Props {
  isCreatingTeam: boolean;
  typeSelect?: boolean;
  createTeamCallback: (type: TeamType, name: string) => void;
}

interface State {
  teamName: string;
  selectedTeamType?: string;
  [param: string]: string | boolean | undefined;
}

export class AddNewTeam extends React.Component<Props, State> {
  state = {
    teamName: '',
    selectedTeamType: TeamTypeEnum.Diamond,
  };

  getState = () => this.state;

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !nextProps.isCreatingTeam &&
      nextProps.isCreatingTeam !== this.props.isCreatingTeam
    ) {
      this.setState({ teamName: '' });
    }
  }

  handleSave = () => {
    const { selectedTeamType, teamName } = this.state;
    const { createTeamCallback } = this.props;

    if (!selectedTeamType) {
      alert('please select team type');
      return;
    }
    createTeamCallback(selectedTeamType, teamName);
    this.setState({
      teamName: '',
    });
  };

  onInputChange = (prop: string, value: string, isValid?: boolean) => {
    this.setState({
      [prop]: value,
      [`${prop}Valid`]: isValid,
    });
  };

  renderNewTeamNameInput = () => {
    const { teamName } = this.state;

    return (
      <React.Fragment>
        <ValidatedInput
          propName="teamName"
          value={teamName}
          changeCallback={this.onInputChange}
          label="Team name"
        />
      </React.Fragment>
    );
  };

  selectDiamond = () =>
    this.setState({ selectedTeamType: TeamTypeEnum.Diamond });
  selectPlatinum = () =>
    this.setState({ selectedTeamType: TeamTypeEnum.Platinum });

  renderTeamTypeSelect = () => {
    const { selectedTeamType } = this.state;
    return (
      <TogglerWithoutNav
        leftActive={selectedTeamType === TeamTypeEnum.Diamond}
        rightActive={selectedTeamType === TeamTypeEnum.Platinum}
        leftTitle="Diamond"
        rightTitle="Platiunm"
        onLeftClick={this.selectDiamond}
        onRightClick={this.selectPlatinum}
      />
    );
  };

  render() {
    const { typeSelect } = this.props;
    const { teamName } = this.state;

    return (
      <React.Fragment>
        <div className="modal__content">
          {typeSelect && this.renderTeamTypeSelect()}
          {this.renderNewTeamNameInput()}
        </div>
        <div className="modal__footer">
          <button
            className="button  modal__submit"
            onClick={this.handleSave}
            disabled={!teamName.length}
          >
            Create
          </button>
        </div>
      </React.Fragment>
    );
  }
}
