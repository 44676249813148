export const createStripeOptions = () => {
    return {
        style: {
            base: {
                // tslint:disable-next-line:object-literal-key-quotes
                fontSize: '16px',
                // tslint:disable-next-line:object-literal-key-quotes
                fontFamily: 'Open Sans, sans-serif',
                // tslint:disable-next-line:object-literal-key-quotes
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#f44336'
            }
        }
    };
};
