import React from 'react';

import { Hero, Services, MembershipOptions } from 'modules/home';

export class Home extends React.Component {
  render() {
    return (
      <section className="home">
        <Hero />

        <Services />

        <MembershipOptions />

        {/* <Testimonials /> */}
      </section>
    );
  }
}
