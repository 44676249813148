import { useState } from 'react';
import { useStripe } from '../hooks';
import { ConfirmModal, Loading } from 'components';

interface Props {
  canCancel: boolean;
  customerId?: string;
}

export const StripeCancelSubscription: ReactFC<Props> = ({
  canCancel,
  customerId,
}) => {
  const [isModal, setIsModal] = useState(false);

  const { loading, cancelStripeSubscription } = useStripe();

  function toggleCancelModal() {
    setIsModal(!isModal);
  }

  async function confirmCancelSubscription() {
    const localeDate = new Date().toLocaleString();
    setIsModal(false);
    return await cancelStripeSubscription(localeDate);
  }

  if (!customerId || !canCancel) return null;

  if (loading) return <Loading withoutContainer />;

  return (
    <>
      <button
        className="button button--warning button--medium"
        onClick={toggleCancelModal}
        disabled={loading}
      >
        Cancel subscription
      </button>

      {isModal && (
        <ConfirmModal
          confirm={confirmCancelSubscription}
          description="Cancelling your subscription will stop future billing. Your current subscription will remain active until the end of the billing period."
          title="Are you sure you wish to cancel your subscription?"
          close={toggleCancelModal}
        />
      )}
    </>
  );
};
