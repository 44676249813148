import React, { useEffect, useState } from 'react';
import { ConfirmModal, Loading } from 'components';
import { useStripe } from '../hooks';
import { toast } from 'react-toastify';

interface Props {
  canResubscribe: boolean;
  customerId?: string;
}

export const StripeResubscribe: ReactFC<Props> = ({
  canResubscribe,
  customerId,
}) => {
  const { loading, renewStripeSubscription } = useStripe();
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [renewing, setRenewing] = useState(false);

  useEffect(() => {
    if (!renewing) return;
    const timeout = setTimeout(() => setRenewing(false), 15000);
    return () => clearTimeout(timeout);
  }, [renewing]);

  function toggleRenewModal() {
    setShowRenewModal(!showRenewModal);
  }

  async function confirmSubscriptionRenewal() {
    setShowRenewModal(false);
    if (!customerId) return;

    try {
      setRenewing(true);
      await renewStripeSubscription(customerId);
    } catch (error) {
      toast.error(
        'Failed to renew your subscription. Try with a different card.',
      );
    } finally {
      setRenewing(false);
    }
  }

  if (!customerId || !canResubscribe) {
    return null;
  }

  if (renewing) return <Loading withoutContainer />;

  return (
    <>
      <button
        className="button button--medium button--primary s-bottom--lrg"
        disabled={loading || renewing}
        onClick={toggleRenewModal}
      >
        Renew subscription
      </button>
      {showRenewModal && (
        <ConfirmModal
          confirm={confirmSubscriptionRenewal}
          description="You are about to renew your subscription to TeamMAK. Your subscription will continue on a monthly basis. If you wish to stop, you can cancel your subscription at any time. You will also be charged the price of one month of subscription."
          title="Renew subscription"
          close={toggleRenewModal}
        />
      )}
    </>
  );
};
