import { useAuthentication } from 'modules/authentication';
import { Collection, FirestoreService } from 'modules/firebase';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { SubscriptionAtoms } from '../recoil';

const UserInfoCollection = new FirestoreService<CustomUserInfo>(
  Collection.UserInfo,
);

export function useSubscriptionListener() {
  const { user } = useAuthentication();
  const setSubscription = useSetRecoilState(SubscriptionAtoms.subscription);
  const setLoading = useSetRecoilState(SubscriptionAtoms.loading);

  /** Set to loading only on first mount to avoid redirects and issues when resetting to default value. */
  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (!user?.id) return;
    setLoading(true);

    const listener = UserInfoCollection.listenToId<UserSubscription>({
      path: `${user?.id}/private/subscription`,
      onSuccess: setSubscription,
      onError: error => console.log('Error loading subscription info: ', error),
      onFinished: () => setLoading(false),
    });

    return listener;
  }, [user?.id]);
}
