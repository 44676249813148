interface Props {
  icon?: string;
}

export const DashboardToolbarStatsItem: ReactFC<Props> = ({
  icon,
  children,
}) => {
  return (
    <div className="dashboard-toolbar__item">
      {icon && <img src={icon} alt="toolbar-item" />}
      {children}
    </div>
  );
};
