import { AudioInfoForm } from './AudioInfoForm';

export interface AudioInfoConstructor extends AudioInfo {}
export class AudioInfoConstructor {
  constructor(
    data: AudioInfoForm,
    audio: FileReference | null,
    thumbnail: FileReference | null,
  ) {
    this.title = data.title;
    this.normalizedTitle = data.title.toUpperCase();
    this.description = data.description;
    this.shortDescription = data.shortDescription;
    this.author = data.author;
    this.categoryIds = data.categoryIds;
    this.status = data.status;

    /** Should never be an empty string provided FE validation is OK. If you get empty audioPath or audioUrl in the DB, check AudioForm validation. */
    this.audioPath = audio?.path || data.audioPath || '';
    this.audioUrl = audio?.url || data.audioUrl || '';

    this.thumbnailPath = thumbnail?.path || data.thumbnailPath;
    this.thumbnailUrl = thumbnail?.url || data.thumbnailUrl;
  }
}
