import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {
  AuthService,
  callable,
  Collection,
  FirebaseService,
  FirestoreService,
} from 'modules/firebase';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  RegistrationData,
  UpdateProfileData,
  UserProfileConstructor,
  UserProfileUpdateConstructor,
} from '../models';
import { AuthAtoms } from '../recoil';

const Auth = new AuthService();
const UserInfoCollection = new FirestoreService<CustomUserInfo>(
  Collection.UserInfo,
);

export function useAuthentication() {
  const user = useRecoilValue(AuthAtoms.user);
  const isLoggedIn = useRecoilValue(AuthAtoms.isLoggedIn);
  const [loading, setLoading] = useRecoilState(AuthAtoms.loading);

  const loginAsync = async (
    email: string,
    password: string,
    rememberMe: boolean,
  ) => {
    try {
      setLoading(true);
      return await Auth.loginWithEmailAndPasswordAsync(
        email,
        password,
        rememberMe,
      );
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const registerAsync = async (data: RegistrationData) => {
    try {
      setLoading(true);
      const credential = await Auth.registerWithEmailAndPasswordAsync(data);
      const userInfo = new UserProfileConstructor(credential, data);
      await UserInfoCollection.set(userInfo.id, userInfo);
      await UserInfoCollection.set(`${userInfo.id}/private/subscription`, {
        isLegacySubscription: false,
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const sendResetEmail = async (email: string) => {
    await Auth.sendPasswordResetEmail(email);
  };

  const updateUserEmail = async (oldEmail: string, newEmail: string) => {
    const callableFn = callable<
      { oldEmail: string; newEmail: string },
      { success: true; payload: string } | { success: false; error: string }
    >('updateUserEmail');

    const response = await callableFn({ oldEmail, newEmail });
    return response.data;
  };

  const updateProfile = async (id: string, data: UpdateProfileData) => {
    const { avatar, email, oldEmail } = data;
    const shouldUpdateEmail = email !== oldEmail;

    if (avatar) {
      const reference = ref(
        FirebaseService.Storage,
        `avatars/${id}/${avatar.name}`,
      );

      await uploadBytes(reference, avatar);
      const photoURL = await getDownloadURL(reference);
      UserInfoCollection.set(id, { photoURL });
    }

    const updatedProfile = new UserProfileUpdateConstructor(data);
    await UserInfoCollection.set(id, updatedProfile);

    if (shouldUpdateEmail) {
      setLoading(true);
      await updateUserEmail(data.oldEmail, data.email);
      setLoading(false);
      Auth.logoutAsync();
    }
  };

  const changePassword = async (
    currentPassword: string,
    newPassword: string,
  ) => {
    const user = Auth.auth.currentUser!;
    if (!user || !user.email) {
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword,
      );

      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      Auth.logoutAsync();
    } catch (error) {
      throw error;
    }
  };

  const checkEmailExist = async (email: string) => {
    email = email.trim();

    let result = await fetchSignInMethodsForEmail(Auth.auth, email);

    // Check for lowercased user email just in case.
    if (!result || !result.length) {
      result = await fetchSignInMethodsForEmail(Auth.auth, email.toLowerCase());
    }

    return Boolean(result?.length);
  };

  return {
    user,
    loading,
    isLoggedIn,
    loginAsync,
    logoutAsync: Auth.logoutAsync,
    registerAsync,
    changePassword,
    updateProfile,
    updateUserEmail,
    sendResetEmail,
    checkEmailExist,
  };
}
