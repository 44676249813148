import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SeekBar } from 'modules/audios';
import { DivWithBackgroundImage } from 'components';
import IconPrevious from 'assets/icons/audio-previous.svg';
import IconNext from 'assets/icons/audio-next.svg';
import IconPlay from 'assets/icons/audio-play.svg';
import IconPause from 'assets/icons/audio-pause.svg';
import IconForward from 'assets/icons/audio-forward.svg';
import IconBackward from 'assets/icons/audio-backward.svg';
import { useAudioPlayer } from '../hooks';
import { useAudioStats } from 'modules/audio-stats';

interface Props {
  playerIsExpanded: boolean;
  isPlaylist?: boolean;
  audio: AudioInfo;
  favoriteButton: JSX.Element | null;
  playlistButton: JSX.Element | null;
  downloadButton: JSX.Element | null;
  expandButton: JSX.Element;
}

type Speed = 1 | 1.25 | 1.5 | 1.75 | 2.0;

const playbackRates: Speed[] = [1, 1.25, 1.5, 1.75, 2.0];

/** Initialise audio element to control with component. */
let PLAYER = new Audio();

export const AudioPlayerCore: React.FC<Props> = ({
  audio,
  isPlaylist,
  ...props
}) => {
  const [speed, setSpeed] = useState<Speed>(playbackRates[0]);
  /** State updater so we can get the correct player state (current time, duration) at all times. */
  const [refresher, setRefresher] = useState<number>(0);
  const { stats, onTrackCompleted, updatePlayProgress } = useAudioStats();
  const {
    showNext,
    showPrevious,
    playing,
    next,
    previous,
    play,
    pause,
    close,
  } = useAudioPlayer();

  const player = useMemo(() => {
    /** Get last played state from audio stats. */
    const last = stats.find(s => s.trackId === audio.id);
    /** Unset source to stop loading previous value */
    PLAYER.src = '';
    /** Load new audio URL */
    PLAYER.src = audio.audioUrl;
    PLAYER.onplay = play;
    PLAYER.onpause = pause;
    PLAYER.onended = onEnd;
    PLAYER.title = `${audio.title} by ${audio.author}`;
    PLAYER.currentTime = last?.secondsListened || 0;
    PLAYER.autoplay = true;

    return PLAYER;
  }, [audio]);

  const updateProgressCallback = useCallback(
    (currentTime: number, duration: number) => {
      const percent = (currentTime / duration) * 100;
      if (percent >= 99) onTrackCompleted(audio);
      else updatePlayProgress(audio, percent, currentTime);
      /** Refresher needs to be a dependency so we can get in-time player state, since it's state updates do not refresh the component. */
    },
    [audio],
  );

  async function onEnd() {
    onTrackCompleted(audio);
    next();
  }

  function handlePlay() {
    player.play();
  }

  function handlePause() {
    player.pause();
  }

  function handleClose() {
    updateProgressCallback(player.currentTime, player.duration);
    close();
    player.pause();
    player.src = '';
  }

  function handleNext() {
    updateProgressCallback(player.currentTime, player.duration);
    next();
  }

  function handlePrevious() {
    updateProgressCallback(player.currentTime, player.duration);
    previous();
  }

  function seekTo(position: number) {
    if (!player) return;
    player.currentTime = position;
    updateProgressCallback(position, player.duration);
  }

  function skipForwards() {
    if (!player) return;

    if (player.duration - player.currentTime > 15) {
      player.currentTime += 15;
    } else {
      player.currentTime = player.duration;
    }
    updateProgressCallback(player.currentTime, player.duration);
  }

  function skipBackwards() {
    if (!player) return;

    if (player.currentTime > 15) {
      player.currentTime -= 15;
    } else {
      player.currentTime = 0;
    }

    updateProgressCallback(player.currentTime, player.duration);
  }

  /** Refresh state every second so current time updates.  */
  useEffect(() => {
    const interval = setInterval(() => setRefresher(+new Date()), 500);
    return () => clearInterval(interval);
  }, []);

  /** Update playback rate when speed changes */
  useEffect(() => {
    if (!player) return;
    player.playbackRate = speed;
  }, [speed, player]);

  useEffect(() => {
    updateProgressCallback(player.currentTime, player.duration);
  }, [playing]);

  /** Navigator controls metadata load */
  useEffect(() => {
    navigator.mediaSession.metadata = new MediaMetadata({
      artist: audio.author,
      title: audio.title,
      album: '',
      artwork: [
        {
          src:
            audio.thumbnailUrl ||
            require('assets/images/placeholder-square.png'),
          sizes: '80x80',
          type: 'image/*',
        },
      ],
    });
  }, [audio]);

  /** Play / pause controls */
  useEffect(() => {
    navigator.mediaSession.setActionHandler('play', handlePlay);
    navigator.mediaSession.setActionHandler('pause', handlePause);
  }, [handlePlay, handlePause]);

  useEffect(() => {
    if (!player) return;
    const { duration, currentTime, playbackRate } = player;

    if (!duration || duration < currentTime) return;

    navigator.mediaSession.setPositionState({
      // Duration must always be positive and larger than the current position
      duration: duration,
      // Position can never be a negative value, so fallback to 0 if value is lower than 0
      position: currentTime,
      playbackRate: playbackRate,
    });
    /** @param refresher must be a dependency because that's triggering the state update, the @param player reference does not refresh the component. Once the updater does it's thing, you can read the actual value from @param player */
  }, [refresher]);

  /** Previous / skip backwards controls */
  useEffect(() => {
    navigator.mediaSession.setActionHandler(
      'previoustrack',
      showPrevious ? handlePrevious : null,
    );

    navigator.mediaSession.setActionHandler(
      'seekbackward',
      showPrevious ? null : skipBackwards,
    );
  }, [showPrevious, handlePrevious, skipBackwards]);

  /** Next / skip forwards controls */
  useEffect(() => {
    navigator.mediaSession.setActionHandler(
      'nexttrack',
      showNext ? handleNext : null,
    );

    navigator.mediaSession.setActionHandler(
      'seekforward',
      showNext ? null : skipForwards,
    );
  }, [showNext, handleNext, skipForwards]);

  const renderSeekBar = () => {
    if (!player) return;

    return (
      <SeekBar
        position={player.currentTime}
        duration={player.duration}
        onSlide={seekTo}
      />
    );
  };

  const renderPlaybackRates = () => (
    <>
      <div className="player__naration f f--gap-small">
        {playbackRates.map(rate => {
          return (
            <button
              onClick={() => setSpeed(rate)}
              key={rate}
              className={`button button--unpadded button--ghost player__naration-speed ${
                speed === rate
                  ? 'player__naration-speed--active'
                  : 'player__naration-speed--inactive'
              }`}
            >
              {rate.toFixed(2)}x
            </button>
          );
        })}
      </div>
      <p className="player__naration-label u-opacity--60">Narration speed</p>
    </>
  );

  const renderControls = () => {
    return (
      <React.Fragment>
        <div className="player__controls">
          <button
            disabled={!showPrevious}
            className="button button--unpadded"
            onClick={handlePrevious}
          >
            <img
              className=""
              style={{ opacity: showPrevious ? 1 : 0.3 }}
              src={IconPrevious}
              alt="Previous"
            />
          </button>
          <button className="button button--unpadded" onClick={skipBackwards}>
            <img className="" src={IconBackward} alt="Skip back" />
          </button>
          {player?.paused ? (
            <button className="button button--unpadded" onClick={handlePlay}>
              <img className="" src={IconPlay} alt="Play button" />
            </button>
          ) : (
            <button className="button button--unpadded" onClick={handlePause}>
              <img className="" src={IconPause} alt="Pause button" />
            </button>
          )}
          <button className="button button--unpadded" onClick={skipForwards}>
            <img className="" src={IconForward} alt="Skip forward" />
          </button>
          <button
            disabled={!showNext}
            className="button button--unpadded"
            onClick={handleNext}
          >
            <img
              className=""
              style={{ opacity: showNext ? 1 : 0.3 }}
              src={IconNext}
              alt="Next"
            />
          </button>
        </div>
        {renderPlaybackRates()}
      </React.Fragment>
    );
  };

  const {
    favoriteButton,
    expandButton,
    downloadButton,
    playlistButton,
    playerIsExpanded,
  } = props;

  return (
    <>
      <div className="persistent-player">
        <div
          className={`${
            playerIsExpanded
              ? 'persistent-player__player persistent-player__player--expanded'
              : 'persistent-player__player'
          }`}
        >
          {renderSeekBar()}
          <div className="player">
            <div className="player__info">
              <DivWithBackgroundImage
                fallbackImageUrl={require('assets/images/placeholder-member.jpg')}
                mainImageUrl={audio.thumbnailUrl}
                classes="player__visual"
              />
              <div className="player__copy">
                <h3 className="player__title t-bold">{audio && audio.title}</h3>
                <p className="player__author t-bold u-opacity--40">
                  by {audio ? audio.author : 'Unknown'}
                </p>
              </div>
            </div>
            <div className="player__all-controls">{renderControls()}</div>
            <div className="player__actions">
              {favoriteButton}
              {playlistButton}
              {downloadButton}
              <button
                className="button button--ghost button--unpadded"
                onClick={handleClose}
              >
                <svg width="16" height="17">
                  <g
                    transform="translate(0 .4161)"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <circle fill="#31263A" opacity=".2" cx="8" cy="8" r="8" />
                    <g
                      stroke="#FFF"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M11.5 4.5l-7 7" />
                      <path d="M4.5 4.5l7 7" />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          {expandButton}
        </div>
      </div>
    </>
  );
};
