import { atom } from 'recoil';

const playlist = atom<string[]>({
  key: 'video-player-ids',
  default: [],
  dangerouslyAllowMutability: true,
});

const playlistIndex = atom<number | undefined>({
  key: 'video-player-index',
  default: undefined,
});

export const VideoPlayerAtoms = {
  playlist,
  playlistIndex,
};
