import { where } from 'firebase/firestore';
import {
  Collection,
  FirestoreService,
  useFirebaseStorage,
} from 'modules/firebase';
import moment from 'moment';
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { EventAtoms } from '../recoil';
import { toast } from 'react-toastify';
import { EventInfoConstructor, EventInfoForm } from '../models';
import { useAuthentication } from 'modules/authentication';
import { Role } from 'modules/authorization';

const EventsCollection = new FirestoreService<EventInfo>(Collection.Events);

export function useEvents() {
  const { user } = useAuthentication();
  const { uploadFile } = useFirebaseStorage();
  const [loading, setLoading] = useRecoilState(EventAtoms.loading);
  const [pastEvents, setPastEvents] = useRecoilState(EventAtoms.pastEvents);
  const events = useRecoilValue(EventAtoms.events);
  const allEvents = useMemo(
    () =>
      [...events, ...pastEvents].sort((a, b) =>
        a.startTimestamp < b.startTimestamp ? -1 : 1,
      ),
    [pastEvents, events],
  );

  function isSoldOut(event: EventInfo) {
    return event.ticketsSold >= event.numberOfTickets;
  }

  function isTicketPurchaseEnabled(event: EventInfo) {
    return (
      event.status === 'published' &&
      event.cutoffTimestamp >= Date.now() &&
      !isSoldOut(event)
    );
  }

  async function loadPastEvents() {
    if (pastEvents.length) return;

    try {
      setLoading(true);
      /** Set end date 1 day in the past so there is enough buffer to show tickets. */
      const endTimestamp = moment()
        .add({ days: -1 })
        .toDate().getTime();

      const documents = await EventsCollection.get(
        user?.role === Role.Administrator
          ? [where('endTimestamp', '<=', endTimestamp)]
          : [
              where('status', '==', 'published'),
              where('endTimestamp', '<=', endTimestamp),
            ],
      );

      setPastEvents(documents);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function publishEvent(event: EventInfo) {
    try {
      toast.info('Publishing event', { toastId: 'event-publish' });

      await EventsCollection.set(event.id, { status: 'published' });

      toast.dismiss('event-publish');
      toast.success('Video has been published.');
    } catch (error) {
      console.error(error);
      toast.dismiss('event-publish');
      toast.error(
        error instanceof Error ? error.message : 'Something went wrong.',
      );
    }
  }

  async function addEvent(data: EventInfoForm) {
    const featuredImage = data.featuredImageFile
      ? await uploadFile('thumbnails', data.featuredImageFile)
      : null;

    const document = new EventInfoConstructor(data, featuredImage);
    document.timeCreated = Date.now();
    document.ticketsSold = 0;

    return EventsCollection.add(document);
  }

  async function updateEvent(id: string, data: EventInfoForm) {
    const featuredImage = data.featuredImageFile
      ? await uploadFile('audios', data.featuredImageFile)
      : null;

    const document = new EventInfoConstructor(data, featuredImage);
    return EventsCollection.set(id, document);
  }

  return {
    allEvents,
    loading,
    events,
    pastEvents,
    loadPastEvents,
    publishEvent,
    addEvent,
    updateEvent,
    isTicketPurchaseEnabled,
    isSoldOut,
  };
}
