import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { InputDate, Loading, ScrollingContent, Search } from 'components';
import {
  EventItem,
  filterEvents,
  useEvents,
  useEventForm,
} from 'modules/events';
import IconEmptyStateEvents from 'assets/empty-states/empty-state-event.svg';
import IconHistory from 'assets/icons/revert-time-secondary.svg';
import { Role, RoleCheck } from 'modules/authorization';
import { useUrlState } from 'hooks';

export const Events: ReactFC = () => {
  const location = useLocation();
  const { open } = useEventForm();
  const { loading, events, pastEvents, loadPastEvents } = useEvents();
  const [query, setQuery] = useUrlState('search', '');
  const [from, setFrom] = useUrlState('from', '');
  const [to, setTo] = useUrlState('to', '');

  const isEventsHistory = location.pathname.includes('/events-history');
  const currentEvents = isEventsHistory ? pastEvents : events;

  useEffect(() => {
    if (isEventsHistory) loadPastEvents();
  }, [isEventsHistory]);

  const renderEvents = () => {
    const filteredEvents = filterEvents(
      currentEvents,
      from ? +new Date(from) : null,
      to ? +new Date(to) : null,
      query,
    );

    if (loading) {
      return (
        <div className="wrapper">
          <Loading />
        </div>
      );
    }

    if (!filteredEvents || !filteredEvents.length) {
      return (
        <div className="wrapper">
          <div className="empty-state">
            <img
              className="empty-state__visual"
              alt="empty"
              src={IconEmptyStateEvents}
            />
            <p className="empty-state__label">
              There are no events available at the moment. Please check back
              later again.
            </p>
          </div>
        </div>
      );
    }

    return (
      <ScrollingContent
        data={filteredEvents}
        renderItem={event => (
          <EventItem
            key={event.id}
            event={event}
            isPastEvent={isEventsHistory}
          />
        )}
      />
    );
  };

  return (
    <>
      <section className="content-page">
        <div className="wrapper">
          <div className="content-page__heading-container">
            <h1 className="content-page__title">
              {isEventsHistory ? 'Past events' : 'Events'}
            </h1>
          </div>
          <div className="content-page__controls">
            <Search
              placeholder="Search events by name"
              changeCallback={setQuery}
              query={query}
            />

            <div className="content-page__controls-actions">
              <RoleCheck role={Role.Administrator}>
                <div className="f f--gap">
                  <button
                    onClick={open}
                    type="button"
                    className="button button--primary button--medium"
                  >
                    + New event
                  </button>
                </div>
              </RoleCheck>
              {!isEventsHistory && (
                <Link
                  className="button button--ghost button--ghost-primary button--medium"
                  to="/events-history"
                >
                  <img src={IconHistory} alt="" />
                  View history
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="content-page__body">
        <div className="wrapper">
          <div className="f f--gap s-bottom--lrg">
            <InputDate
              onChange={setFrom}
              value={from}
              type="date"
              label="Date from"
              placeholder="Select date"
            />

            <InputDate
              label="Date to"
              type="date"
              value={to}
              onChange={setTo}
              placeholder="Select date"
            />
          </div>
          {renderEvents()}
        </div>
      </section>
    </>
  );
};
